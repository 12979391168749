import React, { Fragment } from 'react';
import Button from '../../Buttons/Button/Button';

const FileUpload = (props) => {
    // Create a reference to the hidden file input element
    const hiddenFileInput = React.useRef(null);

    // Programatically click the hidden file input element when the Button component is clicked
    const handleClick = event => {
        hiddenFileInput.current.click();
    };
    // TODO: Kill dead code deaderer
    // Call a function (passed as a prop from the parent component) to handle the user-selected file 
    // const handleChange = event => {
    //     // const fileUploaded = event.target.files[0];
    //     props.onChange(event.target.files);
    //     // props.setUploadFile(event.target.files);
    //     event.target.value = '';
    // };
    return (
        <Fragment>
            <Button onClick={handleClick}>
                Upload a file
            </Button>
            <input
                type="file"
                multiple
                ref={hiddenFileInput}
                onChange={props.onChange}
                style={{ display: 'none' }}
            />
        </Fragment>
    );
}
export default React.memo(FileUpload);