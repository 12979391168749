import React from "react";
import {Autocomplete} from "@material-ui/lab";
import {TextField} from "@material-ui/core";
// import {InputAdornment, TextField, Tooltip} from "@material-ui/core";
// import {Tune} from "@material-ui/icons";
import PropTypes from "prop-types";
import {removeDuplicatesFromArray, removeNullUndefinedEmptyFromArray} from "../../../assets/helpers/globals";

const FilterAutocomplete = ({ value, options, onChange, placeholder, noOptionsText }) => {
    const formattedOptions = removeNullUndefinedEmptyFromArray(removeDuplicatesFromArray(options))

    return <Autocomplete
        id={`filter-select-${placeholder.toLowerCase()}`}
        options={formattedOptions.some(value => typeof value === "object" ) ? formattedOptions.sort((a,b) => a.description.localeCompare(b.description)) : formattedOptions.sort()}
        autoHighlight
        fullWidth
        getOptionLabel={(option) => (typeof option === 'string' ? option : option.description)}
        autoComplete
        value={value}
        noOptionsText={noOptionsText}
        onChange={onChange}
        getOptionSelected={(option, value) => (typeof option === 'string' ? option === value : option.description === value.description)}
        renderInput={(params) => (
            <TextField
                {...params}
                // placeholder={placeholder}
                fullWidth
                label={placeholder}
                size={"small"}
                InputProps={{
                    ...params.InputProps,
                    style: {fontSize: "inherit"},
                    // startAdornment: (
                    //     <InputAdornment position="start">
                    //         <Tooltip title={`Filter ${placeholder}`}>
                    //             <Tune/>
                    //         </Tooltip>
                    //     </InputAdornment>
                    // )
                }}
            />
        )}
    />
}

FilterAutocomplete.propTypes = {
    options: PropTypes.array.isRequired,
    value: PropTypes.any,
    onChange: PropTypes.func.isRequired,
    placeholder: PropTypes.string,
};

export default FilterAutocomplete;