//layout
import DefaultLayout from "../../hoc/Layouts/Default/DefaultLayout";
import AuthLayout from "../../hoc/Layouts/Auth/AuthLayout";
//component
import Login from "../../containers/Login/Login";
import Logout from "../../containers/Logout/Logout";
import Boardrooms from "../../containers/Admin/Boardrooms/Boardrooms";
import ProjectStatus from "../../containers/ProjectStatus/index";
import CurrentEPCTenders from "../../containers/Tenders/EPC/CurrentEPCTenders";
import CurrentEPCMTenders from "../../containers/Tenders/EPCM/CurrentEPCMTenders";
import CurrentProductTenders from "../../containers/Tenders/Product/CurrentProductTenders";
import Invoices from "../../containers/Invoices";
import Approvals from "../../containers/Purchases/Approvals";
import PurchaseOrders from "../../containers/Purchases/PurchaseOrders"
import ITSupport from "../../containers/ITSupport";
import AuditLog from "../../containers/Audit";
import Devs from "../../containers/Devs";
// import NotificationsIcon from "@material-ui/icons/Notifications";
// import Dashboard from '../../containers/Dashboard/Dashboard';
// import Notifications from "../../containers/Notifications/Notifications";
// import Vehicles from "../../containers/Admin/Vehicles/Vehicles";
// import CurrentEPCProjects from "../../containers/Projects/EPC/CurrentEPCProjects";
// import CurrentEPCMProjects from "../../containers/Projects/EPCM/CurrentEPCMProjects";
// import CurrentAssets from "../../containers/AssetAndFleetManagement/CurrentList/CurrentList";
// import AssetRequests from "../../containers/AssetAndFleetManagement/Requests/Requests";

//icons
import AdminIcon from "../../components/Icons/AdminIcon";
import ApprovalsIcon from "../../components/Icons/ApprovalsIcon";
import PurchaseOrderIcon from "../../components/Icons/PurchaseOrderIcon";
import DNAIcon from "../../components/Icons/DNAIcon";
import AuditLogIcon from "../../components/Icons/AuditLogIcon";
import BookingIcon from "../../components/Icons/BookingIcon";
import BoardroomIcon from "../../components/Icons/BoardroomIcon";
import InvoiceIcon from "../../components/Icons/InvoiceIcon";
import PurchaseIcon from "../../components/Icons/PurchaseIcon";
import ProjectIcon from "../../components/Icons/ProjectIcon";
import TenderIcon from "../../components/Icons/TenderIcon";
import LogoutIcon from "../../components/Icons/LogoutIcon";
import ItSupportIcon from "../../components/Icons/ItSupportIcon";
import FingerprintIcon from "@material-ui/icons/Fingerprint";
import ProjectRegister from "../../containers/ProjectRegister";
import TenderRegister from "../../containers/TenderRegister";
// import DashboardIcon from '@material-ui/icons/Dashboard';
// import AssignmentIcon from "@material-ui/icons/Assignment";
// import DateRangeIcon from "@material-ui/icons/DateRange";
// import DirectionsWalkIcon from "@material-ui/icons/DirectionsWalk";
// import EventAvailableIcon from "@material-ui/icons/EventAvailable";
// import DriveEtaIcon from "@material-ui/icons/DriveEta";

const allRoutes = [
	{
		id: 1,
		layout: DefaultLayout,
		layoutName: "DefaultLayout",
		paths: [
			"/app",
			"/app/test",
			"/app/admin/users",
			"/app/admin/users/user",
			"/app/notifications",
			"/app/admin/boardrooms",
			"/app/admin/bookings/vehicles",
			"/app/projects/epc/current",
			"/app/projects/epc/status",
			"/app/projects/epcm/current",
			"/app/projects/epcm/current",
			"/app/tenders/epcm/current",
			"/app/tenders/epc/current",
			"/app/tenders/product/current",
			"/app/admin/assets/requests",
			"/app/admin/assets/current",
			"/app/admin/invoices",
			"/app/admin/support",
			"/app/admin/purchases/approvals",
			"/app/admin/purchases/purchaseOrders",
			"/app/auditLog",
			"/app/development",
			"/app/projects/register",
			"/app/tenders/register"
		],
		routes: [
			// {
			//     id: 1,
			//     title: "Dashboard",
			//     path: "/app",
			//     component: Dashboard,
			//     icon: DashboardIcon,
			//     areas: ["sidebar"]
			// },
			// {
			// 	id: 2,
			// 	title: "Notifications",
			// 	path: "/app/notifications",
			// 	component: Notifications,
			// 	icon: NotificationsIcon,
			// 	areas: [],
			// },
			{
				id: 1,
				collapse: true,
				title: "Admin",
				path: "/app/admin",
				icon: AdminIcon,
				areas: ["sidebar"],
				collapseSub: "admin",
				views: [
					{
						id: 2,
						title: "DNA",
						external: true,
						path: "https://dna.payus.co.za/",
						icon: DNAIcon,
					},
					{
						id: 3,
						collapse: true,
						title: "Bookings",
						path: "/app/admin/bookings",
						icon: BookingIcon,
						collapseSub: "bookings",
						views: [
							{
								id: 4,
								title: "Boardrooms",
								path: "/app/admin/boardrooms",
								component: Boardrooms,
								icon: BoardroomIcon,
							},
							// {
							// 	id: 5,
							// 	title: "Vehicles",
							// 	path: "/app/admin/bookings/vehicles",
							// 	component: Vehicles,
							// 	icon: DriveEtaIcon,
							// },
						],
					},
					// {
					// 	id: 6,
					// 	collapse: true,
					// 	title: "Assets & Fleet",
					// 	icon: AssignmentIcon,
					// 	path: "/app/assets",
					// 	collapseSub: "assets	",
					// 	views: [
					// 		{
					// 			id: 7,
					// 			title: "Current List",
					// 			path: "/app/assets/current",
					// 			component: CurrentAssets,
					// 		},
					// 		{
					// 			id: 8,
					// 			title: "Requests",
					// 			path: "/app/assets/requests",
					// 			component: AssetRequests,
					// 		},
					// 	],
					// },
					{
						id: 5,
						title: "Invoices",
						icon: InvoiceIcon,
						path: "/app/admin/invoices",
						component: Invoices
					},
					{
						id: 6,
						collapse: true,
						title: "Purchases",
						path: "/app/admin/purchases",
						areas: ["sidebar"],
						collapseSub: "purchases",
						icon: PurchaseIcon,
						views: [
							{
								id: 7,
								title: "Approvals",
								icon: ApprovalsIcon,
								path: "/app/admin/purchases/approvals",
								component: Approvals
							},
							{
								id: 8,
								title: "Orders",
								icon: PurchaseOrderIcon,
								path: "/app/admin/purchases/purchaseOrders",
								component: PurchaseOrders
							},
						]
					},
					{
						id: 9,
						title: "IT Support",
						path: "/app/admin/support",
						component: ITSupport,
						icon: ItSupportIcon,
					},
				],
			},
			{
				id: 10,
				collapse: true,
				title: "Projects",
				path: "/app/projects",
				icon: ProjectIcon,
				areas: ["sidebar"],
				collapseSub: "projects",
				views: [
					{
						// id: 13,
						// collapse: true,
						// title: "EPC",
						// path: "/app/projects/epc",
						// collapseSub: "projectsepc",
						// views: [
							// {
							// 	id: 14,
							// 	title: "Current",
							// 	path: "/app/projects/epc/current",
							// 	component: CurrentEPCProjects,
							// },
							// {
								id: 11,
								title: "EPC",
								path: "/app/projects/epc/status",
								component: ProjectStatus,
						// 	},
						// ],
					},
					{
						id: 12,
						title: "Register",
						path: "/app/projects/register",
						component: ProjectRegister,
					},
					// {
					// 	id: 16,
					// 	collapse: true,
					// 	title: "EPCM",
					// 	path: "/app/projects/epcm",
					// 	collapseSub: "projectsepcm",
					// 	views: [
					// 		{
					// 			id: 17,
					// 			title: "Current",
					// 			path: "/app/projects/epcm/current",
					// 			component: CurrentEPCMProjects,
					// 		},
					// 	],
					// },
				],
			},
			{
				id: 13,
				collapse: true,
				title: "Tenders",
				path: "/app/tenders",
				icon: TenderIcon,
				areas: ["sidebar"],
				collapseSub: "tenders",
				views: [
					{
						// id: 19,
						// collapse: true,
						// title: "EPC",
						// path: "/app/tenders/epc",
						// collapseSub: "tendersepc",
						// views: [
						// 	{
								id: 14,
								title: "EPC",
								path: "/app/tenders/epc/current",
								component: CurrentEPCTenders,
						// 	},
						// ],
					},
					{
						// id: 21,
						// collapse: true,
						// title: "EPCM",
						// path: "/app/tenders/epcm",
						// collapseSub: "tendersepcm",
						// views: [
						// 	{
								id: 115,
								title: "EPCM",
								path: "/app/tenders/epcm/current",
								component: CurrentEPCMTenders,
						// 	},
						// ],
					},
					{
						// id: 23,
						// collapse: true,
						// title: "Product",
						// path: "/app/tenders/product",
						// collapseSub: "tendersproduct",
						// views: [
						// 	{
								id: 16,
								title: "Product",
								path: "/app/tenders/product/current",
								component: CurrentProductTenders,
						// 	},
						// ],
					},
					{
						id: 17,
						title: "Register",
						path: "/app/tenders/register",
						component: TenderRegister,
					},
				],
			},
			{
				id: 18,
				title: "Audit Log",
				path: "/app/auditLog",
				icon: AuditLogIcon,
				component: AuditLog,
				areas: ["sidebar"],
			},
			{
				id: 19,
				title: "Devs",
				path: "/app/development",
				component: Devs,
				icon: LogoutIcon,
				areas: [""],

			},
		],
	},
	{
		id: 20,
		layout: AuthLayout,
		layoutName: "AuthLayout",
		paths: ["/app/login", "/app/logout"],
		routes: [
			{
				id: 21,
				title: "Login",
				path: "/app/login",
				component: Login,
				icon: FingerprintIcon,
				areas: ["navbar"],
			},
			{
				id: 22,
				title: "Logout",
				path: "/app/logout",
				component: Logout,
				icon: LogoutIcon,
				areas: ["sidebar"],
			},

		],
	},
];
export default allRoutes;
