import { getDayName, getShortDayName, verifyInt, countSpecificDaysInMonthNumber, calendarDate, addZeroIfNeeded, getLongDayName, convertNumberToString, getMonthName } from '../../../assets/helpers/globals';
import moment from 'moment';

export const handleToggleRecurringDialog = (formElementId, type, state) => {
    let updatedForm = { ...state };
    let updatedFormFields = [...updatedForm.fields];
    const formElementIndex = updatedFormFields.findIndex(field => field.id === formElementId);
    const startDateIndex = updatedFormFields.findIndex(field => field.property === 'start');

    if (formElementIndex !== -1 && startDateIndex !== -1) {
        let updatedFormElement = { ...updatedFormFields[formElementIndex] };
        if (type === 'open') {
            updatedFormElement.open = true;
            const startDate = new Date(updatedFormFields[startDateIndex].value);
            const defaultWeekday = getShortDayName(getDayName(startDate.getDay()));
            const onDate = moment(startDate).subtract(1, 'days').add(3, 'months').format();

            let updatedFormElementConfig = { ...updatedFormElement.elementConfig };
            let updatedFormElementConfigRecurring = { ...updatedFormElementConfig.recurring };

            if (!updatedFormElementConfigRecurring.count) {
                updatedFormElementConfigRecurring = {
                    count: 1,
                    freq: 'weekly',
                    ends: 'never',
                    weekDays: [defaultWeekday],
                    monthlyType: 'dayNumber',
                    afterInterval: 1,
                    onDate: onDate
                };
            }
            updatedFormElementConfig.recurring = updatedFormElementConfigRecurring;
            updatedFormElement.elementConfig = updatedFormElementConfig;
        }
        else {
            updatedFormElement.open = false;
        }
        updatedFormFields[formElementIndex] = updatedFormElement;
        updatedForm.fields = updatedFormFields;
    }

    return updatedForm;
};

export const handleClearRecurringDialog = (formElementId, state) => {
    let updatedForm = { ...state };
    let updatedFormFields = [...updatedForm.fields];
    const formElementIndex = updatedFormFields.findIndex(field => field.id === formElementId);

    if (formElementIndex !== -1) {
        let updatedFormElement = { ...updatedFormFields[formElementIndex], open: false, value: '' };
        let updatedFormElementConfig = { ...updatedFormElement.elementConfig };
        updatedFormElementConfig.recurring = {};
        updatedFormElementConfig.recurringErrors = {};
        updatedFormElementConfig.recurringErrorMessages = {};
        updatedFormElement.elementConfig = updatedFormElementConfig;
        updatedFormFields[formElementIndex] = updatedFormElement;
        updatedForm.fields = updatedFormFields;
    }

    return updatedForm;
};

export const buildLongDayString = (days) => {
    let newArray = days.split(',');
    if (newArray && newArray.length > 0) {
        return newArray.map((day, index) => {
            return ' ' + getLongDayName(day);
        });
    }
    return '';
}

export const convertRecurringObjToString = (array) => {
    let returnStr = '';
    if (array && array.length > 0) {
        let rRule = array[0];
        let rRuleTemp = rRule.substring(rRule.indexOf(':') + 1);
        let rRuleArray = rRuleTemp.split(';');

        var freq;
        var interval;
        var count;
        var until;
        var byday;
        var bymonthday;

        rRuleArray.forEach(rule => {
            let keyValueArray = rule.split('=');
            let key = keyValueArray[0].toLowerCase();
            let value = keyValueArray[1];
            switch (key) {
                case 'freq': freq = value; break;
                case 'interval': interval = value; break;
                case 'count': count = value; break;
                case 'until': until = value; break;
                case 'byday': byday = value; break;
                case 'bymonthday': bymonthday = value; break;
                default: break;
            };
        });

        switch (freq.toLowerCase()) {
            case 'daily':
                returnStr = Number.isNaN(parseInt(interval)) || parseInt(interval) === 1 ? 'Daily' : 'Every ' + parseInt(interval) + ' days';
                break;
            case 'weekly':
                returnStr = Number.isNaN(parseInt(interval)) || parseInt(interval) === 1 ? 'Weekly' : 'Every ' + parseInt(interval) + ' weeks';
                returnStr += byday ? ' on ' + buildLongDayString(byday) : '';
                break;
            case 'monthly':
                returnStr = Number.isNaN(parseInt(interval)) || parseInt(interval) === 1 ? 'Monthly' : 'Every ' + parseInt(interval) + ' months';
                returnStr += bymonthday ? ' on day ' + bymonthday : '';
                const byDayInteger = byday ? byday.substring(0, 1) : 0;
                const byDayName = byday ? byday.substring(1) : '';
                returnStr += byday ? ' on the ' + convertNumberToString(parseInt(byDayInteger)) + ' ' + getLongDayName(byDayName) : '';
                break;
            case 'yearly':
                returnStr = Number.isNaN(parseInt(interval)) || parseInt(interval) === 1 ? 'Annually' : 'Every ' + parseInt(interval) + ' years';
                break;
            default: break;
        };

        if (until != null) {
            let date = calendarDate(until);
            returnStr += ', until ' + date.getDate() + ' ' + getMonthName(date.getMonth()) + ' ' + date.getFullYear();
        }
        else if (count != null) {
            let timeStr = parseInt(count) === 1 ? ' time' : ' times';
            returnStr += ', ' + count + timeStr;
        }
    }
    return returnStr;
};

export const convertStringToRecurringObj = (recurringArray) => {
    let rRule = recurringArray[0];
    let returnObj = {};

    let rRuleTemp = rRule.substring(rRule.indexOf(':') + 1);
    let rRuleArray = rRuleTemp.split(';');

    returnObj.ends = 'never';
    returnObj.monthlyType = 'dayName';

    rRuleArray.forEach(rule => {
        let keyValueArray = rule.split('=');
        let key = keyValueArray[0].toLowerCase();
        let value = keyValueArray[1];

        switch (key) {
            case 'freq': returnObj.freq = value.toLowerCase(); break;
            case 'interval': returnObj.count = parseInt(value); break;
            case 'count':
                returnObj.afterInterval = parseInt(value);
                returnObj.ends = 'after';
                break;
            case 'until':
                returnObj.ends = 'on';
                returnObj.onDate = moment(calendarDate(value)).format();
                break;
            case 'byday':
                returnObj.weekDays = [value]; break;
            case 'bymonthday': returnObj.monthlyType = 'dayNumber'; break;
            default: break;
        };
    });
    return returnObj;
}

export const handleToggleWeekRecurringDays = (val, formElementId, state) => {
    let updatedForm = { ...state };
    let updatedFormFields = [...updatedForm.fields];
    const formElementIndex = updatedFormFields.findIndex(field => field.id === formElementId);

    if (formElementIndex !== -1) {
        let updatedFormElement = { ...updatedFormFields[formElementIndex] };
        let updatedFormElementConfig = { ...updatedFormElement.elementConfig };
        let updatedFormElementConfigRecurring = { ...updatedFormElementConfig.recurring };
        let updatedFormElementConfigRecurringErrors = { ...updatedFormElementConfig.recurringErrors };
        let updatedFormElementConfigRecurringErrorMessages = { ...updatedFormElementConfig.recurringErrorMessages };
        let newArray = updatedFormElementConfigRecurring.weekDays ? [...updatedFormElementConfigRecurring.weekDays] : [];

        let dayIndex = newArray.findIndex(day => day === val);
        let dayActive = dayIndex !== -1;

        let error = false;
        let errorMessage = '';

        if (dayActive) {
            newArray.splice(dayIndex, 1);
            if (updatedFormElementConfigRecurring.freq === 'weekly' && (newArray.length === 0)) {
                error = true;
                errorMessage = 'At least one day needs to be chosen';
            }
        }
        else {
            newArray.push(val);
            error = false;
            errorMessage = '';
        }

        updatedFormElementConfigRecurringErrors.weekDays = error;
        updatedFormElementConfigRecurringErrorMessages.weekDays = errorMessage;
        updatedFormElementConfigRecurring.weekDays = newArray;
        updatedFormElementConfig.recurring = updatedFormElementConfigRecurring;
        updatedFormElementConfig.recurringErrors = updatedFormElementConfigRecurringErrors;
        updatedFormElementConfig.recurringErrorMessages = updatedFormElementConfigRecurringErrorMessages;
        updatedFormElement.elementConfig = updatedFormElementConfig;
        updatedFormFields[formElementIndex] = updatedFormElement;
        updatedForm.fields = updatedFormFields;
    }

    return updatedForm
};

export const handleChangeRecurringInputs = (event, date = false, name = '', formElementId, state) => {
    let updatedForm = { ...state };
    let updatedFormFields = [...updatedForm.fields];
    const formElementIndex = updatedFormFields.findIndex(field => field.id === formElementId);
    const startDateIndex = updatedFormFields.findIndex(field => field.property === 'start');

    if (formElementIndex !== -1 && startDateIndex !== -1) {

        let updatedFormElement = { ...updatedFormFields[formElementIndex] };
        let updatedFormElementConfig = { ...updatedFormElement.elementConfig };
        let updatedFormElementConfigRecurringErrors = { ...updatedFormElementConfig.recurringErrors };
        let updatedFormElementConfigRecurringErrorMessages = { ...updatedFormElementConfig.recurringErrorMessages };
        let updatedFormElementConfigRecurring = { ...updatedFormElementConfig.recurring };

        let val = date ? event.format() : event.target.value;
        let stateName = date ? name : event.target.name;

        let error = false;
        let errorMessage = '';

        if (stateName === 'count') {
            if (!verifyInt(updatedFormElementConfigRecurring.count)) {
                error = true;
                errorMessage = 'Invalid value';
            }

            updatedFormElementConfigRecurring[stateName] = verifyInt(val) ? parseInt(val) : 1;
            updatedFormElementConfigRecurringErrors[stateName] = error;
            updatedFormElementConfigRecurringErrorMessages[stateName] = errorMessage;
        }
        else if (stateName === 'freq') {
            var onDate;
            switch (val) {
                case 'daily':
                    onDate = moment(updatedFormFields[startDateIndex].value).subtract(1, 'days').add(1, 'months').format();
                    break;
                case 'weekly':
                    onDate = moment(updatedFormFields[startDateIndex].value).subtract(1, 'days').add(3, 'months').format();
                    break;
                case 'monthly':
                    onDate = moment(updatedFormFields[startDateIndex].value).subtract(1, 'days').add(1, 'years').format();
                    break;
                case 'yearly':
                    onDate = moment(updatedFormFields[startDateIndex].value).subtract(1, 'days').add(5, 'years').format();
                    break;
                default:
                    onDate = moment(updatedFormFields[startDateIndex].value).format();
                    break;

            }
            updatedFormElementConfigRecurring[stateName] = val;
            updatedFormElementConfigRecurring.onDate = onDate;
        }
        else if (stateName === 'afterInterval') {
            if (updatedFormElementConfigRecurring.ends === 'after' && !verifyInt(updatedFormElementConfigRecurring.afterInterval)) {
                error = true;
                errorMessage = 'Invalid value';
            }
            updatedFormElementConfigRecurring[stateName] = verifyInt(val) ? parseInt(val) : 1;
            updatedFormElementConfigRecurringErrors[stateName] = error;
            updatedFormElementConfigRecurringErrorMessages[stateName] = errorMessage;
        }
        else {
            updatedFormElementConfigRecurring[stateName] = val;
        }
        updatedFormElement.elementConfig = updatedFormElementConfig;
        updatedFormElement.elementConfig.recurring = updatedFormElementConfigRecurring;
        updatedFormFields[formElementIndex] = updatedFormElement;
        updatedForm.fields = updatedFormFields;
    }

    return updatedForm;

};

export const validateRecurrenceFields = (reccurrenceObj, formElementId, state) => {
    let valid = true;
    let updatedForm = {...state};
    if (!verifyInt(reccurrenceObj.count)) {
        updatedForm = setRecurringErrorState('count', true, 'Invalid value', formElementId, updatedForm);
        valid = false;
    }
    if (reccurrenceObj.ends === 'after' && !verifyInt(reccurrenceObj.afterInterval)) {
        updatedForm = setRecurringErrorState('afterInterval', true, 'Invalid value', formElementId, updatedForm);
        valid = false;
    }

    if (reccurrenceObj.freq === 'weekly' && (!reccurrenceObj.weekDays || reccurrenceObj.weekDays.length === 0)) {
        updatedForm = setRecurringErrorState('weekDays', true, 'At least one day needs to be chosen', formElementId, updatedForm);
        valid = false;
    }
    return {
        valid : valid,
        updatedForm : updatedForm
    };
};

export const handleAddRecurrenceDetails = (formElementId, state) => {
    let updatedForm = { ...state };
    let formFields = [...updatedForm.fields];
    const formElementIndex = formFields.findIndex(field => field.id === formElementId);
    const startDateIndex = formFields.findIndex(field => field.property === 'start');

    if (formElementIndex !== -1 && startDateIndex !== -1) {

        let formElement = { ...formFields[formElementIndex] };
        let formElementConfig = { ...formElement.elementConfig };
        let recurrenceObj = { ...formElementConfig.recurring };

        let valid = validateRecurrenceFields(recurrenceObj, formElementId).valid;
        updatedForm = validateRecurrenceFields(recurrenceObj, formElementId, updatedForm).updatedForm;

        if (valid) {
            let recurrenceArray = [];
            let startDate = new Date(formFields[startDateIndex].value);
            let rRule = 'RRULE:FREQ=' + recurrenceObj.freq.toUpperCase() + ';INTERVAL=' + recurrenceObj.count;

            if (recurrenceObj.ends === 'after') {
                rRule += ';COUNT=' + recurrenceObj.afterInterval;
            }
            else if (recurrenceObj.ends === 'on') {
                let date = new Date(recurrenceObj.onDate);
                let dateString = date.getFullYear().toString() + addZeroIfNeeded((date.getMonth() + 1)).toString() + addZeroIfNeeded(date.getDate()).toString();
                rRule += ';UNTIL=' + dateString;
            }

            if (recurrenceObj.freq === 'weekly') {
                rRule += ';BYDAY=' + recurrenceObj.weekDays;
            }
            else if (recurrenceObj.freq === 'monthly') {
                if (recurrenceObj.monthlyType === 'dayNumber') {
                    rRule += ';BYMONTHDAY=' + startDate.getDate();
                }
                else {
                    let countPos = countSpecificDaysInMonthNumber(startDate.getFullYear(), startDate.getMonth(), startDate.getDay(), startDate.getTime());
                    rRule += ';BYDAY=' + countPos + getShortDayName(getDayName(startDate.getDay()));
                }
            }
            recurrenceArray.push(rRule);
            formElement.value = recurrenceArray;
            formElement.open = false;

            formFields[formElementIndex] = formElement;
            updatedForm.fields = formFields;
        }
    }
    return updatedForm;
};

export const setRecurringErrorState = (property, errorVal, errorMessage, formElementId, state) => {
    let updatedForm = { ...state };
    let updatedFormFields = [...updatedForm.fields];

    const updatedFormElementIndex = updatedFormFields.findIndex(field => field.id === formElementId);

    let updatedFormElement = { ...updatedFormFields[updatedFormElementIndex] };
    let updatedFormElementConfig = { ...updatedFormElement.elementConfig };
    let updatedFormElementRecurrenceErrors = { ...updatedFormElementConfig.recurringErrors };
    let updatedFormElementRecurrenceErrorMessages = { ...updatedFormElementConfig.recurringErrorMessages };

    updatedFormElementRecurrenceErrors[property] = errorVal;
    updatedFormElementRecurrenceErrorMessages[property] = errorMessage;

    updatedFormElementConfig.recurringErrors = updatedFormElementRecurrenceErrors;
    updatedFormElementConfig.recurringErrorMessages = updatedFormElementRecurrenceErrorMessages;
    updatedFormElement.elementConfig = updatedFormElementConfig;
    updatedFormFields[updatedFormElementIndex] = updatedFormElement;
    updatedForm.fields = updatedFormFields;

    return updatedForm;
}