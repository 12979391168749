import React, {useEffect, useState} from "react";
import { connect } from "react-redux";
import MaterialTable from "material-table";
import CustomAction from "../../../../components/Table/CustomAction";
import styled, {css} from "styled-components";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import FilterAutocomplete from "../../../../components/Inputs/Filter/FilterAutocomplete";
import Button from "@material-ui/core/Button";
import {Autorenew, Done, Clear, Info, DoneAll} from "@material-ui/icons";
import isEqual from "lodash/isEqual";
import {getFilteredData} from "../../../../assets/helpers/globals";

import * as purchaseOrderActions from "../../../../store/purchaseOrders/actions";
import * as xeroActions from "../../../../store/xero/actions";
import {getCurrentUser} from "../../../../store/user/actions";
import {getDeliveryAddressPerLegalEntity} from "../../../../store/legalEntities/actions";
import * as loadingTypes from "../../../../store/loading/types";
import {getPermissionsByRole} from "../../../../store/permissions/actions";
import debounce from "lodash/debounce";
import AuditLoggingTableDialog from "../../../Audit/TableDialog";
import {getAuditLog, getTransactionSubTypes} from "../../../../store/audit/actions";
import {useInterval} from "../../../../hooks/useInterval";
import DetailsDialog from "./DetailsDialog";
import NewRequestDialog from "./NewRequestDialog";
import ConfirmationDialog from "../../../../components/Dialogs/ConfirmationDialog/ConfirmationDialog";

const PurchaseOrderSubmittedIcon = styled(DoneAll)`
  display: none;
  ${props => props.status === "PO Submitted to Xero" && css`
    margin-top: -1px;
    margin-right: 2px;
    display: flex;
    color: #388e3c;
  `}
`;

const PurchaseOrderReviewedIcon = styled(Done)`
  display: none;
  ${props => props.status === "PO Reviewed" && css`
    margin-top: -1px;
    margin-right: 2px;
    display: flex;
    color: #388e3c;
  `}
`;

const PurchaseOrderReviewInProgressIcon = styled(Info)`
  display: none;
  ${props => props.status === "PO Review In Progress (Draft)" && css`
    margin-top: -1px;
    margin-right: 2px;
    display: flex;
    color: #1976d2;
  `}
`;

const PurchaseOrderPendingIcon = styled(Autorenew)`
  display: none;
  ${props => props.status === "PO Pending" && css`
    margin-top: -1px;
    margin-right: 2px;
    display: flex;
    color: #f57c00;
  `}
`;

const P = styled.p`
  color: #f57c00;
  border: 1px solid #ff9800;
  border-radius: 16px;
  height: 24px;
  padding-left: 4px;
  padding-right: 8px;
  white-space: nowrap;
  display: flex;
  max-width: fit-content;

  ${props => (props.status === "PO Submitted to Xero" || props.status === "PO Reviewed") && css`
    color: #388e3c;
    border: 1px solid #4caf50;
  `}
  ${props => props.status === "PO Review In Progress (Draft)" && css`
    color: #1976d2;
    border: 1px solid #2196f3;
  `}
`;

const FilterPaper = styled(Paper)`
  margin-bottom: 18px;
  margin-top: 14px;
`;

const FilterGrid = styled(Grid)`
  ${props => props.container && css`
    margin-left: 10px;
    width: 98%;
  `}
  ${props => props.item && css`
    align-self: center
  `}
`;

const XeroReadyPurchaseOrders = ({
                            //state
                            user,
                            loading,
                            auditLog,
                            transactionSubTypes,
                            taxTypesByLegalEntity,
                            accountsByLegalEntity,
                            trackingByLegalEntity,
                            deliveryAddressPerLegalEntity,
                            allPurchaseOrders,

                            //actions
                            getCurrentUser,
                            getAllPurchaseOrders,
                            getUserRoles,
                            getAuditLog,
                            getTransactionSubTypes,
                            getAllLatestAccounts,
                            getTaxTypesByLegalEntity,
                            getAccountsByLegalEntity,
                            getTrackingByLegalEntity,
                            getDeliveryAddressPerLegalEntity,
                            submitToXero,
                            updatePurchaseOrder
                           }) => {
    const initialDialogState = false;
    const initialDetailsState = { open: false, data: {} };
    const initialFilterState = { status: null, name: null, vendorName: null, taxOptionText: null, entityName: null };

    const [editDialogOpen, setEditDialogOpen] = useState(initialDialogState);
    const [purchaseOrderDetails, setPurchaseOrderDetails] = useState(initialDetailsState);
    const [confirmSubmitToXeroConfirmationOpen, setConfirmSubmitToXeroConfirmationOpen] = useState(false);
    const [selectedPurchaseOrder, setSelectedPurchaseOrder] = useState(undefined);
    const [editing, setEditing] = useState(undefined);
    const [auditLogDetails, setAuditLogDetails] = useState(undefined);
    const [auditLogDialogOpen, setAuditLogDialogOpen] = useState({open: false, type: ""});
    const [filter, setFilter] = useState(initialFilterState);
    const [roles, setRoles] = useState({
        is_purchase_order_creator: false,
        is_purchase_order_viewer: false
    })

    const actions = [
        {
            icon: "visibility",
            tooltip: "View Request",
            onClick: async (_event, selectedPurchaseOrder) => {
                setPurchaseOrderDetails({
                    open: true,
                    data: selectedPurchaseOrder
                })
            }
        },
        rowData =>
            ({
            icon: "edit",
            tooltip: "Review and Amend",
            hidden: !(roles.is_purchase_order_creator && rowData.status !== "PO Submitted to Xero"),
            onClick: async (_event, selectedPurchaseOrder) => {
                await getTaxTypesByLegalEntity(selectedPurchaseOrder.legalEntity.uniqueId);
                await getAccountsByLegalEntity(selectedPurchaseOrder.legalEntity.uniqueId);
                await getTrackingByLegalEntity(selectedPurchaseOrder.legalEntity.uniqueId);
                await getDeliveryAddressPerLegalEntity(selectedPurchaseOrder.legalEntity.uniqueId)

                setEditing(selectedPurchaseOrder);
                setEditDialogOpen(true);
            }
        }),
        rowData =>
            ({
            icon: "launchSharp",
            tooltip: "Submit To Xero",
            hidden: !(roles.is_purchase_order_creator && rowData.status === "PO Reviewed" && rowData.status !== "PO Submitted to Xero"),
            onClick: async (_event, selectedPurchaseOrder) => {
                setSelectedPurchaseOrder(selectedPurchaseOrder);
                setConfirmSubmitToXeroConfirmationOpen(true);
            }
        }),
        {
            icon: "history",
            tooltip: "View Purchase Order History",
            onClick: async (_event, selectedPurchaseOrder) => {
                if (!isEqual(selectedPurchaseOrder, auditLogDetails)) {
                    let typeId

                    if (!transactionSubTypes || !transactionSubTypes.length) {
                        let res = await getTransactionSubTypes();
                        typeId = res.data.find(transactionSubType => transactionSubType.subTypeName === "N/A" && transactionSubType.transactionType?.typeName === "Purchase Order").uniqueId;
                    } else
                        typeId = transactionSubTypes.find(transactionSubType => transactionSubType.subTypeName === "N/A" && transactionSubType.transactionType?.typeName === "Purchase Order").uniqueId;

                    await getAuditLog(selectedPurchaseOrder.uniqueId, typeId);
                    setAuditLogDetails(selectedPurchaseOrder)
                    setAuditLogDialogOpen({open: true, type: "Purchase Order"})
                } else
                    setAuditLogDialogOpen({open: true, type: "Purchase Order"})
            }
        },
        {
            icon: 'refresh',
            tooltip: 'Refresh Data',
            isFreeAction: true,
            onClick: debounce(() => getAllPurchaseOrders("Generated"), 300, {
                'leading': false,
                'trailing': true
            }),
            // hidden: !(roles.is_purchase_order_creator || roles.is_purchase_order_viewer)
        },
    ];

    const filterData = (data, query) => {
        return data.filter((item) => {
            for (let key in query) {
                switch (key) {
                    case "entityName":
                        if (item.legalEntity === null || item.legalEntity[key] === undefined || !query[key].includes(item.legalEntity[key])) {
                            return false;
                        }
                        break;
                    case "vendorName":
                        if (item.vendor === null || item.vendor[key] === undefined || !query[key].includes(item.vendor[key]))
                            return false;
                        break;
                    case "name":
                        if (item.currency === null || item.currency[key] === undefined || !query[key].includes(item.currency[key]))
                            return false;
                        break;
                    case "taxOptionText":
                        if (item.purchaseTaxOption === null || item.purchaseTaxOption[key] === undefined || !query[key].includes(item.purchaseTaxOption[key]))
                            return false;
                        break;
                    default:
                        if (item[key] === undefined || !query[key].includes(item[key]))
                            return false;
                        break;
                }
            }
            return true;
        });
    };

    useInterval(() => {
        // if (roles.is_purchase_order_creator || roles.is_purchase_order_viewer)
        getAllPurchaseOrders("Generated");
    }, 300000);

    useEffect(() => {
        (async function() {
            const res = await getUserRoles("purchase","order");
            const permissionObj = {
                is_purchase_order_creator: res.some(name => name === "purchase_order_creator"),
                is_purchase_order_viewer: res.some(name => name === "purchase_order_viewer")
            }

            setRoles(permissionObj);
        })();

        if (!user.userEmail)
            getCurrentUser();
        // if (roles.is_purchase_order_creator || roles.is_purchase_order_viewer)
        getAllPurchaseOrders("Generated");
    }, [getCurrentUser, getAllPurchaseOrders, getUserRoles, user.userEmail, roles.is_purchase_order_creator, roles.is_purchase_order_viewer])

    return (
        <>
            <FilterPaper>
                <FilterGrid container spacing={2}>
                    <FilterGrid item xs={12} sm={6} md={4} lg={3}>
                        <FilterAutocomplete
                            onChange={(event, newValue) => setFilter({...filter, status: newValue})}
                            value={filter.status}
                            options={allPurchaseOrders.map(purchaseOrderItem => purchaseOrderItem.status)}
                            placeholder="Status"
                            noOptionsText="No Statuses"
                        />
                    </FilterGrid>
                    <FilterGrid item xs={12} sm={6} md={4} lg={3}>
                        <FilterAutocomplete
                            onChange={(event, newValue) => setFilter({...filter, entityName: newValue})}
                            value={filter.entityName}
                            options={allPurchaseOrders.map(purchaseOrderItem => purchaseOrderItem.legalEntity?.entityName)}
                            placeholder="Legal Entity"
                            noOptionsText="No Legal Entities"
                        />
                    </FilterGrid>
                    <FilterGrid item xs={12} sm={6} md={4} lg={3}>
                        <FilterAutocomplete
                            onChange={(event, newValue) => setFilter({...filter, vendorName: newValue})}
                            value={filter.vendorName}
                            options={ allPurchaseOrders
                                .map(purchaseOrderItem => purchaseOrderItem.vendor !== null ? purchaseOrderItem.vendor.vendorName : '')
                                .filter((purchaseOrderItem, index, array) => array.indexOf(purchaseOrderItem) === index && purchaseOrderItem !== '')}
                            placeholder="Supplier"
                            noOptionsText="No Suppliers"
                        />
                    </FilterGrid>
                    <FilterGrid item xs={12} sm={6} md={4} lg={3}>
                        <FilterAutocomplete
                            onChange={(event, newValue) => setFilter({...filter, name: newValue})}
                            value={filter.name}
                            options={allPurchaseOrders.map(purchaseOrderItem => purchaseOrderItem.currency?.name)}
                            placeholder="Currency"
                            noOptionsText="No Currencies"
                        />
                    </FilterGrid>
                    <FilterGrid item xs={12} sm={6} md={4} lg={3}>
                        <FilterAutocomplete
                            onChange={(event, newValue) => setFilter({...filter, taxOptionText: newValue})}
                            value={filter.taxOptionText}
                            options={allPurchaseOrders.map(purchaseOrderItem => purchaseOrderItem.purchaseTaxOption?.taxOptionText)}
                            placeholder="Tax"
                            noOptionsText="No Tax Options"
                        />
                    </FilterGrid>
                    <FilterGrid item xs={12} sm={6} md={4} lg={3}>
                        <Button
                            color="secondary"
                            size="small"
                            startIcon={<Clear/>}
                            disabled={isEqual(filter, initialFilterState)}
                            onClick={() => setFilter(initialFilterState)}>
                            Clear All
                        </Button>
                    </FilterGrid>
                </FilterGrid>
            </FilterPaper>

            <MaterialTable
                columns={[
                    {
                        title: "Created Date",
                        field: "createdDate",
                        type: "date",
                    },
                    {
                        title: "Modified Date",
                        field: "lastModifiedDate",
                        type: "date",
                        defaultSort: "desc",
                        customSort: (a, b) => {
                            let d1 = new Date(a.lastModifiedDate);
                            let d2 = new Date(b.lastModifiedDate);
                            return d1.getTime() - d2.getTime();
                        }
                    },
                    {
                        title: "Legal Entity",
                        field: "legalEntity.entityName",
                    },
                    {
                        title: "Supplier",
                        field: "vendor.vendorName",
                    },
                    {
                        title: "Reference",
                        field: "reference"
                    },
                    {
                        title: "Currency",
                        field: "currency.name",
                    },
                    {
                        title: "Tax",
                        field: "purchaseTaxOption.taxOptionText",
                    },
                    {
                        title: "Status",
                        field: "status",
                        render: data => (
                            <P status={data.status}>
                                <PurchaseOrderPendingIcon status={data.status}/>
                                <PurchaseOrderReviewInProgressIcon status={data.status}/>
                                <PurchaseOrderReviewedIcon status={data.status}/>
                                <PurchaseOrderSubmittedIcon status={data.status}/>{data.status}</P>
                        ),
                    }
                ]}
                actions={actions}
                components={{ Action: CustomAction }}
                data={isEqual(filter, initialFilterState) ? allPurchaseOrders : getFilteredData(filter, allPurchaseOrders, [], filterData)}
                isLoading={editDialogOpen === false && (
                    loading.type === loadingTypes.purchaseOrdersGetAllPurchaseOrders ||
                    loading.type === loadingTypes.xeroGetAllLatestAccounts ||
                    loading.type === loadingTypes.xeroGetTaxTypesByLegalEntity ||
                    loading.type === loadingTypes.xeroGetAccountsByLegalEntity ||
                    loading.type === loadingTypes.xeroGetTrackingByLegalEntity ||
                    loading.type === loadingTypes.legalEntitiesGetDeliveryAddressPerLegalEntity ||
                    loading.type === loadingTypes.auditGetTransactionSubTypes ||
                    loading.type === loadingTypes.auditGetAuditLog ||
                    loading.type === loadingTypes.permissionsGetPermissionsByRole)
                }
                options={{
                    actionsColumnIndex: -1,
                    pageSize: 10,
                    searchFieldAlignment: "left",
                    showTitle: false,
                    isLoading: true,
                    search: true,
                    // loadingType: "linear",
                    // thirdSortClick: false,
                    toolbarButtonAlignment: "right",
                    sorting: true
                }}
            />

            <DetailsDialog
                key={"Purchase-Order-Details-" + purchaseOrderDetails?.data?.uniqueId}
                details={purchaseOrderDetails.data}
                onClose={() => setPurchaseOrderDetails(initialDetailsState)}
                open={purchaseOrderDetails.open}
            />
            <NewRequestDialog
                key={"Purchase-Order-Edit-" + editing?.uniqueId}
                open={editDialogOpen}
                onClose={() => setEditDialogOpen(initialDialogState)}
                initialValues={editing}
                onSubmit={async updatedPurchaseOrder => {
                    await updatePurchaseOrder(updatedPurchaseOrder);
                    getAllPurchaseOrders("Generated");
                    setEditDialogOpen(initialDialogState);
                }}
                loading={loading}
                taxTypesByLegalEntity={taxTypesByLegalEntity}
                accountsByLegalEntity={accountsByLegalEntity}
                trackingByLegalEntity={trackingByLegalEntity}
                deliveryAddressPerLegalEntity={deliveryAddressPerLegalEntity}
                getAllLatestAccounts={getAllLatestAccounts}
                getAccountsByLegalEntity={getAccountsByLegalEntity}
            />

            <AuditLoggingTableDialog
                open={auditLogDialogOpen.open}
                auditPayload={auditLog}
                type={auditLogDialogOpen.type}
                onClose={() => setAuditLogDialogOpen({open: false, type: ""})}
                loading={loading.type === loadingTypes.auditGetAuditLog}
            />
            <ConfirmationDialog
                open={confirmSubmitToXeroConfirmationOpen}
                handleClose={() => setConfirmSubmitToXeroConfirmationOpen(false)}
                headerText={"Confirm submission to Xero"}
                handleConfirmation={async confirm => {
                    if (confirm) {
                        await submitToXero(selectedPurchaseOrder.uniqueId);
                        getAllPurchaseOrders("Generated");
                    }
                    setConfirmSubmitToXeroConfirmationOpen(false);
                }}
                promptText={"Are you sure you want to submit this purchase order to Xero? Please ensure that all information is correct as you will not be able to make any further changes to the purchase order once submitted."}
                loading={loading.type === loadingTypes.purchaseOrdersSubmitToXero}
            />

        </>
    );
}

const mapStateToProps = state => ({
    allPurchaseOrders: state.purchaseOrdersReducer.allPurchaseOrders,
    user: state.userReducer.currentUser,
    loading: state.loadingReducer.loading,
    auditLog: state.auditReducer.auditLog,
    transactionSubTypes: state.auditReducer.transactionSubTypes,
    taxTypesByLegalEntity: state.xeroReducer.taxTypesByLegalEntity,
    accountsByLegalEntity: state.xeroReducer.accountsByLegalEntity,
    trackingByLegalEntity: state.xeroReducer.trackingByLegalEntity,
    deliveryAddressPerLegalEntity: state.legalEntitiesReducer.deliveryAddressPerLegalEntity
});

const mapDispatchToProps = dispatch => ({
    //region General API's
    getCurrentUser: () => getCurrentUser()(dispatch),
    getUserRoles: (roleType, roleSubType) => dispatch(getPermissionsByRole(roleType, roleSubType)),
    //endregion

    // region Xero
    getAllLatestAccounts: () => dispatch(xeroActions.getAllLatestAccounts()),
    getTaxTypesByLegalEntity: (id) => dispatch(xeroActions.getTaxTypesByLegalEntity(id)),
    getAccountsByLegalEntity: (id) => dispatch(xeroActions.getAccountsByLegalEntity(id)),
    getTrackingByLegalEntity: (id) => dispatch(xeroActions.getTrackingByLegalEntity(id)),
    getDeliveryAddressPerLegalEntity: (id) => dispatch(getDeliveryAddressPerLegalEntity(id)),
    //endregion

    //region Purchase Order
    getAllPurchaseOrders: (status) => dispatch(purchaseOrderActions.getAllPurchaseOrders(status)),
    updatePurchaseOrder: (data) => dispatch(purchaseOrderActions.updatePurchaseOrder(data)),
    submitToXero: (id) => dispatch(purchaseOrderActions.submitToXero(id)),
    //endregion

    //region Audit Logging
    getAuditLog: (id, type) => dispatch(getAuditLog(id, type)),
    getTransactionSubTypes: () => dispatch(getTransactionSubTypes())
    //endregion
});

export default connect(mapStateToProps, mapDispatchToProps)(XeroReadyPurchaseOrders);