// export const BASE_URL = process.env.REACT_APP_ENV === 'dev' ? 'http://localhost:5000' :
//     process.env.REACT_APP_ENV === 'prod' ? 'https://testing.epcmportal.com' :
//         'http://epcmprod-env.eba-stspdawj.us-east-2.elasticbeanstalk.com/app/login';

export const BASE_URL = window.location.origin;
// console.log("BASE_URL - "+ BASE_URL);
// console.log("ENV:  - "+ process.env.REACT_APP_ENV);

export const UI_HOST = window.location.protocol + '//' + window.location.host;
// console.log("UI_HOST - "+ UI_HOST);

export const ACCESS_TOKEN = 'accessToken';
// console.log("ACCESS_TOKEN - "+ ACCESS_TOKEN);

export const OAUTH2_REDIRECT_URI = UI_HOST + '/oauth2/redirect';
// console.log("OAUTH2_REDIRECT_URI - "+ OAUTH2_REDIRECT_URI);

export const GOOGLE_AUTH_URL = BASE_URL + '/oauth2/authorize/google?redirect_uri=' + OAUTH2_REDIRECT_URI;
// console.log("GOOGLE_AUTH_URL - "+ GOOGLE_AUTH_URL);

const DAYS_SHORT = ['SU', 'MO', 'TU', 'WE', 'TH', 'FR', 'SA'];
const DAYS = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
const MONTHS = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
const MONTHS_NUM = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'];
const DAYS_NUM = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24', '25', '26', '27', '28', '29', '30', '31'];
// set the first letter in a word to uppercase
export const firstToUpper = (word) => {
    return word.charAt(0).toUpperCase() + word.substring(1);
}

// function that verifies if value is a valid email
export const verifyEmail = (value) => {
    let emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return (emailRex.test(value));
};

export const verifyInt = (value) => {
    // return parseInt(value);
    let intRex = /^([+-]?[1-9]\d*|0)$/;
    return (intRex.test(value));
}

export const isEmptyTextInput = (value) => {
    return !value || !value.replace(/\s/g, '').length;
};

export const isEmptyObject = (obj) => {
    return obj && Object.keys(obj).length === 0 && obj.constructor === Object;
}

export const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));

export const removeDuplicatesFromArray = (array) => {
    array.splice(0, array.length, ...(new Set(array)))
    return array;
}

export const removeNullUndefinedEmptyFromArray = (arrayToClean) => {
    const cleanedArray = [];
    arrayToClean.forEach((val) => {
        if(val !== null && typeof val !== "undefined" && (""+val).trim() !== ""){
            cleanedArray.push(val);
        }
    });

    return cleanedArray;
}

export const buildFilter = (filter) => {
    let query = {};
    for (let keys in filter) {
        if ( (filter[keys] !== null) && ((filter[keys].constructor === String) || (filter[keys].constructor === Object) || (filter[keys].constructor === Array && filter[keys].length > 0))) {
            query[keys] = filter[keys];
        }
    }

    return query;
};

export const filterData = (data, query, keysWithMinMax) => {
    return data.filter((item) => {
        for (let key in query) {
            if (item[key] === undefined) {
                return false;
            } else if (keysWithMinMax.includes(key)) {
                if (query[key]['min'] !== null && item[key] < query[key]['min']) {
                    return false;
                }
                if (query[key]['max'] !== null && item[key] > query[key]['max']) {
                    return false;
                }
            } else if (!query[key].includes(item[key])) {
                return false;
            }
        }
        return true;
    });
};

export const getFilteredData = (filter, array, keysWitMinMax, customFilter) => {
    let query = buildFilter(filter);
    return customFilter ? customFilter(array, query) : filterData(array, query, keysWitMinMax);
}

//TODO: debounce a function call
// export function debounce_leading(func, timeout) {
//     let timer;
//     return(...args) => {
//         if (!timer) {
//             func.apply(this, args);
//         }
//         clearTimeout(timer);
//         timer = setTimeout(() => {
//             timer = undefined;
//         }, timeout);
//     };
// }

export const logFormData = (formData) => {
    for (let pair of formData.entries()) {
        console.log(pair[0] + ', ' + pair[1]);
    }
}

export const capitalize = (s) => {
    if (typeof s !== 'string') return ''
    return s.charAt(0).toUpperCase() + s.slice(1)
}

export const formatAmount = (num, curr) => {
    if (isNaN(num) || typeof curr !== 'string')
        return num;

    const options = {
        currency: curr,
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
        style: "currency",
    };

    return Number(num).toLocaleString("en", options);
};

export const formatPercentage = (num) => {
    if (isNaN(num))
        return num;

    const options = {
        minimumFractionDigits: 0,
        maximumFractionDigits: 2,
        style: "percent",
    };

    return Number(num).toLocaleString("en", options);
};

export const getDayName = (index) => {
    return DAYS[index]; 
}

export const getShortDayName = (dayName) => {
    return DAYS_SHORT[DAYS.findIndex(day => day === dayName)]; 
}

export const getLongDayName = (dayName) => {
    return DAYS[DAYS_SHORT.findIndex(day => day === dayName)]; 
}

export const getMonthName = (index) => {
    return MONTHS[index]; 
}

export const checkTimePeriodMorning = (hour) => {
    return hour < 12 ? "am" : "pm";
}

export const addZeroIfNeeded = (indicator) => {
    return indicator < 10 ? "0" + indicator.toString() : indicator
};

export const removeProtocolFromString = (url) => {
    return url ? url.split('https://')[1] : '';
}

export const convertNumberToString = (number) => {
    var returnStr;
    switch(number){
        case 1:
            returnStr = 'first';
            break;
        case 2:
            returnStr = 'second';
            break;
        case 3:
            returnStr = 'third';
            break;
        case 4:
            returnStr = 'fourth';
            break;
        case 5:
            returnStr = 'fifth';
            break;
        case -1 : 
            returnStr = 'last';
            break;
        default :
            returnStr = '';
            break;
    }
    return returnStr;
}

export const countSpecificDaysInMonth = (year, month, dayIndex, timeInMilliseconds) => {
    var day, counter, date;

    day = 1;
    counter = 0;
    date = new Date(year, month, day);
    while (date.getMonth() === month) {
        if(date.getTime() > timeInMilliseconds){
            break;
        }

        if (date.getDay() === dayIndex) {
            counter += 1;
        }
        day += 1;
        date = new Date(year, month, day);
    }
   return convertNumberToString(counter);
};

export const countSpecificDaysInMonthNumber = (year, month, dayIndex, timeInMilliseconds) => {
    var day, counter, date;

    day = 1;
    counter = 0;
    date = new Date(year, month, day);
    while (date.getMonth() === month) {
        if(date.getTime() > timeInMilliseconds){
            break;
        }
        
        if (date.getDay() === dayIndex) {
            counter += 1;
        }
        day += 1;
        date = new Date(year, month, day);
    }

    return counter <=4 ? counter : -1;
};

export const convertToUTCDate = (date) => {
    let now_utc =  Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(),
    date.getUTCHours(), date.getUTCMinutes(), date.getUTCSeconds());

    return new Date(now_utc);
}

export const calendarDate = (icalStr) => {
    // icalStr = '20110914T184000Z'             
    var strYear = icalStr.substr(0,4);
    var strMonth = parseInt(icalStr.substr(4,2),10)-1;
    var strDay = icalStr.substr(6,2);
    var strHour = icalStr.substr(9,2);
    var strMin = icalStr.substr(11,2);
    var strSec = icalStr.substr(13,2);
    
    return new Date(strYear,strMonth, strDay, strHour, strMin, strSec);
}

export const formatLongDate = (dateVal) => {
    return new Date(dateVal) ? new Date(dateVal).getDate() + ' ' + MONTHS[new Date(dateVal).getMonth()] + ' ' + new Date(dateVal).getFullYear() + ' at ' +  addZeroIfNeeded(new Date(dateVal).getHours()) + ':' + addZeroIfNeeded(new Date(dateVal).getMinutes()) : '';
}

export const formatShortDate = (dateVal) => {
    return new Date(dateVal) ? new Date(dateVal).getDate() + ' ' + MONTHS[new Date(dateVal).getMonth()] + ' ' + new Date(dateVal).getFullYear() : '';
}

export const formatShortImperialFormatDate = (dateVal) => {
    const dateValArray = dateVal.split("/");
    return new Date(dateVal) ? DAYS_NUM[dateValArray[0]-1] + " " + MONTHS[dateValArray[1]-1] + " " + dateValArray[2] : '';
}

export const formatShortTableDate = (dateVal) => {
    if (dateVal.length <= 8) {
        const dateValArray = dateVal.split("/");
        return DAYS_NUM[dateValArray[0]-1] + '/' + MONTHS_NUM[dateValArray[1]-1] + '/20' + dateValArray[2]
    }
    if (dateVal.length === 10)
        return dateVal

    return new Date(dateVal) ? DAYS_NUM[new Date(dateVal).getDate() - 1] + '/' + MONTHS_NUM[new Date(dateVal).getMonth()] + '/' + new Date(dateVal).getFullYear() : '';
}

export const getMillisecondsFromDate = (dateVal) => {
    return new Date(dateVal) ? new Date(dateVal).getTime() : 0;
};

export const formatDateRange = (startDate, endDate) => {
    if (startDate.getDate() === endDate.getDate()) {
      return (getDayName(startDate.getDay()) +
        ", " + startDate.getDate() + " " + getMonthName(startDate.getMonth()) + " " + startDate.getFullYear() +
        " " + addZeroIfNeeded(startDate.getHours()) + ":" + addZeroIfNeeded(startDate.getMinutes()) +
        " - " + addZeroIfNeeded(endDate.getHours()) + ":" + addZeroIfNeeded(endDate.getMinutes()));
    }
    else {
      return (startDate.getDate() + " " + getMonthName(startDate.getMonth()) + " " + startDate.getFullYear() +
        " " + addZeroIfNeeded(startDate.getHours()) + ":" + addZeroIfNeeded(startDate.getMinutes()) +
        " - " + endDate.getDate() + " " + getMonthName(endDate.getMonth()) + " " + endDate.getFullYear() + " " +
        addZeroIfNeeded(endDate.getHours()) + ":" + addZeroIfNeeded(endDate.getMinutes()));
    }
  };






