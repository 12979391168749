import React, { Fragment } from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';
import ListItemText from '@material-ui/core/ListItemText';
import styles from './NotificationList.module.css';

const NotificationList = (props) => {

    // const clickHandler = (id) => {
    //     console.log("Clicked notification with id : " + id);
    // };

    const generateList = props.data.sort((a, b) => b.date - a.date).map((notification, index) => (
        <Fragment key={notification.id}>
            <ListItem className={notification.read ? '' : styles.UnreadListItem} /*onClick={() => clickHandler(notification.id)}*/>
                 <ListItemText
                    primary={notification.title}
                    secondary={notification.description}>
                 </ListItemText>
            </ListItem>
            {props.data[index + 1] ? <Divider /> : null}
        </Fragment>
    ));

    return (
        <div className={styles.Wrapper}>
            <List>
                {generateList}
            </List>
        </div>
    );
};

export default NotificationList;