import React from "react";
import AdminView from "./DetailsViews/AdminView";
import RequesterView from "./DetailsViews/RequesterView";

const DetailsDialog = ({
                                   details,
                                   onClose,
                                   open,
                                   isAdmin,
                                   onSubmit,
                                   loading,
                                   downloadFile,
                               }) =>
   isAdmin ?
        <AdminView details={details} onClose={onClose} open={open} onSubmit={onSubmit} loading={loading} downloadFile={downloadFile} /> :
        <RequesterView details={details} onClose={onClose} open={open} loading={loading} downloadFile={downloadFile} />

export default DetailsDialog;
