import axiosInstance from '../../assets/helpers/axiosInstance';
import { setSuccessMessage } from "../../store/success/actions";

export const CREATE_FLEET_START = 'fleets/CREATE_FLEET_START'
export const CREATE_FLEET_SUCCESS = 'fleets/CREATE_FLEET_SUCCESS'
export const CREATE_FLEET_FAIL = 'fleets/CREATE_FLEET_FAIL'

export const UPDATE_FLEET_START = 'fleets/UPDATE_FLEET_START'
export const UPDATE_FLEET_SUCCESS = 'fleets/UPDATE_FLEET_SUCCESS'
export const UPDATE_FLEET_FAIL = 'fleets/UPDATE_FLEET_FAIL'

export const GET_FLEETS_START = 'fleets/GET_FLEETS_START'
export const GET_FLEETS_SUCCESS = 'fleets/GET_FLEETS_SUCCESS'
export const GET_FLEETS_FAIL = 'fleets/GET_FLEETS_FAIL'

export const DELETE_FLEETS_START = 'fleets/DELETE_FLEETS_START'
export const DELETE_FLEETS_SUCCESS = 'fleets/DELETE_FLEETS_SUCCESS'
export const DELETE_FLEETS_FAIL = 'fleets/DELETE_FLEETS_FAIL'

export const createFleet = ({ data: fleet }) => dispatch => {
	dispatch({ type: CREATE_FLEET_START })
	
	return axiosInstance.post('/fleet/addSingleFleet', fleet)
		.then(() => {
			dispatch({ type: CREATE_FLEET_SUCCESS })
			dispatch(setSuccessMessage('Success! Fleet created.'))
		})
		.catch(error => {
			dispatch({
				type: CREATE_FLEET_FAIL,
				error,
			})
			throw error
		})
}

export const updateFleet = ({ data: updatedFleet }) => dispatch => {
	dispatch({ type: UPDATE_FLEET_START })

	return axiosInstance.post('/fleet/updateFleet', updatedFleet)
		.then(res => {
			dispatch({ type: UPDATE_FLEET_SUCCESS, fleet: res.data })
		})
		.catch(error => {
			dispatch({ type: UPDATE_FLEET_FAIL, error })
			throw error
		})
}

export const deleteFleet = fleetNo => dispatch => {
	dispatch({ type: DELETE_FLEETS_START })

	return axiosInstance.delete(`/fleet/deleteFleet/${fleetNo}`)
		.then(() => {
			dispatch({ type: DELETE_FLEETS_SUCCESS, fleetNo })
		})
		.catch(error => {
			dispatch({ type: DELETE_FLEETS_FAIL, error })
			throw error
		})
}

export const getFleets = () => dispatch => {
	dispatch({ type: GET_FLEETS_START })

	return axiosInstance.get('/fleet/getFleet')
		.then(res => {
			if (res.headers['content-type'] !== 'application/json') {
				return dispatch({ type: GET_FLEETS_FAIL, error: new Error('Unknown data format, try logging out and in again.') })
			} 

			dispatch({ type: GET_FLEETS_SUCCESS, fleets: res.data })
		})
		.catch(error => {
			dispatch({ type: GET_FLEETS_FAIL, error })
		})
}
