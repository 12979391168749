import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import React from "react";
import {formatLongDate} from "../../../assets/helpers/globals";
import Dialog from "@material-ui/core/Dialog";
import styled, {css} from "styled-components";
import DialogContent from "@material-ui/core/DialogContent";
import CustomDialogTitle from "../../../components/Dialogs/CustomDialogTitle/CustomDialogTitle";

const H3 = styled.h3`
  margin: 0;
`;

const P = styled.p`
  margin: 0;
  ${props => props.blue && css`color: RoyalBlue;`}
  ${props => props.small && css`font-size: small;`}
`;

const FormDialogContent = styled(DialogContent)`
  @media only screen and (max-width: 425px) {
    padding: 12px !important;
  }
`;

const DetailsDialog = ({
                           details,
                           onClose,
                           open
                   }) => (
<Dialog
    aria-labelledby="tender-details-dialog-title"
    onClose={onClose}
    fullWidth
    maxWidth={"sm"}
    open={open}
>
    <CustomDialogTitle
        id="tender-details-dialog-title"
        onClose={onClose}
        showTextContent
    >
        Tender Details
    </CustomDialogTitle>
    <FormDialogContent dividers>
        <ListItem>
            <ListItemText>
                <H3>Tender Number</H3>
                <P>{details.tenderNumber || "Not specified"}</P>
            </ListItemText>
        </ListItem>
        <ListItem>
            <ListItemText>
                <H3>Tender Description</H3>
                <P>{details.tenderDescription || "Not specified"}</P>
            </ListItemText>
        </ListItem>
        <ListItem>
            <ListItemText>
                <H3>Technical Lead</H3>
                <P>{details.technicalLead?.userFullName || "Not specified"}</P>
            </ListItemText>
        </ListItem>
        <ListItem>
            <ListItemText>
                <H3>Tender Coordinator</H3>
                <P>{details.tenderCoordinator?.userFullName || "Not specified"}</P>
            </ListItemText>
        </ListItem>
        <ListItem>
            <ListItemText>
                <H3>Legal Entity</H3>
                <P>{details.legalEntity ? details.legalEntity?.entityName : details.otherLegalEntity || "Not specified"}</P>
            </ListItemText>
        </ListItem>
        <ListItem>
            <ListItemText>
                <H3>Client Name</H3>
                <P>{details.clientName || "Not specified"}</P>
            </ListItemText>
        </ListItem>
        <ListItem>
            <ListItemText>
                <H3>Profit Centre</H3>
                <P>{details.profitCentres?.centres || "Not specified"}</P>
            </ListItemText>
        </ListItem>
        <ListItem>
            <ListItemText>
                <H3>Due Date</H3>
                <P>{details.dueDate  !== null ? formatLongDate(details.dueDate) + " " + details.dueDateTimeZone : "Not specified"}</P>
            </ListItemText>
        </ListItem>
        <ListItem>
            <ListItemText>
                <H3>Site Visited</H3>
                <P>{details.siteVisit !== null ? formatLongDate(details.siteVisit) : "Not specified"}</P>
            </ListItemText>
        </ListItem>
        <ListItem>
            <ListItemText>
                <H3>Bid Type</H3>
                <P>{details.bidType || "Not specified"}</P>
            </ListItemText>
        </ListItem>
        <ListItem>
            <ListItemText>
                <H3>Bidding Comment</H3>
                <P>{details.bidTypeComment || "Not specified"}</P>
            </ListItemText>
        </ListItem>
        <ListItem>
            <ListItemText>
                <H3>Comments</H3>
                <P>{details.comments || "Not specified"}</P>
            </ListItemText>
        </ListItem>
        <ListItem>
            <ListItemText>
                <H3>Status</H3>
                <P>{details.status || "Not specified"}</P>
            </ListItemText>
        </ListItem>
        <ListItem>
            <ListItemText>
                <H3>Created Date</H3>
                <P>{details.createdDate !== null ? formatLongDate(details.createdDate) : "Not specified"}</P>
            </ListItemText>
        </ListItem>
        <ListItem>
            <ListItemText>
                <H3>Modified Date</H3>
                <P>{details.lastModifiedDate !== null ? formatLongDate(details.lastModifiedDate) : "Not specified"}</P>
            </ListItemText>
        </ListItem>
        <ListItem>
            <ListItemText>
                <H3>Deleted Date</H3>
                <P>{details.deletedAt !== null ? formatLongDate(details.deletedAt) : "Not specified"}</P>
            </ListItemText>
        </ListItem>
    </FormDialogContent>
</Dialog>
)

export default DetailsDialog;