import * as actionTypes from "./actions";

const initialState = {
    invoices: [],
};

const setInvoices = (state, { invoices }) => ({ ...state, invoices });

export default (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_INVOICES:
            return setInvoices(state, action);
        default:
            return state;
    }
};
