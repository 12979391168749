import React from 'react';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import PropTypes from "prop-types";

const SplitButton = ({ options, color, variant, handleClickParent, handleMenuItemClickParent, disabled, containerStyle, selectButtonStyle, dropdownButtonStyle }) => {
    const [openDropdown, setOpenDropdown] = React.useState(false);
    const anchorRef = React.useRef(null);
    const [selectedIndex, setSelectedIndex] = React.useState(0);

    const handleClick = (event) => {
        handleClickParent(event, selectedIndex)
    }

    const handleMenuItemClick = (event, index) => {
        setSelectedIndex(index);
        handleMenuItemClickParent(event, index);
        handleClose(event);
    }

    const handleToggle = () => {
        setOpenDropdown((prevOpen) => !prevOpen);
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target))
            return;

        setOpenDropdown(false);
    };

    return (
        <Grid container direction="column" alignItems="center" style={containerStyle}>
            <Grid item xs={12}>
                <ButtonGroup variant={variant} color={color} ref={anchorRef} aria-label="split button" disabled={disabled}>
                    <Button onClick={(event) => handleClick(event)} style={selectButtonStyle}>{options.length ? options[selectedIndex] : []}</Button>
                    <Button
                        color={color}
                        size="small"
                        aria-controls={openDropdown ? 'split-button-menu' : undefined}
                        aria-expanded={openDropdown ? 'true' : undefined}
                        aria-label="select add purchase request"
                        aria-haspopup="menu"
                        onClick={(event) => handleToggle(event)}
                        style={dropdownButtonStyle}
                    >
                        <ArrowDropDownIcon />
                    </Button>
                </ButtonGroup>
                <Popper open={openDropdown} anchorEl={anchorRef.current} role={undefined} transition disablePortal style={{ zIndex: "11" }}>
                    {({ TransitionProps, placement }) => (
                        <Grow
                            {...TransitionProps}
                            style={{
                                transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
                            }}
                        >
                            <Paper>
                                <ClickAwayListener onClickAway={handleClose}>
                                    <MenuList id="split-button-menu">
                                        {options.length ? options.map((option, index) => (
                                            <MenuItem
                                                key={option}
                                                selected={index === selectedIndex}
                                                onClick={(event) => handleMenuItemClick(event, index)}
                                            >
                                                {option}
                                            </MenuItem>
                                        )) : ""}
                                    </MenuList>
                                </ClickAwayListener>
                            </Paper>
                        </Grow>
                    )}
                </Popper>
            </Grid>
        </Grid>
    );
}

SplitButton.propTypes = {
    disabled: PropTypes.bool,
    options: PropTypes.array.isRequired,
    color: PropTypes.string,
    variant: PropTypes.string,
    handleClickParent: PropTypes.func.isRequired,
    handleMenuItemClickParent: PropTypes.func.isRequired,
};

export default SplitButton;