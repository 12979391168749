import * as actionTypes from './actions';

const initialState = {
    timeZones: []
};

const setTimeZones = (state, { timeZones }) => ({ ...state, timeZones });

export default (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_TIME_ZONES:
            return setTimeZones(state, action);
        default:
            return state;
    }
};