import React from "react";
import { Form } from 'react-final-form'
import CustomDialogTitle from "../../components/Dialogs/CustomDialogTitle/CustomDialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Dialog from "@material-ui/core/Dialog";
import Grid from "@material-ui/core/Grid";
import { Radios, TextField } from "mui-rff";
import Button from "@material-ui/core/Button";
import Loader from "../../components/Loader/Loader";
import * as loadingTypes from "../../store/loading/types";
import styled from 'styled-components';
import {formatShortDate, sleep} from "../../assets/helpers/globals";
import { DialogActions } from "@material-ui/core";

const LoadingDiv = styled.div`
  position: absolute;
  bottom: 11px;
  right: 36px;
`;

const FormDialogContent = styled(DialogContent)`
  @media only screen and (max-width: 425px) {
    padding: 12px !important;
  }
`;

const ActionButton = styled(Button)`
  margin: 0 5px;
`;

function NewRequestDialog({
                              open,
                              onClose,
                              onSubmit,
                              initialValues,
                              loading
}) {
    const formFields = [
        {
            size: 12,
            field: (
                <Radios
                    label="Category"
                    name="category"
                    formControlProps={{ margin: 'none' }}
                    radioGroupProps={{ row: true }}
                    data={[
                        { label: 'Hardware', value: 'Hardware' },
                        { label: 'Software', value: 'Software' },
                        { label: 'EPCM Portal Bug', value: 'EPCM Portal Bug' },
                    ]}
                    required
                />
            ),
        },
        {
            size: 12,
            field: (
                <Radios
                    label="Location"
                    name="location"
                    formControlProps={{ margin: 'none' }}
                    radioGroupProps={{ row: true }}
                    data={[
                        { label: 'Office', value: 'Office' },
                        { label: 'Workshop', value: 'Workshop' },
                        { label: 'Site', value: 'Site' },
                    ]}
                    required
                />
            ),
        },
        {
            size: 12,
            field: (
                <TextField
                    label="Description"
                    name="description"
                    margin="none"
                    multiline
                    required
                />
            ),
        },
        {
            size: 12,
            field: (
                <TextField
                    label="Additional Info"
                    name="additionalInfo"
                    margin="none"
                    multiline
                />
            ),
        },
    ];

    const validate = values => {
        const errors = {}
        if (!values.description)
            errors.description = 'Required'

        return errors
    }

    const onSubmitForm = async values => {
        let formData = new FormData();

        formData.append('category', values.category);
        formData.append('location', values.location);
        formData.append('description', values.description);
        formData.append('additionalInfo', values.additionalInfo);
        formData.append('status', "Queued");
        formData.append('requestSubmittedDate', formatShortDate(new Date()));

        await onSubmit(formData);
    }

    let submit;

    return (
        <Form
            onSubmit={onSubmitForm}
            validate={validate}
            initialValues={initialValues ? initialValues : { category: "Hardware", location: "Office", additionalInfo: "" }}
            render={({ handleSubmit, submitting, form, pristine }) => {
                submit = handleSubmit
                return (
                    <Dialog
                        aria-labelledby="add-support-request-dialog-title"
                        onClose={onClose}
                        fullWidth
                        maxWidth={"sm"}
                        open={open}
                    >
                        <CustomDialogTitle
                            id="add-support-request-dialog-title"
                            onClose={onClose}
                            showTextContent
                        >
                            Add Support Request
                        </CustomDialogTitle>
                        <FormDialogContent dividers>
                            <form onSubmit={handleSubmit} noValidate>
                                <Grid container alignItems="flex-start" spacing={2}>
                                    {formFields.map((item, idx) => (
                                        <Grid item xs={item.size} key={idx}>
                                            {item.field}
                                        </Grid>
                                    ))}
                                    </Grid>
                            </form>
                        </FormDialogContent>
                        <DialogActions>
                            <ActionButton
                                onClick={onClose}
                                type="button"
                                disabled={submitting}
                                color="primary"
                            >
                                Cancel
                            </ActionButton>
                            <ActionButton
                                type="button"
                                onClick={form.reset}
                                color="primary"
                                disabled={submitting || pristine}
                            >
                                Reset
                            </ActionButton>
                            <ActionButton
                                onClick={event => {
                                    const promise = submit(event);
                                    promise && promise.then(async() => {
                                        await sleep(300);
                                        form.reset();
                                    })
                                    return promise;
                                }}
                                type="submit"
                                disabled={submitting || pristine}
                                color="default"
                            >
                                Submit
                            </ActionButton>
                            <LoadingDiv>
                                <Loader loading={loading.type === loadingTypes.supportAddSupportRequest} size="small" />
                            </LoadingDiv>
                        </DialogActions>
                    </Dialog>
                )
            }
        }
    />
    )
}

export default NewRequestDialog;
