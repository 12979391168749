import React from "react";
import Dialog from "@material-ui/core/Dialog";
import CustomDialogTitle from "../../../components/Dialogs/CustomDialogTitle/CustomDialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { formatShortDate } from "../../../assets/helpers/globals";
import Loader from "../../../components/Loader/Loader";
import * as loadingTypes from "../../../store/loading/types";
import styled, {css} from "styled-components";
import Grid from "@material-ui/core/Grid";
import {Form} from "react-final-form";
import {Radios, TextField} from "mui-rff";
import Button from "@material-ui/core/Button";
import {DialogActions} from "@material-ui/core";
import {focusOnError} from "../../../assets/helpers/finalFormHelpers";

const LoadingDiv = styled.div`
  position: absolute;
  bottom: 11px;
  right: 36px;
`;

const H3 = styled.h3`
  margin: 0;
`;

const P = styled.p`
  margin: 0;
`;

const ActionButton = styled(Button)`
  margin: 0 5px;
`;

const FormDialogContent = styled(DialogContent)`
  @media only screen and (max-width: 425px) {
    padding: 12px !important;
  }
`;

const FormInput = styled.form`
  padding: 8px 16px;
`;

const StatusRadios = styled(Radios)`
  ${props => props.data[0]?.label === "Resolved" && css`&.Mui-checked { color: Green }`}
  ${props => props.data[0]?.label === "Unresolved" && css`&.Mui-checked { color: Red }`}
  ${props => props.data[0]?.label === "In Progress" && css`&.Mui-checked { color: RoyalBlue }`}
`;

const AdminView = ({
                                   details,
                                   onClose,
                                   open,
                                   onSubmit,
                                   loading
                               }) => {
    const formFields =
        (details.status === "In Progress" || details.status === "Resolved" || details.status === "Unresolved") ?
            [
                {
                    externalLabel: "Status",
                    size: 12,
                    field: (
                        <>
                            <StatusRadios
                                name="status"
                                formControlProps={{ margin: 'none'}}
                                radioGroupProps={{ row: true }}
                                data={[
                                    { label: 'Resolved', value: "Resolved" },
                                ]}
                            />
                            <StatusRadios
                                name="status"
                                formControlProps={{ margin: 'none'}}
                                radioGroupProps={{ row: true }}
                                data={[
                                    { label: 'Unresolved', value: "Unresolved" },
                                ]}
                                showError={() => false}
                            />
                        </>
                    ),
                },
                {
                    externalLabel: "Comment",
                    size: 12,
                    field: (
                        <TextField
                            name="comment"
                            margin="none"
                            required
                            placeholder={"Comment"}
                            multiline
                        />
                    ),
                },
            ] :
            [
                {
                    externalLabel: "Status",
                    size: 12,
                    field: (
                        <StatusRadios
                            name="status"
                            formControlProps={{ margin: 'none' }}
                            radioGroupProps={{ row: true }}
                            data={[
                                { label: 'In Progress', value: "In Progress" },
                            ]}
                        />
                    ),
                },
            ];

    const validate = values => {
        const errors = {}
        if (!values.status) {
            errors.status = 'Required'
        }

        if (values.status === "Unresolved")
            if (!values.comment) {
                errors.comment = 'Required'
            }
        return errors
    }

    let submit;

    return <Form
        onSubmit={(values) => onSubmit({ ...values, responseDate: formatShortDate(new Date()) })}
        validate={validate}
        decorators={[focusOnError]}
        initialValues={
            details.status !== "Queued" && details.status !== "In Progress" ?
                { status: details.status, comment: details.comment } :
                {}
        }
        render={({ handleSubmit, submitting, pristine }) => {
            submit = handleSubmit
            return (
                <Dialog
                    aria-labelledby="support-request-details-dialog-title"
                    onClose={onClose}
                    fullWidth
                    maxWidth={"sm"}
                    open={open}
                >
                    <CustomDialogTitle
                        id="support-request-details-dialog-title"
                        onClose={onClose}
                        showTextContent
                    >
                        Support Request Details
                    </CustomDialogTitle>
                    <FormDialogContent dividers>
                        <ListItem>
                            <ListItemText>
                                <H3>Category</H3>
                                <P>{details.category || "Not specified"}</P>
                            </ListItemText>
                        </ListItem>
                        <ListItem>
                            <ListItemText>
                                <H3>Location</H3>
                                <P>{details.location || "Not specified"}</P>
                            </ListItemText>
                        </ListItem>
                        <ListItem>
                            <ListItemText>
                                <H3>Description</H3>
                                <P>{details.description || "Not specified"}</P>
                            </ListItemText>
                        </ListItem>
                        <ListItem>
                            <ListItemText>
                                <H3>Additional Info</H3>
                                <P>{details.additionalInfo || "Not specified"}</P>
                            </ListItemText>
                        </ListItem>
                        <ListItem>
                            <ListItemText>
                                <H3>Submitted Date</H3>
                                <P>{details.requestSubmittedDate || "Not specified"}</P>
                            </ListItemText>
                        </ListItem>
                        <ListItem>
                            <ListItemText>
                                <H3>Response Date</H3>
                                <P>{details.responseDate || "Not specified"}</P>
                            </ListItemText>
                        </ListItem>
                        <ListItem>
                            <ListItemText>
                                <H3>Support Requestor</H3>
                                <P>{details.requestor?.userFullName || "Not specified"}</P>
                            </ListItemText>
                        </ListItem>
                        <FormInput onSubmit={handleSubmit} noValidate>
                            <Grid container alignItems="flex-start" spacing={1}>
                                {formFields.map((item, idx) => (
                                    <Grid item xs={item.size} key={idx}>
                                        <ListItemText>
                                            <H3>{item.externalLabel}</H3>
                                            {item.field}
                                        </ListItemText>
                                    </Grid>
                                ))}
                            </Grid>
                        </FormInput>
                    </FormDialogContent>

                    <DialogActions>
                        <ActionButton
                            onClick={onClose}
                            type="button"
                            disabled={submitting}
                            color="primary"
                        >
                            Cancel
                        </ActionButton>
                        <ActionButton
                            onClick={event => submit(event)}
                            type="submit"
                            disabled={submitting || pristine}
                            color="default"
                        >
                            Submit
                        </ActionButton>
                        <LoadingDiv>
                            <Loader loading={loading.type === loadingTypes.supportUpdateSupportRequestStatus} size="small"/>
                        </LoadingDiv>
                    </DialogActions>
                </Dialog>
            )
        }}
    />
}

export default AdminView;
