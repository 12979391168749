import * as actionTypes from './actions';

const initialState = {
    currenciesByLegalEntity: [],
    allCurrencies: []
};

const setCurrenciesByLegalEntity = (state, { currenciesByLegalEntity }) => ({ ...state, currenciesByLegalEntity });
const setAllCurrencies = (state, { allCurrencies }) => ({ ...state, allCurrencies });

export default (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_CURRENCIES_BY_LEGAL_ENTITY:
            return setCurrenciesByLegalEntity(state, action);
        case actionTypes.SET_ALL_CURRENCIES:
            return setAllCurrencies(state, action);
        default:
            return state;
    }
};