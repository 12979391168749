import * as actionTypes from "./actions";

const initialState = {
    allPurchases: [],
    purchaseProjects: [],
    purchaseTenders: [],
    purchaseItEquipment: [],
    purchaseOfficeEquipment: [],
    purchaseOthers: [],
};

const setAllPurchases = (state, { allPurchases }) => ({ ...state, allPurchases });
const setPurchaseProjects = (state, { purchaseProjects }) => ({ ...state, purchaseProjects });
const setPurchaseTenders = (state, { purchaseTenders }) => ({ ...state, purchaseTenders });
const setPurchaseItEquipment = (state, { purchaseItEquipment }) => ({ ...state, purchaseItEquipment });
const setPurchaseOfficeEquipment = (state, { purchaseOfficeEquipment }) => ({ ...state, purchaseOfficeEquipment });
const setPurchaseOthers = (state, { purchaseOthers }) => ({ ...state, purchaseOthers });

export default (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_ALL_PURCHASES:
            return setAllPurchases(state, action);
        case actionTypes.SET_PURCHASE_PROJECTS:
            return setPurchaseProjects(state, action);
        case actionTypes.SET_PURCHASE_TENDERS:
            return setPurchaseTenders(state, action);
        case actionTypes.SET_PURCHASE_IT_EQUIPMENT:
            return setPurchaseItEquipment(state, action);
        case actionTypes.SET_PURCHASE_OFFICE_EQUIPMENT:
            return setPurchaseOfficeEquipment(state, action);
        case actionTypes.SET_PURCHASE_OTHERS:
            return setPurchaseOthers(state, action);
        default:
            return state;
    }
};
