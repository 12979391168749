import React from 'react';
import SunEditor from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css'; // Import Sun Editor's CSS File

/*
	buttonList.basic = basic buttons for wordprocessing
	buttonList.formatting = most tools used for formatting - This is the default option
	buttonList.complex = contains most of the buttons
*/

const buttonList = [
    // ['undo', 'redo'],
    // ['font', 'fontSize', 'formatBlock'],
    ['fontSize', 'formatBlock'],
    // ['paragraphStyle', 'blockquote'],
    ['bold', 'underline', 'italic'], //'strike', 'subscript', 'superscript'],
    // ['fontColor', 'hiliteColor', 'textStyle'],
    // ['removeFormat'],
    ['outdent', 'indent'],
    // ['align', 'horizontalRule', 'list', 'lineHeight'],
    ['align', 'list'],
    // ['table', 'link'],//, 'image', 'video', 'math'], // You must add the 'katex' library at options to use the 'math' plugin.
    // ['imageGallery'], // You must add the "imageGalleryUrl".
    ['fullScreen', 'link'],//, 'showBlocks', 'codeView'],
    // ['preview', 'print'],
    // ['print'],
    // ['save', 'template'],
    //  '/', //Line break
];

const RichTextEditor = props => {
  return (
    <div>
      <SunEditor
        width='100%'
        placeholder={props.placeholder}
        setOptions={{
            height: 100,
            buttonList: buttonList 
        }}
        setContents={props.initialValue}
        onBlur={props.handleRichTextEditorBlur} />
    </div>
  );
};
export default RichTextEditor;