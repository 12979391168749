import React, { Component, Fragment } from 'react';

import PropTypes from 'prop-types'; 
import clsx from 'clsx';
import Logo from '../../Logo/Logo';
import SidebarNavigationItems from './SidebarNavigationItems/SidebarNavigationItems';
import classes from './Sidebar.module.css';
import Backdrop from '../../Backdrop/Backdrop';

class Sidebar extends Component{

    shouldComponentUpdate(prevProps, prevState){
        return !(this.props.open === prevProps.open && this.props.isMobile === prevProps.isMobile);
    }

    render(){
        const attachedClasses = this.props.open ? clsx([classes.Sidebar, classes.Open]) : clsx([classes.Sidebar, classes.Close]);

        const backdrop = this.props.isMobile ? (<Backdrop show={this.props.open} clicked={this.props.closed}/>) : null;
        const background = this.props.open ? clsx([classes.Background, classes.Open]) : clsx([classes.Background, classes.Close]);
       
        return (
            <Fragment>
                {backdrop}
                <div className={attachedClasses}>
                    <div className={background}/>
                    <div className={classes.Logo}>
                        <Logo light />
                    </div>
                    <div className={classes.NavigationItems}>
                        <SidebarNavigationItems />
                    </div>
                </div>
         </Fragment>
        );
    }
};

export default Sidebar;

Sidebar.propTypes = {
 open : PropTypes.bool,
 closed : PropTypes.func,
 isMobile : PropTypes.bool
};