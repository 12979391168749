import moment from "moment";
import axios from "axios";
import axiosInstance from "../../assets/helpers/axiosInstance";
import { setError } from "../error/actions";
import * as errorTypes from "../error/types";
import { endLoading, startLoading } from "../loading/actions";
import * as loadingTypes from "../loading/types";
import { setSuccessMessage } from "../success/actions";

export const SET_VEHICLE_BOOKINGS = "vehicles/SET_VEHICLE_BOOKINGS";

const setVehicleBookings = bookings => ({
	type: SET_VEHICLE_BOOKINGS,
	bookings,
});

export const getVehicleBookings = () => async dispatch => {
	const timeMin = moment()
		.set("hour", 0)
		.set("minute", 0)
		.set("second", 0)
		.subtract(1, "months")
		.format();
	const timeMax = moment()
		.set("hour", 0)
		.set("minute", 0)
		.set("second", 0)
		.add(1, "months")
		.format();

	const params = { timeMin, timeMax };

	dispatch(startLoading(loadingTypes.vehicleGetBookings));
	try {
		const res = await axiosInstance.get("/vehicle/vehicleBookings", { params });
		dispatch(setVehicleBookings(res.data));
	} catch (err) {
		dispatch(setError(errorTypes.vehicleGetBookings, `${err.message ? err.message + " at " + errorTypes.vehicleGetBookings : "Something went wrong at " + errorTypes.vehicleGetBookings }`));
	} finally {
		dispatch(endLoading());
	}
};

export const createVehicleBooking = payload => async dispatch => {
	dispatch(startLoading(loadingTypes.vehicleCreateBooking));
	try {
		await axiosInstance.post("/vehicle/vehicleBookings", payload);
		dispatch(setSuccessMessage("Success! Booking request created."));
	} catch (err) {
		dispatch(setError(errorTypes.vehicleCreateBooking, `${err.message ? err.message + " at " + errorTypes.vehicleCreateBooking : "Something went wrong at " + errorTypes.vehicleCreateBooking }`));
	} finally {
		dispatch(endLoading());
	}
};

export const updateVehicleBooking = payload => async dispatch => {
	const { uniqueId } = payload;
	dispatch(startLoading(loadingTypes.vehicleUpdateBooking));
	try {
		await axiosInstance.post(`/vehicle/bookings?uniqueId=${uniqueId}`, payload);
		dispatch(setSuccessMessage("Success! Booking request updated."));
	} catch (err) {
		dispatch(setError(errorTypes.vehicleUpdateBooking, `${err.message ? err.message + " at " + errorTypes.vehicleUpdateBooking : "Something went wrong at " + errorTypes.vehicleUpdateBooking }`));
	} finally {
		dispatch(endLoading());
	}
};

export const deleteVehicleBooking = params => async (dispatch, getState) => {
	const { uniqueId } = params;

	const removeBooking = () => {
		const { bookings } = getState().vehiclesReducer;
		const newBookings = bookings.filter(
			booking => booking.vehicle.UniqueID !== uniqueId
		);
		dispatch(setVehicleBookings(newBookings));
	};

	dispatch(startLoading(loadingTypes.vehicleDeleteBooking));
	try {
		await axiosInstance.delete(
			`/vehicle/vehicleBookings?uniqueId=${uniqueId}`,
			{ params }
		);
		dispatch(setSuccessMessage("Success! Booking cancelled."));
		removeBooking();
	} catch (err) {
		dispatch(setError(errorTypes.vehicleDeleteBooking, `${err.message ? err.message + " at " + errorTypes.vehicleDeleteBooking : "Something went wrong at " + errorTypes.vehicleDeleteBooking }`));
	} finally {
		dispatch(endLoading());
	}
};

export const respondToVehicleBooking = payload => async dispatch => {
	const { uniqueId } = payload;
	dispatch(startLoading(loadingTypes.vehicleRespondToBooking));
	try {
		await axiosInstance.post(`vehicle/vehicleAssessment/${uniqueId}`, payload);
		dispatch(setSuccessMessage("Success! Response submitted."));
	} catch (err) {
		dispatch(setError(errorTypes.vehicleRespondToBooking, `${err.message ? err.message + " at " + errorTypes.vehicleRespondToBooking : "Something went wrong at " + errorTypes.vehicleRespondToBooking }`));
	} finally {
		dispatch(endLoading());
	}
};

export const createInspectionVehicleBooking = (
	payload,
	formData
) => async dispatch => {
	const { uniqueId, type } = payload;
	dispatch(startLoading(loadingTypes.vehicleCreateInspection));

	try {
		await axios.post(
			`/inspection/${
				type === "before" ? "checkout" : "checkin"
			}?uniqueId=${uniqueId}`,
			formData,
			{ headers: { "Content-Type": "multipart/form-data" } }
		);
		dispatch(setSuccessMessage("Success! Inspection submitted."));
	} catch (err) {
		dispatch(setError(errorTypes.vehicleCreateInspection, `${err.message ? err.message + " at " + errorTypes.vehicleCreateInspection : "Something went wrong at " + errorTypes.vehicleCreateInspection }`));
	} finally {
		dispatch(endLoading());
	}
};
