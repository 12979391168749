import { setSuccessMessage } from "../success/actions";
import axiosInstance from '../../assets/helpers/axiosInstance';
import {setError} from "../error/actions";

export const GET_BOARDROOM_RESOURCES_START = 'boardrooms/GET_BOARDROOM_RESOURCES_START';
export const GET_BOARDROOM_RESOURCES_SUCCESS = 'boardrooms/GET_BOARDROOM_RESOURCES_SUCCESS';
export const GET_BOARDROOM_RESOURCES_FAIL = 'boardrooms/GET_BOARDROOM_RESOURCES_FAIL';

export const GET_BOARDROOM_EVENTS_START = 'boardrooms/GET_BOARDROOM_EVENTS_START';
export const GET_BOARDROOM_EVENTS_SUCCESS = 'boardrooms/GET_BOARDROOM_EVENTS_SUCCESS';
export const GET_BOARDROOM_EVENTS_FAIL = 'boardrooms/GET_BOARDROOM_EVENTS_FAIL';
export const GET_BOARDROOM_EVENTS_LOADED = 'boardrooms/GET_BOARDROOM_EVENTS_LOADED';

export const GET_BOARDROOM_EVENT_START = 'boardrooms/GET_BOARDROOM_EVENT_START';
export const GET_BOARDROOM_EVENT_SUCCESS = 'boardrooms/GET_BOARDROOM_EVENT_SUCCESS';
export const GET_BOARDROOM_EVENT_FAIL = 'boardrooms/GET_BOARDROOM_EVENT_FAIL';

export const CREATE_BOARDROOM_EVENT_START = 'boardrooms/CREATE_BOARDROOM_EVENT_START';
export const CREATE_BOARDROOM_EVENT_SUCCESS = 'boardrooms/CREATE_BOARDROOM_EVENT_SUCCESS';
export const CREATE_BOARDROOM_EVENT_FAIL = 'boardrooms/CREATE_BOARDROOM_EVENT_FAIL';

export const UPDATE_BOARDROOM_EVENT_START = 'boardrooms/UPDATE_BOARDROOM_EVENT_START';
export const UPDATE_BOARDROOM_EVENT_SUCCESS = 'boardrooms/UPDATE_BOARDROOM_EVENT_SUCCESS';
export const UPDATE_BOARDROOM_EVENT_FAIL = 'boardrooms/UPDATE_BOARDROOM_EVENT_FAIL';

export const DELETE_BOARDROOM_EVENT_START = 'boardrooms/DELETE_BOARDROOM_EVENT_START';
export const DELETE_BOARDROOM_EVENT_SUCCESS = 'boardrooms/DELETE_BOARDROOM_EVENT_SUCCESS';
export const DELETE_BOARDROOM_EVENT_FAIL = 'boardrooms/DELETE_BOARDROOM_EVENT_FAIL';

export const RESPOND_TO_BOARDROOM_EVENT_START = 'boardrooms/RESPOND_TO_BOARDROOM_EVENT_START';
export const RESPOND_TO_BOARDROOM_EVENT_SUCCESS = 'boardrooms/RESPOND_TO_BOARDROOM_EVENT_SUCCESS';
export const RESPOND_TO_BOARDROOM_EVENT_FAIL = 'boardrooms/RESPOND_TO_BOARDROOM_EVENT_FAIL';

// TODO: This here doesn't seem to be used
export const getBoardroomResources = ( payload ) => {
    return (dispatch) => {
        dispatch({ type : GET_BOARDROOM_RESOURCES_START });
        return axiosInstance.get( '/resource/all/' + payload.type)
            .then( res => dispatch({ type: GET_BOARDROOM_RESOURCES_SUCCESS, resources: res.data }))
            .catch(error => 
                dispatch(
                    {
                        type: GET_BOARDROOM_RESOURCES_FAIL,
                        error,
                        errorMessage : error.message
                    }
                )
            );
    };
};

export const getBoardroomEvents = (params) => {
    return (dispatch) => {
        dispatch({ type : GET_BOARDROOM_EVENTS_START });
        const token = params.cancelToken.token;

        return axiosInstance.get('/calendar/EventsForBoardroom', { params, token })
            .then( res => {
                // TODO: The Fuck this check?
                // const contentType = res.headers['content-type'];
                // if(contentType && contentType.indexOf('application/json') !== -1)
                (res.status && res.status === 200) && dispatch(
                    {
                        type: GET_BOARDROOM_EVENTS_SUCCESS,
                        events: res.data,
                    }
                );
            })
            .catch((err) => {
                dispatch({ type : GET_BOARDROOM_EVENTS_FAIL });
                if (err.response || err.request) {
                    dispatch(setError(GET_BOARDROOM_EVENTS_FAIL, `${err.message ? err.message + " at " + GET_BOARDROOM_EVENTS_FAIL : "Something went wrong at " + GET_BOARDROOM_EVENTS_FAIL }`))
                }
            });
    };
};

export const createBoardroomEvent = ( payload ) => {
    return (dispatch) => {
        dispatch({ type : CREATE_BOARDROOM_EVENT_START });
        return axiosInstance.post('/calendar/book', payload.data)
            .then(() => {
                dispatch({ type: CREATE_BOARDROOM_EVENT_SUCCESS });
                dispatch(setSuccessMessage('Success! Booking created.'));
            })
            .catch(error => {
                dispatch({type: CREATE_BOARDROOM_EVENT_FAIL});
                dispatch(setError(CREATE_BOARDROOM_EVENT_FAIL, error.response.data.message))
            });
    };
};

export const updateBoardroomEvent = ( payload ) => {
    return (dispatch) => {
        dispatch({ type : UPDATE_BOARDROOM_EVENT_START });
        const {param: prevResourceId, type} = payload;
        const params = { prevResourceId, type };
        return axiosInstance.put('/calendar/update_event', payload.data, { params })
            .then(() => {
                dispatch({ type: UPDATE_BOARDROOM_EVENT_SUCCESS });
                dispatch(setSuccessMessage('Success! Booking updated.'));
            })
            .catch(error => {
                dispatch({type: UPDATE_BOARDROOM_EVENT_FAIL});
                dispatch(setError(UPDATE_BOARDROOM_EVENT_FAIL, error.response.data.message))
            });
    };
};

export const deleteBoardroomEvent = (payload) => {
    return (dispatch) => {
        dispatch({ type : DELETE_BOARDROOM_EVENT_START });
        const { id: eventId, resourceId, recurringEventId, type} = payload;
        let params = { eventId,resourceId, recurringEventId, type };
        return axiosInstance.delete('/calendar/delete_event', { params }) 
            .then(() => {
                dispatch({ type: DELETE_BOARDROOM_EVENT_SUCCESS });
                dispatch(setSuccessMessage('Success! Booking cancelled.'));
            })
            .catch(error => {
                dispatch({type: DELETE_BOARDROOM_EVENT_FAIL});
                dispatch(setError(DELETE_BOARDROOM_EVENT_FAIL, error.response.data.message))
            });
    };
};

export const respondToBoardroomEvent = ( payload ) => {
    return (dispatch) => {
        const { response, eventId, resourceId, recurringEventId, type } = payload;
        dispatch({ type : RESPOND_TO_BOARDROOM_EVENT_START, response });
        let params = { eventId, resourceId, response, recurringEventId, type };
        return axiosInstance.put('/calendar/response', null, { params })
            .then(() => {
                dispatch({ type: RESPOND_TO_BOARDROOM_EVENT_SUCCESS, events : null });
                dispatch(setSuccessMessage('Success! Response saved.'));
            })
            .catch(error => {
                dispatch({type: RESPOND_TO_BOARDROOM_EVENT_FAIL});
                dispatch(setError(RESPOND_TO_BOARDROOM_EVENT_FAIL, error.response.data.message))
            });
    };
};
