import React, { Fragment } from "react";

import PropTypes from "prop-types";
import classes from "./Navbar.module.css";
// import Logo from "../../Logo/Logo";
// import NavbarNavigationItems from "./NavbarNavigationItems/NavbarNavigationItems";

const Navbar = () => {
	return (
		<header className={classes.Navbar}>
			<Fragment>
				{/*<div className={classes.Logo}>*/}
				{/*	<Logo light />*/}
				{/*</div>*/}
				{/*<nav>*/}
				{/*	<NavbarNavigationItems />*/}
				{/*</nav>*/}
			</Fragment>
		</header>
	);
};

export default Navbar;

Navbar.propTypes = {
	toggleClicked: PropTypes.func,
	layout: PropTypes.string,
};
