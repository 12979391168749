import React, {useEffect, useState} from "react";
import Grid from "@material-ui/core/Grid";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Paper from "@material-ui/core/Paper";
import TabPanel from "../../../components/TabPanel/TabPanel";
import styled from "styled-components";
import {connect} from "react-redux";
import {getPermissionsByRole} from "../../../store/permissions/actions";
import ApprovedPurchases from "./ApprovedPurchases";
import XeroReadyPurchaseOrders from "./XeroReadyPurchaseOrders";
import PurchaseOrdersSubmittedToXero from "./PurchaseOrdersSubmittedToXero";

const TabPaper = styled(Paper)`
  flex-grow: 1; 
  margin-bottom: 32px;
`;

const PurchasesTabs = ({ getUserRoles }) => {
    const [selectedTab, setSelectedTab] = useState(0)
    const [tabs, setTabs] = React.useState([]);
    const [tabsContent, setTabsContent] = React.useState([]);

    const handleTabChange = (_, newTab) => {
        setSelectedTab(newTab)
    };

    useEffect(() => {
        (async function() {
            const res = await getUserRoles("purchase","order");

            if (res.some(name => name !== "purchase_order_creator") || res.some(name => name !== "purchase_order_viewer")) {
                let approvedPurchaseIndex = 0;
                let xeroReadyPurchaseOrderIndex = 1;
                let purchaseOrderSubmittedToXeroIndex = 2;

                if (res.length && res.length > 0) {
                    setTabs(tabs => [
                        {
                            label: "Approved Purchases",
                            key: approvedPurchaseIndex
                        },
                        {
                            label: "Xero Ready PO's",
                            key: xeroReadyPurchaseOrderIndex
                        },
                        {
                            label: "PO's Submitted To Xero",
                            key: purchaseOrderSubmittedToXeroIndex
                        }
                    ])
                    setTabsContent([
                        {
                            index: approvedPurchaseIndex,
                            key: approvedPurchaseIndex,
                            children: <ApprovedPurchases/>
                        },
                        {
                            index: xeroReadyPurchaseOrderIndex,
                            key: xeroReadyPurchaseOrderIndex,
                            children: <XeroReadyPurchaseOrders/>
                        },
                        {
                            index: purchaseOrderSubmittedToXeroIndex,
                            key: purchaseOrderSubmittedToXeroIndex,
                            children: <PurchaseOrdersSubmittedToXero/>
                        }
                    ])
                }
            }
        })();
    }, [getUserRoles])

    return (
        <>
            <Grid container alignItems="center" justify="center">
                <Grid item xs={12}>
                    <TabPaper>
                        <Tabs
                            value={selectedTab}
                            onChange={handleTabChange}
                            variant="scrollable"
                            scrollButtons="auto"
                        >
                            {tabs.map(({key, label}) => <Tab label={label} key={key}/>)}
                        </Tabs>
                    </TabPaper>
                    {tabsContent.map(({index, key, children}) =>
                        <TabPanel value={selectedTab} index={index} key={key}>{children}</TabPanel>
                    )}
                </Grid>
            </Grid>
        </>
    );
}

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => ({
    getUserRoles: (roleType, roleSubType) => dispatch(getPermissionsByRole(roleType, roleSubType)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PurchasesTabs);