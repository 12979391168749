import axiosInstance from '../../assets/helpers/axiosInstance';
import { setSuccessMessage } from "../success/actions";
import { endLoading, startLoading } from "../loading/actions";
import * as loadingTypes from "../loading/types";
import {setError} from "../error/actions";
import * as errorTypes from "../error/types";

export const SET_ALL_PURCHASE_ORDERS = "purchaseOrders/SET_ALL_PURCHASE_ORDERS";
export const SET_APPROVED_PURCHASES = "purchaseOrders/SET_APPROVED_PURCHASES";

const setAllPurchaseOrders = allPurchaseOrders => ({ type: SET_ALL_PURCHASE_ORDERS, allPurchaseOrders });
const setApprovedPurchases = approvedPurchases => ({ type: SET_APPROVED_PURCHASES, approvedPurchases });

// START: Approved Purchases
//region
export const getApprovedPurchases = () => async dispatch => {
    dispatch(startLoading(loadingTypes.purchaseOrdersGetApprovedPurchases));
    try {
        const res = await axiosInstance.get("/purchases/getApprovedPurchases");
        dispatch(setApprovedPurchases(res.data));
    } catch (err) {
        dispatch(setError(errorTypes.purchaseOrdersGetApprovedPurchases, `${err.message ? err.message + " at " + errorTypes.purchaseOrdersGetApprovedPurchases : "Something went wrong at " + errorTypes.purchaseOrdersGetApprovedPurchases }`));
    } finally {
        dispatch(endLoading());
    }
};

export const createPurchaseOrder = (id, type) => async dispatch => {
    dispatch(startLoading(loadingTypes.purchaseOrdersCreatePurchaseOrder));
    try {
        await axiosInstance.post(`/purchaseOrder/generatePo?uniqueId=${id}&type=${type}`);
        dispatch(setSuccessMessage("Success! Purchase order has been created."));
    } catch (err) {
        dispatch(setError(errorTypes.purchaseOrdersCreatePurchaseOrder, `${err.message ? err.message + " at " + errorTypes.purchaseOrdersCreatePurchaseOrder : "Something went wrong at " + errorTypes.purchaseOrdersCreatePurchaseOrder }`));
    } finally {
        dispatch(endLoading());
    }
}

export const updatePurchaseRequestStatus = (id, data) => async dispatch => {
    dispatch(startLoading(loadingTypes.purchaseOrdersUpdatePurchaseRequestStatus));
    try {
        await axiosInstance.post(`/purchases/updatePurchaseStatus?uniqueId=${id}`, data);
        dispatch(setSuccessMessage("Success! Purchase request status updated."));
    } catch (err) {
        dispatch(setError(errorTypes.purchaseOrdersUpdatePurchaseRequestStatus, `${err.message ? err.message + " at " + errorTypes.purchaseOrdersUpdatePurchaseRequestStatus : "Something went wrong at " + errorTypes.purchaseOrdersUpdatePurchaseRequestStatus }`));
    } finally {
        dispatch(endLoading());
    }
};

export const deletePurchaseOrder = (id, type) => async (dispatch) => {
    dispatch(startLoading(loadingTypes.purchaseOrdersDeletePurchaseOrder));
    try {
        let resp = await axiosInstance.delete(`/purchaseOrder/deletePurchaseOrder?uniqueId=${id}&type=${type}`);
        dispatch(setSuccessMessage("Success! Purchase order removed."));
        return resp;
    } catch (err) {
        dispatch(setError(errorTypes.purchaseOrdersDeletePurchaseOrder, `${err.message ? err.message + " at " + errorTypes.purchaseOrdersDeletePurchaseOrder : "Something went wrong at " + errorTypes.purchaseOrdersDeletePurchaseOrder }`));
    } finally {
        dispatch(endLoading());
    }
}
//endregion
// END: Approved Purchases

// START: Purchase Orders
//region
export const getAllPurchaseOrders = (status) => async dispatch => {
    dispatch(startLoading(loadingTypes.purchaseOrdersGetAllPurchaseOrders));
    try {
        const res = await axiosInstance.get(`/purchaseOrder/getPurchaseOrdersByStatus?status=${status}`);
        dispatch(setAllPurchaseOrders(res.data));
    } catch (err) {
        dispatch(setError(errorTypes.purchaseOrdersGetAllPurchaseOrders, `${err.message ? err.message + " at " + errorTypes.purchaseOrdersGetAllPurchaseOrders : "Something went wrong at " + errorTypes.purchaseOrdersGetAllPurchaseOrders }`));
    } finally {
        dispatch(endLoading());
    }
};

export const updatePurchaseOrder = (data) => async dispatch => {
    dispatch(startLoading(loadingTypes.purchaseOrdersUpdatePurchaseOrder));
    try {
        await axiosInstance.post(`/purchaseOrder/updatePurchaseOrder`, data);
        dispatch(setSuccessMessage("Success! Purchase order updated."));
    } catch (err) {
        dispatch(setError(errorTypes.purchaseOrdersUpdatePurchaseOrder, `${err.message ? err.message + " at " + errorTypes.purchaseOrdersUpdatePurchaseOrder : "Something went wrong at " + errorTypes.purchaseOrdersUpdatePurchaseOrder }`));
    } finally {
        dispatch(endLoading());
    }
};

export const submitToXero = (id) => async dispatch => {
    dispatch(startLoading(loadingTypes.purchaseOrdersSubmitToXero));
    try {
        await axiosInstance.post(`/purchaseOrder/submitToXero?uniqueId=${id}`);
        dispatch(setSuccessMessage("Success! Purchase order submitted to Xero."));
    } catch (err) {
        if (err.response.data.message) {
            const errorMessage = err.response.data.message;
            const newErrorString = errorMessage.substr(1, errorMessage.length - 2);
            dispatch(setError(errorTypes.purchaseOrdersSubmitToXero, `${newErrorString}`));
        }
        else
            dispatch(setError(errorTypes.purchaseOrdersSubmitToXero, `${err.message ? err.message + " at " + errorTypes.purchaseOrdersSubmitToXero : "Something went wrong at " + errorTypes.purchaseOrdersSubmitToXero }`));
    } finally {
        dispatch(endLoading());
    }
}

//endregion
// END: Purchase Orders