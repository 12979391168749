import React, {useEffect, useState} from "react";
import Grid from "@material-ui/core/Grid";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Paper from "@material-ui/core/Paper";
import TabPanel from "../../../components/TabPanel/TabPanel";
import styled from "styled-components";

import AllPurchasesTable from "./All";
import ProjectsTable from "./Project";
import TendersTable from "./Tender";
import ItEquipmentTable from "./IT";
import OfficeEquipmentTable from "./Office";
import OthersTable from "./Other";
import {connect} from "react-redux";
import {getPermissionsByRole} from "../../../store/permissions/actions";

const TabPaper = styled(Paper)`
  flex-grow: 1; 
  margin-bottom: 32px;
`;

const PurchasesTabs = ({ getUserRoles }) => {
    const [selectedTab, setSelectedTab] = useState(0)
    const [tabs, setTabs] = React.useState([]);
    const [tabsContent, setTabsContent] = React.useState([]);

    const handleTabChange = (_, newTab) => {
        setSelectedTab(newTab)
    };

    useEffect(() => {
        (async function() {
            const res = await getUserRoles("purchase","");
            let index = 0;

            if (res.some(name => name !== "purchase_order_creator")) {
                setTabs(tabs => [...tabs, {label: "All", key: index}])
                setTabsContent(tabsContent => [...tabsContent, {index: index, key: index, children: <AllPurchasesTable/>} ])
                index++;
            }
            if (res.some(name => name === "purchase_project_admin" || name === "purchase_project_2nd_50k_approver" || name === "purchase_project_requestor" || name === "purchase_project_1st_workshop_approver" || name === "purchase_project_1st_approver")) {
                setTabs(tabs => [...tabs, {label: "Projects", key: index}])
                setTabsContent(tabsContent => [...tabsContent, {index: index, key: index, children: <ProjectsTable/>} ])
                index++;
            }
            if (res.some(name => name === "purchase_tender_2nd_50k_approver" || name === "purchase_tender_1st_approver" || name === "purchase_tender_requestor")) {
                setTabs(tabs => [...tabs, {label: "Tenders", key: index}])
                setTabsContent(tabsContent => [...tabsContent, {index: index, key: index, children: <TendersTable/>} ])
                index++;
            }
            if (res.some(name => name === "purchase_it_requestor" || name === "purchase_it_approver")) {
                setTabs(tabs => [...tabs, {label: "IT", key: index}])
                setTabsContent(tabsContent => [...tabsContent, {index: index, key: index, children: <ItEquipmentTable/>} ])
                index++;
            }

            if (res.some(name => name === "purchase_office_requestor" || name === "purchase_office_approver")) {
                setTabs(tabs => [...tabs, {label: "Office", key: index}])
                setTabsContent(tabsContent => [...tabsContent, {index: index, key: index, children: <OfficeEquipmentTable/>} ])
                index++;
            }

            if (res.some(name => name === "purchase_other_approver" || name === "purchase_other_requestor")) {
                setTabs(tabs => [...tabs, {label: "Other", key: index}])
                setTabsContent(tabsContent => [...tabsContent, {index: index, key: index, children: <OthersTable/>} ])
                index++;
            }
        })();
    }, [getUserRoles])

    return (
        <>
            <Grid container alignItems="center" justify="center">
                <Grid item xs={12}>
                    <TabPaper>
                        <Tabs
                            value={selectedTab}
                            onChange={handleTabChange}
                            variant="scrollable"
                            scrollButtons="auto"
                        >
                            {tabs.map(({key, label}) => <Tab label={label} key={key}/>)}
                        </Tabs>
                    </TabPaper>
                    {tabsContent.map(({index, key, children}) =>
                        <TabPanel value={selectedTab} index={index} key={key}>{children}</TabPanel>
                    )}
                </Grid>
            </Grid>
        </>
    );
}

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => ({
    getUserRoles: (roleType, roleSubType) => dispatch(getPermissionsByRole(roleType, roleSubType)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PurchasesTabs);