import axiosInstance from '../../assets/helpers/axiosInstance';

export const LOGOUT_START = 'auth/LOGOUT_START';
export const LOGOUT_SUCCESS = 'auth/LOGOUT_SUCCESS';
export const LOGOUT_FAIL = 'auth/LOGOUT_FAIL';

export const logout = () => 
    dispatch => {
        dispatch({ type : LOGOUT_START });
        return axiosInstance.post( '/test/logout')
            .then(() => dispatch({ type: LOGOUT_SUCCESS }))
            .catch(error => dispatch({ type : LOGOUT_FAIL, error }));
    };
