import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import CustomDialogTitle from "../../components/Dialogs/CustomDialogTitle/CustomDialogTitle";
import Table from "material-table";
import {formatAmount, formatPercentage} from "../../assets/helpers/globals";
import styled, {css} from "styled-components";
import SentimentSatisfiedAltIcon from '@material-ui/icons/SentimentSatisfiedAlt';
import SentimentVeryDissatisfiedIcon from '@material-ui/icons/SentimentVeryDissatisfied';
import SentimentDissatisfiedIcon from '@material-ui/icons/SentimentDissatisfied';

const GoodIcon = styled(SentimentSatisfiedAltIcon)`
  display: none;
  ${props => props.status === "Good" && css`
    margin-top: -1px;
    margin-right: 8px;
    display: flex;
    color: #388e3c;
  	font-size: 1.7rem;
  `}
`;

const BadIcon = styled(SentimentVeryDissatisfiedIcon)`
  display: none;
  ${props => props.status === "Bad" && css`
    margin-top: -1px;
    margin-right: 8px;
    display: flex;
    color: #d32f2f;
  	font-size: 1.7rem;
  `}
`;

const AverageIcon = styled(SentimentDissatisfiedIcon)`
  display: none;
  ${props => props.status === "Average" && css`
    margin-top: -1px;
    margin-right: 8px;
    display: flex;
    color: #efbb5aa3;
  	font-size: 1.7rem;
  `}
`;

const H3 = styled.h3`
  margin: 0;
`;

const H3Status = styled.h3`
	margin-top: 0;
`;

const H4 = styled.h4`
	display: flex;
	align-items: center; 
	margin: 5px 0;
`;

const P = styled.p`
  margin: 0;
`;

const FormDialogContent = styled(DialogContent)`
  @media only screen and (max-width: 425px) {
    padding: 12px !important;
  }
`;

const PercentageOuterDiv = styled.div`
	width: 100%;
	border: 1px solid rgba(0, 0, 0, 0.12);
	height: 26px;
	overflow: hidden;
	position: relative;
	border-radius: 2px;
`;

const PercentageTextDiv = styled.div`
	width: 100%;
	display: flex;
	position: absolute;
	line-height: 24px;
	justify-content: center;
`;

const PercentageBarDiv = styled.div`
	height: 100%;

	${props => props.value >= 0 && props.value < 30 && css`
		max-width: ${props.value}%;
		background-color: #f44336;
  	`}

	${props => props.value >= 30 && props.value < 70 && css`
		max-width: ${props.value}%;
		background-color: #efbb5aa3;
  	`}

	${props =>  props.value >= 70 && css`
		max-width: ${props.value}%;
		background-color: #088208a3;
  	`}
`;

const DetailsDialog = ({
						   details,
						   onClose,
						   open
}) => {
	return <Dialog
		aria-labelledby="project-status-details-dialog-title"
		onClose={onClose}
		fullWidth
		maxWidth={"sm"}
		open={open}
	>
		<CustomDialogTitle
			id="project-status-details-dialog-title"
			onClose={onClose}
			showTextContent
			onLink={details.sheetUrl}
			onLinkTitle={"Go to Google Sheet"}
		>
			Project Details
		</CustomDialogTitle>

		<FormDialogContent dividers>
			<ListItem>
				<ListItemText>
					<H3>Project ID</H3>
					<P>
						{details.projectId || "Not specified"}
					</P>
				</ListItemText>
			</ListItem>
			<ListItem>
				<ListItemText>
					<H3>Project Name</H3>
					<P>
						{details.projectName || "Not specified"}
					</P>
				</ListItemText>
			</ListItem>
			{/*<ListItem>*/}
			{/*	<ListItemText>*/}
			{/*		<H3>Project Type</H3>*/}
			{/*		<P>*/}
			{/*			{details.projectType?.projectTypeName || "Not specified"}*/}
			{/*		</P>*/}
			{/*	</ListItemText>*/}
			{/*</ListItem>*/}
			<ListItem>
				<ListItemText>
					<H3>Project Director</H3>
					<P>
						{details.projectDetails?.projectDirector || "Not specified"}
					</P>
				</ListItemText>
			</ListItem>
			<ListItem>
				<ListItemText>
					<H3>Project Manager</H3>
					<P>
						{details.projectDetails?.projectLead || "Not specified"}
					</P>
				</ListItemText>
			</ListItem>
			<ListItem>
				<ListItemText>
					<H3 style={{marginTop: 0}}>Project Values</H3>
					<Table
						columns={[
							{title: "Column Name", field: "title"},
							{
								title: "Value",
								field: "value",
								render: (rowData) => rowData.type === "percent" && rowData.value !== "N/A" ?
									<PercentageOuterDiv>
										<PercentageBarDiv value={rowData.value?.slice(0,-1)}>
											<PercentageTextDiv>{rowData.value}</PercentageTextDiv>
										</PercentageBarDiv>
									</PercentageOuterDiv>:
									<span>{rowData.value}</span>},
						]}
						data={[
							{
								title: "Project Value",
								value:details.projectValue ? formatAmount(details.projectValue, "ZAR"):"N/A"
							},
							{
								title: "Remaining Value To Completion",
								value: details.projectValues?.remainingValueToCompletion ? formatAmount(details.projectValues?.remainingValueToCompletion, "ZAR"):"N/A"
							},
							{
								title: "Start Value",
								value: details.projectValues?.startValue ? formatAmount(details.projectValues?.startValue, "ZAR"):"N/A"
							},
							{
								title: "Total Spent",
								value: details.projectValues?.totalSpent ? formatAmount(details.projectValues?.totalSpent, "ZAR"):"N/A"
							},
							{
								title: "Total Invoiced",
								value: details.projectValues?.totalInvoiced ? formatAmount(details.projectValues?.totalInvoiced, "ZAR"):"N/A"
							},
							{
								title: "Total Received",
								value: details.projectValues?.totalReceived ? formatAmount(details.projectValues?.totalReceived, "ZAR"):"N/A"
							},
							{
								title: "Completion % Confirmed",
								value: details.projectValues?.completionPercentageConfirmed ? formatPercentage(details.projectValues?.completionPercentageConfirmed): "N/A",
								type: "percent"
							},
							// {title: "Total Received", value: details.projectValues.gross},
							// {title: "Remaining Value to Completion", value: details.projectValues.gross},
							{
								title: "Gross Profit Planned",
								value: details.projectValues?.grossProfitPlanned ? formatAmount(details.projectValues?.grossProfitPlanned, "ZAR"):"N/A"
							},
							{
								title: "Gross Percent Planned",
								value: details.projectValues?.grossPercentPlanned ? formatPercentage(details.projectValues?.grossPercentPlanned): "N/A",
								// type: "percent",
								// compareTo: formatPercentage(details.projectValues?.grossPercentActual) > formatPercentage(details.projectValues?.grossPercentPlanned)
							},
							{
								title: "Gross Profit Actual",
								value: details.projectValues?.grossProfitActual ? formatAmount(details.projectValues?.grossProfitActual, "ZAR") :"N/A"
							},
							{
								title: "Gross Percent Actual",
								value: details.projectValues?.grossPercentActual ? formatPercentage(details.projectValues?.grossPercentActual): "N/A",
								// type: "percent",
								// compareTo: formatPercentage(details.projectValues?.grossPercentActual) > formatPercentage(details.projectValues?.grossPercentPlanned)
							},
							{
								title: "Approved Changes",
								value: details.projectValues?.approvedChanges ? formatAmount(details.projectValues?.approvedChanges, "ZAR"): "N/A"
							},
							// {title: "Current Contract Value", value: details.projectValues.gross},
							{
								title: "Changes to Come",
								value: details.projectValues?.changesToCome ? formatAmount(details.projectValues?.changesToCome, "ZAR"):"N/A"
							},
							{
								title: "Forecasted Value",
								value: details.projectValues?.forecastedValue ?  formatAmount(details.projectValues?.forecastedValue, "ZAR"):"N/A"
							},
							{
								title: "Final Contract Value",
								value: details.projectValues?.finalContractValue ? formatAmount(details.projectValues?.finalContractValue, "ZAR"): "N/A"
							},
						]}
						options={{
							header: false,
							search: false,
							showTitle: false,
							paging: false,
							toolbar: false
						}}
						style={{ boxShadow: "none", marginTop: 10 }}
					/>
				</ListItemText>
			</ListItem>
			<ListItem>
				<ListItemText>
					<H3Status>Statuses</H3Status>
					<H4>
						<GoodIcon status={details.projectStatuses?.projectStatus.statusName}/>
						<BadIcon status={details.projectStatuses?.projectStatus.statusName}/>
						<AverageIcon status={details.projectStatuses?.projectStatus.statusName}/>
						Overall Project Status
					</H4>
					<H4>
						<GoodIcon status={details.projectStatuses?.clientAttitudeStatus.statusName}/>
						<BadIcon status={details.projectStatuses?.clientAttitudeStatus.statusName}/>
						<AverageIcon status={details.projectStatuses?.clientAttitudeStatus.statusName}/>
						Client Attitude
					</H4>
					<H4>
						<GoodIcon status={details.projectStatuses?.contractStatus.statusName}/>
						<BadIcon status={details.projectStatuses?.contractStatus.statusName}/>
						<AverageIcon status={details.projectStatuses?.contractStatus.statusName}/>
						Contract Status
					</H4>
					<H4>
						<GoodIcon status={details.projectStatuses?.grossProfitStatus.statusName}/>
						<BadIcon status={details.projectStatuses?.grossProfitStatus.statusName}/>
						<AverageIcon status={details.projectStatuses?.grossProfitStatus.statusName}/>
						Gross Profit Status
					</H4>
					<H4>
						<GoodIcon status={details.projectStatuses?.projectCashflowStatus.statusName}/>
						<BadIcon status={details.projectStatuses?.projectCashflowStatus.statusName}/>
						<AverageIcon status={details.projectStatuses?.projectCashflowStatus.statusName}/>
						Project Cashflow Status
					</H4>
					<H4>
						<GoodIcon status={details.projectStatuses?.projectScheduleStatus.statusName}/>
						<BadIcon status={details.projectStatuses?.projectScheduleStatus.statusName}/>
						<AverageIcon status={details.projectStatuses?.projectScheduleStatus.statusName}/>
						Project Schedule Status
					</H4>
				</ListItemText>
			</ListItem>
		</FormDialogContent>
	</Dialog>
}
export default DetailsDialog;
