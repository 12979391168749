import Axios from "axios";
import moment from "moment";
import {
	isEmptyTextInput,
	getDayName,
	getShortDayName,
	verifyInt,
	verifyEmail,
} from "../../../assets/helpers/globals";
import * as EventRecurrenceHelper from "../../../assets/helpers/forms/eventRecurrence";
import {
	clearUploadFiles,
	setUploadFile,
} from "../../../store/file/actions";

import store from "../../../store";

const CancelToken = Axios.CancelToken;

export const getStartDateValue = (id, state) => {
	const index = state.fields.findIndex(field => field.id === id);
	return index !== -1 ? new Date(state.fields[index].value) : new Date();
};

export const handleChangeFormInputs = (event, formElementId, state) => {
	let updatedForm = { ...state };
	let updatedFormFields = [...updatedForm.fields];
	const updatedFormElementIndex = updatedFormFields.findIndex(
		f => f.id === formElementId
	);
	if (updatedFormElementIndex !== -1) {
		let updatedFormElement = { ...updatedFormFields[updatedFormElementIndex] };

		if (updatedFormElement.elementType === "file") {
			let payload = {
				files: event.target.files,
				originType: updatedFormElement.originType,
				cancelToken: CancelToken.source(),
			};
			store.dispatch(setUploadFile(payload));
			event.target.value = "";
		} else if (updatedFormElement.elementType === "files") {
			updatedFormElement.value.push(event.target.value);
			event.target.value = "";
		} else {
			let val;
			if (updatedFormElement.elementType === "dateTime") {
				val = moment(event).format();
			} else if (updatedFormElement.elementType === "checkbox") {
				updatedFormElement.elementConfig = {
					...updatedFormElement.elementConfig,
					checked: event.target.checked,
				};
				val = event.target.checked;
			} else {
				val = event.target.value;
			}
			updatedFormElement.value = val;

			if (
				updatedFormElement.elementType === "dateTime" &&
				updatedFormElement.dateChangeMaster &&
				updatedFormElement.dateChangeReference
			) {
				const dateChangeFormElementIndex = updatedFormFields.findIndex(
					f => f.id === updatedFormElement.dateChangeReference
				);
				if (dateChangeFormElementIndex !== -1) {
					let dateChangeFormElement = {
						...updatedFormFields[dateChangeFormElementIndex],
					};
					dateChangeFormElement.value = moment(event)
						.add(dateChangeFormElement.dateChangeValue, "hours")
						.format();
					dateChangeFormElement.error = false;
					dateChangeFormElement.helperText = "";
					updatedFormFields[dateChangeFormElementIndex] = dateChangeFormElement;
					updatedForm.fields = updatedFormFields;
				}
			}

			const validateObj = validInput(
				updatedForm,
				updatedFormElement,
				"change",
				val
			);
			if (validateObj.valid) {
				updatedFormElement.error = false;
				updatedFormElement.helperText = "";
			} else {
				updatedFormElement.error = true;
				updatedFormElement.helperText = validateObj.helperText;
			}
		}

		updatedFormFields[updatedFormElementIndex] = updatedFormElement;
		updatedForm.fields = updatedFormFields;
	}

	return updatedForm;
};

export const handleEditEvent = (state, details) => {
	let updatedForm = { ...state };
	let updatedFormFields = [...updatedForm.fields];

	updatedFormFields.forEach((input, index) => {
		let updatedBookingInput = { ...input };
		if (updatedBookingInput.elementType === "resourcesAutoComplete") {
			updatedBookingInput.value =
				details[updatedBookingInput.resourceProperty][
					updatedBookingInput.property
				];
			const updatedElementConfig = {
				...updatedBookingInput.elementConfig,
				inputValue:
					details[updatedBookingInput.resourceProperty][
						updatedBookingInput.inputProperty
					],
			};
			updatedBookingInput.elementConfig = updatedElementConfig;
		} else if (updatedBookingInput.elementType === "guestsAutoComplete") {
			let attendees = details.attendees.map(att => {
				if (att.displayName) {
					return {
						displayName: att.displayName,
						email: att.email,
					};
				} else {
					return {
						email: att.email,
					};
				}
			});
			updatedBookingInput.value = attendees;
		} else if (updatedBookingInput.elementType === "eventRecurrence") {
			const startDate = new Date(details.start.dateTime);
			const defaultWeekday = getShortDayName(getDayName(startDate.getDay()));
			const onDate = moment(startDate)
				.subtract(1, "days")
				.add(3, "months")
				.format();

			let recurring =
				details.recurrence && details.recurrence.length > 0
					? EventRecurrenceHelper.convertStringToRecurringObj(
							details.recurrence
					  )
					: {
							count: 1,
							freq: "weekly",
							ends: "never",
							weekDays: [defaultWeekday],
							monthlyType: "dayNumber",
							afterInterval: 1,
							onDate: onDate,
					  };

			let updatedElementConfig = { ...updatedBookingInput.elementConfig };
			updatedElementConfig.recurring = recurring;
			updatedBookingInput.elementConfig = updatedElementConfig;
			updatedBookingInput.value =
				details[updatedBookingInput.property] &&
				details[updatedBookingInput.property].length > 0
					? [...details[updatedBookingInput.property]]
					: null;
		} else if (updatedBookingInput.elementType === "richEditor") {
			updatedBookingInput.value = details[updatedBookingInput.property];

			let val = details[updatedBookingInput.property]
				? details[updatedBookingInput.property]
				: "";
			const updatedElementConfig = {
				...updatedBookingInput.elementConfig,
				initialValue: val,
			};
			updatedBookingInput.elementConfig = updatedElementConfig;
		} else if (updatedBookingInput.elementType === "googleLocation") {
			updatedBookingInput.value = details[updatedBookingInput.property];
			const updatedElementConfig = {
				...updatedBookingInput.elementConfig,
				googleLocationObj: details[updatedBookingInput.property],
			};
			updatedBookingInput.elementConfig = updatedElementConfig;
		} else if (updatedBookingInput.elementType === "dateTime") {
			if (updatedBookingInput.secondaryProperty) {
				updatedBookingInput.value =
					details[updatedBookingInput.property][
						updatedBookingInput.secondaryProperty
					];
			} else {
				updatedBookingInput.value = details[updatedBookingInput.property];
			}
		} else if (updatedBookingInput.property === "googleMeet") {
			let checked = details.hangoutLink ? true : false;
			updatedBookingInput.value = checked;
			const updatedElementConfig = {
				...updatedBookingInput.elementConfig,
				checked: checked,
			};
			updatedBookingInput.elementConfig = updatedElementConfig;
		} else if (updatedBookingInput.elementType === "text") {
			updatedBookingInput.value = details[updatedBookingInput.property]
				? details[updatedBookingInput.property]
				: "";
		} else {
			updatedBookingInput.value = details[updatedBookingInput.property];
		}
		updatedFormFields[index] = updatedBookingInput;
		updatedForm.fields = updatedFormFields;
	});

	return {
		updatedForm: updatedForm,
		initialResourceEdit: details.resourceId,
	};
};

export const validInput = (formState, input, type, val) => {
	let value = type === "change" ? val : input.value;
	value =
		input.elementType === "resourcesAutoComplete" && input.useResources
			? input.elementConfig.inputValue
			: value;
	let valid = true;
	let message = "";
	if (input.required && input.render !== false) {
		switch (input.elementType) {
			case "text":
				valid = !isEmptyTextInput(value);
				message = "Required Field";
				break;
			case "resourcesAutoComplete":
				valid = !isEmptyTextInput(value);
				message = "Required Field";
				break;
			case "integer":
				valid = verifyInt(value);
				message = "Required Field, only numbers are allowed";
				break;
			case "dateTime":
				let currentInputValid = true;
				let multiFieldValid = true;

				if (new Date(value).getTime() <= new Date().getTime()) {
					currentInputValid = false;
					message = "Date cannot be in the past";
				}

				if (
					input.dateChangeMaster &&
					input.dateChangeReference &&
					type === "submit"
				) {
					let multiFieldReferenceIndex = formState.fields.findIndex(
						field => field.id === input.dateChangeReference
					);

					if (multiFieldReferenceIndex !== -1) {
						let multiFieldValue =
							formState.fields[multiFieldReferenceIndex].value;
						if (
							new Date(multiFieldValue).getTime() <= new Date(value).getTime()
						) {
							multiFieldValid = false;
							message = "End date cannot be before the start date";
						}
					}
				} else if (!input.dateChangeMaster && input.dateChangeReference) {
					let multiFieldReferenceIndex = formState.fields.findIndex(
						field => field.id === input.dateChangeReference
					);

					if (multiFieldReferenceIndex !== -1) {
						let multiFieldValue =
							formState.fields[multiFieldReferenceIndex].value;
						if (
							new Date(value).getTime() <= new Date(multiFieldValue).getTime()
						) {
							multiFieldValid = false;
							message = "End date cannot be before the start date";
						}
					}
				}
				valid = currentInputValid && multiFieldValid;
				break;

			default:
				break;
		}
	} else if (!input.required && input.render !== false) {
		switch (input.elementType) {
			case "integer":
				valid = isEmptyTextInput(value) || verifyInt(value);
				message = "Only numbers are allowed";
				break;
			case "dateTime":
				let currentInputValid = true;
				let multiFieldValid = true;

				if (new Date(value).getTime() <= new Date().getTime()) {
					currentInputValid = false;
					message = "Date cannot be in the past";
				}

				if (input.multiField) {
					if (input.multiFieldReference) {
						let multiFieldReferenceIndex = formState.fields.findIndex(
							field => field.id === input.multiFieldReference
						);

						if (multiFieldReferenceIndex !== -1) {
							let multiFieldValue = formState.fields[multiFieldReferenceIndex];

							if (
								new Date(multiFieldValue).getTime() <= new Date(value).getTime()
							) {
								multiFieldValid = false;
								message = "End date cannot be before the start date";
							}
						}
					}
				}
				valid = currentInputValid && multiFieldValid;
				break;
			default:
				break;
		}
	}
	let returnObj = { valid: valid };

	if (!valid) {
		returnObj.helperText = message;
	}
	return returnObj;
};

export const validateForm = state => {
	let valid = true;
	let updatedForm = { ...state };
	let updatedFormFields = [...updatedForm.fields];
	updatedFormFields.forEach((field, index) => {
		const validateObj = validInput(updatedForm, field, "submit");
		if (!validateObj.valid) {
			valid = false;
			const updatedFormElement = {
				...field,
				error: true,
				helperText: validateObj.helperText,
			};
			updatedFormFields[index] = updatedFormElement;
			updatedForm.fields = updatedFormFields;
		}
	});
	return {
		valid: valid,
		updatedForm: updatedForm,
	};
};

export const buildRequestPayload = formState => {
	let returnObj = {};
	formState.fields.forEach(input => {
		if (input.value) {
			if (input.elementType === "file") {
				let uploadArr = [];
				Object.values(store.getState().uploadFileReducer.fileProgress).forEach(
					file => {
						if (file.type === input.originType && file.status === 1) {
							uploadArr.push({ id: file.id });
						}
					}
				);

				if (uploadArr.length > 0) {
					returnObj[input.property] = uploadArr;
				}
			} else {
				if (input.secondaryProperty) {
					returnObj[input.property] = {};
					returnObj[input.property][input.secondaryProperty] = input.value;
				} else {
					returnObj[input.property] = input.value;
				}
			}
		}
	});
	return returnObj;
};

export const handleClearFormState = formState => {
	let updatedForm = { ...formState };
	let updatedFormFields = [...formState.fields];
	let alreadyCleared = [];
	updatedFormFields.forEach((field, index) => {
		if (alreadyCleared.findIndex(f => f === field.id) === -1) {
			let clearValue = null;
			switch (field.elementType) {
				case "dateTime":
					if (field.dateChangeMaster && field.dateChangeReference) {
						const dateChangeFormElementIndex = updatedFormFields.findIndex(
							f => f.id === field.dateChangeReference
						);
						if (dateChangeFormElementIndex !== -1) {
							let dateChangeFormElement = {
								...updatedFormFields[dateChangeFormElementIndex],
							};
							dateChangeFormElement.value = moment().add(1, "hours").format();
							dateChangeFormElement.error = false;
							dateChangeFormElement.helperText = "";
							updatedFormFields[
								dateChangeFormElementIndex
							] = dateChangeFormElement;
							updatedForm.fields = updatedFormFields;
							alreadyCleared.push(field.dateChangeReference);
						}
					}
					clearValue = moment().format();
					break;
				case "resourcesAutoComplete":
					clearValue = null;
					break;
				case "guestsAutoComplete":
					clearValue = null;
					break;
				case "eventRecurrence":
					clearValue = [];
					break;
				case "file":
					clearValue = [];
					Object.values(
						store.getState().uploadFileReducer.fileProgress
					).forEach(file => {
						if (file.type === field.originType) {
							if (file.status !== 1 && file.status !== 0) {
								CancelToken.source().cancel();
							}
						}
					});
					store.dispatch(clearUploadFiles(field.originType));
					break;
				default:
					clearValue = "";
					break;
			}
			const updatedFormElement = {
				...field,
				value: clearValue,
				error: false,
				helperText: "",
			};
			if (field.elementConfig.inputValue) {
				const updatedElementConfig = {
					...updatedFormElement.elementConfig,
					inputValue: "",
				};
				updatedFormElement.elementConfig = updatedElementConfig;
			}
			if (field.elementConfig.initialValue) {
				const updatedElementConfig = {
					...updatedFormElement.elementConfig,
					initialValue: "",
				};
				updatedFormElement.elementConfig = updatedElementConfig;
			}
			if (field.elementConfig.googleLocationObj) {
				const updatedElementConfig = {
					...updatedFormElement.elementConfig,
					googleLocationObj: null,
				};
				updatedFormElement.elementConfig = updatedElementConfig;
			}
			if (field.elementConfig.checked) {
				const updatedElementConfig = {
					...updatedFormElement.elementConfig,
					checked: false,
				};
				updatedFormElement.elementConfig = updatedElementConfig;
			}
			if (field.elementConfig.recurring) {
				const updatedElementConfig = {
					...updatedFormElement.elementConfig,
					recurring: {},
					recurringErrors: {},
					recurringErrorMessages: {},
				};
				updatedFormElement.elementConfig = updatedElementConfig;
			}
			updatedFormFields[index] = updatedFormElement;
			updatedForm.fields = updatedFormFields;
			alreadyCleared.push(field.id);
		}
	});

	return updatedForm;
};

export const handleResourceChange = (value, reason, formElementId, state) => {
	let updatedForm = { ...state };
	let updatedFormFields = [...updatedForm.fields];

	const updatedFormElementIndex = updatedFormFields.findIndex(
		f => f.id === formElementId
	);
	if (updatedFormElementIndex !== -1) {
		let updatedFormElement = { ...updatedFormFields[updatedFormElementIndex] };
		if (reason === "select-option") {
			updatedFormElement.value = value.resourceId;
			updatedFormElement.error = false;
			updatedFormElement.helperText = "";
			const updatedElementConfig = {
				...updatedFormElement.elementConfig,
				inputValue: value.title,
			};
			updatedFormElement.elementConfig = updatedElementConfig;
		} else if (reason === "clear") {
			const updatedElementConfig = {
				...updatedFormElement.elementConfig,
				inputValue: "",
			};
			updatedFormElement.elementConfig = updatedElementConfig;
			updatedFormElement.value = null;
			if (updatedFormElement.required) {
				updatedFormElement.error = true;
				updatedFormElement.helperText = "Required Field";
			}
		}
		updatedFormFields[updatedFormElementIndex] = updatedFormElement;
		updatedForm.fields = updatedFormFields;
	}

	return updatedForm;
};

export const handleResourceInputChange = (
	event,
	value,
	reason,
	formElementId,
	state,
	resources
) => {
	let updatedForm = { ...state };
	let updatedFormFields = [...updatedForm.fields];
	const updatedFormElementIndex = updatedFormFields.findIndex(
		f => f.id === formElementId
	);
	if (updatedFormElementIndex !== -1) {
		let updatedFormElement = { ...updatedFormFields[updatedFormElementIndex] };
		if (reason !== "reset") {
			const updatedElementConfig = {
				...updatedFormElement.elementConfig,
				inputValue: value,
			};
			updatedFormElement.elementConfig = updatedElementConfig;
			const validateObj = validInput(
				updatedForm,
				updatedFormElement,
				"change",
				value
			);
			if (validateObj.valid) {
				updatedFormElement.error = false;
				updatedFormElement.helperText = "";
			} else {
				updatedFormElement.value = null;
				updatedFormElement.error = true;
				updatedFormElement.helperText = "Required Field";
			}
		}

		if (event && event.type === "blur") {
			const index = resources.findIndex(
				r => r.title === updatedFormElement.elementConfig.inputValue
			);
			if (index === -1) {
				const updatedElementConfig = {
					...updatedFormElement.elementConfig,
					inputValue: "",
				};
				updatedFormElement.value = null;
				if (updatedFormElement.required) {
					updatedFormElement.error = true;
					updatedFormElement.helperText = "Required Field";
				}
				updatedFormElement.elementConfig = updatedElementConfig;
			} else {
				updatedFormElement.value = resources[index].resourceId;
			}
		}
		updatedFormFields[updatedFormElementIndex] = updatedFormElement;
		updatedForm.fields = updatedFormFields;
	}
	return updatedForm;
};

export const handleGoogleLocationChange = (value, formElementId, state) => {
	let updatedForm = { ...state };
	let updatedFormFields = [...updatedForm.fields];
	const updatedFormElementIndex = updatedFormFields.findIndex(
		f => f.id === formElementId
	);
	if (updatedFormElementIndex !== -1 && value) {
		let updatedFormElement = {
			...updatedFormFields[updatedFormElementIndex],
			value: value.description,
		};
		let updateFormConfig = {
			...updatedFormElement.elementConfig,
			googleLocationObj: value,
		};

		updatedFormElement.elementConfig = updateFormConfig;
		updatedFormFields[updatedFormElementIndex] = updatedFormElement;
		updatedForm.fields = updatedFormFields;
	}

	return updatedForm;
};

export const handleRichTextEditorBlur = (
	editorContents,
	formElementId,
	state
) => {
	let updatedForm = { ...state };
	let updatedFormFields = [...updatedForm.fields];
	const updatedFormElementIndex = updatedFormFields.findIndex(
		f => f.id === formElementId
	);
	if (updatedFormElementIndex !== -1) {
		let updatedFormElement = {
			...updatedFormFields[updatedFormElementIndex],
			value: editorContents,
		};
		updatedFormFields[updatedFormElementIndex] = updatedFormElement;
		updatedForm.fields = updatedFormFields;
	}
	return updatedForm;
};

export const handleGuestChange = (values, reasons, formElementId, state) => {
	let updatedForm = { ...state };
	let updatedFormFields = [...updatedForm.fields];

	const updatedFormElementIndex = updatedFormFields.findIndex(
		f => f.id === formElementId
	);
	if (updatedFormElementIndex !== -1) {
		let updatedFormElement = { ...updatedFormFields[updatedFormElementIndex] };
		if (reasons === "create-option") {
			updatedForm = handleCreateAttendeeObject(
				values[values.length - 1],
				updatedFormElementIndex,
				updatedForm
			);
		} else {
			let updatedFormElementConfig = {
				...updatedFormElement.elementConfig,
				inputValue: "",
			};
			updatedFormElement.error = false;
			updatedFormElement.helpertext = "";

			if (updatedFormElement.required) {
				updatedFormElement.error = true;
				updatedFormElement.helperText = "Required Field";
			}
			updatedFormElement.value = values.slice(0);
			updatedFormElement.elementConfig = updatedFormElementConfig;

			updatedFormFields[updatedFormElementIndex] = updatedFormElement;
			updatedForm.fields = updatedFormFields;
		}
	}
	return updatedForm;
};

export const handleCreateAttendeeObject = (email, formElementIndex, state) => {
	let updatedForm = { ...state };
	let updatedFormFields = [...updatedForm.fields];
	let updatedFormElement = updatedFormFields[formElementIndex];

	if (verifyEmail(email)) {
		const newObj = {
			email: email,
		};

		let attendeesArray = [];
		if (updatedFormElement.value) {
			attendeesArray = [...updatedFormElement.value, newObj];
		} else {
			attendeesArray = [newObj];
		}

		let updatedFormElementConfig = {
			...updatedFormElement.elementConfig,
			inputValue: "",
		};

		updatedFormElement.error = false;
		updatedFormElement.helperText = "";
		updatedFormElement.value = attendeesArray;
		updatedFormElement.elementConfig = updatedFormElementConfig;
		updatedFormFields[formElementIndex] = updatedFormElement;
		updatedForm.fields = updatedFormFields;
		updatedForm = handleChangeGuestInput("", "input", 5, updatedForm);
	} else {
		updatedFormElement.error = true;
		updatedFormElement.helperText = "Invalid Email";
		updatedFormFields[formElementIndex] = updatedFormElement;
		updatedForm.fields = updatedFormFields;
	}

	return updatedForm;
};

export const handleChangeGuestInput = (value, reason, formElementId, state) => {
	let updatedForm = { ...state };
	let updatedFormFields = [...updatedForm.fields];

	const updatedFormElementIndex = updatedFormFields.findIndex(
		f => f.id === formElementId
	);
	if (updatedFormElementIndex !== -1) {
		let updatedFormElement = { ...updatedFormFields[updatedFormElementIndex] };
		if (reason === "input") {
			if (verifyEmail(value)) {
				updatedFormElement.error = false;
				updatedFormElement.helperText = "";
			}
			let updatedFormElementConfig = {
				...updatedFormElement.elementConfig,
				inputValue: value,
			};

			updatedFormElement.elementConfig = updatedFormElementConfig;
			updatedFormFields[updatedFormElementIndex] = updatedFormElement;
			updatedForm.fields = updatedFormFields;
		}
	}
	return updatedForm;
};

export const handleResponseAction = (responseId, state) => {
	let updatedResponseData = { ...state };
	const actionIndex = updatedResponseData.actions.findIndex(
		action => action.id === responseId
	);

	if (actionIndex !== -1) {
		updatedResponseData.currentResponse =
			updatedResponseData.actions[actionIndex].value;
	}

	let updatedResponseFields = [...updatedResponseData.fields];
	let responseFieldsFlag = false;
	updatedResponseFields.forEach((field, index) => {
		let updatedResponseField = { ...field };
		if (
			updatedResponseField.actions &&
			updatedResponseField.actions.length > 0
		) {
			const fieldActionIndex = field.actions.findIndex(
				actionId => actionId === responseId
			);
			if (fieldActionIndex !== -1) {
				updatedResponseField.render = true;
				responseFieldsFlag = true;
			} else {
				updatedResponseField.render = false;
			}
			updatedResponseFields[index] = updatedResponseField;
		}
	});
	updatedResponseData.fields = updatedResponseFields;

	if (responseFieldsFlag) {
		updatedResponseData.noRenderedFields = false;
	} else {
		updatedResponseData.noRenderedFields = true;
	}

	return updatedResponseData;
};
