import * as actionTypes from "./actions";

const initialState = {
	assets: [],
	loadingAssets: false,
	assetsError: null,
	createAssetLoading: false,
	updateAssetLoading: false,
	deleteAssetLoading: false,
};

const getAssetsSuccess = (state, action) => ({
	...state,
	assets: action.assets,
	error: null,
});
const deleteAssetSuccess = (state, action) => ({
	...state,
	deleteAssetLoading: false,
	assets: state.assets.filter(
		({ Asset: { AssetNo } }) => AssetNo !== action.AssetNo
	),
});
const updateAssetSuccess = (state, action) => {
	return {
		...state,
		updateAssetLoading: false,
		assets: state.assets.map(asset => {
			if (asset.Asset.AssetNo !== action.asset.AssetNo) {
				return asset;
			}

			return action.asset;
		}),
	};
};

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.UPDATE_ASSET_SUCCESS:
			return updateAssetSuccess(state, action);
		case actionTypes.GET_ASSETS_SUCCESS:
			return getAssetsSuccess(state, action);
		case actionTypes.DELETE_ASSETS_START:
			return { ...state, deleteAssetLoading: true };
		case actionTypes.DELETE_ASSETS_FAIL:
			return { ...state, deleteAssetLoading: false };
		case actionTypes.DELETE_ASSETS_SUCCESS:
			return deleteAssetSuccess(state, action);
		default:
			return state;
	}
};

export default reducer;
