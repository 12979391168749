import React from 'react';
import List from '@material-ui/core/List';
import DetailsListItem from './DetailsListItem/DetailsListItem';

const DetailsList = (props) => {

    const { bookingListItems } = props;

    return (
        <List>
            {bookingListItems.map((detailItem, index) => {
                return detailItem.itemType !== 'title' ? <DetailsListItem key={index} item={detailItem} /> : null;
            })}
        </List>
    );
};

export default React.memo(DetailsList);