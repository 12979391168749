import * as actionTypes from './actions';

const initialState = {
    taxOptions: []
};

const setTaxOptions = (state, { taxOptions }) => ({ ...state, taxOptions });

export default (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_TAX_OPTIONS:
            return setTaxOptions(state, action);
        default:
            return state;
    }
};