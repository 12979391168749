import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import MaterialTable from "material-table";
import Button from "@material-ui/core/Button";
import CustomAction from "../../../../components/Table/CustomAction";
import isEqual from "lodash/isEqual";
import styled, {css} from "styled-components";
import NewRequestDialog from "./NewRequestDialog";
import DetailsDialog from "./DetailsDialog"

import * as purchaseActions from "../../../../store/purchases/actions";
import {getAllProjects} from "../../../../store/projects/actions";
import {getCurrentUser} from "../../../../store/user/actions";
import * as loadingTypes from "../../../../store/loading/types";
import ConfirmationDialog from "../../../../components/Dialogs/ConfirmationDialog/ConfirmationDialog";
import {downloadFile} from "../../../../store/file/actions";
import {getAllLegalEntities} from "../../../../store/legalEntities/actions";
import {getVendorsBySupplier} from "../../../../store/vendors/actions";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import FilterAutocomplete from "../../../../components/Inputs/Filter/FilterAutocomplete";
import {getFilteredData} from "../../../../assets/helpers/globals";
import {Clear, Autorenew, Done, Warning} from "@material-ui/icons";
import ResponseDialog from "./ResponseDialog";
import {getNextApprovers, getPermissionsByRole} from "../../../../store/permissions/actions";
import debounce from "lodash/debounce";
import AuditLoggingTableDialog from "../../../Audit/TableDialog";
import {getAuditLog, getTransactionSubTypes} from "../../../../store/audit/actions";
import {getCurrenciesByLegalEntity} from "../../../../store/currencies/actions";
import {useInterval} from "../../../../hooks/useInterval";
import {getTaxOptions} from "../../../../store/tax/actions";

const ApprovedIcon = styled(Done)`
  display: none;
  ${props => props.status === "Approved" && css`
    margin-top: -1px;
    margin-right: 2px;
    display: flex;
    color: #388e3c;
  `}
`;

const RejectedIcon = styled(Warning)`
  display: none;
  ${props => props.status === "Rejected" && css`
    margin-top: -1px;
    margin-right: 2px;
    display: flex;
    color: #d32f2f;
  `}
`;

const AwaitingApprovalIcon = styled(Autorenew)`
  display: none;
  ${props => props.status === "Awaiting Approval" && css`
    margin-top: -1px;
    margin-right: 2px;
    display: flex;
    color: #f57c00;

  `}
`;

const AwaitingSecondApprovalIcon = styled(Autorenew)`
  display: none;
  ${props => props.status === "Awaiting 2nd Approval" && css`
    margin-top: -1px;
    margin-right: 2px;
    display: flex;
    color: #f57c00;
  `}
`;

const P = styled.p`
  color: #f57c00;
  border: 1px solid #ff9800;
  border-radius: 16px;
  height: 24px;
  padding-left: 4px;
  padding-right: 8px;
  white-space: nowrap;
  display: flex;
  max-width: fit-content;

  ${props => props.status === "Approved" && css`
    color: #388e3c;
    border: 1px solid #4caf50;
  `}
  ${props => props.status === "Rejected" && css`
    color: #d32f2f;
    border: 1px solid #f44336;
  `}
`;

const FilterPaper = styled(Paper)`
  margin-bottom: 18px;
  margin-top: 14px;

`;

const FilterGrid = styled(Grid)`
  ${props => props.container && css`
    margin-left: 10px; 
    width: 98%;
  `}
  ${props => props.item && css`
    align-self: center
  `}
`;

const PurchasesProject = ({
                                user,
                                loading,
                                purchaseProjects,
                                getCurrentUser,
                                getPurchaseProjects,
                                addPurchaseProjectRequest,
                                deletePurchaseProjectRequest,
                                updatePurchaseProjectRequest,
                                getAllProjects,
                                projects,
                                downloadFile,
                                updatePurchaseProjectRequestStatus,
                                getAllLegalEntities,
                                legalEntities,
                                getVendorsBySupplier,
                                vendorsBySupplier,
                                getUserRoles,
                                getNextApprovers,
                                getAuditLog,
                                auditLog,
                                getTransactionSubTypes,
                                transactionSubTypes,
                                currenciesByLegalEntity,
                                getCurrenciesByLegalEntity,
                                taxOptions,
                                getTaxOptions
                          }) => {
    const initialFilterState = {status: null, name: null, projectNumber: null, vendorName: null, totalCost: null, actions: null, taxOption: null, entityName: null}
    const initialDialogState = {open: false, data: {}}

    const [addDialogOpen, setAddDialogOpen] = useState(false);
    const [editDialogOpen, setEditDialogOpen] = useState(false);
    const [purchaseDetails, setPurchaseDetails] = useState(initialDialogState);
    const [purchaseResponse, setPurchaseResponse] = useState(initialDialogState);
    const [editing, setEditing] = useState(undefined);
    const [selectedPurchaseRequest, setSelectedPurchaseRequest] = useState([]);
    const [deletePurchaseRequestConfirmationOpen, setDeletePurchaseRequestConfirmationOpen] = useState(false);
    const [auditLogDetails, setAuditLogDetails] = useState(undefined);
    const [auditLogDialogOpen, setAuditLogDialogOpen] = useState({open: false, type: ""});
    const [filter, setFilter] = useState(initialFilterState);
    const [roles, setRoles] = useState({
        is_purchase_project_2nd_50k_approver: false,
        is_purchase_project_requestor: false,
        is_purchase_project_1st_workshop_approver: false,
        is_purchase_project_1st_approver: false,
        is_purchase_project_admin: false
    });

    const actions = [
        {
            icon: "visibility",
            tooltip: "View Request",
            onClick: async (_event, selectedPurchase) =>{
                const res = await getNextApprovers("PurchaseProject", selectedPurchase.uniqueId)

                setPurchaseDetails({open: true, data:{...selectedPurchase, nextApprovers: res}})
            }
        },
        rowData =>
            ({
                icon: "reply",
                tooltip: "Respond to Request",
                onClick: (_event, selectedPurchase) =>
                    setPurchaseResponse({open: true, data: selectedPurchase}),
                hidden: !(roles.is_purchase_project_admin ||
                    (roles.is_purchase_project_2nd_50k_approver &&
                        rowData.purchase?.totalCostExchange > 50000 &&
                        rowData.purchase?.purchaseLevel === 2 &&
                        rowData.purchase.status !== "Approved") ||
                    (roles.is_purchase_project_1st_approver &&
                        // rowData.workshop === "" &&
                        rowData.purchase?.purchaseLevel === 1 &&
                        rowData.purchase.status !== "Approved") ||
                    (roles.is_purchase_project_1st_workshop_approver &&
                        rowData.workshop === "Roodekop" &&
                        rowData.purchase?.purchaseLevel === 1 &&
                        rowData.purchase.status !== "Approved")),
            }),
        rowData =>
            ({
                icon: "edit",
                tooltip: "Edit Request",
                hidden: !(rowData.purchase.requestor.userEmail === user.userEmail && (rowData.purchase.status === "Awaiting Approval" || rowData.purchase.status === "Rejected")),
                onClick: async (_event, selectedPurchase) => {
                    // if (!projects || !projects.length)
                        await getAllProjects("Registered");
                    if (!legalEntities || !legalEntities.length)
                        await getAllLegalEntities();
                    if (!taxOptions || !taxOptions.length)
                        await getTaxOptions();
                    if (selectedPurchase.purchase.vendor)
                        await getVendorsBySupplier(selectedPurchase.purchase.vendor.legalEntities.uniqueId);
                    else
                        await getVendorsBySupplier(selectedPurchase.purchase.legalEntity.uniqueId)

                    await getCurrenciesByLegalEntity(selectedPurchase.purchase.legalEntity.uniqueId);

                    setEditing({
                        ...selectedPurchase,
                        vendor: selectedPurchase.purchase.vendor ? selectedPurchase.purchase.vendor : "",
                        legalEntity: selectedPurchase.purchase.legalEntity,
                        vendorOther: selectedPurchase.purchase.vendorOther ? selectedPurchase.purchase.vendorOther : "",
                        project: selectedPurchase.workshop === "" ? selectedPurchase.projectsDetails[0].project : "",
                        projectManager: selectedPurchase.workshop === "" ? selectedPurchase.projectsDetails[0].project?.projectManager?.userFullName : "",
                        vendorType: selectedPurchase.purchase.vendor ? "existingVendor" : "otherVendor",
                        isWorkshop: selectedPurchase.workshop !== "",
                        description: selectedPurchase.purchase.description,
                        attachments: selectedPurchase.purchase.attachments,
                        totalCost: selectedPurchase.purchase.totalCost,
                        currency: selectedPurchase.purchase.currency,
                        xeroTaxOption: selectedPurchase.purchase.xeroTaxOption,
                        quoteNumber: selectedPurchase.purchase.quoteNumber,
                        projectList: selectedPurchase.projectsDetails
                    })

                    setEditDialogOpen(true);
                }
            }),
        rowData =>
            ({
                icon: "delete",
                tooltip: "Cancel Request",
                hidden: !(rowData.purchase.requestor.userEmail === user.userEmail && rowData.purchase.status === "Awaiting Approval"),
                onClick: async (_event, selectedPurchase) => {
                    setSelectedPurchaseRequest(selectedPurchase);
                    setDeletePurchaseRequestConfirmationOpen(true)
                },
            }),
        {
            icon: "history",
            tooltip: "View Request History",
            onClick: async (_event, selectedPurchase) => {
                if (!isEqual(selectedPurchase, auditLogDetails)) {
                    let typeId

                    if (!transactionSubTypes || !transactionSubTypes.length) {
                        const res = await getTransactionSubTypes();
                        typeId = res.data.find(transactionSubType => transactionSubType.subTypeName === selectedPurchase.purchase?.purchaseType?.typeName).uniqueId;
                    } else
                        typeId = transactionSubTypes.find(transactionSubType => transactionSubType.subTypeName === selectedPurchase.purchase?.purchaseType?.typeName).uniqueId;

                    await getAuditLog(selectedPurchase.purchase?.uniqueId, typeId);
                    setAuditLogDetails(selectedPurchase)
                    setAuditLogDialogOpen({open: true, type: "Purchase"})
                }
                else
                    setAuditLogDialogOpen({open: true, type: "Purchase"})
            }
        },
        {
            icon: 'refresh',
            tooltip: 'Refresh Data',
            isFreeAction: true,
            onClick: debounce(getPurchaseProjects, 300, {
                'leading': false,
                'trailing': true
            }),
        },
        {
            icon: "add",
            isFreeAction: true,
            custom: true,
            component: Button,
            onClick: () => {
            },
            props: {
                disabled: !roles.is_purchase_project_requestor,
                variant: "contained",
                color: "primary",
                children: "Add Project Purchase",
                onClick: async () => {
                    // if (!projects || !projects.length)
                        getAllProjects("Registered");
                    if (!legalEntities || !legalEntities.length)
                        getAllLegalEntities();
                    if (!taxOptions || !taxOptions.length)
                        getTaxOptions();
                    setAddDialogOpen(true)
                },
            },
        },
    ];

    const filterData = (data, query) => {
        return data.filter((item) => {
            for (let key in query) {
                switch (key) {
                    case "entityName":
                        if (item.purchase.legalEntity === null || item.purchase.legalEntity[key] === undefined || !query[key].includes(item.purchase.legalEntity[key])) {
                            return false;
                        }
                        break;
                    case "projectNumber":
                        if (!item.projectsDetails.length || item.projectsDetails.findIndex(projectsDetailsItem => projectsDetailsItem.project[key] === query[key]) === -1)
                            return false;
                        break;
                    case "vendorName":
                        if (item.purchase.vendor === null || item.purchase.vendor[key] === undefined || !query[key].includes(item.purchase.vendor[key])) {
                            return false;
                        }
                        break;
                    case "name":
                        if (item.purchase.currency === null || item.purchase.currency[key] === undefined || !query[key].includes(item.purchase.currency[key])) {
                            return false;
                        }
                        break;
                    case "totalCost":
                        if (item.purchase[key] === undefined || item.purchase[key] === null) {
                            return false;
                        }
                        if (query[key]['value']['min'] !== null && item.purchase[key] < query[key]['value']['min']) {
                            return false;
                        }
                        if (query[key]['value']['max'] !== null && item.purchase[key] >= query[key]['value']['max']) {
                            return false;
                        }
                        break;
                    case "actions":
                        if (query[key]['value'] === "approve") {
                            if(actions[1](item).hidden)
                                return false;
                        } else if ( !(item.purchase.requestor.userEmail === user.userEmail) )
                                return false;
                        break;
                    case "taxOption":
                        if (item.purchase.xeroTaxOption === null || item.purchase.xeroTaxOption[key] === undefined || !query[key].includes(item.purchase.xeroTaxOption[key]))
                            return false;
                        break;
                    default:
                        if (item.purchase[key] === undefined || !query[key].includes(item.purchase[key])) {
                            return false;
                        }
                        break;
                }
            }
            return true;
        });
    };

    useInterval(() => {
        getPurchaseProjects();
    }, 300000);

    useEffect(() => {
        (async function() {
            const res = await getUserRoles("purchase","project");
            const permissionObj = {
                is_purchase_project_2nd_50k_approver: res.some(name => name === "purchase_project_2nd_50k_approver"),
                is_purchase_project_requestor: res.some(name => name === "purchase_project_requestor"),
                is_purchase_project_1st_workshop_approver: res.some(name => name === "purchase_project_1st_workshop_approver"),
                is_purchase_project_1st_approver: res.some(name => name === "purchase_project_1st_approver"),
                is_purchase_project_admin: res.some(name => name === "purchase_project_admin"),
            }

            setRoles(permissionObj);

            if (Object.entries(permissionObj).some(([key, value]) => key !== "is_purchase_project_requestor" && value === true))
                setFilter(f => ({...f, actions: {description: "Awaiting Your Approval", value: "approve"}}))
        })();

        if (!user.userEmail)
            getCurrentUser();
        getPurchaseProjects();
    }, [getCurrentUser, getPurchaseProjects, getUserRoles, user.userEmail])

    return (
        <>
            <FilterPaper>
                <FilterGrid container spacing={2}>
                    <FilterGrid item xs={12} sm={6} md={4} lg={3}>
                        <FilterAutocomplete
                            onChange={(event, newValue) => setFilter(filter => ({...filter, status: newValue}))}
                            value={filter.status}
                            options={purchaseProjects.map(purchaseProject => purchaseProject.purchase?.status)}
                            placeholder="Status"
                            noOptionsText="No Statuses"
                        />
                    </FilterGrid>
                    <FilterGrid item xs={12} sm={6} md={4} lg={3}>
                        <FilterAutocomplete
                            onChange={(event, newValue) => setFilter({...filter, name: newValue})}
                            value={filter.name}
                            options={purchaseProjects.map(purchaseProject => purchaseProject.purchase?.currency?.name)}
                            placeholder="Currency"
                            noOptionsText="No Currencies"
                        />
                    </FilterGrid>
                    <FilterGrid item xs={12} sm={6} md={4} lg={3}>
                        <FilterAutocomplete
                            onChange={(event, newValue) => setFilter({...filter, taxOption: newValue})}
                            value={filter.taxOption}
                            options={purchaseProjects.map(purchaseProject => purchaseProject.purchase?.xeroTaxOption?.taxOptionText)}
                            placeholder="Tax"
                            noOptionsText="No Tax Options"
                        />
                    </FilterGrid>
                    <FilterGrid item xs={12} sm={6} md={4} lg={3}>
                        <FilterAutocomplete
                            onChange={(event, newValue) => setFilter(filter => ({...filter, projectNumber: newValue}))}
                            value={filter.projectNumber}
                            options={purchaseProjects.flatMap(purchaseProject => purchaseProject.projectsDetails.map(projectListItem => projectListItem.project?.projectNumber))}
                            placeholder="Project"
                            noOptionsText="No Projects"
                        />
                    </FilterGrid>
                    <FilterGrid item xs={12} sm={6} md={4} lg={3}>
                        <FilterAutocomplete
                            onChange={(event, newValue) => setFilter({...filter, entityName: newValue})}
                            value={filter.entityName}
                            options={purchaseProjects.map(purchaseProject => purchaseProject.purchase?.legalEntity?.entityName)}
                            placeholder="Legal Entity"
                            noOptionsText="No Legal Entities"
                        />
                    </FilterGrid>
                    <FilterGrid item xs={12} sm={6} md={4} lg={3}>
                        <FilterAutocomplete
                            onChange={(event, newValue) => setFilter(filter => ({...filter, vendorName: newValue}))}
                            value={filter.vendorName}
                            options={purchaseProjects
                                .map(purchaseProject => purchaseProject.purchase?.vendor !== null ? purchaseProject.purchase.vendor.vendorName : '')
                                .filter((purchaseProject, index, array) => array.indexOf(purchaseProject) === index && purchaseProject !== '')}
                            placeholder="Supplier"
                            noOptionsText="No Suppliers"
                        />
                    </FilterGrid>
                    <FilterGrid item xs={12} sm={6} md={4} lg={3}>
                        <FilterAutocomplete
                            onChange={(event, newValue) => setFilter(filter => ({...filter, totalCost: newValue}))}
                            value={filter.totalCost}
                            options={[
                                {description: "Quote less than 50K", value: {min: 0, max: 50000}},
                                {description: "Quote greater than 50K", value: {min: 50000, max: null}},
                            ]}
                            placeholder="Quote"
                            noOptionsText="No Quotes"
                        />
                    </FilterGrid>
                    <FilterGrid item xs={12} sm={6} md={4} lg={3}>
                        <FilterAutocomplete
                            onChange={(event, newValue) => setFilter(filter => ({...filter, actions: newValue}))}
                            value={filter.actions}
                            options={[
                                {description: "Your Purchases", value: "own"},
                                {description: "Awaiting Your Approval", value: "approve"},
                            ]}
                            placeholder="Actions"
                            noOptionsText="No Actions"
                        />
                    </FilterGrid>
                    <FilterGrid item xs={12} sm={6} md={4} lg={3}>
                        <Button
                            color="secondary"
                            size="small"
                            startIcon={<Clear/>}
                            disabled={isEqual(filter, initialFilterState)}
                            onClick={() => setFilter(initialFilterState)}>
                            Clear All
                        </Button>
                    </FilterGrid>
                </FilterGrid>
            </FilterPaper>

            <MaterialTable
                columns={[
                    {
                        title: "Submitted Date",
                        field: "purchase.createdDate",
                        type: "date",
                    },
                    {
                        title: "Modified Date",
                        field: "purchase.lastModifiedDate",
                        type: "date",
                        defaultSort: "desc",
                        customSort: (a, b) => {
                            let d1 = new Date(a.purchase.lastModifiedDate);
                            let d2 = new Date(b.purchase.lastModifiedDate);
                            return d1.getTime() - d2.getTime();
                        }
                    },
                    {
                        title: "Project",
                        field: "projectsDetails.project.projectNumber",
                        minWidth: "140px",
                        render: rowData => <span>{rowData.projectsDetails.map((projectDetailsItem, projectDetailsItemIdx) => projectDetailsItemIdx === 0 ? projectDetailsItem.project.projectNumber + " (" + projectDetailsItem.projectCostPercentage + "%)" :
                            ", " + projectDetailsItem.project.projectNumber + " (" + projectDetailsItem.projectCostPercentage + "%)")}</span>,
                    },
                    {
                        title: "Legal Entity",
                        field: "purchase.legalEntity.entityName",
                    },
                    {
                        title: "Supplier",
                        field: "vendor.vendorName",
                        render: rowData => rowData.purchase.vendor ? <span>{rowData.purchase.vendor.vendorName}</span> :
                            <span>{rowData.purchase.vendorOther}</span>,
                    },
                    {
                        title: "Amount",
                        field: "purchase.totalCost",
                        align: "right",
                    },
                    {
                        title: "Currency",
                        field: "purchase.currency.name",
                    },
                    {
                        title: "Tax",
                        field: "purchase.xeroTaxOption.taxOptionText",
                    },
                    {
                        title: "Quote Number",
                        field: "purchase.quoteNumber"
                    },
                    {
                        title: "Requestor",
                        field: "purchase.requestor.userFullName",
                    },
                    {
                        title: "Status",
                        field: "purchase.status",
                        render: data => (
                            <P status={data.purchase.status}>
                                <ApprovedIcon status={data.purchase.status}/>
                                <RejectedIcon status={data.purchase.status}/>
                                <AwaitingApprovalIcon status={data.purchase.status}/>
                                <AwaitingSecondApprovalIcon status={data.purchase.status}/>{data.purchase.status}</P>
                        ),
                    }
                ]}
                actions={actions}
                components={{ Action: CustomAction }}
                data={isEqual(filter, initialFilterState) ? purchaseProjects : getFilteredData(filter, purchaseProjects, [], filterData)}
                isLoading={addDialogOpen === false &&
                    editDialogOpen === false && (
                        loading.type === loadingTypes.purchasesGetPurchaseProjects ||
                        loading.type === loadingTypes.projectsGetAllProjects ||
                        loading.type === loadingTypes.legalEntitiesGetAllLegalEntities ||
                        loading.type === loadingTypes.taxGetTaxOptions ||
                        loading.type === loadingTypes.vendorsGetVendorsBySupplier ||
                        loading.type === loadingTypes.currenciesGetCurrenciesByLegalEntity ||
                        loading.type === loadingTypes.auditGetTransactionSubTypes ||
                        loading.type === loadingTypes.auditGetAuditLog ||
                        loading.type === loadingTypes.permissionsGetNextApprovers ||
                        loading.type === loadingTypes.permissionsGetPermissionsByRole)
                }
                options={{
                    actionsColumnIndex: -1,
                    pageSize: 10,
                    searchFieldAlignment: "left",
                    showTitle: false,
                    isLoading: true,
                    search: true,
                    filtering: false,
                    // loadingType: "linear",
                    // thirdSortClick: false,
                    sorting: true,
                }}
            />

            <DetailsDialog
                key={"Purchase-Project-Details-" + purchaseDetails?.data?.uniqueId}
                details={purchaseDetails.data}
                onClose={() => setPurchaseDetails(initialDialogState)}
                open={purchaseDetails.open}
                isAdmin={!actions[1](purchaseDetails.data).hidden}
                isProjectAdmin={roles.is_purchase_project_admin}
                isWorkshop={roles.is_purchase_project_1st_workshop_approver}
                userEmail={user.userEmail}
                downloadFile={downloadFile}
                onSubmit={async updatedPurchaseRequest => {
                    await updatePurchaseProjectRequestStatus(purchaseDetails.data.uniqueId, updatedPurchaseRequest);
                    getPurchaseProjects();
                    setPurchaseDetails(initialDialogState)
                }}
                loading={loading}
            />
            <ResponseDialog
                key={"Purchase-Project-Respond-" + purchaseResponse.data?.uniqueId}
                details={purchaseResponse.data}
                onClose={() => setPurchaseResponse(initialDialogState)}
                open={purchaseResponse.open}
                isProjectAdmin={roles.is_purchase_project_admin}
                isWorkshop={roles.is_purchase_project_1st_workshop_approver}
                userEmail={user.userEmail}
                onSubmit={async (updatedPurchaseRequest) => {
                    await updatePurchaseProjectRequestStatus(purchaseResponse.data.uniqueId, updatedPurchaseRequest);
                    getPurchaseProjects();
                    setPurchaseResponse(initialDialogState);
                }}
                loading={loading}
            />
            <NewRequestDialog
                open={addDialogOpen}
                onClose={() => setAddDialogOpen(false)}
                onSubmit={async newPurchase => {
                    await addPurchaseProjectRequest(newPurchase);
                    getPurchaseProjects();
                    setAddDialogOpen(false);
                }}
                loading={loading}
                projects={projects}
                legalEntities={legalEntities}
                getVendorsBySupplier={getVendorsBySupplier}
                vendorsBySupplier={vendorsBySupplier}
                getCurrenciesByLegalEntity={getCurrenciesByLegalEntity}
                currenciesByLegalEntity={currenciesByLegalEntity}
                taxOptions={taxOptions}
            />
            <NewRequestDialog
                key={"Purchase-Project-Edit-" + editing?.uniqueId}
                open={editDialogOpen}
                onClose={() => {
                    setEditDialogOpen(false);
                }}
                initialValues={editing}
                onSubmit={async updatedPurchase => {
                    await updatePurchaseProjectRequest(editing?.uniqueId, updatedPurchase);
                    getPurchaseProjects();
                    setEditDialogOpen(false);
                }}
                loading={loading}
                projects={projects}
                getVendorsBySupplier={getVendorsBySupplier}
                vendorsBySupplier={vendorsBySupplier}
                legalEntities={legalEntities}
                getCurrenciesByLegalEntity={getCurrenciesByLegalEntity}
                currenciesByLegalEntity={currenciesByLegalEntity}
                taxOptions={taxOptions}
            />
            <AuditLoggingTableDialog
                open={auditLogDialogOpen.open}
                auditPayload={auditLog}
                type={auditLogDialogOpen.type}
                onClose={() => setAuditLogDialogOpen({open: false, type: ""})}
                loading={loading.type === loadingTypes.auditGetAuditLog}
            />
            <ConfirmationDialog
                open={deletePurchaseRequestConfirmationOpen}
                handleClose={() => setDeletePurchaseRequestConfirmationOpen(false)}
                handleConfirmation={async confirm => {
                    if (confirm) {
                        await deletePurchaseProjectRequest(selectedPurchaseRequest.uniqueId);
                        getPurchaseProjects();
                    }

                    setDeletePurchaseRequestConfirmationOpen(false);
                }}
                promptText={"Are you sure you want to cancel the purchase request ?"}
                loading={loading.type === loadingTypes.purchasesDeletePurchaseProjectRequest}
            />
        </>
    );
}

const mapStateToProps = state => ({
    purchaseProjects: state.purchasesReducer.purchaseProjects,
    user: state.userReducer.currentUser,
    loading: state.loadingReducer.loading,
    projects: state.projectsReducer.projects,
    vendorsBySupplier: state.vendorsReducer.vendorsBySupplier,
    legalEntities: state.legalEntitiesReducer.legalEntities,
    nextApprovers: state.permissionsReducer.nextApprovers,
    auditLog: state.auditReducer.auditLog,
    currenciesByLegalEntity: state.currenciesReducer.currenciesByLegalEntity,
    taxOptions: state.taxReducer.taxOptions,
    transactionSubTypes: state.auditReducer.transactionSubTypes,
});

const mapDispatchToProps = dispatch => ({
    getPurchaseProjects: () => dispatch(purchaseActions.getPurchaseProjects()),
    addPurchaseProjectRequest: body => dispatch(purchaseActions.addPurchaseProjectRequest(body)),
    updatePurchaseProjectRequest: (id, body) => dispatch(purchaseActions.updatePurchaseProjectRequest(id, body)),
    updatePurchaseProjectRequestStatus: (id, body) => dispatch(purchaseActions.updatePurchaseProjectRequestStatus(id, body)),
    deletePurchaseProjectRequest: id => dispatch(purchaseActions.deletePurchaseProjectRequest(id)),
    getCurrentUser: () => getCurrentUser()(dispatch),
    getAllProjects: (type) => dispatch(getAllProjects(type)),
    getAllLegalEntities: () => dispatch(getAllLegalEntities()),
    getVendorsBySupplier: id => dispatch(getVendorsBySupplier(id)),
    downloadFile: (id, name) => dispatch(downloadFile(id, name)),
    getUserRoles: (roleType, roleSubType) => dispatch(getPermissionsByRole(roleType, roleSubType)),
    getAuditLog: (id, type) => dispatch(getAuditLog(id, type)),
    getTransactionSubTypes: () => dispatch(getTransactionSubTypes()),
    getCurrenciesByLegalEntity: (id) => dispatch(getCurrenciesByLegalEntity(id)),
    getTaxOptions: () => dispatch(getTaxOptions()),
    getNextApprovers: (type,id) => dispatch(getNextApprovers(type, id))
});

export default connect(mapStateToProps, mapDispatchToProps)(PurchasesProject);


