import CustomDialogTitle from "../../../../components/Dialogs/CustomDialogTitle/CustomDialogTitle";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import React from "react";
import {formatAmount, formatShortDate} from "../../../../assets/helpers/globals";
import Dialog from "@material-ui/core/Dialog";
import styled, {css} from "styled-components";
import DialogContent from "@material-ui/core/DialogContent";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Typography from "@material-ui/core/Typography";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import List from "@material-ui/core/List";

const H3 = styled.h3`
  margin: 0;
`;

const P = styled.p`
  margin: 0;
  ${props => props.blue && css`color: RoyalBlue;`}
  ${props => props.small && css`font-size: small;`}
`;

const FormDialogContent = styled(DialogContent)`
  @media only screen and (max-width: 425px) {
    padding: 12px !important;
  }
`;

const RequesterView = ({
                           details,
                           onClose,
                           open,
                       }) =>
    <Dialog
        aria-labelledby="invoice-request-details-dialog-title"
        onClose={onClose}
        fullWidth
        maxWidth={"sm"}
        open={open}
    >
        <CustomDialogTitle
            id="invoice-request-details-dialog-title"
            onClose={onClose}
            showTextContent
        >
            Invoice Request Details
        </CustomDialogTitle>
        <FormDialogContent dividers>
            <ListItem>
                <ListItemText>
                    <H3>Contract Number / PO Number / Order Number</H3>
                    <P>{details?.contractNumber || "Not specified"}</P>
                </ListItemText>
            </ListItem>
            <ListItem>
                <ListItemText>
                    <H3>Invoice Requestor</H3>
                    <P>{details?.requestor?.userFullName || "Not specified"}</P>
                </ListItemText>
            </ListItem>
            <ListItem>
                <ListItemText>
                    <H3>Request Submitted</H3>
                    <P>{details?.requestSubmittedDate || "Not specified"}</P>
                </ListItemText>
            </ListItem>
            <ListItem>
                <ListItemText>
                    <H3>Last Response Date</H3>
                    <P>{details.lastResponseDate || "Not specified"}</P>
                </ListItemText>
            </ListItem>
            {details.nextApprovers?.length !== 0 ?
                <ListItem style={{padding: "16px 16px"}}>
                    <ExpansionPanel style={{ boxShadow: "none",width: "99.4%" }} >
                        <ExpansionPanelSummary
                            expandIcon={<ExpandMoreIcon />}
                            style={{ minHeight: "20px",height:"10px",padding:"0px"}}
                        >
                            <Typography><span style={{fontWeight:"bold",fontSize:"1.17em"}}>{details?.nextApprovers?.length} Allowed Approvers</span></Typography>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails style={{ padding: "8px 16px 0px 16px" }}>
                            <List style={{ paddingBottom: 0, paddingTop: 14 }}>
                                { details?.nextApprovers?.map(approver => (
                                    <ListItem   key={approver?.userEmail}>
                                        <ListItemText primary={approver.userFullName}
                                                      secondary={ approver.userEmail}
                                        />
                                    </ListItem>
                                )) || "None"}
                            </List>
                        </ExpansionPanelDetails>
                    </ExpansionPanel>
                </ListItem> : ""}
            <ListItem>
                <ListItemText>
                    <H3>Project Number</H3>
                    <P>{details.project?.projectNumber || "Not specified"}</P>
                </ListItemText>
            </ListItem>
            <ListItem>
                <ListItemText>
                    <H3>Client</H3>
                    <P>{details.vendor ? details.vendor.vendorName : details.vendorOther}</P>
                </ListItemText>
            </ListItem>
            <ListItem>
                <ListItemText>
                    <H3>Invoice Due Date</H3>
                    <P>{formatShortDate(details?.invoiceDueDate) || "Not specified"}</P>
                </ListItemText>
            </ListItem>
            <ListItem>
                <ListItemText>
                    <H3>Invoice Date</H3>
                    <P>
                        {formatShortDate(details?.invoiceDate) || "Not specified"}
                    </P>
                </ListItemText>
            </ListItem>
            <ListItem>
                <ListItemText>
                    <H3>Invoice Amount ({details.xeroTaxOption?.taxOptionText})</H3>
                    <P>{formatAmount(details.invoiceAmount, details.currency?.name) || "Not specified"}</P>
                    {details.exchangeRate && details.currency?.name !== "ZAR" ? <P blue small>{`~ 1 ${details.currency?.name} = ${details.exchangeRate} ZAR`}</P> : ""}
                </ListItemText>
            </ListItem>
            <ListItem>
                <ListItemText>
                    <H3>Full Description</H3>
                    <P>{details?.fullDescription || "Not specified"}</P>
                </ListItemText>
            </ListItem>
            <ListItem>
                <ListItemText>
                    <H3>Status</H3>
                    <P>{details.status}</P>
                </ListItemText>
            </ListItem>
            <ListItem>
                <ListItemText>
                    <H3>Comment</H3>
                    <P>{details.comment || "Not specified"}</P>
                </ListItemText>
            </ListItem>
        </FormDialogContent>
    </Dialog>

export default RequesterView;