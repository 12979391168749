import React from "react";
import Button from "@material-ui/core/Button";
import styled from "styled-components";
import Tooltip from "@material-ui/core/Tooltip";
import {Close} from "@material-ui/icons";
import {FieldArray} from "react-final-form-arrays";
import Grid from "@material-ui/core/Grid";
import FormLabel from "@material-ui/core/FormLabel";
import {Autocomplete, TextField} from "mui-rff";
import {TextField as MuiTextField} from "@material-ui/core";
import {PercentageFormatOnInput, WhenFieldChanges} from "../../../assets/helpers/finalFormHelpers";

const Error = styled.p`
  color: #ea4335;
  margin: 0;
  font-size: 0.75rem;
  margin-top: 3px;
  text-align: left;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  line-height: 1.66;
  letter-spacing: 0.03333em;
`;

const MultipleProjectFieldAdapter = ({ errors, input: { name }, push, removeBatch, required, values, projects, touched, itemOneName, itemTwoName, disabled }) => {
    const projectArray = values[name];

    const reducer = (previousValue, currentValue) => {
        if (currentValue && currentValue[itemTwoName])
            return parseFloat(previousValue) + parseFloat(currentValue[itemTwoName]);
        else
            return parseFloat(previousValue)
    };

    return (
        <>
            <div style={{ marginBottom: 12 }}>
                <Button
                    type="button"
                    variant="contained"
                    disabled={disabled}
                    onClick={() => push(`${name}`, {[itemOneName]: undefined, [itemTwoName]: undefined})}
                >
                    Add Project
                </Button>
                <Button
                    type="button"
                    variant="contained"
                    disabled={disabled}
                    onClick={() => values[name] && values[name].length ? removeBatch(`${name}`, values[name].map((projectListItem, projectListItemIdx) => projectListItemIdx)) : ""}>
                    Remove All Projects
                </Button>
            </div>
            <FieldArray name={name}>
                {({ fields }) =>
                    fields.map((name, index) => (
                        <Grid key={name} container spacing={2} alignItems="center">
                            <Grid item xs={3} style={{ textAlign: "center" }}>
                                <FormLabel style={{fontSize: 12}}>Project. #{index + 1}</FormLabel>
                            </Grid>
                            <Grid item xs={8}>
                                <Autocomplete
                                    label="Project Number"
                                    name={`${name}.${itemOneName}`}
                                    noOptionsText={"No projects found"}
                                    required={required}
                                    options={["", ...projects.sort((a, b) => b.projectNumber.localeCompare(a.projectNumber))]}
                                    disabled={disabled}
                                    getOptionLabel={option => option ? `${option.projectNumber}: ${option.projectName}` : ""}
                                    getOptionSelected={(option, value) => option.uniqueId === value.uniqueId}
                                    filterOptions={(options, params) => options.filter((option) => option !== "" &&
                                        (option.projectNumber.toLowerCase().includes(params.inputValue.toLowerCase()) ||
                                            option.projectName.toLowerCase().includes(params.inputValue.toLowerCase()) ||
                                            (option.projectNumber + ": " + option.projectName).toLowerCase().includes(params.inputValue.toLowerCase()))
                                        && !projectArray.some((projectListItem) => projectListItem ? projectListItem.project?.uniqueId === option.uniqueId : true))}
                                    handleHomeEndKeys
                                    renderInput={(params) =>
                                        <MuiTextField
                                            {...params}
                                            label="Project Number"
                                            name={`${name}.${itemOneName}`}
                                            required={required}
                                            error={required && touched[`${name}.${itemOneName}`] && errors[`${name}.${itemOneName}`] !== undefined}
                                        />}
                                />
                                {required && touched[`${name}.${itemOneName}`] && errors[`${name}.${itemOneName}`] && <Error>{errors[`${name}.${itemOneName}`]}</Error>}
                            </Grid>
                            <Grid item xs={1} style={{ paddingLeft: 0 }}>
                                <Button
                                    disabled={disabled}
                                    onClick={() => fields.remove(index)}
                                >
                                    <Tooltip title="Remove">
                                        <Close color={"error"}/>
                                    </Tooltip>
                                </Button>
                            </Grid>
                            <WhenFieldChanges
                                field={`${name}.${itemOneName}`}
                                set={`${name}.${itemOneName}.projectManager.userFullName`}
                                to={fields.value[index].project?.projectManager?.userFullName}
                                shouldChangeHandler={(project) => project !== null}
                            />
                            <WhenFieldChanges
                                field={`${name}.${itemOneName}`}
                                set={`${name}.${itemOneName}.projectManager.userFullName`}
                                to={undefined}
                                shouldChangeHandler={(project) => project === null}
                            />
                            <Grid item xs={3} />
                            <Grid item xs={5}>
                                <TextField
                                    label="Project Manager"
                                    name={`${name}.${itemOneName}.projectManager.userFullName`}
                                    margin="none"
                                    disabled
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <TextField
                                    label="% Allocation"
                                    margin="none"
                                    name={`${name}.${itemTwoName}`}
                                    disabled={disabled}
                                    required={required}
                                    error={required && touched[`${name}.${itemTwoName}`] && !!errors[`${name}.${itemTwoName}`]}
                                    InputProps={{
                                        inputComponent: PercentageFormatOnInput,
                                    }}
                                />
                                {required && touched[`${name}.${itemTwoName}`] && errors[`${name}.${itemTwoName}`] && <Error>{errors[`${name}.${itemTwoName}`]}</Error>}
                            </Grid>
                        </Grid>
                    ))
                }
            </FieldArray>
            {values[name] && values[name].length && values[name].length > 0 ?
                <Grid container spacing={2} alignItems="center">
                    <Grid item xs={8} style={{textAlign: "end"}}>
                        <FormLabel style={{fontSize: 12}}>Total Percentage Allocation: </FormLabel>
                    </Grid>
                    <Grid item xs={3}>
                        <TextField
                            name="totalAllocation"
                            margin="normal"
                            disabled={true}
                            value={values[name] && values[name].length ? values[name].reduce(reducer, 0) + "%" : "0%"}>
                        </TextField>
                    </Grid>
                </Grid> : null
            }
            {required && touched[name] && errors[name] && <Error>{errors[name]}</Error>}
        </>
    )
}

export default MultipleProjectFieldAdapter;