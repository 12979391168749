import React from "react";
import AdminView from "./DetailsViews/AdminView";
import RequesterView from "./DetailsViews/RequesterView";

const DetailsDialog = ({
                            details,
                            onClose,
                            open,
                            isAdmin,
                            isProjectAdmin,
                            isWorkshop,
                            userEmail,
                            onSubmit,
                            loading,
                            downloadFile,
                               }) =>
   isAdmin ?
        <AdminView details={details} onClose={onClose} open={open} onSubmit={onSubmit} loading={loading} downloadFile={downloadFile} isProjectAdmin={isProjectAdmin} userEmail={userEmail} isWorkshop={isWorkshop} /> :
        <RequesterView details={details} onClose={onClose} open={open} loading={loading} downloadFile={downloadFile} />

export default DetailsDialog;
