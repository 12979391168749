import React from "react";
import Grid from "@material-ui/core/Grid";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import {Autocomplete, TextField, Radios, Checkboxes} from "mui-rff";

import styled, {css} from "styled-components";
import {Form, Field} from "react-final-form";

import {
    Condition,
    focusOnError,
    NumberFormatOnInput,
    WhenFieldChanges
} from "../../../../../assets/helpers/finalFormHelpers";
import FileFieldAdapter from "../../../../../components/Inputs/FileField/FileField";
import {formatShortDate, sleep} from "../../../../../assets/helpers/globals";
import CustomDialogTitle from "../../../../../components/Dialogs/CustomDialogTitle/CustomDialogTitle";
import Loader from "../../../../../components/Loader/Loader";
import * as loadingTypes from "../../../../../store/error/types";

import arrayMutators from 'final-form-arrays'
import MultipleProjectFieldAdapter from "../../../../../components/Inputs/MultipleProjectField/MultipleProjectField";
import CustomButton from "../../../../../components/Buttons/Button/Button";
import Tooltip from "@material-ui/core/Tooltip";
import {Refresh} from "@material-ui/icons";
// import SplitButton from "../../../../../components/Buttons/SplitButton/SplitButton";

const LoadingDiv = styled.div`
  position: absolute;
  bottom: 11px;
  ${props => props.value && css`right: 81px;`}
  ${props => !props.value && css`right: 36px;`}
`;

const FormDialogContent = styled(DialogContent)`
  padding: 24px 30px;
  @media only screen and (max-width: 425px) {
    padding: 12px !important;
  }
`;

const ActionButton = styled(Button)`
  margin: 0 5px;
`;

function NewRequestDialog  ({
                                open,
                                onClose,
                                onSubmit,
                                loading,
                                initialValues,
                                projects,
                                legalEntities,
                                vendorsBySupplier,
                                getVendorsBySupplier,
                                currenciesByLegalEntity,
                                getCurrenciesByLegalEntity,
                                taxOptions,
                                isAdmin,
                                downloadFile,
                                populateVendors
}) {
    const whenFieldChanges = values => <>
        <WhenFieldChanges
            field="project"
            set="projectManager"
            to={values.project?.projectManager?.userFullName || ''}
            shouldChangeHandler={(project) => project !== null}
        />
        <WhenFieldChanges
            field="project"
            set="projectManager"
            to={''}
            shouldChangeHandler={(project) => project === null}
        />
        <WhenFieldChanges
            field="legalEntity"
            set="vendor"
            to={''}
            shouldChangeHandler={(legalEntity) => legalEntity !== null}
        />
        <WhenFieldChanges
            field="legalEntity"
            set="vendor"
            to={''}
            shouldChangeHandler={(legalEntity) => legalEntity === null}
        />
        <WhenFieldChanges
            field="legalEntity"
            set="currency"
            to={''}
            shouldChangeHandler={(legalEntity) => legalEntity !== null}
        />
        <WhenFieldChanges
            field="legalEntity"
            set="currency"
            to={''}
            shouldChangeHandler={(legalEntity) => legalEntity === null}
        />
    </>

    const formFields = (values, errors, touched, push, removeBatch) => [
        {
            field: (
                <Grid item xs={12}>
                    <Autocomplete
                        label="Billing From"
                        name="legalEntity"
                        noOptionsText={"No legal entities found"}
                        required
                        disabled={!isAdmin}
                        autoHighlight
                        // filterSelectedOptions
                        // disableClearable
                        onChange={(event, value) => {
                            if (value) {
                                getVendorsBySupplier(value.uniqueId);
                                getCurrenciesByLegalEntity(value.uniqueId);
                            }
                        }}
                        options={["", ...legalEntities.sort((a, b) => a.entityName.localeCompare(b.entityName))]}
                        getOptionLabel={option => option ? `${option.entityName}` : ""}
                        getOptionSelected={(option, value) => option.uniqueId === value.uniqueId}
                        filterOptions={(options, params) => options.filter((option) => option !== "" && option.entityName.toLowerCase().includes(params.inputValue.toLowerCase()))}
                        handleHomeEndKeys
                    />
                </Grid>
            ),
        },
        {
            field: (
                <Grid item xs={12}>
                    <Radios
                        label="Supplier"
                        name="vendorType"
                        margin="none"
                        disabled={!values.legalEntity || !isAdmin || loading.type === loadingTypes.xeroPopulateVendors || loading.type === loadingTypes.vendorsGetVendorsBySupplier}
                        required
                        radioGroupProps={{row: true}}
                        data={[
                            {label: 'Existing Supplier', value: 'existingVendor'},
                            {label: 'Other Supplier', value: 'otherVendor'},
                        ]}
                    />
                </Grid>
            ),
        },
        {
            field: (
                <Condition when="vendorType" is="existingVendor">
                    <Grid item xs={10}>
                        <Autocomplete
                            label="Supplier Name"
                            name="vendor"
                            noOptionsText={"No suppliers found"}
                            disabled={!values.legalEntity || !isAdmin || loading.type === loadingTypes.xeroPopulateVendors || loading.type === loadingTypes.vendorsGetVendorsBySupplier}
                            required
                            options={["", ...vendorsBySupplier.sort((a, b) => a.vendorName.localeCompare(b.vendorName))]}
                            getOptionLabel={option => option ? `${option.vendorName}` : ""}
                            getOptionSelected={(option, value) => option.uniqueId === value.uniqueId}
                            filterOptions={(options, params) => options.filter((option) => option !== "" && option.vendorName.toLowerCase().includes(params.inputValue.toLowerCase()))}
                            handleHomeEndKeys
                        />
                    </Grid>
                </Condition>
            ),
        },
        {
            field: (
                <Condition when="vendorType" is="existingVendor">
                    <Grid item xs={2}>
                        <CustomButton
                            color='default'
                            onClick={async () => {
                                await populateVendors();
                                if (initialValues.purchase.vendor)
                                    await getVendorsBySupplier(initialValues.purchase.vendor.legalEntities.uniqueId);
                                else
                                    await getVendorsBySupplier(initialValues.purchase.legalEntity.uniqueId)
                            }}
                            disabled={!isAdmin || loading.type === loadingTypes.xeroPopulateVendors || loading.type === loadingTypes.vendorsGetVendorsBySupplier}
                            // style={{textAlign: "center"}}
                            hasLoader={true}
                            loading={loading.type === loadingTypes.xeroPopulateVendors || loading.type === loadingTypes.vendorsGetVendorsBySupplier}
                        >
                            <Tooltip title={"Get Latest Suppliers"}>
                                <Refresh/>
                            </Tooltip>
                        </CustomButton>
                    </Grid>
                </Condition>
            ),
        },
        {
            field: (
                <Condition when="vendorType" is="otherVendor">
                    <Grid item xs={12}>
                        <TextField
                            label="Other Supplier Name"
                            name="vendorOther"
                            margin="none"
                            disabled={!values.legalEntity || !isAdmin || loading.type === loadingTypes.xeroPopulateVendors || loading.type === loadingTypes.vendorsGetVendorsBySupplier}
                            required
                        />
                    </Grid>
                </Condition>
            ),
        },
        {
            field: (
                <Grid item xs={12}>
                    <Checkboxes
                        name="isWorkshop"
                        formControlProps={{margin: 'none'}}
                        data={{label: 'Workshop', value: false}}
                        disabled={!isAdmin}
                    />
                </Grid>
            ),
        },
        {
            field: (
                <Condition when="isWorkshop" is={false}>
                    <Grid item xs={12}>
                        <Autocomplete
                            label="Project Number"
                            name="project"
                            noOptionsText={"No projects found"}
                            required
                            disabled={!isAdmin}
                            options={["", ...projects.sort((a, b) => b.projectNumber.localeCompare(a.projectNumber))]}
                            getOptionLabel={option => option ? `${option.projectNumber}: ${option.projectName}` : ""}
                            getOptionSelected={(option, value) => option.uniqueId === value.uniqueId}
                            filterOptions={(options, params) => options.filter((option) => option !== "" &&
                                (option.projectNumber.toLowerCase().includes(params.inputValue.toLowerCase()) ||
                                    option.projectName.toLowerCase().includes(params.inputValue.toLowerCase()) ||
                                    (option.projectNumber + ": " + option.projectName).toLowerCase().includes(params.inputValue.toLowerCase())))}
                            handleHomeEndKeys
                        />
                    </Grid>
                </Condition>
            ),
        },
        {
            field: (
                <Condition when="isWorkshop" is={false}>
                    <Grid item xs={12}>
                        <TextField
                            label="Project Manager"
                            name="projectManager"
                            margin="none"
                            disabled
                        />
                    </Grid>
                </Condition>
            ),
        },
        {
            field: (
                <Condition when="isWorkshop" is={true}>
                    <Grid item xs={12}>
                        <Field
                            component={MultipleProjectFieldAdapter}
                            name="projectsDetails"
                            label="Project List"
                            values={values}
                            disabled={!isAdmin}
                            errors={errors}
                            itemOneName={"project"}
                            itemTwoName={"projectCostPercentage"}
                            touched={touched}
                            push={push}
                            removeBatch={removeBatch}
                            projects={projects}
                            required={true}
                        />
                    </Grid>
                </Condition>
            ),
        },
        {
            field: (
                <Grid item xs={12}>
                    <Radios
                        label="Material type"
                        name="materialType"
                        margin="none"
                        required
                        disabled={!isAdmin}
                        radioGroupProps={{ row: true }}
                        data={[
                            {label: 'Consumable', value: 'Consumable'},
                            {label: 'Raw', value: 'Raw'},
                            {label: 'Rental', value: 'Rental'},
                        ]}
                    />
                </Grid>
            ),
        },
        {
            field: (
                <Grid item xs={12}>
                    <TextField
                        label="Purchase Description"
                        name="description"
                        margin="none"
                        required
                        disabled={!isAdmin}
                        multiline
                    />
                </Grid>
            ),
        },
        {
            field: (
                <Grid item xs={12}>
                    <TextField
                        label="Quote Number"
                        name="quoteNumber"
                        margin="none"
                        required
                        disabled={!isAdmin}
                    />
                </Grid>
            ),
        },
        {
            field: (
                <Grid item xs={12}>
                    <Field
                        component={FileFieldAdapter}
                        name="attachments"
                        label="Quote"
                        buttonLabel="UPLOAD A QUOTE"
                        required
                        hideRemoveButton
                        initialLength={initialValues?.attachments?.length}
                        showDownloadButton
                        downloadFile={downloadFile}
                        loadingType={loading.type}
                    />
                </Grid>
            ),
        },
        {
            field: (
                <Grid item xs={4}>
                    <Autocomplete
                        label="Currency"
                        name="currency"
                        noOptionsText={"No currencies found"}
                        disabled={!values.legalEntity || !isAdmin}
                        required
                        options={["", ...currenciesByLegalEntity.sort((a, b) => a.name.localeCompare(b.name))]}
                        getOptionLabel={option => option ? option.name : ""}
                        getOptionSelected={(option, value) => option.uniqueId === value.uniqueId}
                        filterOptions={(options, params) => options.filter((option) => option !== "" &&
                            (option.name.toLowerCase().includes(params.inputValue.toLowerCase())))}
                        handleHomeEndKeys
                        // disableClearable
                    />
                </Grid>
            ),
        },
        {
            field: (
                <Grid item xs={4}>
                    <TextField
                        label="Amount"
                        name="totalCost"
                        margin="none"
                        required
                        disabled={!isAdmin}
                        InputProps={{
                            inputComponent: NumberFormatOnInput,
                        }}
                    />
                </Grid>
            ),
        },
        {
            field: (
                <Grid item xs={4}>
                    <Autocomplete
                        label="Tax Option"
                        name="xeroTaxOption"
                        noOptionsText={"No tax options found"}
                        required
                        disabled={!isAdmin}
                        options={["", ...taxOptions.sort((a, b) => a.taxOptionText.localeCompare(b.taxOptionText))]}
                        getOptionLabel={option => option ? option.taxOptionText : ""}
                        getOptionSelected={(option, value) => option.uniqueId === value.uniqueId}
                        filterOptions={(options, params) => options.filter((option) => option !== "" &&
                            (option.taxOptionText.toLowerCase().includes(params.inputValue.toLowerCase())))}
                        handleHomeEndKeys
                        // disableClearable
                    />
                </Grid>
            ),
        },
    ]

    const validate = values => {
        const errors = {}

        if (!values.legalEntity)
            errors.legalEntity = 'Required'
        if (values.vendorType === "existingVendor" && !values.vendor)
            errors.vendor = 'Required'
        if (values.vendorType === "otherVendor" && !values.vendorOther)
            errors.vendorOther = 'Required'
        if (!values.description)
            errors.description = 'Required'
        if (!values.attachments || !values.attachments.length)
            errors.attachments = 'Required'
        if (!values.currency)
            errors.currency = 'Required'
        if (!values.totalCost)
            errors.totalCost = 'Required'
        if (!values.xeroTaxOption)
            errors.xeroTaxOption = 'Required'
        if (!values.quoteNumber)
            errors.quoteNumber = 'Required'
        if (values.isWorkshop === true) {
            if (!values.projectsDetails ||
                !values.projectsDetails.length ||
                values.projectsDetails.indexOf(null) !== -1)
                errors.projectsDetails = "Required"
            else {
                let totalProjectCostPercentage = 0
                values.projectsDetails.forEach((projectsDetailsItem, projectsDetailsItemIdx) => {
                    Object.entries(projectsDetailsItem).forEach(([key, value]) => {
                        if (key === "project" && !value)
                            errors["projectsDetails[" + projectsDetailsItemIdx + "].project"] = "Required"
                        if (key === "projectCostPercentage" && !value)
                            errors["projectsDetails[" + projectsDetailsItemIdx + "].projectCostPercentage"] = "Required"
                        if (key === "projectCostPercentage" && (value === "0" || value === "0.0" || value === "0.00"))
                            errors["projectsDetails[" + projectsDetailsItemIdx + "].projectCostPercentage"] = "Value may not be 0. Please enter a valid value."
                        if (key === "projectCostPercentage" && value)
                            totalProjectCostPercentage += parseFloat(value)
                    })
                    if (!projectsDetailsItem.hasOwnProperty('projectCostPercentage'))
                        errors["projectsDetails[" + projectsDetailsItemIdx + "].projectCostPercentage"] = "Required"
                })

                if (totalProjectCostPercentage < 100)
                    errors.totalAllocation = "Total percentage allocation should add up to 100%. Please re-allocate."
                if (totalProjectCostPercentage > 100)
                    errors.totalAllocation = "Total percentage allocation exceeds 100%. Please re-allocate."
            }
        }
        else if (!values.project)
            errors.project = 'Required'

        return errors
    }

    const onSubmitForm = async values => {
        let formData = new FormData();
        let attachArray = [];

        // if (values.purchaseOrderStatus === "Quote Reviewed") {
        //     let errors = validate(values)
        //     if (!isEmptyObject(errors))
        //         return errors
        //     else {
                values.attachments.forEach(file => {
                    if (file.generatedFilename)
                        attachArray.push(file.generatedFilename)
                    else
                        formData.append("file", file);
                });

                if (attachArray && attachArray.length)
                    formData.append("existingAttachments", attachArray);
                else
                    formData.append("existingAttachments", null);

                formData.append('requestDate', formatShortDate(new Date()));
                formData.append('description', values.description);
                formData.append('purchaseOrderStatus', isAdmin ? "Quote Reviewed" : "Quote Awaiting Review");
                formData.append('status', values.status);
                formData.append('rejectReason', "");
                formData.append('totalCost', values.totalCost);
                formData.append('currency', values.currency.uniqueId);
                formData.append('quoteNumber', values.quoteNumber);
                formData.append('xeroTaxOption', values.xeroTaxOption.uniqueId);
                formData.append("purchaseOfficeType", values.purchaseOfficeType);
                formData.append('legalEntity', values.legalEntity.uniqueId);
                formData.append('materialType', values.materialType);

                if (values.vendorType === "existingVendor" && values.vendor)
                    formData.append('vendor', values.vendor.uniqueId);
                else
                    formData.append('vendorOther', values.vendorOther);

                formData.append('purchaseLevel', values.purchaseLevel);

                if (values.isWorkshop === true) {
                    values.projectsDetails.forEach((projectsDetailsItem, projectsDetailsItemIdx) => {
                        formData.append(`projectsDetails[${projectsDetailsItemIdx}].project`, projectsDetailsItem.project.uniqueId);
                        formData.append(`projectsDetails[${projectsDetailsItemIdx}].projectCostPercentage`, projectsDetailsItem.projectCostPercentage);
                        formData.append(`projectsDetails[${projectsDetailsItemIdx}].status`, projectsDetailsItem.status);
                    });
                    formData.append('workshopApprovalStatus', values.workshopApprovalStatus);
                }
                else {
                    formData.append(`projectsDetails[0].project`, values.projectsDetails[0].project.uniqueId)
                    formData.append(`projectsDetails[0].projectCostPercentage`, values.projectsDetails[0].projectCostPercentage)
                    formData.append(`projectsDetails[0].status`, values.projectsDetails[0].status)
                }

                if (values.isWorkshop === true)
                    formData.append('workshop', "Roodekop");
                else
                    formData.append('workshop', "");
        //     }
        // }
        // else if (values.purchaseOrderStatus === "Quote Awaiting Review") {
        //     let errors = {}
        //     if (values.attachments.length === initialValues.attachments.length) {
        //         errors.attachments = 'Required';
        //         return errors;
        //     }
        //     else {
        //         values.attachments.forEach(file => {
        //             if (file.generatedFilename)
        //                 attachArray.push(file.generatedFilename)
        //             else
        //                 formData.append("file", file);
        //         });
        //
        //         if (attachArray && attachArray.length)
        //             formData.append("existingAttachments", attachArray);
        //         else
        //             formData.append("existingAttachments", null);
        //
        //         formData.append('requestDate', formatShortDate(new Date()));
        //         formData.append('description', values.description);
        //         formData.append('purchaseOrderStatus', values.purchaseOrderStatus);
        //         formData.append('status', values.status);
        //         formData.append('rejectReason', "");
        //         formData.append('totalCost', values.totalCost);
        //         formData.append('currency', values.currency.uniqueId);
        //         formData.append('quoteNumber', values.quoteNumber);
        //         formData.append('xeroTaxOption', values.xeroTaxOption.uniqueId);
        //         formData.append("purchaseOfficeType", values.purchaseOfficeType);
        //         formData.append('legalEntity', values.legalEntity.uniqueId);
        //         formData.append('materialType', values.materialType);
        //
        //         if (values.vendorType === "existingVendor" && values.vendor)
        //             formData.append('vendor', values.vendor.uniqueId);
        //         else
        //             formData.append('vendorOther', values.vendorOther);
        //
        //         formData.append('purchaseLevel', values.purchaseLevel);
        //
        //         if (values.isWorkshop === true) {
        //             values.projectsDetails.forEach((projectsDetailsItem, projectsDetailsItemIdx) => {
        //                 formData.append(`projectsDetails[${projectsDetailsItemIdx}].project`, projectsDetailsItem.project.uniqueId);
        //                 formData.append(`projectsDetails[${projectsDetailsItemIdx}].projectCostPercentage`, projectsDetailsItem.projectCostPercentage);
        //                 formData.append(`projectsDetails[${projectsDetailsItemIdx}].status`, projectsDetailsItem.status);
        //             });
        //             formData.append('workshopApprovalStatus', values.workshopApprovalStatus);
        //         }
        //         else {
        //             formData.append(`projectsDetails[0].project`, values.projectsDetails[0].project.uniqueId);
        //             formData.append(`projectsDetails[0].projectCostPercentage`, values.projectsDetails[0].projectCostPercentage);
        //             formData.append(`projectsDetails[0].status`, values.projectsDetails[0].status);
        //         }
        //
        //         if (values.isWorkshop === true)
        //             formData.append('workshop', values.workshop);
        //         else
        //             formData.append('workshop', "");
        //     }
        // }
        // else {
        //     values.attachments.forEach(file => {
        //         if (file.generatedFilename)
        //             attachArray.push(file.generatedFilename)
        //         else
        //             formData.append("file", file);
        //     });
        //
        //     if (attachArray && attachArray.length)
        //         formData.append("existingAttachments", attachArray);
        //     else
        //         formData.append("existingAttachments", null);
        //
        //     formData.append('requestDate', formatShortDate(new Date()));
        //     formData.append('description', values.description || "");
        //     formData.append('purchaseOrderStatus', values.purchaseOrderStatus);
        //     formData.append('status', values.status);
        //     formData.append('rejectReason', "");
        //     formData.append('totalCost', values.totalCost || "");
        //     formData.append('currency', values.currency.uniqueId || "");
        //     formData.append('quoteNumber', values.quoteNumber || "");
        //     formData.append('xeroTaxOption', values.xeroTaxOption.uniqueId || "");
        //     formData.append("purchaseOfficeType", values.purchaseOfficeType);
        //     formData.append('legalEntity', values.legalEntity.uniqueId);
        //     formData.append('materialType', values.materialType);
        //
        //     if (values.vendorType === "existingVendor" && values.vendor)
        //         formData.append('vendor', values.vendor.uniqueId || "");
        //     else
        //         formData.append('vendorOther', values.vendorOther || "");
        //
        //     formData.append('purchaseLevel', values.purchaseLevel);
        //
        //     if (values.isWorkshop === true) {
        //         values.projectsDetails.forEach((projectsDetailsItem, projectsDetailsItemIdx) => {
        //             formData.append(`projectsDetails[${projectsDetailsItemIdx}].project`, projectsDetailsItem.project.uniqueId || "");
        //             formData.append(`projectsDetails[${projectsDetailsItemIdx}].projectCostPercentage`, projectsDetailsItem.projectCostPercentage || "");
        //             formData.append(`projectsDetails[${projectsDetailsItemIdx}].status`, projectsDetailsItem.status);
        //         });
        //         formData.append('workshopApprovalStatus', values.workshopApprovalStatus);
        //     }
        //     else {
        //         formData.append(`projectsDetails[0].project`, values.projectsDetails[0].project.uniqueId || "")
        //         formData.append(`projectsDetails[0].projectCostPercentage`, values.projectsDetails[0].projectCostPercentage || "")
        //         formData.append(`projectsDetails[0].status`, values.projectsDetails[0].status)
        //     }
        //
        //     if (values.isWorkshop === true)
        //         formData.append('workshop', values.workshop);
        //     else
        //         formData.append('workshop', "");
        // }

        await onSubmit(formData);
    }

    let submit;

    // const setDialogActions = async (event, selectedIndex, form) => {
    //     switch (selectedIndex) {
    //         case 0:
    //             isAdmin ?
    //                 form.change("purchaseOrderStatus", "Quote Reviewed") :
    //                 form.change("purchaseOrderStatus", "Quote Awaiting Review")
    //             break;
    //         case 1:
    //             form.change("purchaseOrderStatus", "Quote Review In Progress (Draft)")
    //             break;
    //         case 2:
    //             form.change("purchaseOrderStatus", "New Quote Requested")
    //             break;
    //         default:
    //             break;
    //     }
    //
    //     submit(event);
    // }

    return (
        <Form
            onSubmit={onSubmitForm}
            decorators={[focusOnError]}
            mutators={{...arrayMutators}}
            initialValues={initialValues}
            validate={validate}
            render={({handleSubmit, submitting, form, pristine, values, form: { mutators: { push, removeBatch }}, errors, touched}) => {
                submit = handleSubmit
                return (
                    <Dialog
                        aria-labelledby="add-project-purchase-request-dialog-title"
                        onClose={onClose}
                        fullWidth
                        maxWidth={"sm"}
                        open={open}
                    >
                        <CustomDialogTitle
                            id="add-project-purchase-request-dialog-title"
                            onClose={onClose}
                            showTextContent
                        >
                            Add Project Purchase
                        </CustomDialogTitle>
                        <FormDialogContent dividers>
                            <form onSubmit={submit} noValidate>
                                {whenFieldChanges(values)}
                                <Grid container alignItems="flex-start" spacing={4}>
                                    {formFields(values, errors, touched, push, removeBatch).map((item, idx) => (
                                        <React.Fragment key={idx}>
                                            {item.field}
                                        </React.Fragment>
                                    ))}
                                </Grid>
                            </form>
                        </FormDialogContent>
                        <DialogActions>
                            <ActionButton
                                onClick={onClose}
                                type="button"
                                disabled={submitting}
                                color="primary"
                            >
                                Cancel
                            </ActionButton>
                            <ActionButton
                                type="button"
                                onClick={form.reset}
                                color="primary"
                                disabled={submitting || pristine}
                            >
                                Reset
                            </ActionButton>
                            <ActionButton
                                onClick={event => {
                                    const promise = submit(event);
                                    promise && promise.then(async() => {
                                        await sleep(300);
                                        form.reset();
                                    })
                                    return promise;
                                }}
                                type="submit"
                                disabled={isAdmin ? submitting || loading.type === loadingTypes.xeroPopulateVendors || loading.type === loadingTypes.vendorsGetVendorsBySupplier : submitting || pristine}
                                color="default"
                            >
                                {isAdmin ? "Review and Amend" : "Submit"}
                            </ActionButton>
                            {/*<SplitButton*/}
                            {/*    handleClickParent={(event, selectedIndex) => setDialogActions(event, selectedIndex, form)}*/}
                            {/*    options={isAdmin ? ["Review and Amend", "Save as Draft", "Request New Quote"] : ["Submit"]}*/}
                            {/*    handleMenuItemClickParent={(event, selectedIndex) => setDialogActions(event, selectedIndex, form)}*/}
                            {/*    containerStyle={{ width: "unset" }}*/}
                            {/*    selectButtonStyle={{ border: "none", borderRight: "1px solid rgba(0, 0, 0, 0.23)" }}*/}
                            {/*    dropdownButtonStyle={{ border: "none" }}*/}
                            {/*    disabled={isAdmin ? submitting : submitting || pristine}*/}
                            {/*/>*/}
                            <LoadingDiv value={isAdmin}>
                                <Loader
                                    loading={loading.type === loadingTypes.purchasesUpdatePurchaseProjectRequest}
                                    size="small" />
                            </LoadingDiv>
                        </DialogActions>
                    </Dialog>
                )
            }}
        />
    )
}
export default NewRequestDialog;