import { createStore, combineReducers, applyMiddleware } from "redux";
import thunkMiddleware from "redux-thunk";

import authReducer from "./auth";
import boardroomsReducer from "./boardrooms";
import errorReducer from "./error";
import globalReducer from "./global";
import loadingReducer from "./loading";
import projectsReducer from "./projects";
import successReducer from "./success";
import userReducer from "./user";
import fileReducer from "./file";
import vehiclesReducer from "./vehicles";
import assetsReducer from "./assets";
import fleetsReducer from "./fleets";
import invoicesReducer from "./invoices";
import supportReducer from "./itSupport";
import purchasesReducer from "./purchases";
import tendersReducer from "./tenders";
import vendorsReducer from "./vendors";
import legalEntitiesReducer from "./legalEntities";
import permissionsReducer from "./permissions";
import auditReducer from "./audit";
import currenciesReducer from "./currencies";
import taxReducer from "./tax";
import purchaseOrdersReducer from "./purchaseOrders";
import xeroReducer from "./xero";
import profitCentreReducer from "./profitCentre";
import timeZoneReducer from "./timeZone"

const rootReducer = combineReducers({
	authReducer,
	boardroomsReducer,
	errorReducer,
	globalReducer,
	loadingReducer,
	projectsReducer,
	successReducer,
	fileReducer,
	userReducer,
	vehiclesReducer,
	assetsReducer,
	fleetsReducer,
	invoicesReducer,
	supportReducer,
	purchasesReducer,
	tendersReducer,
	vendorsReducer,
	legalEntitiesReducer,
	permissionsReducer,
	auditReducer,
	currenciesReducer,
	taxReducer,
	purchaseOrdersReducer,
	xeroReducer,
	profitCentreReducer,
	timeZoneReducer
});

export default createStore(rootReducer, applyMiddleware(thunkMiddleware));
