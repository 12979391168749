import React, {useEffect, useState} from "react";
import MaterialTable from "material-table";
import DetailsDialog from "./DetailsDialog";
import styled, {css} from "styled-components";
import {Clear, Done, Warning} from "@material-ui/icons";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import {connect} from "react-redux";
import * as projectActions from "../../store/projects/actions";
import {getPermissionsByRole} from "../../store/permissions/actions";
import FilterAutocomplete from "../../components/Inputs/Filter/FilterAutocomplete";
import Button from "@material-ui/core/Button";
import {formatShortDate, formatShortTableDate, getFilteredData} from "../../assets/helpers/globals";
import debounce from "lodash/debounce";
import isEqual from "lodash/isEqual";
import {useInterval} from "../../hooks/useInterval";
import * as loadingTypes from "../../store/loading/types";
import AuditLoggingTableDialog from "../Audit/TableDialog";
import ConfirmationDialog from "../../components/Dialogs/ConfirmationDialog/ConfirmationDialog";
import {getAuditLog, getTransactionSubTypes} from "../../store/audit/actions";
import NewProjectDialog from "./NewProjectDialog";
import {getAllCurrencies} from "../../store/currencies/actions";
import {getAllProfitCentres} from "../../store/profitCentre/actions";
import {getAllEOINumbers, getAllQuoteNumbers, getAllTenders} from "../../store/tenders/actions";
import {getAllLegalEntities} from "../../store/legalEntities/actions";
import CustomAction from "../../components/Table/CustomAction";
import {getUsers} from "../../store/user/actions";
import {CsvBuilder} from 'filefy';

const FilterPaper = styled(Paper)`
  margin-bottom: 18px;
  margin-top: 14px;
`;

const FilterGrid = styled(Grid)`
  ${props => props.container && css`
    margin-left: 10px; 
    width: 98%;
  `}
  ${props => props.item && css`
    align-self: center
  `}
`;

const P = styled.p`
  color: #388e3c;
  border: 1px solid #4caf50;
  border-radius: 16px;
  height: 24px;
  padding-left: 4px;
  padding-right: 8px;
  white-space: nowrap;
  display: flex;
  max-width: fit-content;
  ${props => props.status === "Deleted" && css`
    color: #d32f2f;
    border: 1px solid #f44336;
  `}
`;

const RegisteredIcon = styled(Done)`
  display: none;
  ${props => props.status === "Registered" && css`
    margin-top: -1px;
    margin-right: 2px;
    display: flex;
    color: #388e3c;
  `}
`;

const DeletedIcon = styled(Warning)`
  display: none;
  ${props => (props.status === "Deleted") && css`
    margin-top: -1px;
    margin-right: 2px;
    display: flex;
    color: #d32f2f;
  `}
`;

const ProjectRegister = ({
						// state
							projects,
							allQuoteNumbers,
							allEOINumbers,
							legalEntities,
						    tenders,
						    allProfitCentres,
						    allCurrencies,
							users,
							auditLog,
							transactionSubTypes,
							loading,
						    usersLoading,

						// actions
							getAllProjects,
							addProject,
							updateProject,
							deleteProject,
							undoDeleteProject,
							getNewProjectNumber,
							getAllQuoteNumbers,
							getAllEOINumbers,

							getAllLegalEntities,
							getAllTenders,
							getAllCurrencies,
							getAllProfitCentres,
							getUsers,

							getUserRoles,
							getAuditLog,
							getTransactionSubTypes,
					   }) => {
	const initialFilterState = {userFullName: null, entityName: null, clientName: null, currency: null, centres: null, status: null}
	const initialDialogState = {open: false, data: {}}
	const initialAuditState = {open: false, type: ""}

	const [addProjectDialogOpen, setAddProjectDialogOpen] = useState(false);
	const [editDialogOpen, setEditDialogOpen] = useState(false);
	const [deleteProjectConfirmationOpen, setDeleteProjectConfirmationOpen] = useState(false);
	const [undoDeleteProjectConfirmationOpen, setUndoDeleteProjectConfirmationOpen] = useState(false);
	const [projectDetails, setProjectDetails] = useState(initialDialogState);
	const [selectedProject, setSelectedProject] = useState(undefined);
	const [auditLogDetails, setAuditLogDetails] = useState(undefined);
	const [auditLogDialogOpen, setAuditLogDialogOpen] = useState(initialAuditState);
	const [adding, setAdding] = useState(undefined);
	const [editing, setEditing] = useState(undefined);
	const [filter, setFilter] = useState(initialFilterState);
	const [roles, setRoles] = useState({
		is_project_register_viewer: false,
		is_project_register_creator: false,
		is_project_register_modifier: false,
	})

	const actions = [
		{
			icon: "visibility",
			tooltip: "View Project",
			onClick: async (_event, project) => {
				setProjectDetails({open: true, data: project});
			},
		},
		{
			icon: "edit",
			tooltip: "Edit Project",
			onClick: async (_event, project) => {
				let currencyData
				let awardDateArray
				let newAwardDate

				// if (!projects || !projects.length)
					await getAllProjects("");
				// if (!allQuoteNumbers || !allQuoteNumbers.length)
					await getAllQuoteNumbers("Registered");
				// if (!allEOINumbers || !allEOINumbers.length)
					await getAllEOINumbers("Registered");
				if (!allCurrencies || !allCurrencies.length)
					currencyData = await getAllCurrencies();
				else
					currencyData = allCurrencies.slice()
				if (!legalEntities || !legalEntities.length)
					await getAllLegalEntities();
				if (!tenders || !tenders.length)
					await getAllTenders();
				if (!allProfitCentres || !allProfitCentres.length)
					await getAllProfitCentres();
				if (!users || !users.length)
					await getUsers();

				if (project.awardDate.length <= 8) {
					awardDateArray = formatShortTableDate(project.awardDate).split("/");
					newAwardDate = new Date(awardDateArray[2], awardDateArray[1]-1, awardDateArray[0])
				}

				setEditing({
					...project,
					awardDate: newAwardDate ? newAwardDate : project.awardDate,
					currency: currencyData.find(currency => currency.code === project.currency),
					legalEntityType: project.legalEntity ? "existingLegalEntity" : "otherLegalEntity",
					reference: project.tenders ? "tenderNumber" : project.quotations ? "quotationNumber" : project.eoi ? "eoiNumber" : "tenderNumber"
				})
				setEditDialogOpen(true);
			},
			hidden: !(roles.is_project_register_creator || roles.is_project_register_modifier)
		},
		rowData =>
			({
			icon: "delete",
			tooltip: "Remove Project",
			onClick: async (_event, project) => {
				setSelectedProject(project);
				setDeleteProjectConfirmationOpen(true)
			},
			hidden: !(roles.is_project_register_modifier && !rowData.isDeleted)
		}),
		rowData =>
			({
			icon: "replay",
			tooltip: "Restore Project",
			onClick: async (_event, project) => {
				setSelectedProject(project);
				setUndoDeleteProjectConfirmationOpen(true)
			},
			hidden: !(roles.is_project_register_modifier && rowData.isDeleted)
		}),
		{
			icon: "history",
			tooltip: "View Project History",
			onClick: async (_event, project) => {
				if (!isEqual(project, auditLogDetails)) {
					let typeId

					if (!transactionSubTypes || !transactionSubTypes.length) {
						let res = await getTransactionSubTypes();
						typeId = res.data.find(transactionSubType => transactionSubType.subTypeName === "N/A" && transactionSubType.transactionType?.typeName === "Project").uniqueId;
					} else
						typeId = transactionSubTypes.find(transactionSubType => transactionSubType.subTypeName === "N/A" && transactionSubType.transactionType?.typeName === "Project").uniqueId;

					await getAuditLog(project.uniqueId, typeId);
					setAuditLogDetails(project)
					setAuditLogDialogOpen({open: true, type: "Project"})
				}
				else
					setAuditLogDialogOpen({open: true, type: "Project"})
			}
		},
		{
			icon: 'refresh',
			tooltip: 'Refresh Data',
			isFreeAction: true,
			onClick: debounce(() => getAllProjects(""), 300, {
				'leading': false,
				'trailing': true
			}),
			disabled: !(roles.is_project_register_viewer || roles.is_project_register_creator || roles.is_project_register_modifier)
		},
		{
			icon: 'add',
			isFreeAction: true,
			custom: true,
			component: Button,
			onClick: () => {},
			props: {
				disabled:  !roles.is_project_register_creator,
				variant: "contained",
				color: "primary",
				children: "Add project",
				onClick: async () => {
					let projectNumber = await getNewProjectNumber();

					// if (!projects || !projects.length)
						getAllProjects("");
					// if (!allQuoteNumbers || !allQuoteNumbers.length)
						getAllQuoteNumbers("Registered");
					// if (!allEOINumbers || !allEOINumbers.length)
						getAllEOINumbers("Registered");
					if (!legalEntities || !legalEntities.length)
						getAllLegalEntities();
					if (!tenders || !tenders.length)
						getAllTenders();
					if (!allCurrencies || !allCurrencies.length)
						getAllCurrencies();
					if (!allProfitCentres || !allProfitCentres.length)
						getAllProfitCentres();
					if (!users || !users.length)
						getUsers();

					setAdding({
						projectNumber: projectNumber.data,
						legalEntityType: "existingLegalEntity",
						reference: "tenderNumber",
						currency: '',
						legalEntity: '',
						projectManager: '',
						profitCentres: '',
						tenders: '',
						quotations: '',
						eoi: ''
					})
					setAddProjectDialogOpen(true)
				},
			},
		}
	];

	const filterData = (data, query) => {
		return data.filter((item) => {
			for (let key in query) {
				switch (key) {
					case "entityName":
						if (item.legalEntity === null || item.legalEntity[key] === undefined || !query[key].includes(item.legalEntity[key])) {
							return false;
						}
						break;
					case "userFullName":
						if (item.projectManager === null || item.projectManager[key] === undefined || !query[key].includes(item.projectManager[key])) {
							return false;
						}
						break;
					case "centres":
						if (item.profitCentres === null || item.profitCentres[key] === undefined || !query[key].includes(item.profitCentres[key])) {
							return false;
						}
						break;
					default:
						if (item[key] === undefined || !query[key].includes(item[key])) {
							return false;
						}
						break;
				}
			}
			return true;
		});
	};

	useInterval(() => {
		if (roles.is_project_register_viewer || roles.is_project_register_creator || roles.is_project_register_modifier)
			getAllProjects("");
	}, 300000);

	useEffect(() => {
		(async function() {
			const res = await getUserRoles("project","register");
			setRoles({
				is_project_register_creator: res.some(name => name === "project_register_creator"),
				is_project_register_viewer: res.some(name => name === "project_register_viewer"),
				is_project_register_modifier: res.some(name => name === "project_register_modifier"),
			});
		})();

		if (roles.is_project_register_viewer || roles.is_project_register_creator || roles.is_project_register_modifier)
			getAllProjects("");
	}, [getUserRoles, getAllProjects, roles.is_project_register_viewer, roles.is_project_register_creator, roles.is_project_register_modifier])

	return (
		<>
			<FilterPaper>
				<FilterGrid container spacing={2}>
					<FilterGrid item xs={12} sm={6} md={4} lg={3}>
						<FilterAutocomplete
							onChange={(event, newValue) => setFilter({...filter, userFullName: newValue})}
							value={filter.userFullName}
							options={projects.map(project => project.projectManager?.userFullName)}
							placeholder="Project Manager"
							noOptionsText="No Project Managers"
						/>
					</FilterGrid>
					<FilterGrid item xs={12} sm={6} md={4} lg={3}>
						<FilterAutocomplete
							onChange={(event, newValue) => setFilter({...filter, entityName: newValue})}
							value={filter.entityName}
							options={projects.map(project => project.legalEntity?.entityName)}
							placeholder="Legal Entity"
							noOptionsText="No Legal Entities"
						/>
					</FilterGrid>
					<FilterGrid item xs={12} sm={6} md={4} lg={3}>
						<FilterAutocomplete
							onChange={(event, newValue) => setFilter({...filter, clientName: newValue})}
							value={filter.clientName}
							options={projects.map(project => project.clientName)}
							placeholder="Client Name"
							noOptionsText="No Client Names"
						/>
					</FilterGrid>
					<FilterGrid item xs={12} sm={6} md={4} lg={3}>
						<FilterAutocomplete
							onChange={(event, newValue) => setFilter({...filter, centres: newValue})}
							value={filter.centres}
							options={projects.map(project => project.profitCentres?.centres)}
							placeholder="Profit Centres"
							noOptionsText="No Profit Centres"
						/>
					</FilterGrid>
					<FilterGrid item xs={12} sm={6} md={4} lg={3}>
						<FilterAutocomplete
							onChange={(event, newValue) => setFilter({...filter, currency: newValue})}
							value={filter.currency}
							options={projects.map(project => project.currency)}
							placeholder="Currency"
							noOptionsText="No Currencies"
						/>
					</FilterGrid>
					<FilterGrid item xs={12} sm={6} md={4} lg={3}>
						<FilterAutocomplete
							onChange={(event, newValue) => setFilter({...filter, status: newValue})}
							value={filter.status}
							options={projects.map(project => project.status)}
							placeholder="Status"
							noOptionsText="No Statuses"
						/>
					</FilterGrid>
					<FilterGrid item xs={12} sm={6} md={4} lg={3}>
						<Button
							color="secondary"
							size="small"
							startIcon={<Clear/>}
							disabled={isEqual(filter, initialFilterState)}
							onClick={() => setFilter(initialFilterState)}>
							Clear All
						</Button>
					</FilterGrid>
				</FilterGrid>
			</FilterPaper>

			<MaterialTable
				columns={[
					{
						title: "Unique ID",
						field: "uniqueId",
						hidden: true
					},
					{
						title: "Created Date",
						field: "createdDate",
						type: "date",
					},
					{
						title: "Project Number",
						field: "projectNumber",
						defaultSort: "desc"
					},
					{
						title: "Project Name",
						field: "projectName"
					},
					{
						title: "Project Manager",
						field: "projectManager.userFullName"
					},
					{
						title: "Legal Entity",
						field: "legalEntity.entityName",
						render: rowData => rowData.legalEntity ? <span>{rowData.legalEntity.entityName}</span> :
							<span>{rowData.otherLegalEntity}</span>,
					},
					{
						title: "Client Name",
						field: "clientName"
					},
					{
						title: "Profit Centre",
						field: "profitCentres.centres"
					},
					{
						title: "Reference",
						field: "tenders.tenderNumber",
						render: rowData => <span>{rowData.tenders ? rowData.tenders?.tenderNumber : rowData.quotations ? rowData.quotations?.quotationNumber : rowData.eoi?.eoiNumber}</span>,
					},
					{
						title: "Award Date",
						field: "awardDate",
						// type: "date",
						// render: rowData => <span>{rowData.awardDate ? formatShortTableDate(rowData.awardDate) : ""}</span>,
						customSort: (a, b) => {
							let d1 = new Date(a.awardDate);
							let d2 = new Date(b.awardDate);

							return d1.getTime() - d2.getTime();
						}
					},
					{
						title: "Location",
						field: "location",
					},
					{
						title: "Project Duration",
						field: "projectDuration",
					},
					{
						title: "Project Value",
						field: "projectValue",
					},
					{
						title: "Currency",
						field: "currency",
					},
					{
						title: "Comments",
						field: "comments",
						hidden: true
					},
					{
						title: "Deleted Date",
						field: "deletedAt",
						type: "date",
						hidden: true
					},
					{
						title: "Modified Date",
						field: "lastModifiedDate",
						type: "date",
						hidden: true
					},
					{
						title: "Status",
						field: "status",
						hidden: !roles.is_project_register_modifier,
						render: data => (
							<P status={data.status}>
								<RegisteredIcon status={data.status}/>
								<DeletedIcon status={data.status}/>
								{data.status}</P>
						),
					}
				]}
				components={{ Action: CustomAction }}
				actions={actions}
				data={isEqual(filter, initialFilterState) ? projects : getFilteredData(filter, projects, [], filterData)}
				isLoading={addProjectDialogOpen === false &&
					editDialogOpen === false && (
						loading.type === loadingTypes.projectsGetAllProjects ||
						loading.type === loadingTypes.projectsGetNewProjectNumber ||
						loading.type === loadingTypes.tendersGetAllTenders ||
						loading.type === loadingTypes.legalEntitiesGetAllLegalEntities ||
						loading.type === loadingTypes.currenciesGetAllCurrencies ||
						loading.type === loadingTypes.profitCentresGetAllProfitCentres ||
						usersLoading ||
						loading.type === loadingTypes.auditGetTransactionSubTypes ||
						loading.type === loadingTypes.tendersGetAllEOINumbers ||
						loading.type === loadingTypes.tendersGetAllQuoteNumbers ||
						loading.type === loadingTypes.auditGetAuditLog ||
						loading.type === loadingTypes.permissionsGetPermissionsByRole)
				}
				options={{
					actionsColumnIndex: -1,
					pageSize: 10,
					searchFieldAlignment: "left",
					showTitle: false,
					sorting: true,
					isLoading: true,
					search: true,
					filtering: false,
					exportButton: {
						csv: true,
						pdf: false
					},
					exportCsv: (columns, data) => {
						const columnTitles = columns.map(columnDef => columnDef.title);

						const csvData = data.map(rowData =>
							columns.map(columnDef => {
								switch (columnDef.field) {
									case "legalEntity.entityName":
										return rowData.legalEntity ? rowData.legalEntity.entityName : rowData.otherLegalEntity;
									case "projectManager.userFullName":
										return rowData.projectManager?.userFullName;
									case "profitCentres.centres":
										return rowData.profitCentres?.centres;
									case "tenders.tenderNumber":
										return rowData.tenders ? rowData.tenders?.tenderNumber : rowData.quotations ? rowData.quotations?.quotationNumber : rowData.eoi?.eoiNumber;
									default:
										break;
								}
								if (columnDef.type === "date" && rowData[columnDef.field]) {
									return formatShortTableDate(rowData[columnDef.field])
								}
								return rowData[columnDef.field]}),
						);

						return new CsvBuilder(`Project Register Export - ${formatShortDate(new Date())}`)
							.setColumns(columnTitles)
							.addRows(csvData)
							.exportFile();
					},
				}}
			/>

			<DetailsDialog
				key={"Project-Register-Details-" + projectDetails.data.uniqueId}
				details={projectDetails.data}
				onClose={() => setProjectDetails(initialDialogState)}
				open={projectDetails.open}
			/>
			<NewProjectDialog
				open={addProjectDialogOpen}
				onClose={() => setAddProjectDialogOpen(false)}
				onSubmit={async (projectManagerId, newProject) => {
					await addProject(projectManagerId, newProject);
					getAllProjects("");
					setAddProjectDialogOpen(false)
				}}
				legalEntities={legalEntities}
				tenders={tenders}
				allProfitCentres={allProfitCentres}
				allCurrencies={allCurrencies}
				allQuoteNumbers={allQuoteNumbers}
				allEOINumbers={allEOINumbers}
				users={users}
				initialValues={adding}
				loading={loading}
			/>
			<NewProjectDialog
				key={"Project-Register-Edit-" + editing?.uniqueId}
				open={editDialogOpen}
				onClose={() => setEditDialogOpen(false)}
				onSubmit={async (projectManagerId, updatedProject) => {
					await updateProject(projectManagerId, updatedProject);
					getAllProjects("");
					setEditDialogOpen(false);
				}}
				legalEntities={legalEntities}
				tenders={tenders}
				allProfitCentres={allProfitCentres}
				allCurrencies={allCurrencies}
				allQuoteNumbers={allQuoteNumbers}
				allEOINumbers={allEOINumbers}
				users={users}
				initialValues={editing}
				loading={loading}
			/>
			<AuditLoggingTableDialog
				open={auditLogDialogOpen.open}
				auditPayload={auditLog}
				type={auditLogDialogOpen.type}
				onClose={() => setAuditLogDialogOpen(initialAuditState)}
				loading={loading.type === loadingTypes.auditGetAuditLog}
			/>
			<ConfirmationDialog
				open={deleteProjectConfirmationOpen}
				handleClose={() => setDeleteProjectConfirmationOpen(false)}
				handleConfirmation={async confirm => {
					if (confirm) {
						await deleteProject(selectedProject.uniqueId);
						getAllProjects("");
					}
					setDeleteProjectConfirmationOpen(false);
				}}
				headerText={"Confirm project removal"}
				promptText={"Are you sure you want to remove the project ?"}
				loading={loading.type === loadingTypes.projectsDeleteProject}
			/>
			<ConfirmationDialog
				open={undoDeleteProjectConfirmationOpen}
				handleClose={() => setUndoDeleteProjectConfirmationOpen(false)}
				handleConfirmation={async confirm => {
					if (confirm) {
						await undoDeleteProject(selectedProject.uniqueId);
						getAllProjects("");
					}
					setUndoDeleteProjectConfirmationOpen(false);
				}}
				headerText={"Confirm project restoration"}
				promptText={"Are you sure you want to restore the project ?"}
				loading={loading.type === loadingTypes.projectsUndoDeleteProject}
			/>
		</>
	);
}

const mapStateToProps = state => ({
	projects: state.projectsReducer.projects,
	legalEntities: state.legalEntitiesReducer.legalEntities,
	tenders: state.tendersReducer.tenders,
	allProfitCentres: state.profitCentreReducer.allProfitCentres,
	allCurrencies: state.currenciesReducer.allCurrencies,
	users: state.userReducer.users,
	allQuoteNumbers: state.tendersReducer.allQuoteNumbers,
	allEOINumbers: state.tendersReducer.allEOINumbers,

	usersLoading: state.userReducer.usersLoading,
	loading: state.loadingReducer.loading,
	auditLog: state.auditReducer.auditLog,
	transactionSubTypes: state.auditReducer.transactionSubTypes,
});

const mapDispatchToProps = dispatch => ({
	getAllProjects: (type) => dispatch(projectActions.getAllProjects(type)),
	addProject: (id, project) => dispatch(projectActions.addProject(id, project)),
	updateProject: (id, project) => dispatch(projectActions.updateProject(id, project)),
	deleteProject: id => dispatch(projectActions.deleteProject(id)),
	undoDeleteProject: id => dispatch(projectActions.undoDeleteProject(id)),
	getNewProjectNumber: () => dispatch(projectActions.getNewProjectNumber()),
	getAllQuoteNumbers: (type) => dispatch(getAllQuoteNumbers(type)),
	getAllEOINumbers: (type) => dispatch(getAllEOINumbers(type)),

	getAllLegalEntities: () => dispatch(getAllLegalEntities()),
	getAllTenders: () => dispatch(getAllTenders()),
	getAllCurrencies: () => dispatch(getAllCurrencies()),
	getAllProfitCentres: () => dispatch(getAllProfitCentres()),
	getUsers : () => dispatch(getUsers()),

	getUserRoles: (roleType, roleSubType) => dispatch(getPermissionsByRole(roleType, roleSubType)),
	getAuditLog: (id, type) => dispatch(getAuditLog(id, type)),
	getTransactionSubTypes: () => dispatch(getTransactionSubTypes()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProjectRegister);