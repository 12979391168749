import * as actionTypes from "./actions";

const initialState = {
	users: [],
	userDetails: {},
	currentUser: {},
	userDetailsError: null,
	userDetailsLoading: false,
	usersError: null,
	usersLoading: false,
};

const getUsersStart = state => ({ ...state, usersLoading: true });
const getUsersSuccess = (state, action) => ({
	...state,
	users: action.data,
	usersLoading: false,
});
const getUsersFail = (state, action) => ({
	...state,
	usersError: action.error,
	usersLoading: false,
});
const getUserDetailsStart = state => ({ ...state, userDetailsLoading: true });
const getUserDetailsSuccess = (state, action) => ({
	...state,
	userDetails: action.data,
	userDetailsLoading: false,
});
const getUserDetailsFail = (state, action) => ({
	...state,
	userDetailsError: action.error,
	userDetailsLoading: false,
});
const getCurrentUser = (state, action) => ({
	...state,
	currentUser: action.payload,
});

export default (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.GET_USERS_START:
			return getUsersStart(state);
		case actionTypes.GET_USERS_SUCCESS:
			return getUsersSuccess(state, action);
		case actionTypes.GET_USERS_FAIL:
			return getUsersFail(state, action);
		case actionTypes.GET_USER_DETAILS_START:
			return getUserDetailsStart(state);
		case actionTypes.GET_USER_DETAILS_SUCCESS:
			return getUserDetailsSuccess(state, action);
		case actionTypes.GET_USER_DETAILS_FAIL:
			return getUserDetailsFail(state, action);
		case actionTypes.GET_CURRENT_USER:
			return getCurrentUser(state, action);
		default:
			return state;
	}
};
