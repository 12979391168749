export const vehicleCreateBooking = "vehicle/create/booking";
export const vehicleCreateInspection = "vehicle/create/inspection";
export const vehicleDeleteBooking = "vehicle/delete/booking";
export const vehicleGetBookings = "vehicle/get/booking";
export const vehicleGetResources = "vehicle/get/resources";
export const vehicleRespondToBooking = "vehicle/respond/booking";
export const vehicleUpdateBooking = "vehicle/update/booking";

export const invoicesGetInvoices = "invoices/get/invoices";
export const invoicesAddInvoiceRequest = "invoices/add/invoiceRequest";
export const invoicesUpdateInvoiceRequest = "invoices/update/invoiceRequest";
export const invoicesUpdateInvoiceRequestStatus = "invoices/update/invoiceRequestStatus";
export const invoicesAddInvoiceResponse = "invoices/add/invoiceResponse";
export const invoicesUpdateInvoiceResponse = "invoices/update/invoiceResponse";
export const invoicesUpdateInvoiceResponseStatus = "invoices/update/invoiceResponseStatus";
export const invoicesDeleteInvoiceRequest = "invoices/delete/invoiceRequest";

export const supportGetSupportRequests = "support/get/supportRequests";
export const supportAddSupportRequest = "support/add/supportRequest";
export const supportUpdateSupportRequest = "support/update/supportRequest";
export const supportUpdateSupportRequestStatus = "support/update/supportRequestStatus";
export const supportDeleteSupportRequest = "support/delete/supportRequest";

export const purchasesGetAllPurchases = "purchases/get/allPurchases";

export const purchasesGetPurchaseProjects = "purchases/get/purchaseProjects";
export const purchasesAddPurchaseProjectRequest = "purchases/add/purchaseProjectRequest";
export const purchasesUpdatePurchaseProjectRequest = "purchases/update/purchaseProjectRequest";
export const purchasesDeletePurchaseProjectRequest = "purchases/delete/purchaseProjectRequest";
export const purchasesUpdatePurchaseProjectRequestStatus = "purchases/update/purchaseProjectRequestStatus";

export const purchasesGetPurchaseTenders = "purchases/get/purchaseTenders";
export const purchasesAddPurchaseTenderRequest = "purchases/add/purchaseTenderRequest";
export const purchasesUpdatePurchaseTenderRequest = "purchases/update/purchaseTenderRequest";
export const purchasesDeletePurchaseTenderRequest = "purchases/delete/purchaseTenderRequest";
export const purchasesUpdatePurchaseTenderRequestStatus = "purchases/update/purchaseTenderRequestStatus";

export const purchasesGetPurchaseItEquipment = "purchases/get/purchaseItEquipment";
export const purchasesAddPurchaseItEquipmentRequest = "purchases/add/purchaseItEquipmentRequest";
export const purchasesUpdatePurchaseItEquipmentRequest = "purchases/update/purchaseItEquipmentRequest";
export const purchasesDeletePurchaseItEquipmentRequest = "purchases/delete/purchaseItEquipmentRequest";
export const purchasesUpdatePurchaseItEquipmentRequestStatus = "purchases/update/purchaseItEquipmentRequestStatus";

export const purchasesGetPurchaseOfficeEquipment = "purchases/get/purchaseOfficeEquipment";
export const purchasesAddPurchaseOfficeEquipmentRequest = "purchases/add/purchaseOfficeEquipmentRequest";
export const purchasesUpdatePurchaseOfficeEquipmentRequest = "purchases/update/purchaseOfficeEquipmentRequest";
export const purchasesDeletePurchaseOfficeEquipmentRequest = "purchases/delete/purchaseOfficeEquipmentRequest";
export const purchasesUpdatePurchaseOfficeEquipmentRequestStatus = "purchases/update/purchaseOfficeEquipmentRequestStatus";

export const purchasesGetPurchaseOthers = "purchases/get/purchaseOthers";
export const purchasesAddPurchaseOtherRequest = "purchases/add/purchaseOtherRequest";
export const purchasesUpdatePurchaseOtherRequest = "purchases/update/purchaseOtherRequest";
export const purchasesDeletePurchaseOtherRequest = "purchases/delete/purchaseOtherRequest";
export const purchasesUpdatePurchaseOtherRequestStatus = "purchases/update/purchaseOtherRequestStatus";

export const purchaseOrdersGetApprovedPurchases = "purchaseOrders/get/approvedPurchases";
export const purchaseOrdersGetAllPurchaseOrders = "purchaseOrders/get/allPurchaseOrders";
export const purchaseOrdersCreatePurchaseOrder = "purchaseOrders/create/purchaseOrder";
export const purchaseOrdersUpdatePurchaseRequestStatus = "purchaseOrders/update/purchaseRequestStatus";
export const purchaseOrdersDeletePurchaseOrder = "purchaseOrders/delete/purchaseOrder";

export const purchaseOrdersUpdatePurchaseOrder = "purchaseOrders/update/purchaseOrder";
export const purchaseOrdersSubmitToXero = "purchaseOrders/submit/xero";
export const xeroGetTaxTypesByLegalEntity = "xero/get/taxTypesByLegalEntity";
export const xeroGetAllTaxTypes = "xero/get/allTaxTypes";
export const xeroGetAllAccounts = "xero/get/allAccounts";
export const xeroGetAllLatestAccounts = "xero/get/allLatestAccounts";
export const xeroGetAccountsByLegalEntity = "xero/get/accountsByLegalEntity";
export const xeroGetAllTracking = "xero/get/allTracking";
export const xeroGetTrackingByLegalEntity = "xero/get/trackingByLegalEntity";
export const xeroPopulateAll = "xero/get/populateAll";
export const xeroPopulateCurrencyAddressTaxTypes = "xero/get/populateCurrencyAddressTaxTypes";
export const xeroPopulateTrackingOptions = "xero/get/populateTrackingOptions";
export const xeroPopulateVendors = "xero/get/populateVendors";

export const projectsGetAllProjects = "projects/get/allProjects";
export const projectsGetAllProjectsStatuses = "projects/get/allProjectsStatuses";
export const projectsGetAllProjectsStatusesById = "projects/get/allProjectsStatusesById";
export const projectsAddProject = "projects/add/project";
export const projectsUpdateProject = "projects/update/project";
export const projectsDeleteProject = "projects/delete/project";
export const projectsUndoDeleteProject = "projects/undo/deleteProject";
export const projectsGetNewProjectNumber = "projects/get/newProjectNumber";

export const tendersGetAllTenders = "tenders/get/allTenders";
export const tendersAddTender = "tenders/add/tender";
export const tendersUpdateTender = "tenders/update/tender";
export const tendersDeleteTender = "tenders/delete/tender";
export const tendersUndoDeleteTender = "tenders/undo/deleteTender";
export const tendersGetNewTenderNumber = "tenders/get/newTenderNumber";

export const tendersGetAllQuoteNumbers = "tenders/get/allQuoteNumbers";
export const tendersAddQuote = "tenders/add/quote";
export const tendersUpdateQuote = "tenders/update/quote";
export const tendersDeleteQuote = "tenders/delete/quote";
export const tendersUndoDeleteQuote = "tenders/undo/deleteQuote";
export const tendersGetNewQuoteNumber = "tenders/get/newQuoteNumber";

export const tendersGetAllEOINumbers = "tenders/get/allEOINumbers";
export const tendersAddEOI = "tenders/add/EOI";
export const tendersUpdateEOI = "tenders/update/EOI";
export const tendersDeleteEOI = "tenders/delete/EOI";
export const tendersUndoDeleteEOI = "tenders/undo/deleteEOI";
export const tendersGetNewEOINumber = "tenders/get/newEOINumber";

export const timeZonesGetTimeZones = "timeZones/get/timeZones"

export const legalEntitiesGetAllLegalEntities = "legalEntities/get/allLegalEntities";
export const legalEntitiesGetAllDeliveryAddresses = "legalEntities/get/allDeliveryAddresses";
export const legalEntitiesGetDeliveryAddressPerLegalEntity = "legalEntities/get/deliveryAddressPerLegalEntity";

export const vendorsGetVendorsBySupplier = "vendors/get/vendorsBySupplier";
export const vendorsGetVendorsByCustomer = "vendors/get/vendorsByCustomer";

export const fileDownload = "file/download/file";

export const permissionsGetPermissionsByGroup = "permissions/get/permissionsByGroup";
export const permissionsGetPermissionsByFeature = "permissions/get/permissionsByFeature";
export const permissionsGetPermissionsByRole = "permissions/get/permissionsByRole";
export const permissionsGetNextApprovers = "permissions/get/nextApprovers";

export const auditGetAllAuditLogs = "audit/get/allAuditLogs";
export const auditGetAuditLog = "audit/get/auditLog";
export const auditGetTransactionTypes = "audit/get/transactionTypes";
export const auditGetTransactionSubTypes = "audit/get/transactionSubTypes";

export const currenciesGetCurrenciesByLegalEntity = "currencies/get/currenciesByLegalEntity";
export const currenciesGetAllCurrencies = "currencies/get/allCurrencies";

export const taxGetTaxOptions = "tax/get/taxOptions";

export const profitCentresGetAllProfitCentres = "profitCentres/get/allProfitCentres";