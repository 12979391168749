import React from "react";
import Grid from "@material-ui/core/Grid";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import {Autocomplete, TextField, Radios, KeyboardDatePicker} from "mui-rff";

import styled from "styled-components";
import {Form} from "react-final-form";

import CustomDialogTitle from "../../components/Dialogs/CustomDialogTitle/CustomDialogTitle";
import Loader from "../../components/Loader/Loader";
import * as loadingTypes from "../../store/loading/types";
import {Condition, focusOnError, NumberFormatOnInput} from "../../assets/helpers/finalFormHelpers";
import {sleep} from "../../assets/helpers/globals";
import DateFnsUtils from "@date-io/date-fns";

const LoadingDiv = styled.div`
  position: absolute;
  bottom: 11px;
  right: 36px;
`;

const FormDialogContent = styled(DialogContent)`
  padding: 24px 30px;
  @media only screen and (max-width: 425px) {
    padding: 12px !important;
  }
`;

const ActionButton = styled(Button)`
  margin: 0 5px;
`;

function NewProjectDialog  ({
                                open,
                                onClose,
                                onSubmit,
                                loading,
                                initialValues,
                                tenders,
                                allProfitCentres,
                                allCurrencies,
                                allQuoteNumbers,
                                allEOINumbers,
                                legalEntities,
                                users
}) {
    const formFields = [
        {
            field: (
                <Grid item xs={12}>
                    <TextField
                        label="New Project Number"
                        name="projectNumber"
                        margin="none"
                        disabled
                    />
                </Grid>
            ),
        },
        {
            field: (
                <Grid item xs={12}>
                    <Radios
                        label="Legal Entity"
                        name="legalEntityType"
                        margin="none"
                        required
                        radioGroupProps={{row: true}}
                        data={[
                            {label: 'Existing Legal Entity', value: 'existingLegalEntity'},
                            {label: 'Other Legal Entity', value: 'otherLegalEntity'},
                        ]}
                    />
                </Grid>
            ),
        },
        {
            field: (
                <Condition when="legalEntityType" is="existingLegalEntity">
                    <Grid item xs={12}>
                        <Autocomplete
                            label="Existing Legal Entity"
                            name="legalEntity"
                            noOptionsText={"No legal entities found"}
                            required
                            options={["", ...legalEntities.sort((a, b) => a.entityName.localeCompare(b.entityName))]}
                            getOptionLabel={option => option ? `${option.entityName}` : ""}
                            getOptionSelected={(option, value) => option.uniqueId === value.uniqueId}
                            filterOptions={(options, params) => options.filter((option) => option !== "" && option.entityName.toLowerCase().includes(params.inputValue.toLowerCase()))}
                            handleHomeEndKeys
                        />
                    </Grid>
                </Condition>
            ),
        },
        {
            field: (
                <Condition when="legalEntityType" is="otherLegalEntity">
                    <Grid item xs={12}>
                        <TextField
                            label="Other Legal Entity Name"
                            name="otherLegalEntity"
                            margin="none"
                            required
                        />
                    </Grid>
                </Condition>
            ),
        },
        {
            field: (
                <Grid item xs={12}>
                    <TextField
                        label="Project Name"
                        name="projectName"
                        margin="none"
                        required
                        multiline
                        rowsMax={10}
                    />
                </Grid>
            ),
        },
        {
            field: (
                <Grid item xs={12}>
                    <Autocomplete
                        label="Project Manager"
                        name="projectManager"
                        noOptionsText={"No project managers found"}
                        required
                        options={["", ...users.sort((a, b) => a.userFullName.localeCompare(b.userFullName))]}
                        getOptionLabel={option => option ? `${option.userFullName}` : ""}
                        getOptionSelected={(option, value) => option.id === value.id}
                        filterOptions={(options, params) => options.filter((option) => option !== "" &&
                            option.userFullName.toLowerCase().includes(params.inputValue.toLowerCase()))}
                        handleHomeEndKeys
                    />
                </Grid>
            ),
        },
        {
            field: (
                <Grid item xs={12}>
                    <Autocomplete
                        label="Profit Centre"
                        name="profitCentres"
                        noOptionsText={"No profit centres found"}
                        required
                        options={["", ...allProfitCentres.sort((a, b) => a.centres.localeCompare(b.centres))]}
                        getOptionLabel={option => option ? `${option.centres}` : ""}
                        getOptionSelected={(option, value) => option.uniqueId === value.uniqueId}
                        filterOptions={(options, params) => options.filter((option) => option !== "" &&
                            option.centres.toLowerCase().includes(params.inputValue.toLowerCase()))}
                        handleHomeEndKeys
                    />
                </Grid>
            ),
        },
        {
            field: (
                <Grid item xs={12}>
                    <TextField
                        label="Client Name"
                        name="clientName"
                        margin="none"
                        required
                    />
                </Grid>
            ),
        },
        {
            field: (
                <Grid item xs={12}>
                    <TextField
                        label="Location"
                        name="location"
                        margin="none"
                        multiline
                    />
                </Grid>
            ),
        },
        {
            field: (
                <Grid item xs={6}>
                    <KeyboardDatePicker
                        dateFunsUtils={DateFnsUtils}
                        label="Date Awarded"
                        name="awardDate"
                        margin="none"
                        disableFuture
                        required
                        placeholder={"dd-mm-yyyy"}
                        format="dd-MM-yyyy"
                        showTodayButton
                    />
                </Grid>
            ),
        },
        {
            field: (
                <Grid item xs={12}>
                    <TextField
                        label="Project Duration"
                        name="projectDuration"
                        margin="none"
                        multiline
                        rowsMax={10}
                    />
                </Grid>
            ),
        },
        // {
        //     field: (
        //         <Grid item xs={6}>
        //             <Autocomplete
        //                 label="Time Period"
        //                 name="timePeriod"
        //                 noOptionsText={"No time period found"}
        //                 options={["", "Day/s", "Week/s", "Month/s", "Year/s"]}
        //                 filterOptions={(options, params) => options.filter((option) => option !== "" &&
        //                     option.toLowerCase().includes(params.inputValue.toLowerCase()))}
        //                 getOptionLabel={option => option ? option : ""}
        //                 getOptionSelected={(option, value) => option === value}
        //                 handleHomeEndKeys
        //             />
        //         </Grid>
        //     ),
        // },
        {
            field: (
                <Grid item xs={12}>
                    <Radios
                        label="Reference"
                        name="reference"
                        margin="none"
                        radioGroupProps={{row: true}}
                        data={[
                            {label: 'Tender Number', value: 'tenderNumber'},
                            {label: 'Quotation Number', value: 'quotationNumber'},
                            {label: 'EOI Number', value: 'eoiNumber'},
                        ]}
                    />
                </Grid>
            ),
        },
        {
            field: (
                <Condition when="reference" is="tenderNumber">
                    <Grid item xs={12}>
                        <Autocomplete
                            label="Tender Number"
                            name="tenders"
                            noOptionsText={"No tenders found"}
                            options={["", ...tenders.sort((a, b) => b.tenderNumber.localeCompare(a.tenderNumber))]}
                            getOptionLabel={option => option ? `${option.tenderNumber}: ${option.tenderDescription}` : ""}
                            getOptionSelected={(option, value) => option.uniqueId === value.uniqueId}
                            filterOptions={(options, params) => options.filter((option) => option !== "" &&
                                (option.tenderNumber.toLowerCase().includes(params.inputValue.toLowerCase()) ||
                                    option.tenderDescription.toLowerCase().includes(params.inputValue.toLowerCase()) ||
                                    (option.tenderNumber + ": " + option.tenderDescription).toLowerCase().includes(params.inputValue.toLowerCase())))}
                            handleHomeEndKeys
                        />
                    </Grid>
                </Condition>
            ),
        },
        {
            field: (
                <Condition when="reference" is="quotationNumber">
                    <Grid item xs={12}>
                        <Autocomplete
                            label="Quotation Number"
                            name="quotations"
                            noOptionsText={"No quotations found"}
                            options={["", ...allQuoteNumbers.sort((a, b) => b.quotationNumber.localeCompare(a.quotationNumber))]}
                            getOptionLabel={option => option ? `${option.quotationNumber}: ${option.quotationDescription}` : ""}
                            getOptionSelected={(option, value) => option.uniqueId === value.uniqueId}
                            filterOptions={(options, params) => options.filter((option) => option !== "" &&
                                (option.quotationNumber.toLowerCase().includes(params.inputValue.toLowerCase()) ||
                                    option.quotationDescription.toLowerCase().includes(params.inputValue.toLowerCase()) ||
                                    (option.quotationNumber + ": " + option.quotationDescription).toLowerCase().includes(params.inputValue.toLowerCase())))}
                            handleHomeEndKeys
                        />
                    </Grid>
                </Condition>
            ),
        },
        {
            field: (
                <Condition when="reference" is="eoiNumber">
                    <Grid item xs={12}>
                        <Autocomplete
                            label="EOI Number"
                            name="eoi"
                            noOptionsText={"No EOI's found"}
                            options={["", ...allEOINumbers.sort((a, b) => b.eoiNumber.localeCompare(a.eoiNumber))]}
                            getOptionLabel={option => option ? `${option.eoiNumber}: ${option.eoiDescription}` : ""}
                            getOptionSelected={(option, value) => option.uniqueId === value.uniqueId}
                            filterOptions={(options, params) => options.filter((option) => option !== "" &&
                                (option.eoiNumber.toLowerCase().includes(params.inputValue.toLowerCase()) ||
                                    option.eoiDescription.toLowerCase().includes(params.inputValue.toLowerCase()) ||
                                    (option.eoiNumber + ": " + option.eoiDescription).toLowerCase().includes(params.inputValue.toLowerCase())))}
                            handleHomeEndKeys
                        />
                    </Grid>
                </Condition>
            ),
        },
        {
            field: (
                <Grid item xs={6}>
                    <Autocomplete
                        label="Currency"
                        name="currency"
                        noOptionsText={"No currencies found"}
                        // options={["", ...allCurrencies.filter((option, index, array) => array.findIndex(currency => (option.code === currency.code)) === index).sort((a,b) => a.code.localeCompare(b.code))]}
                        options={["", ...allCurrencies.sort((a,b) => a.code.localeCompare(b.code))]}
                        getOptionLabel={option => option ? `${option.code} - ${option.currency}` : ""}
                        getOptionSelected={(option, value) => option.id === value.id}
                        filterOptions={(options, params) => options.filter((option) => option !== "" &&
                            (option.code.toLowerCase().includes(params.inputValue.toLowerCase()) ||
                                option.currency.toLowerCase().includes(params.inputValue.toLowerCase()) ||
                                (option.code + " - " + option.currency).toLowerCase().includes(params.inputValue.toLowerCase())))}
                        handleHomeEndKeys
                    />
                </Grid>
            ),
        },
        {
            field: (
                <Grid item xs={6}>
                    <TextField
                        label="Project Value"
                        name="projectValue"
                        margin="none"
                        InputProps={{
                            inputComponent: NumberFormatOnInput,
                        }}
                    />
                </Grid>
            ),
        },
        {
            field: (
                <Grid item xs={12}>
                    <TextField
                        label="Comments"
                        name="comments"
                        margin="none"
                        multiline
                        rows={4}
                        rowsMax={10}
                    />
                </Grid>
            ),
        },
    ]

    const validate = values => {
        const errors = {}

        if (values.legalEntityType === "existingLegalEntity" && !values.legalEntity)
            errors.legalEntity = 'Required'
        if (values.legalEntityType === "otherLegalEntity" && !values.otherLegalEntity)
            errors.otherLegalEntity = 'Required'
        if (!values.profitCentres)
            errors.profitCentres = 'Required'
        if (!values.clientName)
            errors.clientName = 'Required'
        if (!values.projectName)
            errors.projectName = 'Required'
        if (!values.projectManager)
            errors.projectManager = 'Required'
        if (!values.awardDate)
            errors.awardDate = 'Required'
        if (isNaN(Date.parse(values.awardDate)))
            errors.awardDate = 'Award date is not a valid date'

        return errors
    }

    const onSubmitForm = async values => {
        let formData = new FormData();

        if (values.legalEntityType === "existingLegalEntity" && values.legalEntity) {
            formData.append('legalEntity', values.legalEntity.uniqueId);
            formData.append('otherLegalEntity', "");
        }
        else {
            formData.append('legalEntity', "");
            formData.append('otherLegalEntity', values.otherLegalEntity);
        }

        // formData.append('projectManager', values.projectManager.id);
        formData.append('profitCentres', values.profitCentres.uniqueId);
        formData.append('projectNumber', values.projectNumber);
        formData.append('projectName', values.projectName);
        formData.append('clientName', values.clientName);
        formData.append('awardDate', values.awardDate);

        if (values.reference === "tenderNumber" && values.tenders) {
            formData.append('tenders', values.tenders?.uniqueId);
            formData.append('quotations', "");
            formData.append('eoi', "");
        }
        else if (values.reference === "quotationNumber" && values.quotations) {
            formData.append('tenders', "");
            formData.append('quotations', values.quotations?.uniqueId);
            formData.append('eoi', "");
        }
        else if (values.reference === "eoiNumber" && values.eoi) {
            formData.append('tenders', "");
            formData.append('quotations', "");
            formData.append('eoi', values.eoi?.uniqueId);
        }

        // formData.append('tenders', values.tenders?.uniqueId || "");

        formData.append('currency', values.currency?.code || "");
        formData.append('location', values.location || "");
        formData.append('projectValue', values.projectValue || "");
        formData.append('projectDuration', values.projectDuration || "");
        formData.append('comments', values.comments || "");

        if (initialValues.uniqueId)
            formData.append('uniqueId', values.uniqueId);

        await onSubmit(values.projectManager.id, formData);
    }

    let submit;

    return (
        <Form
            onSubmit={onSubmitForm}
            validate={validate}
            decorators={[focusOnError]}
            initialValues={initialValues}
            render={({handleSubmit, submitting, form, pristine, values}) => {
                submit = handleSubmit
                return (
                    <Dialog
                        aria-labelledby="add-project-dialog-title"
                        onClose={onClose}
                        fullWidth
                        maxWidth={"sm"}
                        open={open}
                    >
                        <CustomDialogTitle
                            id="add-project-dialog-title"
                            onClose={onClose}
                            showTextContent
                        >
                            Add Project
                        </CustomDialogTitle>
                        <FormDialogContent dividers>
                            <form onSubmit={handleSubmit} noValidate>
                                <Grid container alignItems="flex-start" spacing={4}>
                                    {formFields.map((item, idx) => (
                                        <React.Fragment key={idx}>
                                            {item.field}
                                        </React.Fragment>
                                    ))}
                                </Grid>
                            </form>
                        </FormDialogContent>
                        <DialogActions>
                            <ActionButton
                                onClick={onClose}
                                type="button"
                                disabled={submitting}
                                color="primary"
                            >
                                Cancel
                            </ActionButton>
                            <ActionButton
                                type="button"
                                onClick={form.reset}
                                color="primary"
                                disabled={submitting || pristine}
                            >
                                Reset
                            </ActionButton>
                            <ActionButton
                                onClick={event => {
                                    const promise = submit(event);
                                    promise && promise.then(async () => {
                                        await sleep(300);
                                        form.reset();
                                    })
                                    return promise;
                                }}
                                type="submit"
                                disabled={submitting || pristine}
                                color="default"
                            >
                                Submit
                            </ActionButton>
                            <LoadingDiv>
                                <Loader loading={loading.type === loadingTypes.projectsAddProject ||
                                    loading.type === loadingTypes.projectsUpdateProject}
                                    size="small"/>
                            </LoadingDiv>
                        </DialogActions>
                    </Dialog>
                )
            }}
        />
    )
}
export default NewProjectDialog;