import styled from "styled-components";
import DialogContent from "@material-ui/core/DialogContent";
import Dialog from "@material-ui/core/Dialog";
import CustomDialogTitle from "../../components/Dialogs/CustomDialogTitle/CustomDialogTitle";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import React from "react";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";

const P = styled.p`
  margin: 0;
`;
const H3 = styled.h3`
  margin: 0;
`;

const FormDialogContent = styled(DialogContent)`
  @media only screen and (max-width: 425px) {
    padding: 12px !important;
  }
`;

const ExpansionPanelPayload = styled(ExpansionPanel)`
    box-shadow: none;
    width: 99.4%;
    padding-bottom:8px;
    padding-top:6px;
`;

const SummaryPayload = styled(ExpansionPanelSummary)`
    min-height: 20px !important;
    height:10px;
    padding:0;
`;

const PanelDetails = styled(ExpansionPanelDetails)`
    padding-bottom:0;
    margin-bottom:-20px;
`;

const DetailsDialog = ({
                        details,
                        onClose,
                        open,
                    }) => {
    return <Dialog
        aria-labelledby="audit-log-details-dialog-title"
        onClose={onClose}
        fullWidth
        maxWidth={"sm"}
        open={open}
    >
        <CustomDialogTitle
            id="audit-log-details-dialog-title"
            onClose={onClose}
            showTextContent
        >
            Audit Log Details
        </CustomDialogTitle>

        <FormDialogContent dividers>
            <ListItem>
                <ListItemText>
                    <H3>Id</H3>
                    <P>
                        {details.uniqueId || "Not specified"}
                    </P>
                </ListItemText>
            </ListItem>
            <ListItem>
                <ListItemText>
                    <H3>Item Id</H3>
                    <P>
                        {details.transactionId || "Not specified"}
                    </P>
                </ListItemText>
            </ListItem>
            <ListItem>
                <ListItemText>
                    <H3>Transaction Type</H3>
                    <P>
                        {details.transactionSubType?.transactionType?.typeName || "Not specified"}
                    </P>
                </ListItemText>
            </ListItem>
            <ListItem>
                <ListItemText>
                    <H3>Transaction Subtype</H3>
                    <P>
                        {details.transactionSubType?.subTypeName || "Not specified"}
                    </P>
                </ListItemText>
            </ListItem>
            <ListItem>
                <ListItemText>
                    <ExpansionPanelPayload >
                        <SummaryPayload
                            expandIcon={<ExpandMoreIcon />}
                        >
                            <H3>Full Payload</H3>
                        </SummaryPayload>
                        <PanelDetails>
                            <pre>{JSON.stringify(details?.description,null,2)}</pre>
                        </PanelDetails>
                    </ExpansionPanelPayload>
                </ListItemText>
            </ListItem>
            <ListItem>
                <ListItemText>
                    <H3>Timestamp</H3>
                    <P>
                        {details.timestamp || "Not specified"}
                    </P>
                </ListItemText>
            </ListItem>
            <ListItem>
                <ListItemText>
                    <H3>User Full Name</H3>
                    <P>
                        {details.user?.userFullName || "Not specified"}
                    </P>
                </ListItemText>
            </ListItem>
            <ListItem>
                <ListItemText>
                    <H3>User Email</H3>
                    <P>
                        {details.user?.userEmail || "Not specified"}
                    </P>
                </ListItemText>
            </ListItem>
            <ListItem>
                <ListItemText>
                    <H3>Action Performed</H3>
                    <P>
                        {details.action || "Not specified"}
                    </P>
                </ListItemText>
            </ListItem>
            <ListItem>
                <ListItemText>
                    <H3>Status Change</H3>
                    <P>
                        {details.statusChange || "Not specified"}
                    </P>
                </ListItemText>
            </ListItem>
        </FormDialogContent>
    </Dialog>
}
export default DetailsDialog;

