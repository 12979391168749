import React, {useEffect, useState} from "react";
import MaterialTable from "material-table";
import DetailsDialog from "./DetailsDialog";
import styled, {css} from "styled-components";
import {ThumbsUpDown, ThumbUp, ThumbDown, Clear} from "@material-ui/icons";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import {connect} from "react-redux";
import * as projectActions from "../../store/projects/actions";
import {getPermissionsByRole} from "../../store/permissions/actions";
import {formatAmount} from "../../assets/helpers/globals";
import FilterAutocomplete from "../../components/Inputs/Filter/FilterAutocomplete";
import Button from "@material-ui/core/Button";
import {getFilteredData} from "../../assets/helpers/globals";
import debounce from "lodash/debounce";
import isEqual from "lodash/isEqual";
import {useInterval} from "../../hooks/useInterval";
import * as loadingTypes from "../../store/loading/types";

const GoodIcon = styled(ThumbUp)`
  display: none;
  ${props => props.status === "Good" && css`
    margin-top: -1px;
    margin-right: 8px;
    display: flex;
    color: #388e3c;
  `}
`;

const BadIcon = styled(ThumbDown)`
  display: none;
  ${props => props.status === "Bad" && css`
    margin-top: -1px;
    margin-right: 8px;
    display: flex;
    color: #d32f2f;
  `}
`;

const AverageIcon = styled(ThumbsUpDown)`
  display: none;
  ${props => props.status === "Average" && css`
    margin-top: -1px;
    margin-right: 8px;
    display: flex;
    color: #f57c00;
  `}
`;

const P = styled.p`
  color: #f57c00;
  border: 1px solid #ff9800;
  border-radius: 16px;
  height: 24px;
  padding-left: 4px;
  padding-right: 8px;
  white-space: nowrap;
  display: flex;
  max-width: fit-content;

  ${props => props.status === "Good" && css`
    color: #388e3c;
    border: 1px solid #4caf50;
  `}
  ${props => props.status === "Bad" && css`
    color: #d32f2f;
    border: 1px solid #f44336;
  `}
`;

const FilterPaper = styled(Paper)`
  margin-bottom: 18px;
  margin-top: 14px;
`;

const FilterGrid = styled(Grid)`
  ${props => props.container && css`
    margin-left: 10px; 
    width: 98%;
  `}
  ${props => props.item && css`
    align-self: center
  `}
`;

const ProjectStatus = ({
						   projectsStatuses,
						   getUserRoles,
						   getAllProjectsStatuses,
						   getAllProjectsStatusesById,
						   loading
					   }) => {
	const initialFilterState = {statusName: null, projectPm: null, projectDirector: null}
	const [projectDetails, setProjectDetails] = useState({open: false, data: {}});
	const [filter, setFilter] = useState(initialFilterState);
	const [roles, setRoles] = useState({
		project_status_details_viewer: false,
		project_status_viewer: false,
	})

	const actions = roles.project_status_details_viewer ? [
		{
			icon: "visibility",
			onClick: async (_event, project) => {
				const res = await getAllProjectsStatusesById(project.projectId);
				setProjectDetails({open: true, data: { ...project, ...res }});
			},
		},
		{
			icon: 'refresh',
			tooltip: 'Refresh Data',
			isFreeAction: true,
			onClick: debounce(getAllProjectsStatuses, 300, {
				'leading': false,
				'trailing': true
			}),
			disabled: !(roles.project_status_viewer || roles.project_status_details_viewer)
		}
	] : [];

	const filterData = (data, query) => {
		return data.filter((item) => {
			for (let key in query) {
				switch (key) {
					case "statusName":
						if (item.projectStatus === null || item.projectStatus[key] === undefined || !query[key].includes(item.projectStatus[key])) {
							return false;
						}
						break;
					default:
						if (item[key] === undefined || !query[key].includes(item[key])) {
							return false;
						}
						break;
				}
			}
			return true;
		});
	};

	useInterval(() => {
		if (roles.project_status_viewer || roles.project_status_details_viewer)
			getAllProjectsStatuses();
	}, 300000);

	useEffect(() => {
		(async function() {
			const res = await getUserRoles("project","");
			setRoles({
				project_status_details_viewer: res.some(name => name === "project_status_details_viewer"),
				project_status_viewer: res.some(name => name === "project_status_viewer"),
			});
		})();

		if (roles.project_status_viewer || roles.project_status_details_viewer)
			getAllProjectsStatuses();
	}, [getUserRoles, getAllProjectsStatuses, roles.project_status_viewer, roles.project_status_details_viewer])

	return (
		<>
			<FilterPaper>
				<FilterGrid container spacing={2}>
					<FilterGrid item xs={12} sm={6} md={4} lg={3}>
						<FilterAutocomplete
							onChange={(event, newValue) => setFilter({...filter, statusName: newValue})}
							value={filter.statusName}
							options={projectsStatuses.map(projectStatus => projectStatus.projectStatus?.statusName)}
							placeholder="Status"
							noOptionsText="No Statuses"
						/>
					</FilterGrid>
					<FilterGrid item xs={12} sm={6} md={4} lg={3}>
						<FilterAutocomplete
							onChange={(event, newValue) => setFilter({...filter, projectPm: newValue})}
							value={filter.projectPm}
							options={projectsStatuses.map(projectStatus => projectStatus.projectPm)}
							placeholder="Project Manager"
							noOptionsText="No Project Managers"
						/>
					</FilterGrid>
					<FilterGrid item xs={12} sm={6} md={4} lg={3}>
						<FilterAutocomplete
							onChange={(event, newValue) => setFilter({...filter, projectDirector: newValue})}
							value={filter.projectDirector}
							options={projectsStatuses.map(projectStatus => projectStatus.projectDirector)}
							placeholder="Project Director"
							noOptionsText="No Project Directors"
						/>
					</FilterGrid>
					<FilterGrid item xs={12} sm={6} md={4} lg={3}>
						<Button
							color="secondary"
							size="small"
							startIcon={<Clear/>}
							disabled={isEqual(filter, initialFilterState)}
							onClick={() => setFilter(initialFilterState)}>
							Clear All
						</Button>
					</FilterGrid>
				</FilterGrid>
			</FilterPaper>

			<MaterialTable
				columns={[
					{
						title: "Project ID",
						field: "projectId"
					},
					{
						title: "Project Name",
						field: "projectName"
					},
					{
						title: "Project Manager",
						field: "projectPm"
					},
					{
						title: "Project Director",
						field: "projectDirector"
					},
					{
						title: "Project Value",
						field: "projectValue",
						render: rowData => (
							<span>{formatAmount(rowData.projectValue, "ZAR")}</span>
						)
					},
					{
						title: "Updated",
						field: "lastMonth",
						defaultSort: "desc"
					},
					{
						title: "Status",
						field: "projectStatus.statusName",
						render: data => (
							<P status={data.projectStatus.statusName}>
								<GoodIcon status={data.projectStatus.statusName}/>
								<BadIcon status={data.projectStatus.statusName}/>
								<AverageIcon status={data.projectStatus.statusName}/>{data.projectStatus.statusName}</P>
						),
					}
				]}
				actions={actions}
				data={isEqual(filter, initialFilterState) ? projectsStatuses : getFilteredData(filter, projectsStatuses, [], filterData)}
				isLoading={loading.type === loadingTypes.projectsGetAllProjectsStatuses ||
					loading.type === loadingTypes.projectsGetAllProjectsStatusesById ||
					loading.type === loadingTypes.permissionsGetPermissionsByRole}
				options={{
					actionsColumnIndex: -1,
					pageSize: 10,
					searchFieldAlignment: "left",
					showTitle: false,
					sorting: true,
					isLoading: true,
					search: true,
					filtering: false,
					// loadingType: "linear",
					// thirdSortClick: false,
				}}
			/>

			<DetailsDialog
				details={projectDetails.data}
				onClose={() => setProjectDetails({open: false, data: {}})}
				open={projectDetails.open}
			/>
		</>
	);
}

const mapStateToProps = state => ({
	projectsStatuses: state.projectsReducer.projectsStatuses,
	loading: state.loadingReducer.loading,
});

const mapDispatchToProps = dispatch => ({
	getAllProjectsStatuses: () => dispatch(projectActions.getAllProjectsStatuses()),
	getAllProjectsStatusesById: (id) => dispatch(projectActions.getAllProjectsStatusesById(id)),
	getUserRoles: (roleType, roleSubType) => dispatch(getPermissionsByRole(roleType, roleSubType)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProjectStatus);
