import React, {useEffect, useState} from "react";
import Grid from "@material-ui/core/Grid";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Paper from "@material-ui/core/Paper";
import styled from "styled-components";
import {connect} from "react-redux";
import Tenders from "./Tenders";
import Quotes from "./Quotes";
import EOI from "./EOI"
import TabPanel from "../../components/TabPanel/TabPanel";
import {getPermissionsByRole} from "../../store/permissions/actions";

const TabPaper = styled(Paper)`
  flex-grow: 1; 
  margin-bottom: 32px;
`;

const PurchasesTabs = ({ getUserRoles }) => {
    const [selectedTab, setSelectedTab] = useState(0)
    const [tabs, setTabs] = React.useState([]);
    const [tabsContent, setTabsContent] = React.useState([]);

    const handleTabChange = (_, newTab) => {
        setSelectedTab(newTab)
    };

    useEffect(() => {
        (async function() {
            const res = await getUserRoles("tender","");

            if (res.some(name => name === "tender_register_creator" || name === "tender_register_viewer" || name === "tender_register_modifier")) {
                let tenderIndex = 0;
                let quoteIndex = 1;
                let EOIIndex = 2;

                if (res.length && res.length > 0) {
                    setTabs(tabs => [
                        {
                            label: "Tenders",
                            key: tenderIndex
                        },
                        {
                            label: "Quotes",
                            key: quoteIndex
                        },
                        {
                            label: "EOI",
                            key: EOIIndex
                        }
                    ])
                    setTabsContent([
                        {
                            index: tenderIndex,
                            key: tenderIndex,
                            children: <Tenders/>
                        },
                        {
                            index: quoteIndex,
                            key: quoteIndex,
                            children: <Quotes/>
                        },
                        {
                            index: EOIIndex,
                            key: EOIIndex,
                            children: <EOI/>
                        }
                    ])
                }
            }
        })();
    }, [getUserRoles])

    return (
        <>
            <Grid container alignItems="center" justify="center">
                <Grid item xs={12}>
                    <TabPaper>
                        <Tabs
                            value={selectedTab}
                            onChange={handleTabChange}
                            variant="scrollable"
                            scrollButtons="auto"
                        >
                            {tabs.map(({key, label}) => <Tab label={label} key={key}/>)}
                        </Tabs>
                    </TabPaper>
                    {tabsContent.map(({index, key, children}) =>
                        <TabPanel value={selectedTab} index={index} key={key}>{children}</TabPanel>
                    )}
                </Grid>
            </Grid>
        </>
    );
}

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => ({
    getUserRoles: (roleType, roleSubType) => dispatch(getPermissionsByRole(roleType, roleSubType)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PurchasesTabs);