import React, {useState} from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import CustomDialogTitle from "../../components/Dialogs/CustomDialogTitle/CustomDialogTitle";
import styled, {css} from "styled-components";
import MaterialTable from "material-table";
import {Autorenew, Done, DoneAll, Info, Warning} from "@material-ui/icons";
import AuditLoggingDetailsDialog from "./DetailsDialog";

const P = styled.p`
	color: #f57c00;
	border: 1px solid #ff9800;
	border-radius: 16px;
	height: 24px;
	padding-left: 4px;
	padding-right: 8px;
	white-space: nowrap;
	display: flex;
	max-width: fit-content;
	${props => (props.status === "In Progress" || props.status === "Quote Review In Progress (Draft)" || props.status === "PO Review In Progress (Draft)")  && css`
		color: #1976d2;
		border: 1px solid #2196f3;
	`}
	${props => (props.status === "Invoice Approved" || props.status === "Approved" || props.status === "Resolved" || props.status === "Quote Reviewed" || props.status === "PO Created" || props.status === "PO Reviewed" || props.status === "PO Submitted to Xero" || props.status === "Registered") && css`
		color: #388e3c;
		border: 1px solid #4caf50;
	`}
	${props => (props.status === "Rejected" || props.status === "Invoice Rejected" || props.status === "Unresolved" || props.status === "Deleted") && css`
    color: #d32f2f;
    border: 1px solid #f44336;
  `}
`;

const FormDialogContent = styled(DialogContent)`
	padding-bottom: 0;
  @media only screen and (max-width: 425px) {
    padding: 12px !important;
  }
`;

const ApprovedAllIcon = styled(DoneAll)`
	display: none;
	${props => (props.status === "PO Created" || props.status === "PO Submitted to Xero") && css`
		margin-top: -1px;
		margin-right: 2px;
		display: flex;
		color: #388e3c;
	`}
`;

const ApprovedIcon = styled(Done)`
	display: none;
	${props => (props.status === "Invoice Approved" || props.status === "Approved" || props.status === "Resolved" || props.status === "Quote Reviewed" || props.status === "PO Reviewed" || props.status === "Registered") && css`
		margin-top: -1px;
		margin-right: 2px;
		display: flex;
		color: #388e3c;
	`}
`;

const RejectedIcon = styled(Warning)`
	display: none;
	${props => (props.status === "Rejected" || props.status === "Invoice Rejected" || props.status === "Unresolved" || props.status === "Deleted") && css`
		margin-top: -1px;
		margin-right: 2px;
		display: flex;
		color: #d32f2f;
	`}
`;

const PendingIcon = styled(Autorenew)`
	display: none;
	${props => (props.status === "Pending" || props.status === "Invoice Attached" || props.status === "Awaiting Approval" || props.status === "Awaiting 2nd Approval" || props.status === "Queued" || props.status === "New Quote Requested" || props.status === "PO Pending" || props.status === "Quote Awaiting Review") && css`
		margin-top: -1px;
		margin-right: 2px;
		display: flex;
		color: #f57c00;
	`}
`;

const InProgressIcon = styled(Info)`
  display: none;
  ${props => (props.status === "In Progress" || props.status === "Quote Review In Progress (Draft)" || props.status === "PO Review In Progress (Draft)") && css`
    margin-top: -1px;
    margin-right: 2px;
    display: flex;
    color: #1976d2;
  `}
`;

const TableDialog = ({
						    auditPayload,
						    onClose,
						    open,
							type,
							loading
}) => {
	const [auditDetails, setAuditDetails] = useState({open: false, data: {}});
	const actions = [
		{
			icon: "visibility",
			onClick: async (_event, audit) => {
				setAuditDetails({open: true, data: audit});
			},
		}
	];

	return <>
		<Dialog
			aria-labelledby="audit-logging-details-dialog-title"
			onClose={onClose}
			fullWidth
			maxWidth={"lg"}
			open={open}
		>
			<CustomDialogTitle
				id="audit-logging-details-dialog-title"
				onClose={onClose}
				showTextContent
			>
				Selected {type} Log
			</CustomDialogTitle>

			<FormDialogContent>
				<MaterialTable
					columns={[
						{
							title: "Log Id",
							field: "uniqueId",
							type: "numeric",
							align: "left"
						},
						{
							title: `${type} Id`,
							field: "transactionId",
							type: "numeric",
							align: "left"
						},
						{
							title: "Type",
							field: "transactionSubType.transactionType.typeName",
							type: "string"
						},
						{
							title: "Subtype",
							field: "transactionSubType.subTypeName",
							type: "string"
						},
						{
							title: "Timestamp",
							field: "timestamp",
							type: "datetime",
							defaultSort: "desc",
							customSort: (a, b) => {
								let d1 = new Date(a.timestamp);
								let d2 = new Date(b.timestamp);

								return d1.getTime() - d2.getTime();
							}
						},
						{
							title: "User",
							field: "user.userFullName",
							type: "string"
						},
						{
							title: "Action Performed",
							field: "action",
							type: "string"
						},
						{
							title: "Status Change",
							field: "statusChange",
							render: data => {
								if (data.statusChange)
									return <P status={data.statusChange}>
											 <ApprovedIcon status={data.statusChange}/>
											 <RejectedIcon status={data.statusChange}/>
											 <PendingIcon status={data.statusChange}/>
											 <ApprovedAllIcon status={data.statusChange}/>
											 <InProgressIcon status={data.statusChange}/>
											   {data.statusChange}</P>
							},
							type: "string"
						},
					]}
					actions={actions}
					data={auditPayload}
					isLoading={loading}
					options={{
						actionsColumnIndex: -1,
						// paginationPosition: "top",
						pageSize: 5,
						showTitle: false,
						toolbar: true,
						sorting: true,
						isLoading: true,
						search: true,
						searchFieldAlignment: "left",
						// thirdSortClick: false,
					}}
					style={{ boxShadow: "none" }}
				/>
			</FormDialogContent>
		</Dialog>
		<AuditLoggingDetailsDialog
			details={auditDetails.data}
			onClose={() => setAuditDetails({open: false, data: {}})}
			open={auditDetails.open}
		/>
	</>
}
export default TableDialog;
