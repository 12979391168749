import React from "react";
import styled from "styled-components";
import Tooltip from "@material-ui/core/Tooltip";
import {Refresh} from "@material-ui/icons";
import {FieldArray} from "react-final-form-arrays";
import Grid from "@material-ui/core/Grid";
import FormLabel from "@material-ui/core/FormLabel";
import {Autocomplete, TextField} from "mui-rff";
import {TextField as MuiTextField} from "@material-ui/core";
import {NumberFormatOnInput, WhenFieldChanges} from "../../../assets/helpers/finalFormHelpers";
import * as loadingTypes from "../../../store/error/types";
import Button from "../../Buttons/Button/Button";

const Error = styled.p`
  color: #ea4335;
  margin: 0;
  font-size: 0.75rem;
  margin-top: 3px;
  text-align: left;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  line-height: 1.66;
  letter-spacing: 0.03333em;
`;

const MultiplePurchaseOrderLineItemFieldAdapter = ({
                                                       errors,
                                                       input: { name },
                                                       required,
                                                       values,
                                                       taxTypesByLegalEntity,
                                                       accountsByLegalEntity,
                                                       legalEntityUniqueId,
                                                       trackingByLegalEntity,
                                                       getAllLatestAccounts,
                                                       getAccountsByLegalEntity,
                                                       touched,
                                                       disabled,
                                                       loading
}) => {
    const purchaseOrderLineItemsArray = values[name];

    const calculateTotal = () => {
        if (values.purchaseTaxOption.taxOption === "Exclusive" && values[name][0].xeroTaxTypes?.rate)
            return values[name][0].unitAmount + (values[name][0].unitAmount * values[name][0].xeroTaxTypes.rate / 100)
        else
            return values[name][0].unitAmount
    }

    return (
        <>
            <FormLabel>Line Items</FormLabel>
            <FieldArray name={name}>
                {({ fields }) =>
                    fields.map((name, index) => {
                        return <Grid key={name} container spacing={2} alignItems="center">
                            <Grid item xs={1} style={{textAlign: "center"}}>
                                <FormLabel style={{fontSize: 12}}>Item #{index + 1}</FormLabel>
                            </Grid>
                            <Grid item xs={3}>
                                <TextField
                                    label="Description"
                                    margin="none"
                                    name={`${name}.description`}
                                    disabled={disabled}
                                    required={required}
                                    error={required && touched[`${name}.description`] && !!errors[`${name}.description`]}
                                    multiline
                                />
                                {required && touched[`${name}.description`] && errors[`${name}.description`] &&
                                <Error>{errors[`${name}.description`]}</Error>}
                            </Grid>
                            <Grid item xs={2}>
                                <TextField
                                    label="Quantity"
                                    margin="none"
                                    name={`${name}.quantity`}
                                    disabled
                                    type="number"
                                    required={required}
                                    error={required && touched[`${name}.quantity`] && !!errors[`${name}.quantity`]}
                                />
                                {required && touched[`${name}.quantity`] && errors[`${name}.quantity`] &&
                                <Error>{errors[`${name}.quantity`]}</Error>}
                            </Grid>
                            <Grid item xs={3}>
                                <TextField
                                    label="Unit Price"
                                    margin="none"
                                    name={`${name}.unitAmount`}
                                    disabled
                                    required={required}
                                    error={required && touched[`${name}.unitAmount`] && !!errors[`${name}.unitAmount`]}
                                    InputProps={{
                                        inputComponent: NumberFormatOnInput,
                                    }}
                                />
                                {required && touched[`${name}.unitAmount`] && errors[`${name}.unitAmount`] &&
                                <Error>{errors[`${name}.unitAmount`]}</Error>}
                            </Grid>
                            <Grid item xs={3}>
                                <TextField
                                    label={`Amount ${values.currency?.name}`}
                                    margin="none"
                                    name={`${name}.unitAmount`}
                                    disabled
                                    required={required}
                                    InputProps={{
                                        inputComponent: NumberFormatOnInput,
                                    }}
                                />
                            </Grid>
                            <Grid item xs={1} />
                            <WhenFieldChanges
                                field={`${name}.xeroAccounts`}
                                set={`${name}.xeroTaxTypes`}
                                to={''}
                                shouldChangeHandler={(xeroAccounts) => xeroAccounts !== null}
                            />
                            <WhenFieldChanges
                                field={`${name}.xeroAccounts`}
                                set={`${name}.xeroTaxTypes`}
                                to={''}
                                shouldChangeHandler={(xeroAccounts) => xeroAccounts === null}
                            />
                            <Grid item xs={3}>
                                <Autocomplete
                                    label="Account"
                                    name={`${name}.xeroAccounts`}
                                    noOptionsText={"No accounts found"}
                                    required={required}
                                    options={["", ...accountsByLegalEntity.sort()]}
                                    disabled={disabled || loading.type === loadingTypes.xeroGetAllLatestAccounts || loading.type === loadingTypes.xeroGetAccountsByLegalEntity}
                                    getOptionLabel={option => option && option.code && option.name ? `${option.code}: ${option.name}` : option && option.code ? option.code : option && option.name ? option.name : ""}
                                    getOptionSelected={(option, value) => option.uniqueId === value.uniqueId}
                                    filterOptions={(options, params) => options.filter((option) => option !== "" &&
                                            option.code !== null && option.name != null &&
                                            (option.code.toLowerCase().includes(params.inputValue.toLowerCase()) ||
                                                option.name.toLowerCase().includes(params.inputValue.toLowerCase()) ||
                                                (option.code + ": " + option.name).toLowerCase().includes(params.inputValue.toLowerCase()))
                                    )}
                                    handleHomeEndKeys
                                    renderInput={(params) =>
                                        <MuiTextField
                                            {...params}
                                            label="Account"
                                            name={`${name}.xeroAccounts`}
                                            required={required}
                                            error={required && touched[`${name}.xeroAccounts`] && errors[`${name}.xeroAccounts`] !== undefined}
                                        />}
                                />
                                {required && touched[`${name}.xeroAccounts`] && errors[`${name}.xeroAccounts`] &&
                                <Error>{errors[`${name}.xeroAccounts`]}</Error>}
                            </Grid>
                            <Grid item xs={2}>
                                <Button
                                    color='default'
                                    onClick={async () => {
                                        await getAllLatestAccounts();
                                        getAccountsByLegalEntity(legalEntityUniqueId);
                                    }}
                                    style={{textAlign: "center"}}
                                    hasLoader={true}
                                    disabled={loading.type === loadingTypes.xeroGetAllLatestAccounts || loading.type === loadingTypes.xeroGetAccountsByLegalEntity}
                                    loading={loading.type === loadingTypes.xeroGetAllLatestAccounts || loading.type === loadingTypes.xeroGetAccountsByLegalEntity}
                                >
                                    <Tooltip title={"Get Latest Accounts"}>
                                        <Refresh/>
                                    </Tooltip>
                                </Button>
                            </Grid>
                            <Grid item xs={3}>
                                <Autocomplete
                                    label="Tax Rate"
                                    name={`${name}.xeroTaxTypes`}
                                    noOptionsText={"No tax rates found"}
                                    required={required}
                                    options={["", ...taxTypesByLegalEntity
                                        .filter(taxTypeEntity => taxTypeEntity[purchaseOrderLineItemsArray[index].xeroAccounts?.xeroClass.toLowerCase()])
                                        .sort((a, b) => a.name.localeCompare(b.name))]}
                                    disabled={disabled || loading.type === loadingTypes.xeroGetAllLatestAccounts || loading.type === loadingTypes.xeroGetAccountsByLegalEntity}
                                    getOptionLabel={option => option ? `${option.name} (${option.rate}%)` : ""}
                                    getOptionSelected={(option, value) => option.uniqueId === value.uniqueId}
                                    filterOptions={(options, params) => options.filter((option) => option !== "" &&
                                        (option.name.toLowerCase().includes(params.inputValue.toLowerCase()) ||
                                            option.rate.toString().includes(params.inputValue.toLowerCase()) ||
                                            (option.name + " (" + option.rate + "%)").toLowerCase().includes(params.inputValue.toLowerCase())))}
                                    handleHomeEndKeys
                                    renderInput={(params) =>
                                        <MuiTextField
                                            {...params}
                                            label="Tax Rate"
                                            name={`${name}.xeroTaxTypes`}
                                            required={required}
                                            error={required && touched[`${name}.xeroTaxTypes`] && errors[`${name}.xeroTaxTypes`] !== undefined}
                                        />}
                                />
                                {required && touched[`${name}.xeroTaxTypes`] && errors[`${name}.xeroTaxTypes`] &&
                                <Error>{errors[`${name}.xeroTaxTypes`]}</Error>}
                            </Grid>

                            {trackingByLegalEntity.map((trackingByLegalEntityItem, trackingByLegalEntityIdx) => {
                                let trackingOptionsArray = trackingByLegalEntity[trackingByLegalEntityIdx]?.xeroTrackingOptions;

                                return <Grid item xs={3} key={`grid-${trackingByLegalEntityIdx}`}>
                                    <Autocomplete
                                        key={`autocomplete-input-${trackingByLegalEntityIdx}`}
                                        label={trackingByLegalEntityItem.name}
                                        name={`${name}.purchaseOrderLineItemTrackings[${trackingByLegalEntityIdx}].xeroTrackingOptions`}
                                        noOptionsText={`No ${trackingByLegalEntityItem.name} options found`}
                                        required={required}
                                        options={["", ...trackingOptionsArray.sort((a, b) => a.name.localeCompare(b.name))]}
                                        disabled={disabled || loading.type === loadingTypes.xeroGetAllLatestAccounts || loading.type === loadingTypes.xeroGetAccountsByLegalEntity}
                                        getOptionLabel={option => option ? `${option.name}` : ""}
                                        getOptionSelected={(option, value) => option.uniqueId === value.uniqueId}
                                        filterOptions={(options, params) => options.filter((option) => option !== "" &&
                                            option.name.toLowerCase().includes(params.inputValue.toLowerCase()))}
                                        handleHomeEndKeys
                                        renderInput={(params) =>
                                            <MuiTextField
                                                {...params}
                                                key={`text-field-input-${trackingByLegalEntityIdx}`}
                                                label={trackingByLegalEntityItem.name}
                                                name={`${name}.purchaseOrderLineItemTrackings[${trackingByLegalEntityIdx}].xeroTrackingOptions`}
                                                required={required}
                                                error={required && touched[`${name}.purchaseOrderLineItemTrackings[${trackingByLegalEntityIdx}].xeroTrackingOptions`] && errors[`${name}.purchaseOrderLineItemTrackings[${trackingByLegalEntityIdx}].xeroTrackingOptions`] !== undefined}
                                            />}
                                    />
                                    {required && touched[`${name}.purchaseOrderLineItemTrackings[${trackingByLegalEntityIdx}].xeroTrackingOptions`] && errors[`${name}.purchaseOrderLineItemTrackings[${trackingByLegalEntityIdx}].xeroTrackingOptions`] &&
                                    <Error key={`error-${trackingByLegalEntityIdx}`}>{errors[`${name}.purchaseOrderLineItemTrackings[${trackingByLegalEntityIdx}].xeroTrackingOptions`]}</Error>}
                                </Grid>
                            })}
                        </Grid>
                    })
                }
            </FieldArray>
            {values[name] && values[name].length && values[name].length > 0 ?
                <Grid container spacing={1} alignItems="center">
                    <Grid item xs={9} style={{textAlign: "end"}}>
                        <FormLabel style={{fontSize: 12}}>Subtotal </FormLabel>
                    </Grid>
                    <Grid item xs={3}>
                        <TextField
                            name="subTotal"
                            margin="normal"
                            disabled
                            value={values[name][0].unitAmount}
                            InputProps={{
                                inputComponent: NumberFormatOnInput,
                            }}
                        >
                        </TextField>
                    </Grid>
                    {values[name][0].xeroTaxTypes && values.purchaseTaxOption.taxOption !== "NoTax"? <><Grid item xs={9} style={{textAlign: "end"}}>
                        <FormLabel style={{fontSize: 12}}>{"Total Tax " + values[name][0].xeroTaxTypes.rate + "%"}</FormLabel>
                    </Grid>
                    <Grid item xs={3}>
                        <TextField
                            name="taxType"
                            margin="normal"
                            disabled
                            value={values[name][0].unitAmount * values[name][0].xeroTaxTypes.rate / 100}
                            InputProps={{
                                inputComponent: NumberFormatOnInput,
                            }}
                        >
                        </TextField>
                    </Grid></> : ""}
                    <Grid item xs={9} style={{textAlign: "end"}}>
                        <FormLabel style={{fontSize: 15}}>Total</FormLabel>
                    </Grid>
                    <Grid item xs={3}>
                        <TextField
                            name="total"
                            margin="normal"
                            disabled
                            value={calculateTotal()}
                            InputProps={{
                                inputComponent: NumberFormatOnInput,
                            }}
                        >
                        </TextField>
                    </Grid>
                </Grid> : null
            }
            {required && touched[name] && errors[name] && <Error>{errors[name]}</Error>}
        </>
    )
}

export default MultiplePurchaseOrderLineItemFieldAdapter;