import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import React from "react";
import Dialog from "@material-ui/core/Dialog";
import styled, {css} from "styled-components";
import DialogContent from "@material-ui/core/DialogContent";
import CustomDialogTitle from "../../../../components/Dialogs/CustomDialogTitle/CustomDialogTitle";
import {formatShortDate} from "../../../../assets/helpers/globals";

const H3 = styled.h3`
  margin: 0;
`;

const P = styled.p`
  margin: 0;
  ${props => props.blue && css`color: RoyalBlue;`}
  ${props => props.small && css`font-size: small;`}
`;

const Span = styled.span`
  color: #f57c00;

  ${props => (props.status === "PO Submitted to Xero" || props.status === "PO Reviewed") && css`
    color: #388e3c;
  `}
  ${props => props.status === "PO Review In Progress (Draft)" && css`
    color: #1976d2;
  `}
`;

const Div = styled.div`
  padding: 16px;
`

const FormDialogContent = styled(DialogContent)`
  @media only screen and (max-width: 425px) {
    padding: 12px !important;
  }
`;

const DetailsDialog = ({
                           details,
                           onClose,
                           open
                       }) => (
    <Dialog
        aria-labelledby="purchase-order-details-dialog-title"
        onClose={onClose}
        fullWidth
        maxWidth={"sm"}
        open={open}
    >
        <CustomDialogTitle
            id="purchase-order-details-dialog-title"
            onClose={onClose}
            showTextContent
        >
            Project Purchase Details
        </CustomDialogTitle>
        <FormDialogContent dividers>
            <ListItem>
                <ListItemText>
                    <H3>Supplier</H3>
                    <P>{details.vendor ? details.vendor.vendorName : details.vendorOther}</P>
                </ListItemText>
            </ListItem>
            <ListItem>
                <ListItemText>
                    <H3>Delivery Address</H3>
                    {details.isDefaultDeliveryAddress === "true" ?
                        details.existingDeliveryAddress ? <Div>
                            {details.existingDeliveryAddress?.addressLine1 ? <P>{details.existingDeliveryAddress?.addressLine1}</P> : ""}
                            {details.existingDeliveryAddress?.addressLine2 ? <P>{details.existingDeliveryAddress?.addressLine2}</P> : ""}
                            {details.existingDeliveryAddress?.addressLine3 ? <P>{details.existingDeliveryAddress?.addressLine3}</P> : ""}
                            {details.existingDeliveryAddress?.addressLine4 ? <P>{details.existingDeliveryAddress?.addressLine4}</P> : ""}
                            {details.existingDeliveryAddress?.addressType ? <P>{details.existingDeliveryAddress?.addressType}</P> : ""}
                            {details.existingDeliveryAddress?.city ? <P>{details.existingDeliveryAddress?.city}</P> : ""}
                            {details.existingDeliveryAddress?.region ? <P>{details.existingDeliveryAddress?.region}</P> : ""}
                            {details.existingDeliveryAddress?.postalCode ? <P>{details.existingDeliveryAddress?.postalCode}</P> : ""}
                            {details.existingDeliveryAddress?.country ? <P>{details.existingDeliveryAddress?.country}</P> : ""}
                        </Div>
                            :  <P>Not specified</P>
                        : <P>{details.oneOffDeliveryAddress || "Not specified"}</P>
                    }
                </ListItemText>
            </ListItem>
            <ListItem>
                <ListItemText>
                    <H3>Delivery Date</H3>
                    <P>{details.deliveryDate ? formatShortDate(details.deliveryDate) : "Not specified"}</P>
                </ListItemText>
            </ListItem>
            <ListItem>
                <ListItemText>
                    <H3>Delivery Instructions</H3>
                    <P>{details.deliveryInstructions || "Not specified"}</P>
                </ListItemText>
            </ListItem>
            <ListItem>
                <ListItemText>
                    <H3>Reference</H3>
                    <P>{details.reference || "Not specified"}</P>
                </ListItemText>
            </ListItem>
            <ListItem>
                <ListItemText>
                    <H3>Currency</H3>
                    <P>{details.currency?.name || "Not specified"}</P>
                </ListItemText>
            </ListItem>
            <ListItem>
                <ListItemText>
                    <H3>Tax Option</H3>
                    <P>{details.purchaseTaxOption?.taxOptionText || "Not specified"}</P>
                </ListItemText>
            </ListItem>
            <ListItem>
                <ListItemText>
                    <H3>Legal Entity</H3>
                    <P>{details.legalEntity?.entityName || "Not specified"}</P>
                </ListItemText>
            </ListItem>
            <ListItem>
                <ListItemText>
                    <H3>Status</H3>
                    <P>{<Span status={details.status}>{details.status}</Span> || "Not specified"}</P>
                </ListItemText>
            </ListItem>
            <ListItem>
                <ListItemText>
                    <H3>Line Items</H3>
                    {details.purchaseOrderLineItems?.map((purchaseOrderLineItem, purchaseOrderLineItemIdx) =>
                        <Div key={purchaseOrderLineItemIdx}>
                            <P>Description: {purchaseOrderLineItem.description || "Not specified"}</P>
                            <P>Quantity: {purchaseOrderLineItem.quantity || "Not specified"}</P>
                            <P>Unit Amount: {purchaseOrderLineItem.unitAmount || "Not specified"}</P>
                            <P>Xero Accounts: {!!purchaseOrderLineItem.xeroAccounts ? purchaseOrderLineItem.xeroAccounts.code + " " + purchaseOrderLineItem.xeroAccounts.name : "Not specified"}</P>
                            <P>Xero Tax Types: {!!purchaseOrderLineItem.xeroTaxTypes ? purchaseOrderLineItem.xeroTaxTypes.name + " (" + purchaseOrderLineItem.xeroTaxTypes.rate + "%)" : "Not specified"}</P>
                        </Div>
                    )}
                </ListItemText>
            </ListItem>
        </FormDialogContent>
    </Dialog>
)

export default DetailsDialog;