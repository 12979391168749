import React, { Fragment } from "react";
import TextField from "@material-ui/core/TextField";
import { DateTimePicker } from "@material-ui/pickers";
import Autocomplete from "@material-ui/lab/Autocomplete";
import styles from "./Input.module.css";
import Checkbox from "@material-ui/core/Checkbox";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import parse from "autosuggest-highlight/parse";
import match from "autosuggest-highlight/match";
import GoogleLocation from "../GoogleLocation/GoogleLocation";
import RichTextEditor from "../RichTextEditor/RichTextEditor";
// import Button from '../../Buttons/Button/Button';
import Loader from "../../Loader/Loader";
import FileUpload from "../FileUpload/FileUpload";
import FilesUpload from "../FileUpload/Files";
import UploadProgress from "../../UploadProgress/UploadProgress";
import EventRecurrence from "../../Dialogs/Boardrooms/EventRecurrence/EventRecurrence";
import { connect } from "react-redux";

const Input = props => {
	const { elementData } = props;
	let inputElement = null;

	switch (elementData.elementType) {
		case "text":
			inputElement = (
				<TextField
					className={styles.InputElement}
					{...elementData.elementConfig}
					value={elementData.value}
					error={elementData.error ? true : false}
					helperText={elementData.helperText ? elementData.helperText : ""}
				/>
			);
			break;
		case "resourcesAutoComplete":
			inputElement = (
				<Autocomplete
					className={styles.InputElement}
					options={props.resources ? props.resources : []}
					loading={props.resourcesLoading ? props.resourcesLoading : false}
					{...elementData.elementConfig}
					renderInput={params => (
						<TextField
							{...params}
							{...elementData.elementInputConfig}
							error={elementData.error ? true : false}
							helperText={elementData.helperText ? elementData.helperText : ""}
							InputProps={{
								...params.InputProps,
								endAdornment: (
									<React.Fragment>
										<Loader
											loading={
												elementData.useResources && props.resourcesLoading
											}
											size="small"
										/>
										{params.InputProps.endAdornment}
									</React.Fragment>
								),
							}}
						/>
					)}
					renderOption={(option, { inputValue }) => {
						const matches = match(option.title, inputValue);
						const parts = parse(option.title, matches);

						return (
							<div>
								{parts.map((part, index) => (
									<span
										key={index}
										style={{ fontWeight: part.highlight ? 700 : 400 }}
									>
										{part.text}
									</span>
								))}
							</div>
						);
					}}
				/>
			);
			break;
		case "guestsAutoComplete":
			inputElement = (
				<Autocomplete
					className={styles.InputElement}
					value={elementData.value ? elementData.value : []}
					options={props.contacts ? props.contacts : []}
					getOptionLabel={option =>
						option.displayName
							? option.displayName + " (" + option.email + ")"
							: option.email
					}
					loading={props.contactsLoading ? props.contactsLoading : false}
					{...elementData.elementConfig}
					renderInput={params => {
						return (
							<TextField
								{...params}
								{...elementData.elementInputConfig}
								error={elementData.error ? true : false}
								helperText={
									elementData.helperText ? elementData.helperText : ""
								}
							/>
						);
					}}
					renderOption={(option, { inputValue }) => {
						if (option.displayName) {
							const matches = match(
								option.displayName + " (" + option.email + ")",
								inputValue
							);
							const parts = parse(
								option.displayName + " (" + option.email + ")",
								matches
							);

							return (
								<div>
									{parts.map((part, index) => (
										<span
											key={index}
											style={{ fontWeight: part.highlight ? 700 : 400 }}
										>
											{part.text}
										</span>
									))}
								</div>
							);
						} else {
							const matches = match(option.email, inputValue);
							const parts = parse(option.email, matches);

							return (
								<div>
									{parts.map((part, index) => (
										<span
											key={index}
											style={{ fontWeight: part.highlight ? 700 : 400 }}
										>
											{part.text}
										</span>
									))}
								</div>
							);
						}
					}}
				/>
			);
			break;
		case "dateTime":
			inputElement = (
				<Fragment>
					<h3 style={{ margin: 0 }}>
						{elementData.elementConfig?.placeholder}
					</h3>
					<DateTimePicker
						name={elementData.name}
						className={styles.InputElement}
						{...elementData.elementConfig}
						value={elementData.value}
						error={elementData.error ? true : false}
						helperText={elementData.helperText ? elementData.helperText : ""}
					/>
				</Fragment>
			);
			break;
		case "checkbox":
			inputElement = (
				<FormControlLabel
					className={styles.InputElement}
					control={
						<Checkbox
							checked={elementData.checked}
							{...elementData.elementConfig}
						/>
					}
					label={elementData.label}
				/>
			);
			break;
		case "richEditor":
			inputElement = <RichTextEditor {...elementData.elementConfig} />;
			break;
		case "googleLocation":
			inputElement = (
				<GoogleLocation
					className={styles.InputElement}
					{...elementData.elementConfig}
				/>
			);
			break;
		case "eventRecurrence":
			inputElement = (
				<EventRecurrence
					className={styles.InputElement}
					currentRecurringArray={elementData.value}
					open={elementData.open}
					{...elementData.elementConfig}
				/>
			);
			break;
		case "radioGroup":
			inputElement = (
				<FormControl component="fieldset">
					<FormLabel component="legend">{elementData.label}</FormLabel>
					<RadioGroup
						aria-label={
							elementData.label ? elementData.label.toLowerCase() : null
						}
						value={elementData.value}
						{...elementData.elementConfig}
					>
						{elementData.options.map(option => (
							<FormControlLabel
								key={option.id}
								value={option.value}
								control={<Radio />}
								label={option.label}
							/>
						))}
					</RadioGroup>
				</FormControl>
			);
			break;
		case "integer":
			inputElement = (
				<TextField
					className={styles.InputElement}
					{...elementData.elementConfig}
					value={elementData.value}
					error={elementData.error ? true : false}
					helperText={elementData.helperText ? elementData.helperText : ""}
				/>
			);
			break;
		case "file":
			inputElement = (
				<Fragment>
					<FileUpload {...elementData.elementConfig} />
					<UploadProgress />
				</Fragment>
			);
			break;
		case "files":
			return (
				<FilesUpload {...elementData.elementConfig} value={elementData.value} />
			);
		default:
			return (
				<input
					className={styles.InputElement}
					{...elementData.elementConfig}
					value={elementData.value}
				/>
			);
	}

	return <Fragment>{inputElement}</Fragment>;
};

const mapStateToProps = state => {
	return {
		resources: state.globalReducer.resources,
		resourcesLoading: state.globalReducer.resourcesLoading,
		resourcesError: state.globalReducer.resourcesError,
		contacts: state.globalReducer.contacts,
		contactsLoading: state.globalReducer.contactsLoading,
		contactsError: state.globalReducer.contactsError,
	};
};

export default React.memo(connect(mapStateToProps)(Input));
