import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Button from '@material-ui/core/Button';
import styles from './BoardroomRecurrenceUpdate.module.css';
import Loader from '../../../Loader/Loader';

const BoardroomRecurrenceUpdate = (props) => {

    const generateTitle = () => {
        switch (props.type) {
            case 'delete':
                return 'Cancel recurring event';
            case 'response':
                return 'RSVP to recurring event';
            case 'edit':
                return 'Edit recurring event';
            default:
                return '';
        }
    }

    const isLoading = () => {
        switch (props.type) {
            case 'delete':
                return props.loadingDelete;
            case 'response':
                return props.loadingRespond;
            case 'edit':
                return props.loadingUpdate;
            default:
                return false;
        }
    }

    return (
        props.selectedEvent && props.type && <Dialog
            open={props.open}
            onClose={props.handleClose}>
            <DialogTitle
                disableTypography
                onClose={props.handleClose}
            >
                <h3 className={styles.RecurringHeader}>{generateTitle()}</h3>
            </DialogTitle>
            <DialogContent>
                <RadioGroup value={props.recurringRadio} onChange={props.handleRecurringConfirmationChange} >
                    <FormControlLabel value='single' control={<Radio />} label='This event' /> 
                    {/* <FormControlLabel value='future' control={<Radio />} label='This and future events' /> */}
                    <FormControlLabel value='all' control={<Radio />} label='All events' />
                </RadioGroup>
            </DialogContent>

            <DialogActions className={styles.DialogActions}>
                <Button
                    variant='contained'
                    color='secondary'
                    onClick={props.handleClose}
                    className={styles.Button}>
                    Close
                    </Button>
                <div className={styles.ActionButtonWrapper}>
                    <Button
                        variant='contained'
                        color='primary'
                        onClick={props.handleConfirmation}
                        className={styles.Button}>
                        OK
                        </Button>
                    {<Loader
                        loading={isLoading()}
                        size='small'
                        center
                        light />}
                </div>
            </DialogActions>
        </Dialog>
    );
};
export default React.memo(BoardroomRecurrenceUpdate);