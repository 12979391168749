import React, {useEffect, useState} from "react";
import { connect } from "react-redux";
import MaterialTable from "material-table";
import CustomAction from "../../../../components/Table/CustomAction";
import styled, {css} from "styled-components";
import SplitButton from "../../../../components/Buttons/SplitButton/SplitButton";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import FilterAutocomplete from "../../../../components/Inputs/Filter/FilterAutocomplete";
import Button from "@material-ui/core/Button";
import {Autorenew, Done, Warning, Clear} from "@material-ui/icons";
import isEqual from "lodash/isEqual";
import {getFilteredData} from "../../../../assets/helpers/globals";
import ConfirmationDialog from "../../../../components/Dialogs/ConfirmationDialog/ConfirmationDialog";

import * as purchaseActions from "../../../../store/purchases/actions";
import {getAllProjects} from "../../../../store/projects/actions";
import {getCurrentUser} from "../../../../store/user/actions";
import {getAllTenders} from "../../../../store/tenders/actions";
import * as loadingTypes from "../../../../store/loading/types";
import {downloadFile} from "../../../../store/file/actions";
import {getAllLegalEntities} from "../../../../store/legalEntities/actions";
import {getVendorsBySupplier} from "../../../../store/vendors/actions";

import NewProjectPurchaseRequestDialog from "../Project/NewRequestDialog";
import PurchaseProjectDetailsDialog from "../Project/DetailsDialog"
import PurchaseProjectResponseDialog from "../Project/ResponseDialog"
import PurchaseTenderDetailsDialog from "../Tender/DetailsDialog";
import NewPurchaseTenderRequestDialog from "../Tender/NewRequestDialog";
import PurchaseTenderResponseDialog from "../Tender/ResponseDialog"
import PurchaseItEquipmentDetailsDialog from "../IT/DetailsDialog";
import NewPurchaseItEquipmentRequestDialog from "../IT/NewRequestDialog";
import PurchaseItEquipmentResponseDialog from "../IT/ResponseDialog"
import PurchaseOfficeEquipmentDetailsDialog from "../Office/DetailsDialog";
import NewPurchaseOfficeEquipmentRequestDialog from "../Office/NewRequestDialog";
import PurchaseOfficeEquipmentResponseDialog from "../Office/ResponseDialog"
import PurchaseOtherDetailsDialog from "../Other/DetailsDialog";
import NewPurchaseOtherRequestDialog from "../Other/NewRequestDialog";
import PurchaseOtherResponseDialog from "../Other/ResponseDialog"
import {getNextApprovers, getPermissionsByRole} from "../../../../store/permissions/actions";
import debounce from "lodash/debounce";
import AuditLoggingTableDialog from "../../../Audit/TableDialog";
import {getAuditLog, getTransactionSubTypes} from "../../../../store/audit/actions";
import {getCurrenciesByLegalEntity} from "../../../../store/currencies/actions";
import {useInterval} from "../../../../hooks/useInterval";
import {getTaxOptions} from "../../../../store/tax/actions";

const ApprovedIcon = styled(Done)`
  display: none;
  ${props => props.status === "Approved" && css`
    margin-top: -1px;
    margin-right: 2px;
    display: flex;
    color: #388e3c;
  `}
`;

const RejectedIcon = styled(Warning)`
  display: none;
  ${props => props.status === "Rejected" && css`
    margin-top: -1px;
    margin-right: 2px;
    display: flex;
    color: #d32f2f;
  `}
`;

const AwaitingApprovalIcon = styled(Autorenew)`
  display: none;
  ${props => props.status === "Awaiting Approval" && css`
    margin-top: -1px;
    margin-right: 2px;
    display: flex;
    color: #f57c00;
  `}
`;

const AwaitingSecondApprovalIcon = styled(Autorenew)`
  display: none;
  ${props => props.status === "Awaiting 2nd Approval" && css`
    margin-top: -1px;
    margin-right: 2px;
    display: flex;
    color: #f57c00;
  `}
`;

const P = styled.p`
  color: #f57c00;
  border: 1px solid #ff9800;
  border-radius: 16px;
  height: 24px;
  padding-left: 4px;
  padding-right: 8px;
  white-space: nowrap;
  display: flex;
  max-width: fit-content;

  ${props => props.status === "Approved" && css`
    color: #388e3c;
    border: 1px solid #4caf50;
  `}
  ${props => props.status === "Rejected" && css`
    color: #d32f2f;
    border: 1px solid #f44336;
  `}
`;

const FilterPaper = styled(Paper)`
  margin-bottom: 18px;
  margin-top: 14px;
`;

const FilterGrid = styled(Grid)`
  ${props => props.container && css`
    margin-left: 10px; 
    width: 98%;
  `}
  ${props => props.item && css`
    align-self: center
  `}
`;

const AllPurchases = ({
                        //state
                          user,
                          loading,
                          allPurchases,
                          projects,
                          tenders,
                          legalEntities,
                          vendorsBySupplier,
                          auditLog,
                          transactionSubTypes,
                          currenciesByLegalEntity,
                          taxOptions,

                        //actions
                          getCurrentUser,
                          downloadFile,

                          getAllLegalEntities,
                          getVendorsBySupplier,

                          getAllPurchases,
                          addPurchaseProjectRequest,
                          deletePurchaseProjectRequest,
                          updatePurchaseProjectRequest,
                          updatePurchaseProjectRequestStatus,
                          getAllProjects,

                          addPurchaseTenderRequest,
                          deletePurchaseTenderRequest,
                          updatePurchaseTenderRequest,
                          updatePurchaseTenderRequestStatus,
                          getAllTenders,

                          addPurchaseItEquipmentRequest,
                          deletePurchaseItEquipmentRequest,
                          updatePurchaseItEquipmentRequest,
                          updatePurchaseItEquipmentRequestStatus,

                          addPurchaseOfficeEquipmentRequest,
                          deletePurchaseOfficeEquipmentRequest,
                          updatePurchaseOfficeEquipmentRequest,
                          updatePurchaseOfficeEquipmentRequestStatus,

                          addPurchaseOtherRequest,
                          deletePurchaseOtherRequest,
                          updatePurchaseOtherRequest,
                          updatePurchaseOtherRequestStatus,
                          getUserRoles,
                          getNextApprovers,
                          getAuditLog,
                          getCurrenciesByLegalEntity,
                          getTaxOptions,
                          getTransactionSubTypes
                          }) => {
    const initialDialogState = { open: false, type: "" };
    const initialDetailsState = { open: false, type: "", data: {} };
    const initialResponseState = { open: false, type: "", data: {} };
    const initialFilterState = { status: null, name: null, vendorName: null, totalCost: null, actions: null, taxOption: null, entityName: null };

    const [addDialogOpen, setAddDialogOpen] = useState(initialDialogState);
    const [editDialogOpen, setEditDialogOpen] = useState(initialDialogState);
    const [purchaseDetails, setPurchaseDetails] = useState(initialDetailsState);
    const [purchaseResponse, setPurchaseResponse] = useState(initialResponseState);
    const [editing, setEditing] = useState(undefined);
    const [selectedPurchaseRequest, setSelectedPurchaseRequest] = useState([]);
    const [deletePurchaseRequestConfirmationOpen, setDeletePurchaseRequestConfirmationOpen] = useState(false);
    const [auditLogDetails, setAuditLogDetails] = useState(undefined);
    const [auditLogDialogOpen, setAuditLogDialogOpen] = useState({open: false, type: ""});
    const [filter, setFilter] = useState(initialFilterState);
    const [roles, setRoles] = useState({
        is_purchase_project_2nd_50k_approver: false,
        is_purchase_project_requestor: false,
        is_purchase_project_1st_workshop_approver: false,
        is_purchase_project_1st_approver: false,
        is_purchase_project_admin: false,
        is_purchase_tender_2nd_50k_approver: false,
        is_purchase_tender_1st_approver: false,
        is_purchase_tender_requestor: false,
        is_purchase_it_requestor: false,
        is_purchase_it_approver: false,
        is_purchase_office_requestor: false,
        is_purchase_office_approver: false,
        is_purchase_other_approver: false,
        is_purchase_other_requestor: false,
    })

    const getPurchaseOptions = () => {
        let options = [];

        if (roles.is_purchase_project_requestor)
            options.push("Add Project Purchase")
        if (roles.is_purchase_tender_requestor)
            options.push("Add Tender Purchase")
        if (roles.is_purchase_it_requestor)
            options.push("Add IT Equipment Purchase")
        if (roles.is_purchase_office_requestor)
            options.push("Add Office Equipment Purchase")
        if (roles.is_purchase_other_requestor)
            options.push("Add Other Purchase")

        return options;
    }

    const actions = [
        {
            icon: "visibility",
            tooltip: "View Request",
            onClick: async (_event, selectedPurchase) => {
                const res = await getNextApprovers(selectedPurchase.purchase.purchaseType.tableName, selectedPurchase.uniqueId)

                setPurchaseDetails({
                    open: true,
                    type: selectedPurchase.purchase.purchaseType.typeName,
                    data: {...selectedPurchase, nextApprovers : res}
                })
            }
        },
        rowData =>
            ({
                icon: "reply",
                tooltip: "Respond to Request",
                onClick: (_event, selectedPurchase) =>
                    setPurchaseResponse({
                        open: true,
                        type: selectedPurchase.purchase.purchaseType.typeName,
                        data: selectedPurchase
                    }),
                hidden: !(
                    //region Project Roles
                    (roles.is_purchase_project_admin &&
                        rowData.purchase.purchaseType.typeName === "Project") ||

                    (roles.is_purchase_project_2nd_50k_approver &&
                        rowData.purchase.purchaseType.typeName === "Project" &&
                        rowData.purchase?.totalCostExchange > 50000 &&
                        rowData.purchase?.purchaseLevel === 2 &&
                        rowData.purchase.status !== "Approved") ||

                    (roles.is_purchase_project_1st_approver &&
                        rowData.purchase.purchaseType.typeName === "Project" &&
                        // rowData.workshop === "" &&
                        rowData.purchase?.purchaseLevel === 1 &&
                        rowData.purchase.status !== "Approved") ||

                    (roles.is_purchase_project_1st_workshop_approver &&
                        rowData.purchase.purchaseType.typeName === "Project" &&
                        rowData.workshop === "Roodekop" &&
                        rowData.purchase?.purchaseLevel === 1 &&
                        rowData.purchase.status !== "Approved") ||
                    //endregion

                    //region Tender roles
                    (roles.is_purchase_tender_2nd_50k_approver &&
                        rowData.purchase.purchaseType.typeName === "Tender" &&
                        rowData.purchase?.totalCostExchange > 50000 &&
                        rowData.purchase?.purchaseLevel === 2 &&
                        rowData.purchase.status !== "Approved") ||

                    (roles.is_purchase_tender_1st_approver &&
                        rowData.purchase.purchaseType.typeName === "Tender" &&
                        rowData.purchase?.purchaseLevel === 1 &&
                        rowData.purchase.status !== "Approved") ||
                    //endregion

                    //region IT Roles

                    (roles.is_purchase_it_approver &&
                    rowData.purchase.purchaseType.typeName === "It" &&
                    rowData.purchase.status !== "Approved") ||
                    //endregion

                    //region Office Roles
                    (roles.is_purchase_office_approver &&
                    rowData.purchase.purchaseType.typeName === "Office" &&
                    rowData.purchase.status !== "Approved") ||
                    //endregion

                    //region Other Roles
                    (roles.is_purchase_other_approver &&
                        rowData.purchase.purchaseType.typeName === "Other" &&
                        rowData.purchase.status !== "Approved")
                    //endregion
                )
            }),
        rowData =>
            ({
                icon: "edit",
                tooltip: "Edit Request",
                hidden: !(rowData.purchase.requestor.userEmail === user.userEmail && (rowData.purchase.status === "Awaiting Approval" || rowData.purchase.status === "Rejected")),
                onClick: async (_event, selectedPurchase) => {
                    const defaultFields = await setEditDialogDefaultPurchasePayload(selectedPurchase);
                    switch (selectedPurchase.purchase.purchaseType.typeName) {
                        case "Project":
                            // if (!projects || !projects.length)
                                await getAllProjects("Registered");

                            setEditing({
                                ...defaultFields,
                                project: selectedPurchase.workshop === "" ? selectedPurchase.projectsDetails[0].project : "",
                                projectManager: selectedPurchase.workshop === "" ? selectedPurchase.projectsDetails[0]?.project?.projectManager?.userFullName : "",
                                isWorkshop: selectedPurchase.workshop !== ""
                            })
                            setEditDialogOpen({open: true, type: selectedPurchase.purchase.purchaseType.typeName});
                            break;
                        case "Tender":
                            if (!tenders || !tenders.length)
                                await getAllTenders("Registered");
                            setEditing({
                                ...defaultFields,
                                tenderType: selectedPurchase.tender ? "tender" : "tenderAdmin",
                            });
                            setEditDialogOpen({open: true, type: selectedPurchase.purchase.purchaseType.typeName});
                            break;
                        default:
                            setEditDialogOpen({open: true, type: selectedPurchase.purchase.purchaseType.typeName});
                            break;
                    }
                },
            }),
        rowData => ({
            icon: "delete",
            tooltip: "Cancel Request",
            onClick: async (_event, selectedPurchase) => {
                setSelectedPurchaseRequest(selectedPurchase);
                setDeletePurchaseRequestConfirmationOpen(true)
            },
            hidden: !(rowData.purchase.requestor.userEmail === user.userEmail && rowData.purchase.status === "Awaiting Approval")
        }),
        {
            icon: "history",
            tooltip: "View Request History",
            onClick: async (_event, selectedPurchase) => {
                if (!isEqual(selectedPurchase, auditLogDetails)) {
                    let typeId

                    if (!transactionSubTypes || !transactionSubTypes.length) {
                        const res = await getTransactionSubTypes();
                        typeId = res.data.find(transactionSubType => transactionSubType.subTypeName === selectedPurchase.purchase?.purchaseType?.typeName).uniqueId;
                    } else
                        typeId = transactionSubTypes.find(transactionSubType => transactionSubType.subTypeName === selectedPurchase.purchase?.purchaseType?.typeName).uniqueId;

                    await getAuditLog(selectedPurchase.purchase?.uniqueId, typeId);
                    setAuditLogDetails(selectedPurchase)
                    setAuditLogDialogOpen({open: true, type: "Purchase"})
                }
                else
                    setAuditLogDialogOpen({open: true, type: "Purchase"})
            }
        },
        {
            icon: 'refresh',
            tooltip: 'Refresh Data',
            isFreeAction: true,
            onClick: debounce(getAllPurchases, 300, {
                'leading': false,
                'trailing': true
            }),
        },
        {
            icon: "add",
            isFreeAction: true,
            custom: true,
            component: SplitButton,
            onClick: () => {},
            props: {
                disabled: !(roles.is_purchase_project_requestor ||
                    roles.is_purchase_tender_requestor ||
                    roles.is_purchase_it_requestor  ||
                    roles.is_purchase_office_requestor ||
                    roles.is_purchase_other_requestor),
                containerStyle: { display: (roles.is_purchase_project_requestor ||
                        roles.is_purchase_tender_requestor ||
                        roles.is_purchase_it_requestor  ||
                        roles.is_purchase_office_requestor ||
                        roles.is_purchase_other_requestor) ? "table-header-group" : "none" },
                variant: "contained",
                color: "primary",
                options: getPurchaseOptions(),
                open: addDialogOpen,
                setOpen: setAddDialogOpen,
                handleClickParent: async (event, selectedIndex) => setAddDialogPayload(selectedIndex),
                handleMenuItemClickParent: async (event, selectedIndex) => setAddDialogPayload(selectedIndex),
            },
        },
    ];

    const setAddDialogDefaultPayload = () => {
        if (!legalEntities || !legalEntities.length)
            getAllLegalEntities();
        if (!taxOptions || !taxOptions.length)
            getTaxOptions();
    }

    const setAddDialogPayload = (selectedIndex) => {
        setAddDialogDefaultPayload();
        switch (selectedIndex) {
            case 0:
                // if (!projects || !projects.length)
                    getAllProjects("Registered");
                setAddDialogOpen({open: true, type: "Project"})
                break;
            case 1:
                if (!tenders || !tenders.length)
                    getAllTenders("Registered");
                setAddDialogOpen({open: true, type: "Tender"})
                break;
            case 2:
                setAddDialogOpen({open: true, type: "It"})
                break;
            case 3:
                setAddDialogOpen({open: true, type: "Office"})
                break;
            case 4:
                setAddDialogOpen({open: true, type: "Other"})
                break;
            default:
                break;
        }
    }

    const setEditDialogDefaultPurchasePayload = async selectedPurchase => {
        if (!legalEntities || !legalEntities.length)
            await getAllLegalEntities()
        if (!taxOptions || !taxOptions.length)
            await getTaxOptions();
        if (selectedPurchase.purchase.vendor)
            await getVendorsBySupplier(selectedPurchase.purchase.vendor.legalEntities.uniqueId);
        else
            await getVendorsBySupplier(selectedPurchase.purchase.legalEntity.uniqueId)

        await getCurrenciesByLegalEntity(selectedPurchase.purchase.legalEntity.uniqueId);

        const purchaseObject = {
            ...selectedPurchase,
            vendor: selectedPurchase.purchase.vendor ? selectedPurchase.purchase.vendor : "",
            legalEntity: selectedPurchase.purchase.legalEntity,
            vendorOther: selectedPurchase.purchase.vendorOther ? selectedPurchase.purchase.vendorOther : "",
            vendorType: selectedPurchase.purchase.vendor ? "existingVendor" : "otherVendor",
            description: selectedPurchase.purchase.description,
            attachments: selectedPurchase.purchase.attachments,
            totalCost: selectedPurchase.purchase.totalCost,
            currency: selectedPurchase.purchase.currency,
            xeroTaxOption: selectedPurchase.purchase.xeroTaxOption,
            quoteNumber: selectedPurchase.purchase.quoteNumber
        }

        setEditing(purchaseObject)

        return purchaseObject
    }

    const filterData = (data, query) => {
        return data.filter((item) => {
            for (let key in query) {
                switch (key) {
                    case "entityName":
                        if (item.purchase.legalEntity === null || item.purchase.legalEntity[key] === undefined || !query[key].includes(item.purchase.legalEntity[key])) {
                            return false;
                        }
                        break;
                    case "vendorName":
                        if (item.purchase.vendor === null || item.purchase.vendor[key] === undefined || !query[key].includes(item.purchase.vendor[key]))
                            return false;
                        break;
                    case "name":
                        if (item.purchase.currency === null || item.purchase.currency[key] === undefined || !query[key].includes(item.purchase.currency[key]))
                            return false;
                        break;
                    case "totalCost":
                        if (item.purchase[key] === undefined || item.purchase[key] === null) {
                            return false;
                        }
                        if (query[key]['value']['min'] !== null && item.purchase[key] < query[key]['value']['min']) {
                            return false;
                        }
                        if (query[key]['value']['max'] !== null && item.purchase[key] >= query[key]['value']['max']) {
                            return false;
                        }
                        break;
                    case "actions":
                        if (query[key]['value'] === "approve") {
                            if(actions[1](item).hidden)
                                return false;
                        } else if ( !(item.purchase.requestor.userEmail === user.userEmail) )
                            return false;
                        break;
                    case "taxOption":
                        if (item.purchase.xeroTaxOption === null || item.purchase.xeroTaxOption[key] === undefined || !query[key].includes(item.purchase.xeroTaxOption[key]))
                            return false;
                        break;
                    default:
                        if (item.purchase[key] === undefined || !query[key].includes(item.purchase[key]))
                            return false;
                        break;
                }
            }
            return true;
        });
    };

    useInterval(() => {
        getAllPurchases();
    }, 300000);

    useEffect(() => {
        (async function() {
            const res = await getUserRoles("purchase","");
            const permissionObj = {
                is_purchase_project_2nd_50k_approver: res.some(name => name === "purchase_project_2nd_50k_approver"),
                is_purchase_project_requestor: res.some(name => name === "purchase_project_requestor"),
                is_purchase_project_1st_workshop_approver: res.some(name => name === "purchase_project_1st_workshop_approver"),
                is_purchase_project_1st_approver: res.some(name => name === "purchase_project_1st_approver"),
                is_purchase_project_admin: res.some(name => name === "purchase_project_admin"),

                is_purchase_tender_2nd_50k_approver: res.some(name => name === "purchase_tender_2nd_50k_approver"),
                is_purchase_tender_1st_approver: res.some(name => name === "purchase_tender_1st_approver"),
                is_purchase_tender_requestor: res.some(name => name === "purchase_tender_requestor"),

                is_purchase_it_requestor: res.some(name => name === "purchase_it_requestor"),
                is_purchase_it_approver: res.some(name => name === "purchase_it_approver"),

                is_purchase_office_requestor: res.some(name => name === "purchase_office_requestor"),
                is_purchase_office_approver: res.some(name => name === "purchase_office_approver"),

                is_purchase_other_approver: res.some(name => name === "purchase_other_approver"),
                is_purchase_other_requestor: res.some(name => name === "purchase_other_requestor"),
            }

            setRoles(permissionObj);

            if (Object.entries(permissionObj).some(([key, value]) =>
                key !== "is_purchase_project_requestor" &&
                key !== "is_purchase_tender_requestor" &&
                key !== "is_purchase_it_requestor" &&
                key !== "is_purchase_office_requestor" &&
                key !== "is_purchase_other_requestor" &&
                value === true)
            )
                setFilter(f => ({...f, actions: {description: "Awaiting Your Approval", value: "approve"}}))
        })();

        if (!user.userEmail)
            getCurrentUser();
        getAllPurchases();
    }, [getCurrentUser, getAllPurchases, getUserRoles, user.userEmail])

    return (
        <>
            <FilterPaper>
                <FilterGrid container spacing={2}>
                    <FilterGrid item xs={12} sm={6} md={4} lg={3}>
                        <FilterAutocomplete
                            onChange={(event, newValue) => setFilter({...filter, status: newValue})}
                            value={filter.status}
                            options={Object.values(allPurchases.purchases || {}).reduce((prev, current) => [...prev, ...current], [])
                                .map(purchaseItem => purchaseItem.purchase.status)}
                            placeholder="Status"
                            noOptionsText="No Statuses"
                        />
                    </FilterGrid>
                    <FilterGrid item xs={12} sm={6} md={4} lg={3}>
                        <FilterAutocomplete
                            onChange={(event, newValue) => setFilter({...filter, entityName: newValue})}
                            value={filter.entityName}
                            options={Object.values(allPurchases.purchases || {}).reduce((prev, current) => [...prev, ...current], [])
                                .map(purchaseItem => purchaseItem.purchase?.legalEntity?.entityName)}
                            placeholder="Legal Entity"
                            noOptionsText="No Legal Entities"
                        />
                    </FilterGrid>
                    <FilterGrid item xs={12} sm={6} md={4} lg={3}>
                        <FilterAutocomplete
                            onChange={(event, newValue) => setFilter({...filter, vendorName: newValue})}
                            value={filter.vendorName}
                            options={ Object.values(allPurchases.purchases || {}).reduce((prev, current) => [...prev, ...current], [])
                                .map(purchaseItem => purchaseItem.purchase?.vendor !== null ? purchaseItem.purchase.vendor.vendorName : '')
                                .filter((purchaseItem, index, array) => array.indexOf(purchaseItem) === index && purchaseItem !== '')}
                            placeholder="Supplier"
                            noOptionsText="No Suppliers"
                        />
                    </FilterGrid>
                    <FilterGrid item xs={12} sm={6} md={4} lg={3}>
                        <FilterAutocomplete
                            onChange={(event, newValue) => setFilter({...filter, name: newValue})}
                            value={filter.name}
                            options={Object.values(allPurchases.purchases || {}).reduce((prev, current) => [...prev, ...current], [])
                                .map(purchaseItem => purchaseItem.purchase?.currency?.name)}
                            placeholder="Currency"
                            noOptionsText="No Currencies"
                        />
                    </FilterGrid>
                    <FilterGrid item xs={12} sm={6} md={4} lg={3}>
                        <FilterAutocomplete
                            onChange={(event, newValue) => setFilter(filter => ({...filter, totalCost: newValue}))}
                            value={filter.totalCost}
                            options={[
                                {description: "Quote less than 50K", value: {min: 0, max: 50000}},
                                {description: "Quote greater than 50K", value: {min: 50000, max: null}},
                            ]}
                            placeholder="Quote"
                            noOptionsText="No Quotes"
                        />
                    </FilterGrid>
                    <FilterGrid item xs={12} sm={6} md={4} lg={3}>
                        <FilterAutocomplete
                            onChange={(event, newValue) => setFilter({...filter, taxOption: newValue})}
                            value={filter.taxOption}
                            options={Object.values(allPurchases.purchases || {}).reduce((prev, current) => [...prev, ...current], [])
                                .map(purchaseItem => purchaseItem.purchase?.xeroTaxOption?.taxOptionText)}
                            placeholder="Tax"
                            noOptionsText="No Tax Options"
                        />
                    </FilterGrid>
                    <FilterGrid item xs={12} sm={6} md={4} lg={3}>
                        <FilterAutocomplete
                            onChange={(event, newValue) => setFilter(filter => ({...filter, actions: newValue}))}
                            value={filter.actions}
                            options={[
                                {description: "Your Purchases", value: "own"},
                                {description: "Awaiting Your Approval", value: "approve"},
                            ]}
                            placeholder="Actions"
                            noOptionsText="No Actions"
                        />
                    </FilterGrid>
                    <FilterGrid item xs={12} sm={6} md={4} lg={3}>
                        <Button
                            color="secondary"
                            size="small"
                            startIcon={<Clear/>}
                            disabled={isEqual(filter, initialFilterState)}
                            onClick={() => setFilter(initialFilterState)}>
                            Clear All
                        </Button>
                    </FilterGrid>
                </FilterGrid>
            </FilterPaper>

            <MaterialTable
                columns={[
                    {
                        title: "Submitted Date",
                        field: "purchase.createdDate",
                        type: "date",
                    },
                    {
                        title: "Modified Date",
                        field: "purchase.lastModifiedDate",
                        type: "date",
                        defaultSort: "desc",
                        customSort: (a, b) => {
                            let d1 = new Date(a.purchase.lastModifiedDate);
                            let d2 = new Date(b.purchase.lastModifiedDate);
                            return d1.getTime() - d2.getTime();
                        }
                    },
                    {
                        title: "Legal Entity",
                        field: "purchase.legalEntity.entityName",
                    },
                    {
                        title: "Supplier",
                        field: "vendor.vendorName",
                        render: rowData => rowData.purchase.vendor ? <span>{rowData.purchase.vendor.vendorName}</span> :
                            <span>{rowData.purchase.vendorOther}</span>,
                    },
                    {
                        title: "Amount",
                        field: "purchase.totalCost",
                        align: "right",
                    },
                    {
                        title: "Currency",
                        field: "purchase.currency.name",
                    },
                    {
                        title: "Tax",
                        field: "purchase.xeroTaxOption.taxOptionText",
                    },
                    {
                        title: "Quote Number",
                        field: "purchase.quoteNumber"
                    },
                    {
                        title: "Requestor",
                        field: "purchase.requestor.userFullName",
                    },
                    {
                        title: "Purchase Type",
                        field: "purchase.purchaseType.typeName",
                        render: rowData => <span>{rowData.purchase.purchaseType.typeName}</span>
                    },
                    {
                        title: "Status",
                        field: "purchase.status",
                        render: data => (
                            <P status={data.purchase.status}>
                                <ApprovedIcon status={data.purchase.status}/>
                                <RejectedIcon status={data.purchase.status}/>
                                <AwaitingApprovalIcon status={data.purchase.status}/>
                                <AwaitingSecondApprovalIcon status={data.purchase.status}/>{data.purchase.status}</P>
                        ),
                    }
                ]}
                actions={actions}
                components={{ Action: CustomAction }}
                data={isEqual(filter, initialFilterState) ? Object.values(allPurchases.purchases || {}).reduce((prev, current) => [...prev, ...current], [])
                    : getFilteredData(filter, Object.values(allPurchases.purchases || {}).reduce((prev, current) => [...prev, ...current], []), [], filterData)}
                isLoading={addDialogOpen.open === false &&
                        editDialogOpen.open === false && (
                        loading.type === loadingTypes.purchasesGetAllPurchases ||
                        loading.type === loadingTypes.projectsGetAllProjects ||
                        loading.type === loadingTypes.tendersGetAllTenders ||
                        loading.type === loadingTypes.legalEntitiesGetAllLegalEntities ||
                        loading.type === loadingTypes.taxGetTaxOptions ||
                        loading.type === loadingTypes.vendorsGetVendorsBySupplier ||
                        loading.type === loadingTypes.currenciesGetCurrenciesByLegalEntity ||
                        loading.type === loadingTypes.auditGetTransactionSubTypes ||
                        loading.type === loadingTypes.auditGetAuditLog ||
                        loading.type === loadingTypes.permissionsGetNextApprovers ||
                        loading.type === loadingTypes.permissionsGetPermissionsByRole)
                }
                options={{
                    actionsColumnIndex: -1,
                    pageSize: 10,
                    searchFieldAlignment: "left",
                    showTitle: false,
                    isLoading: true,
                    search: true,
                    // thirdSortClick: false,
                    // loadingType: "linear",
                    toolbarButtonAlignment: "right",
                    sorting: true
                }}
            />

            <PurchaseProjectDetailsDialog
                key={"Purchase-Project-Details-" + purchaseDetails?.data?.uniqueId}
                details={purchaseDetails.data}
                onClose={() => setPurchaseDetails(initialDetailsState)}
                open={purchaseDetails.open && purchaseDetails.type === "Project"}
                isAdmin={
                    (roles.is_purchase_project_admin &&
                        purchaseDetails?.data?.purchase?.purchaseType.typeName === "Project") ||

                    (roles.is_purchase_project_2nd_50k_approver &&
                        purchaseDetails?.data?.purchase?.purchaseType.typeName === "Project" &&
                        purchaseDetails?.data?.purchase?.totalCostExchange > 50000 &&
                        purchaseDetails?.data?.purchase?.purchaseLevel === 2 &&
                        purchaseDetails?.data?.purchase?.status !== "Approved") ||

                    (roles.is_purchase_project_1st_approver &&
                        purchaseDetails?.data?.purchase?.purchaseType.typeName === "Project" &&
                        // purchaseDetails?.data?.workshop === "" &&
                        purchaseDetails?.data?.purchase?.purchaseLevel === 1 &&
                        purchaseDetails?.data?.purchase?.status !== "Approved") ||

                    (roles.is_purchase_project_1st_workshop_approver &&
                        purchaseDetails?.data?.purchase?.purchaseType.typeName === "Project" &&
                        purchaseDetails?.data?.workshop === "Roodekop" &&
                        purchaseDetails?.data?.purchase?.purchaseLevel === 1 &&
                        purchaseDetails?.data?.purchase?.status !== "Approved")
                }
                isProjectAdmin={roles.is_purchase_project_admin}
                isWorkshop={roles.is_purchase_project_1st_workshop_approver}
                userEmail={user.userEmail}
                downloadFile={downloadFile}
                onSubmit={async updatedPurchaseRequest => {
                    await updatePurchaseProjectRequestStatus(purchaseDetails.data.uniqueId, updatedPurchaseRequest);
                    getAllPurchases();
                    setPurchaseDetails(initialDetailsState);
                }}
                loading={loading}
            />
            <PurchaseProjectResponseDialog
                key={"Purchase-Project-Respond-" + purchaseResponse.data?.uniqueId}
                details={purchaseResponse.data}
                onClose={() => setPurchaseResponse(initialResponseState)}
                open={purchaseResponse.open && purchaseResponse.type === "Project"}
                // isAdmin={
                //     (roles.is_purchase_project_admin &&
                //         purchaseResponse?.data?.purchase?.purchaseType.typeName === "Project") ||
                //
                //     (roles.is_purchase_project_2nd_50k_approver &&
                //         purchaseResponse?.data?.purchase?.purchaseType.typeName === "Project" &&
                //         purchaseResponse?.data?.purchase?.totalCostExchange > 50000 &&
                //         purchaseResponse?.data?.purchase?.purchaseLevel === 2 &&
                //         purchaseResponse?.data?.purchase?.status !== "Approved") ||
                //
                //     (roles.is_purchase_project_1st_approver &&
                //         purchaseResponse?.data?.purchase?.purchaseType.typeName === "Project" &&
                //         purchaseResponse?.data?.workshop === "" &&
                //         purchaseResponse?.data?.purchase?.purchaseLevel === 1 &&
                //         purchaseResponse?.data?.purchase?.status !== "Approved") ||
                //
                //     (roles.is_purchase_project_1st_workshop_approver &&
                //         purchaseResponse?.data?.purchase?.purchaseType.typeName === "Project" &&
                //         purchaseResponse?.data?.workshop === "Roodekop" &&
                //         purchaseResponse?.data?.purchase?.purchaseLevel === 1 &&
                //         purchaseResponse?.data?.purchase?.status !== "Approved")
                // }
                isProjectAdmin={roles.is_purchase_project_admin}
                isWorkshop={roles.is_purchase_project_1st_workshop_approver}
                userEmail={user.userEmail}
                onSubmit={async (updatedPurchaseRequest) => {
                    await updatePurchaseProjectRequestStatus(purchaseResponse.data.uniqueId, updatedPurchaseRequest);
                    getAllPurchases();
                    setPurchaseResponse(initialResponseState);
                }}
                loading={loading}
            />
            <NewProjectPurchaseRequestDialog
                open={addDialogOpen.open && addDialogOpen.type === "Project"}
                onClose={() => setAddDialogOpen(initialDialogState)}
                onSubmit={async newPurchase => {
                    await addPurchaseProjectRequest(newPurchase);
                    getAllPurchases();
                    setAddDialogOpen(initialDialogState);
                }}
                loading={loading}
                projects={projects}
                getVendorsBySupplier={getVendorsBySupplier}
                vendorsBySupplier={vendorsBySupplier}
                legalEntities={legalEntities}
                getCurrenciesByLegalEntity={getCurrenciesByLegalEntity}
                currenciesByLegalEntity={currenciesByLegalEntity}
                taxOptions={taxOptions}
            />
            <NewProjectPurchaseRequestDialog
                key={"Purchase-Project-Edit-" + editing?.uniqueId}
                open={editDialogOpen.open && editDialogOpen.type === "Project"}
                onClose={() => setEditDialogOpen(initialDialogState)}
                initialValues={editing}
                onSubmit={async updatedPurchase => {
                    await updatePurchaseProjectRequest(editing?.uniqueId, updatedPurchase);
                    getAllPurchases();
                    setEditDialogOpen(initialDialogState);
                }}
                loading={loading}
                projects={projects}
                getVendorsBySupplier={getVendorsBySupplier}
                vendorsBySupplier={vendorsBySupplier}
                legalEntities={legalEntities}
                getCurrenciesByLegalEntity={getCurrenciesByLegalEntity}
                currenciesByLegalEntity={currenciesByLegalEntity}
                taxOptions={taxOptions}
            />

            <PurchaseTenderDetailsDialog
                key={"Purchase-Tender-Details-" + purchaseDetails?.data?.uniqueId}
                details={purchaseDetails.data}
                onClose={() => setPurchaseDetails(initialDetailsState)}
                open={purchaseDetails.open && purchaseDetails.type === "Tender"}
                isAdmin={(roles.is_purchase_tender_2nd_50k_approver &&
                    purchaseDetails?.data?.purchase?.purchaseType.typeName === "Tender" &&
                    purchaseDetails?.data?.purchase?.totalCostExchange > 50000 &&
                    purchaseDetails?.data?.purchase?.purchaseLevel === 2 &&
                    purchaseDetails?.data?.purchase?.status !== "Approved") ||
                (roles.is_purchase_tender_1st_approver &&
                    purchaseDetails?.data?.purchase?.purchaseType.typeName === "Tender" &&
                    purchaseDetails?.data?.purchase?.purchaseLevel === 1 &&
                    purchaseDetails?.data?.purchase?.status !== "Approved")}
                downloadFile={downloadFile}
                onSubmit={async updatedPurchaseRequest => {
                    await updatePurchaseTenderRequestStatus(purchaseDetails.data.uniqueId, updatedPurchaseRequest);
                    getAllPurchases();
                    setPurchaseDetails(initialDetailsState);
                }}
                loading={loading}
            />
            <PurchaseTenderResponseDialog
                key={"Purchase-Tender-Respond-" + purchaseResponse.data?.uniqueId}
                details={purchaseResponse.data}
                onClose={() => setPurchaseResponse(initialResponseState)}
                open={purchaseResponse.open && purchaseResponse.type === "Tender"}
                onSubmit={async (updatedPurchaseRequest) => {
                    await updatePurchaseTenderRequestStatus(purchaseResponse.data.uniqueId, updatedPurchaseRequest);
                    getAllPurchases();
                    setPurchaseResponse(initialResponseState);
                }}
                loading={loading}
            />
            <NewPurchaseTenderRequestDialog
                open={addDialogOpen.open && addDialogOpen.type === "Tender"}
                onClose={() => setAddDialogOpen(initialDialogState)}
                onSubmit={async newPurchase => {
                    await addPurchaseTenderRequest(newPurchase);
                    getAllPurchases();
                    setAddDialogOpen(initialDialogState);
                }}
                loading={loading}
                tenders={tenders}
                getVendorsBySupplier={getVendorsBySupplier}
                vendorsBySupplier={vendorsBySupplier}
                legalEntities={legalEntities}
                getCurrenciesByLegalEntity={getCurrenciesByLegalEntity}
                currenciesByLegalEntity={currenciesByLegalEntity}
                taxOptions={taxOptions}
            />
            <NewPurchaseTenderRequestDialog
                key={"Purchase-Tender-Edit-" + editing?.uniqueId}
                open={editDialogOpen.open && editDialogOpen.type === "Tender"}
                onClose={() => setEditDialogOpen(initialDialogState)}
                initialValues={editing}
                onSubmit={async updatedPurchase => {
                    await updatePurchaseTenderRequest(editing?.uniqueId, updatedPurchase);
                    getAllPurchases();
                    setEditDialogOpen(initialDialogState);
                }}
                loading={loading}
                tenders={tenders}
                getVendorsBySupplier={getVendorsBySupplier}
                vendorsBySupplier={vendorsBySupplier}
                legalEntities={legalEntities}
                getCurrenciesByLegalEntity={getCurrenciesByLegalEntity}
                currenciesByLegalEntity={currenciesByLegalEntity}
                taxOptions={taxOptions}
            />

            <PurchaseItEquipmentDetailsDialog
                key={"Purchase-It-Equipment-Details-" + purchaseDetails?.data?.uniqueId}
                details={purchaseDetails.data}
                onClose={() => setPurchaseDetails(initialDetailsState)}
                open={purchaseDetails.open && purchaseDetails.type === "It"}
                isAdmin={(roles.is_purchase_it_approver &&
                    purchaseDetails?.data?.purchase?.purchaseType.typeName === "It" &&
                    purchaseDetails?.data?.purchase?.status !== "Approved")}
                downloadFile={downloadFile}
                onSubmit={async updatedPurchaseRequest => {
                    await updatePurchaseItEquipmentRequestStatus(purchaseDetails.data.uniqueId, updatedPurchaseRequest);
                    getAllPurchases();
                    setPurchaseDetails(initialDetailsState);
                }}
                loading={loading}
            />
            <PurchaseItEquipmentResponseDialog
                key={"Purchase-It-Equipment-Respond-" + purchaseResponse.data?.uniqueId}
                details={purchaseResponse.data}
                onClose={() => setPurchaseResponse(initialResponseState)}
                open={purchaseResponse.open && purchaseResponse.type === "It"}
                onSubmit={async (updatedPurchaseRequest) => {
                    await updatePurchaseItEquipmentRequestStatus(purchaseResponse.data.uniqueId, updatedPurchaseRequest);
                    getAllPurchases();
                    setPurchaseResponse(initialResponseState);
                }}
                loading={loading}
            />
            <NewPurchaseItEquipmentRequestDialog
                open={addDialogOpen.open && addDialogOpen.type === "It"}
                onClose={() => setAddDialogOpen(initialDialogState)}
                onSubmit={async newPurchase => {
                    await addPurchaseItEquipmentRequest(newPurchase);
                    getAllPurchases();
                    setAddDialogOpen(initialDialogState);
                }}
                loading={loading}
                getVendorsBySupplier={getVendorsBySupplier}
                vendorsBySupplier={vendorsBySupplier}
                legalEntities={legalEntities}
                getCurrenciesByLegalEntity={getCurrenciesByLegalEntity}
                currenciesByLegalEntity={currenciesByLegalEntity}
                taxOptions={taxOptions}
            />
            <NewPurchaseItEquipmentRequestDialog
                key={"Purchase-It-Equipment-Edit-" + editing?.uniqueId}
                open={editDialogOpen.open && editDialogOpen.type === "It"}
                onClose={() => setEditDialogOpen(initialDialogState)}
                initialValues={editing}
                onSubmit={async updatedPurchase => {
                    await updatePurchaseItEquipmentRequest(editing?.uniqueId, updatedPurchase);
                    getAllPurchases();
                    setEditDialogOpen(initialDialogState);
                }}
                loading={loading}
                getVendorsBySupplier={getVendorsBySupplier}
                vendorsBySupplier={vendorsBySupplier}
                legalEntities={legalEntities}
                getCurrenciesByLegalEntity={getCurrenciesByLegalEntity}
                currenciesByLegalEntity={currenciesByLegalEntity}
                taxOptions={taxOptions}
            />

            <PurchaseOfficeEquipmentDetailsDialog
                key={"Purchase-Office-Equipment-Details-" + purchaseDetails?.data?.uniqueId}
                details={purchaseDetails.data}
                onClose={() => setPurchaseDetails(initialDetailsState)}
                open={purchaseDetails.open && purchaseDetails.type === "Office"}
                isAdmin={(roles.is_purchase_office_approver &&
                    purchaseDetails?.data?.purchase?.purchaseType.typeName === "Office" &&
                    purchaseDetails?.data?.purchase?.status !== "Approved")}
                downloadFile={downloadFile}
                onSubmit={async updatedPurchaseRequest => {
                    await updatePurchaseOfficeEquipmentRequestStatus(purchaseDetails.data.uniqueId, updatedPurchaseRequest);
                    getAllPurchases();
                    setPurchaseDetails(initialDetailsState);
                }}
                loading={loading}
            />
            <PurchaseOfficeEquipmentResponseDialog
                key={"Purchase-Office-Equipment-Respond-" + purchaseResponse.data?.uniqueId}
                details={purchaseResponse.data}
                onClose={() => setPurchaseResponse(initialResponseState)}
                open={purchaseResponse.open && purchaseResponse.type === "Office"}
                onSubmit={async (updatedPurchaseRequest) => {
                    await updatePurchaseOfficeEquipmentRequestStatus(purchaseResponse.data.uniqueId, updatedPurchaseRequest);
                    getAllPurchases();
                    setPurchaseResponse(initialResponseState);
                }}
                loading={loading}
            />
            <NewPurchaseOfficeEquipmentRequestDialog
                open={addDialogOpen.open && addDialogOpen.type === "Office"}
                onClose={() => setAddDialogOpen(initialDialogState)}
                onSubmit={async newPurchase => {
                    await addPurchaseOfficeEquipmentRequest(newPurchase);
                    getAllPurchases();
                    setAddDialogOpen(initialDialogState);
                }}
                loading={loading}
                getVendorsBySupplier={getVendorsBySupplier}
                vendorsBySupplier={vendorsBySupplier}
                legalEntities={legalEntities}
                getCurrenciesByLegalEntity={getCurrenciesByLegalEntity}
                currenciesByLegalEntity={currenciesByLegalEntity}
                taxOptions={taxOptions}
            />
            <NewPurchaseOfficeEquipmentRequestDialog
                key={"Purchase-Office-Equipment-Edit-" + editing?.uniqueId}
                open={editDialogOpen.open && editDialogOpen.type === "Office"}
                onClose={() => setEditDialogOpen(initialDialogState)}
                initialValues={editing}
                onSubmit={async updatedPurchase => {
                    await updatePurchaseOfficeEquipmentRequest(editing?.uniqueId, updatedPurchase);
                    getAllPurchases();
                    setEditDialogOpen(initialDialogState);
                }}
                loading={loading}
                getVendorsBySupplier={getVendorsBySupplier}
                vendorsBySupplier={vendorsBySupplier}
                legalEntities={legalEntities}
                getCurrenciesByLegalEntity={getCurrenciesByLegalEntity}
                currenciesByLegalEntity={currenciesByLegalEntity}
                taxOptions={taxOptions}
            />

            <PurchaseOtherDetailsDialog
                key={"Purchase-Other-Details-" + purchaseDetails?.data?.uniqueId}
                details={purchaseDetails.data}
                onClose={() => setPurchaseDetails(initialDetailsState)}
                open={purchaseDetails.open && purchaseDetails.type === "Other"}
                isAdmin={roles.is_purchase_other_approver &&
                    purchaseDetails?.data?.purchase?.purchaseType.typeName === "Other" &&
                    purchaseDetails?.data?.purchase?.status !== "Approved"}
                downloadFile={downloadFile}
                onSubmit={async updatedPurchaseRequest => {
                    await updatePurchaseOtherRequestStatus(purchaseDetails.data.uniqueId, updatedPurchaseRequest);
                    getAllPurchases();
                    setPurchaseDetails(initialDetailsState);
                }}
                loading={loading}
            />
            <PurchaseOtherResponseDialog
                key={"Purchase-Other-Respond-" + purchaseResponse.data?.uniqueId}
                details={purchaseResponse.data}
                onClose={() => setPurchaseResponse(initialResponseState)}
                open={purchaseResponse.open && purchaseResponse.type === "Other"}
                onSubmit={async (updatedPurchaseRequest) => {
                    await updatePurchaseOtherRequestStatus(purchaseResponse.data.uniqueId, updatedPurchaseRequest);
                    getAllPurchases();
                    setPurchaseResponse(initialResponseState);
                }}
                loading={loading}
            />
            <NewPurchaseOtherRequestDialog
                open={addDialogOpen.open && addDialogOpen.type === "Other"}
                onClose={() => setAddDialogOpen(initialDialogState)}
                onSubmit={async newPurchase => {
                    await addPurchaseOtherRequest(newPurchase);
                    getAllPurchases();
                    setAddDialogOpen(initialDialogState);
                }}
                loading={loading}
                getVendorsBySupplier={getVendorsBySupplier}
                vendorsBySupplier={vendorsBySupplier}
                legalEntities={legalEntities}
                getCurrenciesByLegalEntity={getCurrenciesByLegalEntity}
                currenciesByLegalEntity={currenciesByLegalEntity}
                taxOptions={taxOptions}
            />
            <NewPurchaseOtherRequestDialog
                key={"Purchase-Other-Edit-" + editing?.uniqueId}
                open={editDialogOpen.open && editDialogOpen.type === "Other"}
                onClose={() => setEditDialogOpen(initialDialogState)}
                initialValues={editing}
                onSubmit={async updatedPurchase => {
                    await updatePurchaseOtherRequest(editing?.uniqueId, updatedPurchase);
                    getAllPurchases();
                    setEditDialogOpen(initialDialogState);
                }}
                loading={loading}
                getVendorsBySupplier={getVendorsBySupplier}
                vendorsBySupplier={vendorsBySupplier}
                legalEntities={legalEntities}
                getCurrenciesByLegalEntity={getCurrenciesByLegalEntity}
                currenciesByLegalEntity={currenciesByLegalEntity}
                taxOptions={taxOptions}
            />

            <AuditLoggingTableDialog
                open={auditLogDialogOpen.open}
                auditPayload={auditLog}
                type={auditLogDialogOpen.type}
                onClose={() => setAuditLogDialogOpen({open: false, type: ""})}
                loading={loading.type === loadingTypes.auditGetAuditLog}
            />

            <ConfirmationDialog
                open={deletePurchaseRequestConfirmationOpen}
                handleClose={() => setDeletePurchaseRequestConfirmationOpen(false)}
                handleConfirmation={async confirm => {
                    if (confirm) {
                        switch(selectedPurchaseRequest.purchase.purchaseType.typeName) {
                            case "Project":
                                await deletePurchaseProjectRequest(selectedPurchaseRequest.uniqueId);
                                break;
                            case "Tender":
                                await deletePurchaseTenderRequest(selectedPurchaseRequest.uniqueId);
                                break;
                            case "It":
                                await deletePurchaseItEquipmentRequest(selectedPurchaseRequest.uniqueId);
                                break;
                            case "Office":
                                await deletePurchaseOfficeEquipmentRequest(selectedPurchaseRequest.uniqueId);
                                break;
                            case "Other":
                                await deletePurchaseOtherRequest(selectedPurchaseRequest.uniqueId);
                                break;
                            default:
                                break;
                        }

                        getAllPurchases();
                    }
                    setDeletePurchaseRequestConfirmationOpen(false);
                }}
                promptText={"Are you sure you want to cancel the purchase request ?"}
                loading={loading.type === loadingTypes.purchasesDeletePurchaseProjectRequest
                || loading.type === loadingTypes.purchasesDeletePurchaseTenderRequest
                || loading.type === loadingTypes.purchasesDeletePurchaseItEquipmentRequest
                || loading.type === loadingTypes.purchasesDeletePurchaseOfficeEquipmentRequest
                || loading.type === loadingTypes.purchasesDeletePurchaseOtherRequest}
            />
        </>
    );
}

const mapStateToProps = state => ({
    allPurchases: state.purchasesReducer.allPurchases,
    user: state.userReducer.currentUser,
    loading: state.loadingReducer.loading,
    projects: state.projectsReducer.projects,
    tenders: state.tendersReducer.tenders,
    vendorsBySupplier: state.vendorsReducer.vendorsBySupplier,
    legalEntities: state.legalEntitiesReducer.legalEntities,
    auditLog: state.auditReducer.auditLog,
    taxOptions: state.taxReducer.taxOptions,
    transactionSubTypes: state.auditReducer.transactionSubTypes,
    currenciesByLegalEntity: state.currenciesReducer.currenciesByLegalEntity
});

const mapDispatchToProps = dispatch => ({
    //region General API's
    getAllPurchases: () => dispatch(purchaseActions.getAllPurchases()),
    getCurrentUser: () => getCurrentUser()(dispatch),
    downloadFile: (id, name) => dispatch(downloadFile(id, name)),
    getUserRoles: (roleType, roleSubType) => dispatch(getPermissionsByRole(roleType, roleSubType)),
    getCurrenciesByLegalEntity: (id) => dispatch(getCurrenciesByLegalEntity(id)),
    getTaxOptions: () => dispatch(getTaxOptions()),
    //endregion

    //region Project Purchase
    addPurchaseProjectRequest: body => dispatch(purchaseActions.addPurchaseProjectRequest(body)),
    updatePurchaseProjectRequest: (id, body) => dispatch(purchaseActions.updatePurchaseProjectRequest(id, body)),
    updatePurchaseProjectRequestStatus: (id, body) => dispatch(purchaseActions.updatePurchaseProjectRequestStatus(id, body)),
    deletePurchaseProjectRequest: id => dispatch(purchaseActions.deletePurchaseProjectRequest(id)),
    getAllProjects: (type) => dispatch(getAllProjects(type)),
    //endregion

    //region Tender Purchase
    addPurchaseTenderRequest: body => dispatch(purchaseActions.addPurchaseTenderRequest(body)),
    updatePurchaseTenderRequest: (id, body) => dispatch(purchaseActions.updatePurchaseTenderRequest(id, body)),
    updatePurchaseTenderRequestStatus: (id, body) => dispatch(purchaseActions.updatePurchaseTenderRequestStatus(id, body)),
    deletePurchaseTenderRequest: id => dispatch(purchaseActions.deletePurchaseTenderRequest(id)),
    getAllTenders: (type) => dispatch(getAllTenders(type)),
    //endregion

    //region IT Equipment Purchase
    addPurchaseItEquipmentRequest: body => dispatch(purchaseActions.addPurchaseItEquipmentRequest(body)),
    updatePurchaseItEquipmentRequest: (id, body) => dispatch(purchaseActions.updatePurchaseItEquipmentRequest(id, body)),
    updatePurchaseItEquipmentRequestStatus: (id, body) => dispatch(purchaseActions.updatePurchaseItEquipmentRequestStatus(id, body)),
    deletePurchaseItEquipmentRequest: id => dispatch(purchaseActions.deletePurchaseItEquipmentRequest(id)),
    //endregion

    //region Office Equipment Purchase
    addPurchaseOfficeEquipmentRequest: body => dispatch(purchaseActions.addPurchaseOfficeEquipmentRequest(body)),
    updatePurchaseOfficeEquipmentRequest: (id, body) => dispatch(purchaseActions.updatePurchaseOfficeEquipmentRequest(id, body)),
    updatePurchaseOfficeEquipmentRequestStatus: (id, body) => dispatch(purchaseActions.updatePurchaseOfficeEquipmentRequestStatus(id, body)),
    deletePurchaseOfficeEquipmentRequest: id => dispatch(purchaseActions.deletePurchaseOfficeEquipmentRequest(id)),
    //endregion

    //region Other Purchase
    addPurchaseOtherRequest: body => dispatch(purchaseActions.addPurchaseOtherRequest(body)),
    updatePurchaseOtherRequest: (id, body) => dispatch(purchaseActions.updatePurchaseOtherRequest(id, body)),
    updatePurchaseOtherRequestStatus: (id, body) => dispatch(purchaseActions.updatePurchaseOtherRequestStatus(id, body)),
    deletePurchaseOtherRequest: id => dispatch(purchaseActions.deletePurchaseOtherRequest(id)),
    //endregion

    //region Legal Entities and Suppliers
    getAllLegalEntities: () => dispatch(getAllLegalEntities()),
    getVendorsBySupplier: id => dispatch(getVendorsBySupplier(id)),
    //endregion

    //region Next Approver
    getNextApprovers: (type,id) => dispatch(getNextApprovers(type, id)),
    //endregion

    //region Audit Logging
    getAuditLog: (id, type) => dispatch(getAuditLog(id, type)),
    getTransactionSubTypes: () => dispatch(getTransactionSubTypes())
    //endregion
});

export default connect(mapStateToProps, mapDispatchToProps)(AllPurchases);


