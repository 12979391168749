import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider } from '@material-ui/core/styles';
import App from './App';
import theme from './theme';
import { Provider } from 'react-redux';
import ErrorAlert from './components/Alerts/ErrorAlert';
import SuccessAlert from './components/Alerts/SuccessAlert';
import store from './store';

ReactDOM.render(
  <Provider store={store}>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <App />
      <ErrorAlert />
      <SuccessAlert />
    </ThemeProvider>
  </Provider>,
  document.getElementById('root'),
);
