import axiosInstance from "../../assets/helpers/axiosInstance";

export const GET_USER_DIRECTORY_START = "global/GET_USER_DIRECTORY_START";
export const GET_USER_DIRECTORY_SUCCESS = "global/GET_USER_DIRECTORY_SUCCESS";
export const GET_USER_DIRECTORY_FAIL = "global/GET_USER_DIRECTORY_FAIL";
export const CLEAR_USER_DIRECTORY = "global/CLEAR_USER_DIRECTORY";

export const GET_RESOURCES_START = "global/GET_RESOURCES_START";
export const GET_RESOURCES_SUCCESS = "global/GET_RESOURCES_SUCCESS";
export const GET_RESOURCES_FAIL = "global/GET_RESOURCES_FAIL";
export const CLEAR_RESOURCES = "global/CLEAR_RESOURCES";

export const getUserDirectory = () => dispatch => {
	dispatch({ type: GET_USER_DIRECTORY_START });
	return axiosInstance
		.get("/contacts/All")
		.then(res =>
			dispatch({ type: GET_USER_DIRECTORY_SUCCESS, contacts: res.data })
		)
		.catch(error => dispatch({ type: GET_USER_DIRECTORY_FAIL, error }));
};

export const clearUserDirectory = () => ({ type: CLEAR_USER_DIRECTORY });

export const getResources = payload => dispatch => {
	dispatch({ type: GET_RESOURCES_START });
	return axiosInstance
		.get("/resource/all/" + payload.type)
		.then(res => dispatch({ type: GET_RESOURCES_SUCCESS, resources: res.data }))
		.catch(error =>
			dispatch({
				type: GET_RESOURCES_FAIL,
				error,
				errorMessage: error.message,
			})
		);
};
