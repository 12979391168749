import React from "react";
import Grid from "@material-ui/core/Grid";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import {Autocomplete, TextField, Radios, Checkboxes} from "mui-rff";

import styled from "styled-components";
import {Form, Field} from "react-final-form";

import CustomDialogTitle from "../../../../components/Dialogs/CustomDialogTitle/CustomDialogTitle";
import Loader from "../../../../components/Loader/Loader";
import * as loadingTypes from "../../../../store/loading/types";
import FileFieldAdapter from "../../../../components/Inputs/FileField/FileField";
import {
    Condition,
    focusOnError,
    NumberFormatOnInput,
    WhenFieldChanges
} from "../../../../assets/helpers/finalFormHelpers";
import {formatShortDate, sleep} from "../../../../assets/helpers/globals";

import arrayMutators from 'final-form-arrays'
import MultipleProjectFieldAdapter from "../../../../components/Inputs/MultipleProjectField/MultipleProjectField";
// import {OnChange} from "react-final-form-listeners";

const LoadingDiv = styled.div`
  position: absolute;
  bottom: 11px;
  right: 36px;
`;

const FormDialogContent = styled(DialogContent)`
  padding: 24px 30px;
  @media only screen and (max-width: 425px) {
    padding: 12px !important;
  }
`;

const ActionButton = styled(Button)`
  margin: 0 5px;
`;

function NewRequestDialog  ({
                                open,
                                onClose,
                                onSubmit,
                                loading,
                                initialValues,
                                projects,
                                legalEntities,
                                vendorsBySupplier,
                                getVendorsBySupplier,
                                currenciesByLegalEntity,
                                getCurrenciesByLegalEntity,
                                taxOptions
}) {
    const whenFieldChanges = values => <>
        <WhenFieldChanges
            field="project"
            set="projectManager"
            to={values.project?.projectManager?.userFullName || ''}
            shouldChangeHandler={(project) => project !== null}
        />
        <WhenFieldChanges
            field="project"
            set="projectManager"
            to={''}
            shouldChangeHandler={(project) => project === null}
        />
        <WhenFieldChanges
            field="legalEntity"
            set="vendor"
            to={''}
            shouldChangeHandler={(legalEntity) => legalEntity !== null}
        />
        <WhenFieldChanges
            field="legalEntity"
            set="vendor"
            to={''}
            shouldChangeHandler={(legalEntity) => legalEntity === null}
        />
        <WhenFieldChanges
            field="legalEntity"
            set="currency"
            to={''}
            shouldChangeHandler={(legalEntity) => legalEntity !== null}
        />
        <WhenFieldChanges
            field="legalEntity"
            set="currency"
            to={''}
            shouldChangeHandler={(legalEntity) => legalEntity === null}
        />
    </>

    const formFields = (values, errors, touched, push, removeBatch) => [
        {
            field: (
                <Grid item xs={12}>
                    <Autocomplete
                        label="Billing From"
                        name="legalEntity"
                        noOptionsText={"No legal entities found"}
                        required
                        autoHighlight
                        // filterSelectedOptions
                        // disableClearable
                        onChange={(event, value) => {
                            if (value) {
                                getVendorsBySupplier(value.uniqueId);
                                getCurrenciesByLegalEntity(value.uniqueId);
                            }
                        }}
                        options={["", ...legalEntities.sort((a, b) => a.entityName.localeCompare(b.entityName))]}
                        getOptionLabel={option => option ? `${option.entityName}` : ""}
                        getOptionSelected={(option, value) => option.uniqueId === value.uniqueId}
                        filterOptions={(options, params) => options.filter((option) => option !== "" && option.entityName.toLowerCase().includes(params.inputValue.toLowerCase()))}
                        handleHomeEndKeys
                    />
                    {/*<OnChange name={"legalEntity"}>*/}
                    {/*    {(value) => {*/}
                    {/*        if (value) {*/}
                    {/*            getVendorsBySupplier(value.uniqueId);*/}
                    {/*            getCurrenciesByLegalEntity(value.uniqueId);*/}
                    {/*        }*/}
                    {/*    }}*/}
                    {/*</OnChange>*/}
                </Grid>
            ),
        },
        {
            field: (
                <Grid item xs={12}>
                    <Radios
                        label="Supplier"
                        name="vendorType"
                        margin="none"
                        disabled={!values.legalEntity}
                        required
                        radioGroupProps={{row: true}}
                        data={[
                            {label: 'Existing Supplier', value: 'existingVendor'},
                            {label: 'Other Supplier', value: 'otherVendor'},
                        ]}
                    />
                </Grid>
            ),
        },
        {
            field: (
                <Condition when="vendorType" is="existingVendor">
                    <Grid item xs={12}>
                        <Autocomplete
                            label="Supplier Name"
                            name="vendor"
                            noOptionsText={"No suppliers found"}
                            disabled={!values.legalEntity}
                            required
                            options={["", ...vendorsBySupplier.sort((a, b) => a.vendorName.localeCompare(b.vendorName))]}
                            getOptionLabel={option => option ? `${option.vendorName}` : ""}
                            getOptionSelected={(option, value) => option.uniqueId === value.uniqueId}
                            filterOptions={(options, params) => options.filter((option) => option !== "" && option.vendorName.toLowerCase().includes(params.inputValue.toLowerCase()))}
                            handleHomeEndKeys
                        />
                    </Grid>
                </Condition>
            ),
        },
        {
            field: (
                <Condition when="vendorType" is="otherVendor">
                    <Grid item xs={12}>
                        <TextField
                            label="Other Supplier Name"
                            name="vendorOther"
                            margin="none"
                            disabled={!values.legalEntity}
                            required
                        />
                    </Grid>
                </Condition>
            ),
        },
        {
            field: (
                <Grid item xs={12}>
                    <Checkboxes
                        name="isWorkshop"
                        formControlProps={{margin: 'none'}}
                        data={{label: 'Workshop', value: false}}
                    />
                </Grid>
            ),
        },
        {
            field: (
                <Condition when="isWorkshop" is={false}>
                    <Grid item xs={12}>
                        <Autocomplete
                            label="Project Number"
                            name="project"
                            noOptionsText={"No projects found"}
                            required
                            options={["", ...projects.sort((a, b) => b.projectNumber.localeCompare(a.projectNumber))]}
                            getOptionLabel={option => option ? `${option.projectNumber}: ${option.projectName}` : ""}
                            getOptionSelected={(option, value) => option.uniqueId === value.uniqueId}
                            filterOptions={(options, params) => options.filter((option) => option !== "" &&
                                (option.projectNumber.toLowerCase().includes(params.inputValue.toLowerCase()) ||
                                    option.projectName.toLowerCase().includes(params.inputValue.toLowerCase()) ||
                                    (option.projectNumber + ": " + option.projectName).toLowerCase().includes(params.inputValue.toLowerCase())))}
                            handleHomeEndKeys
                        />
                    </Grid>
                </Condition>
            ),
        },
        {
            field: (
                <Condition when="isWorkshop" is={false}>
                    <Grid item xs={12}>
                        <TextField
                            label="Project Manager"
                            name="projectManager"
                            margin="none"
                            disabled
                        />
                    </Grid>
                </Condition>
            ),
        },
        {
            field: (
                <Condition when="isWorkshop" is={true}>
                    <Grid item xs={12}>
                        <Field
                            component={MultipleProjectFieldAdapter}
                            name="projectsDetails"
                            label="Project List"
                            values={values}
                            errors={errors}
                            itemOneName={"project"}
                            itemTwoName={"projectCostPercentage"}
                            touched={touched}
                            push={push}
                            removeBatch={removeBatch}
                            projects={projects}
                            required={true}
                        />
                    </Grid>
                </Condition>
            ),
        },
        {
            field: (
                <Grid item xs={12}>
                    <Radios
                        label="Material type"
                        name="materialType"
                        margin="none"
                        required
                        radioGroupProps={{ row: true }}
                        data={[
                            {label: 'Consumable', value: 'Consumable'},
                            {label: 'Raw', value: 'Raw'},
                            {label: 'Rental', value: 'Rental'},
                        ]}
                    />
                </Grid>
            ),
        },
        {
            field: (
                <Grid item xs={12}>
                    <TextField
                        label="Purchase Description"
                        name="description"
                        margin="none"
                        required
                        multiline
                    />
                </Grid>
            ),
        },
        {
            field: (
                <Grid item xs={12}>
                    <TextField
                        label="Quote Number"
                        name="quoteNumber"
                        margin="none"
                        required
                    />
                </Grid>
            ),
        },
        {
            field: (
                <Grid item xs={12}>
                    <Field
                        component={FileFieldAdapter}
                        name="attachments"
                        label="Quote"
                        buttonLabel="UPLOAD A QUOTE"
                        required
                    />
                </Grid>
            ),
        },
        {
            field: (
                <Grid item xs={4}>
                    <Autocomplete
                        label="Currency"
                        name="currency"
                        noOptionsText={"No currencies found"}
                        disabled={!values.legalEntity}
                        required
                        options={["", ...currenciesByLegalEntity.sort((a, b) => a.name.localeCompare(b.name))]}
                        getOptionLabel={option => option ? option.name : ""}
                        getOptionSelected={(option, value) => option.uniqueId === value.uniqueId}
                        filterOptions={(options, params) => options.filter((option) => option !== "" &&
                            (option.name.toLowerCase().includes(params.inputValue.toLowerCase())))}
                        handleHomeEndKeys
                        // disableClearable
                    />
                </Grid>
            ),
        },
        {
            field: (
                <Grid item xs={4}>
                    <TextField
                        label="Amount"
                        name="totalCost"
                        margin="none"
                        required
                        InputProps={{
                            inputComponent: NumberFormatOnInput,
                        }}
                    />
                </Grid>
            ),
        },
        {
            field: (
                <Grid item xs={4}>
                    <Autocomplete
                        label="Tax Option"
                        name="xeroTaxOption"
                        noOptionsText={"No tax options found"}
                        required
                        options={["", ...taxOptions.sort((a, b) => a.taxOptionText.localeCompare(b.taxOptionText))]}
                        getOptionLabel={option => option ? option.taxOptionText : ""}
                        getOptionSelected={(option, value) => option.uniqueId === value.uniqueId}
                        filterOptions={(options, params) => options.filter((option) => option !== "" &&
                            (option.taxOptionText.toLowerCase().includes(params.inputValue.toLowerCase())))}
                        handleHomeEndKeys
                        // disableClearable
                    />
                </Grid>
            ),
        },
    ]

    const validate = values => {
        const errors = {}

        if (!values.legalEntity)
            errors.legalEntity = 'Required'
        if (values.vendorType === "existingVendor" && !values.vendor)
            errors.vendor = 'Required'
        if (values.vendorType === "otherVendor" && !values.vendorOther)
            errors.vendorOther = 'Required'
        if (!values.description)
            errors.description = 'Required'
        if (!values.attachments || !values.attachments.length)
            errors.attachments = 'Required'
        if (!values.currency)
            errors.currency = 'Required'
        if (!values.totalCost)
            errors.totalCost = 'Required'
        if (!values.xeroTaxOption)
            errors.xeroTaxOption = 'Required'
        if (!values.quoteNumber)
            errors.quoteNumber = 'Required'
        if (values.isWorkshop === true) {
            if (!values.projectsDetails ||
                !values.projectsDetails.length ||
                values.projectsDetails.indexOf(null) !== -1)
                errors.projectsDetails = "Required"
            else {
                let totalProjectCostPercentage = 0
                values.projectsDetails.forEach((projectsDetailsItem, projectsDetailsItemIdx) => {
                    Object.entries(projectsDetailsItem).forEach(([key, value]) => {
                        if (key === "project" && !value)
                            errors["projectsDetails[" + projectsDetailsItemIdx + "].project"] = "Required"
                        if (key === "projectCostPercentage" && !value)
                            errors["projectsDetails[" + projectsDetailsItemIdx + "].projectCostPercentage"] = "Required"
                        if (key === "projectCostPercentage" && (value === "0" || value === "0.0" || value === "0.00"))
                            errors["projectsDetails[" + projectsDetailsItemIdx + "].projectCostPercentage"] = "Value may not be 0. Please enter a valid value."
                        if (key === "projectCostPercentage" && value)
                            totalProjectCostPercentage += parseFloat(value)
                    })
                    if (!projectsDetailsItem.hasOwnProperty('projectCostPercentage'))
                        errors["projectsDetails[" + projectsDetailsItemIdx + "].projectCostPercentage"] = "Required"
                })

                if (totalProjectCostPercentage < 100)
                    errors.totalAllocation = "Total percentage allocation should add up to 100%. Please re-allocate."
                if (totalProjectCostPercentage > 100)
                    errors.totalAllocation = "Total percentage allocation exceeds 100%. Please re-allocate."
            }
        }
        else if (!values.project)
            errors.project = 'Required'

        return errors
    }

    const onSubmitForm = async values => {
        let formData = new FormData();
        let attachArray = [];

        values.attachments.forEach(file => {
            if (file.generatedFilename)
                attachArray.push(file.generatedFilename)
            else
                formData.append("file", file);
        });

        if (attachArray && attachArray.length)
            formData.append("existingAttachments", attachArray);
        else
            formData.append("existingAttachments", null);

        formData.append('requestDate', formatShortDate(new Date()));
        formData.append('description', values.description);
        formData.append('status', "Awaiting Approval");
        formData.append('rejectReason', "");
        formData.append('totalCost', values.totalCost);
        formData.append('currency', values.currency.uniqueId);
        formData.append('quoteNumber', values.quoteNumber);
        formData.append('xeroTaxOption', values.xeroTaxOption.uniqueId);
        formData.append('materialType', values.materialType);
        formData.append('legalEntity', values.legalEntity.uniqueId);
        formData.append('purchaseLevel', "1");

        if (values.isWorkshop === true) {
            values.projectsDetails.forEach((projectsDetailsItem, projectsDetailsItemIdx) => {
                formData.append(`projectsDetails[${projectsDetailsItemIdx}].project`, projectsDetailsItem.project.uniqueId)
                formData.append(`projectsDetails[${projectsDetailsItemIdx}].projectCostPercentage`, projectsDetailsItem.projectCostPercentage)
                formData.append(`projectsDetails[${projectsDetailsItemIdx}].status`, "Awaiting Approval")
            });
            formData.append('workshopApprovalStatus', "Awaiting Approval");
        }
        else {
            formData.append(`projectsDetails[0].project`, values.project.uniqueId)
            formData.append(`projectsDetails[0].projectCostPercentage`, 100)
            formData.append(`projectsDetails[0].status`, "Awaiting Approval")
        }

        if (values.vendorType === "existingVendor" && values.vendor)
            formData.append('vendor', values.vendor.uniqueId);
        else
            formData.append('vendorOther', values.vendorOther);

        if (values.isWorkshop === true)
            formData.append('workshop', "Roodekop");
        else
            formData.append('workshop', "");

        // for (let pair of formData.entries()) {
        //     console.log(pair[0]+ ', ' + pair[1]);
        // }

        await onSubmit(formData);
    }

    let submit;

    return (
        <Form
            onSubmit={onSubmitForm}
            validate={validate}
            decorators={[focusOnError]}
            mutators={{...arrayMutators}}
            initialValues={initialValues ? initialValues : {
                vendorType: "existingVendor",
                vendor: '',
                currency: '',
                materialType: "Consumable",
                isWorkshop: false,
                // projectsDetails: [{"project": undefined, "projectCostPercentage": undefined}],
                xeroTaxOption: taxOptions.find(taxOption => taxOption.taxOptionText === "Tax Exclusive")
            }}
            render={({handleSubmit, submitting, form, pristine, values, form: { mutators: { push, removeBatch }}, errors, touched}) => {
                submit = handleSubmit
                return (
                    <Dialog
                        aria-labelledby="add-project-purchase-request-dialog-title"
                        onClose={onClose}
                        fullWidth
                        maxWidth={"sm"}
                        open={open}
                    >
                        <CustomDialogTitle
                            id="add-project-purchase-request-dialog-title"
                            onClose={onClose}
                            showTextContent
                        >
                            Add Project Purchase
                        </CustomDialogTitle>
                        <FormDialogContent dividers>
                            <form onSubmit={submit}>
                                {whenFieldChanges(values)}
                                <Grid container alignItems="flex-start" spacing={4}>
                                    {formFields(values, errors, touched, push, removeBatch).map((item, idx) => (
                                        <React.Fragment key={idx}>
                                            {item.field}
                                        </React.Fragment>
                                    ))}
                                </Grid>
                            </form>
                        </FormDialogContent>
                        <DialogActions>
                            <ActionButton
                                onClick={onClose}
                                type="button"
                                disabled={submitting}
                                color="primary"
                            >
                                Cancel
                            </ActionButton>
                            <ActionButton
                                type="button"
                                onClick={form.reset}
                                color="primary"
                                disabled={submitting || pristine}
                            >
                                Reset
                            </ActionButton>
                            <ActionButton
                                onClick={event => {
                                    const promise = submit(event);
                                    promise && promise.then(async() => {
                                        await sleep(300);
                                        form.reset();
                                    })
                                    return promise;
                                }}
                                type="submit"
                                disabled={submitting || pristine}
                                color="default"
                            >
                                Submit
                            </ActionButton>
                            <LoadingDiv>
                                <Loader loading={loading.type === loadingTypes.purchasesAddPurchaseProjectRequest ||
                                        loading.type === loadingTypes.purchasesUpdatePurchaseProjectRequest}
                                        size="small"/>
                            </LoadingDiv>
                        </DialogActions>
                    </Dialog>
                )
            }}
        />
    )
}
export default NewRequestDialog;