import React, { Fragment } from 'react';

import classes from './SidebarNavigationItem.module.css';
import { NavLink } from 'react-router-dom';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import Icon from "@material-ui/core/Icon";

const SidebarNavigationItem = (props) => {

    const generateIcon = (item) => {
        if (item) {
            return typeof (item.icon) === "string" ? (<Icon className={classes.Icon} >{item.icon}</Icon>) : (<item.icon className={classes.Icon} />);
        }
        else {
            const route = props.route;
            return typeof (route.icon) === "string" ? (<Icon className={classes.Icon} >{route.icon}</Icon>) : (<route.icon className={classes.Icon} />);
        }
    };

    const generateLink = () => {

        if(props.route.external){
            return (
                <ListItem
                    className={classes.ListItem}
                    key={props.route.id}>
                    <a
                        href={props.route.path}
                        target="_blank"
                        rel="noopener noreferrer"
                        className={classes.ItemLink}
                    >
                        {props.route.icon ? generateIcon(props.route) : null}
                        <span>{props.route.title}</span>
                    </a>
                </ListItem>);
        }
        else if (props.route.collapse) {
            return (<ListItem className={classes.ListItem} key={props.route.id}>
                <NavLink
                    to={props.route.path}
                    className={classes.ItemLink}
                    activeClassName={classes.CollapseItemLinkActive}
                    onClick={e => {
                        e.preventDefault();
                        props.toggleCollapse(props.route.collapseSub + props.route.id);
                    }}
                >
                    {props.route.icon ? generateIcon(props.route) : null}
                    <ListItemText
                        primary={props.route.title}
                        disableTypography={true}
                        className={classes.ItemText}
                    />
                    {props[props.route.collapseSub + props.route.id + "_collapse"] ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                </NavLink>

                <Collapse in={props[props.route.collapseSub + props.route.id + "_collapse"]} timeout="auto" unmountOnExit className={classes.Collapse}>
                    <List component="nav" disablePadding>
                        {generateNestedLinks(props.route.views)}
                    </List>
                </Collapse>

            </ListItem>);
        }
        else {
            return (
                <ListItem className={classes.ListItem} key={props.route.id}>
                    <NavLink
                        exact
                        to={props.route.path}
                        className={classes.ItemLink}
                        activeClassName={classes.ItemLinkActive}
                    >
                        {props.route.icon ? generateIcon(props.route) : null}
                        <ListItemText
                            primary={props.route.title}
                            disableTypography={true}
                            className={classes.ItemText}
                        />
                    </NavLink>

                </ListItem>
            );
        }
    };

    const generateNestedLinks = (views) => {
        return views.map((item) => {
            if(item.external){
                return (
                    <ListItem
                        className={classes.NestedListItem}
                        key={item.id}>
                        <a
                            href={item.path}
                            target="_blank"
                            rel="noopener noreferrer"
                            className={classes.ItemLink}
                        >
                            {item.icon ? generateIcon(item) : null}
                            <span>{item.title}</span>
                        </a>
                    </ListItem>);
            }
            else if (item.collapse) {
                return (<ListItem className={classes.ListItem} key={item.id}>
                    <NavLink
                        to={item.path}
                        className={classes.ItemLink}
                        activeClassName={classes.CollapseItemLinkActive}
                        onClick={e => {
                            e.preventDefault();
                            props.toggleCollapse(item.collapseSub + item.id);
                        }}
                    >
                        {item.icon ? generateIcon(item) : null}
                        <ListItemText
                            primary={item.title}
                            disableTypography={true}
                            className={classes.ItemText}
                        />
                        {props[item.collapseSub + item.id + "_collapse"] ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                    </NavLink>
    
                    <Collapse in={props[item.collapseSub + item.id + "_collapse"]} timeout="auto" unmountOnExit className={classes.Collapse}>
                        <List component="nav" disablePadding>
                            {generateNestedLinks(item.views)}
                        </List>
                    </Collapse>
    
                </ListItem>);
            }

            return (
                <ListItem
                    className={classes.NestedListItem}
                    key={item.id}>
                    <NavLink
                        // exact
                        to={item.path}
                        className={classes.ItemLink}
                        activeClassName={classes.ItemLinkActive}
                    >
                        {item.icon ? generateIcon(item) : null}
                        <ListItemText
                            primary={item.title}
                            disableTypography={true}
                            className={classes.ItemText}
                        />
                    </NavLink>
                </ListItem>);
        });
    };

    return (
        <Fragment>
            {generateLink()}
        </Fragment>
    );
};

export default SidebarNavigationItem;