import React, { useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';
import { logout } from "../../store/auth/actions";

const Logout = (props) =>  {
    useEffect(() => {
        props.onLogout();
    }, [props]);

    if(props.loading) return <CircularProgress />;
    else if(props.error) return null;
    else return <Redirect to="/app/login" push={true} />;
}

const mapStateToProps = state => (
    {
        logoutLoading: state.authReducer.logoutLoading,
        logoutError: state.authReducer.logoutError
    }
);

const mapDispatchToProps = dispatch => ({ onLogout : () => dispatch(logout()) });

export default connect(mapStateToProps, mapDispatchToProps)(Logout);