import React, {Fragment, useRef} from "react";
import Button from "@material-ui/core/Button";
import styled, {css} from "styled-components";
import Tooltip from "@material-ui/core/Tooltip";
import {Close} from "@material-ui/icons";
import GetApp from "@material-ui/icons/GetApp";
import Loader from "../../Loader/Loader";
import * as loadingTypes from "../../../store/loading/types";

const ClearButton = styled(Button)`
  margin-left: 10px
`;

const Input = styled.input`
  display: none
`;

const Div = styled.div`
  margin-top: 10px
`;

const Error = styled.p`
  color: #ea4335;
  margin: 0;
  font-size: 0.75rem;
  margin-top: 10px;
  text-align: left;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  line-height: 1.66;
  letter-spacing: 0.03333em;
`;

const Label = styled.p`
  color: rgba(0, 0, 0, 0.54);
  padding: 0;
  font-size: 1rem;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  line-height: 1;
  letter-spacing: 0.00938em;
  ${props => props.error && css`color: #ea4335;`}
`;

const UploadText = styled.p`
  margin: 15px 0 0 0;
`;

const DeleteButton = styled(Button)`
  float: right;
  margin-top: -5px;
  background: white;
  box-shadow: none;
`;

const DownloadButton = styled(Button)`
  float: right;
  margin-top: -5px;
  background: white;
  box-shadow: none;
`;

const FileFieldAdapter = ({input: {value, onChange, name, ...input}, meta: { error, touched }, ...rest}) => {
    const hiddenFileInput = useRef(null);
    const handleClick = () => hiddenFileInput.current.click();

    return (
        <Div>
            {error && touched ?
                <Label error>{rest.label} {rest.required ? '*' : ""}</Label> :
                <Label>{rest.label} {rest.required ? '*' : ""}</Label>
            }
            <Button variant="contained" color={"default"} onClick={handleClick}>{rest.buttonLabel ? rest.buttonLabel : "Upload a file"}</Button>
            <Input
                {...input}
                type="file"
                ref={hiddenFileInput}
                onFocus={(event) => input.onFocus(event)}
                onChange={(event) => {
                    const {files} = event.target;
                    const newFiles = [...value];

                    for (let c = 0; c < files.length; c++) {
                        const file = files.item(c);
                        newFiles.push(file);
                    }

                    event.target.value = '';
                    onChange(newFiles);
                }}
                {...rest}
            />
            {value.length && !rest.hideRemoveButton ? (
                <Fragment>
                    <ClearButton variant="contained" color={"default"} onClick={() => onChange([])}>
                        Clear All {rest.clearLabel ? rest.clearLabel : "Files"}
                    </ClearButton>
                </Fragment>
            ) : null}
            {value.length ? value.map((file, idx) => (
                <Fragment key={`UPLOAD_FILE_${idx}`}>
                    <UploadText>- {file.name}
                        {rest.showDownloadButton && idx < (rest.initialLength) &&
                            <>
                                <DownloadButton onClick={() => rest.downloadFile(file.uniqueId, file.name)}>
                                    <Tooltip title="Download File">
                                        <GetApp color={"primary"}/>
                                    </Tooltip>
                                </DownloadButton>
                            </>}
                        {(!rest.hideRemoveButton || idx > (rest.initialLength-1)) && <DeleteButton onClick={() => onChange(value.filter(value => value !== file))}>
                            <Tooltip title="Remove">
                                <Close color={"error"}/>
                            </Tooltip>
                        </DeleteButton>}
                    </UploadText>
                </Fragment>
            )) : ""}
            {error && touched && <Error>{error}</Error>}
            <Loader loading={rest.loadingType === loadingTypes.fileDownload} center />
        </Div>
    )
}

export default FileFieldAdapter;