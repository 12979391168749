import React, { Component } from "react";

import classes from "./DefaultLayout.module.css";
import CustomToolbar from "../../../components/Navigation/CustomToolbar/CustomToolbar";
import Sidebar from "../../../components/Navigation/Sidebar/Sidebar";
import clsx from "clsx";
import { withRouter } from "react-router-dom";
import allRoutes from "../../../assets/helpers/routes";
import axiosInstance from "../../../assets/helpers/axiosInstance";
import CookieConsent from "react-cookie-consent";

class DefaultLayout extends Component {
	state = {
		showSideBar: true,
		isMobile: false,
		user: undefined,
	};

	sideBarClosedHandler = () => {
		this.setState({ showSideBar: false });
	};

	sideBarToggleHandler = () => {
		this.setState(prevState => {
			return { showSideBar: !prevState.showSideBar };
		});
	};

	windowResize = () => {
		if (window.innerWidth < 960) {
			this.setState({
				isMobile: true,
				showSideBar: false,
			});
		} else {
			this.setState({
				isMobile: false,
				showSideBar: true,
			});
		}
	};

	getPageTitle = () => {
		let title = null;
		const layoutRoutes = allRoutes.find(
			layoutRoute => layoutRoute.layoutName === "DefaultLayout"
		);

		if (layoutRoutes) {
			layoutRoutes.routes.forEach(route => {
				if (route.collapse) {
					route.views.forEach(initialView => {
						if (initialView.collapse) {
							initialView.views.forEach(view => {
								if (view.path === this.props.location.pathname)
									title = view.title;
							});
						} else {
							if (initialView.path === this.props.location.pathname) {
								title = initialView.title;
							}
						}
					});
				} else {
					if (route.path === this.props.location.pathname) {
						title = route.title;
					}
				}
			});
		}
		return title;
	};

	componentDidMount() {
		this.windowResize();
		window.addEventListener("resize", this.windowResize);
		axiosInstance
			.get("/api/user/me")
			.then(res => this.setState({ user: res.data }))
			.catch(error => console.log(error));
	}

	render() {
		const showSidebarClassName = this.state.showSideBar
			? clsx([classes.Content, classes.SidebarOpen])
			: classes.Content;
		const contentClassName = this.state.isMobile
			? clsx([classes.Content])
			: showSidebarClassName;

		return (
			<div className={classes.Layout}>
				<CustomToolbar
					layout={"default"}
					toggleClicked={this.sideBarToggleHandler}
					isMobile={this.state.isMobile}
					user={this.state.user}
					title={this.getPageTitle()}
				/>
				<Sidebar
					open={this.state.showSideBar}
					closed={this.sideBarClosedHandler}
					isMobile={this.state.isMobile}
				/>
				<div className={contentClassName}>{this.props.children}</div>
				<CookieConsent
					// debug={true}
					location="bottom"
					style={{ background: "linear-gradient(to left, #233448, #416283)", textAlign: "left", color: "#fff", alignItems: "center", padding: "20px 10px" }}
					buttonText="Okay, I Understand"
					buttonStyle={{ color: "#233448", background: "#fff", fontSize: "14px", padding: "10px 20px", borderRadius: "4px" }}
					expires={365}>
					We use cookies to provide you with the best possible experience. They also allow us to analyze user behaviour in order to constantly improve the website for you.</CookieConsent>
			</div>
		);
	}
}

export default withRouter(DefaultLayout);
