import * as actionTypes from './actions';

// TODO: Just group all the loading and errors into one object FFS
const initialState = {
    currentResponse : '',
    deleteEventLoading : false,
    deleteEventError : null,
    event: null, // TODO: Not acceptable, change!!!
    eventError : null, // TODO: The Fuck?
    eventLoading : false, // TODO: Still not acceptable
    events: [],
    eventsError : null, // TODO: Questionable to have error per state
    eventsLoading : false,
    newEventLoading: false, // TODO: Why are there new event loading but no corresponding new event?
    newEventError : null, // TODO: Why are there new event loading but no corresponding new event?
    resources: [],
    resourcesError : null,
    resourcesLoading : false,
    respondToEventLoading : false,
    respondToEventError : null,
    updateEventLoading: false,
    updateEventError : null
};

// TODO: These bastard actions need to move to actions, not in the reducer
const getBoardroomResourcesStart = (state) => ({ ...state, resourcesLoading : true });
const getBoardroomResourcesSuccess = (state, action) => (
    { ...state, resources : action.resources, resourcesError : null, resourcesLoading : false   }
);
const getBoardroomResourcesFail = (state, action) => (
    { ...state, resourcesError : action.error, resourcesLoading : false }
);
const getBoardroomEventsStart = (state) => ({  ...state, eventsLoading : true });
const getBoardroomEventsSuccess = (state, action) => (
    { ...state, events : action.events, eventsError : null, eventsLoading : false }
);
const getBoardroomEventsFail = (state, action) => ({ ...state, eventsError : action.error, eventsLoading : false });
const getBoardroomEventsLoaded = (state) => ({ ...state, eventsLoading : false });
const createBoardroomEventStart = (state) => ({ ...state, newEventLoading : true });
const createBoardroomEventSuccess = (state) => ({ ...state, newEventError : null, newEventLoading : false });
const createBoardroomEventFail = (state, action) => ({ ...state, newEventError : action.error, newEventLoading : false });
const updateBoardroomEventStart = (state) => ({ ...state, updateEventLoading : true});
const updateBoardroomEventSuccess = (state) => ({ ...state, updateEventError : null, updateEventLoading : false });
const updateBoardroomEventFail = (state, action) => ({ ...state, updateEventError : action.error, updateEventLoading : false });
const deleteBoardroomEventStart = (state) => ({ ...state, deleteEventLoading : true });
const deleteBoardroomEventSuccess = (state) => ({  ...state, deleteEventError : null, deleteEventLoading : false });
const deleteBoardroomEventFail = (state, action) => ({  ...state, deleteEventError : action.error, deleteEventLoading : false });
const respondToBoardroomEventStart = (state, action) => (
    { ...state, respondToEventLoading : true, currentResponse : action.response }
);
const respondToBoardroomEventSuccess = (state) => (
    { ...state, respondToEventError : null, respondToEventLoading : false, currentResponse : '' }
);
const respondToBoardroomEventFail = (state, action) => (
    { ...state, respondToEventError : action.error, respondToEventLoading : false, currentResponse : '' }
);

export default (state = initialState, action) => {
    switch ( action.type ) {
        case actionTypes.GET_BOARDROOM_RESOURCES_START: return getBoardroomResourcesStart(state);
        case actionTypes.GET_BOARDROOM_RESOURCES_SUCCESS: return getBoardroomResourcesSuccess(state, action);
        case actionTypes.GET_BOARDROOM_RESOURCES_FAIL: return getBoardroomResourcesFail(state, action);
        case actionTypes.GET_BOARDROOM_EVENTS_START: return getBoardroomEventsStart(state);
        case actionTypes.GET_BOARDROOM_EVENTS_SUCCESS: return getBoardroomEventsSuccess(state, action);
        case actionTypes.GET_BOARDROOM_EVENTS_FAIL: return getBoardroomEventsFail(state, action);
        case actionTypes.GET_BOARDROOM_EVENTS_LOADED: return getBoardroomEventsLoaded(state);
        case actionTypes.CREATE_BOARDROOM_EVENT_START: return createBoardroomEventStart(state);
        case actionTypes.CREATE_BOARDROOM_EVENT_SUCCESS: return createBoardroomEventSuccess(state, action);
        case actionTypes.CREATE_BOARDROOM_EVENT_FAIL: return createBoardroomEventFail(state, action);
        case actionTypes.UPDATE_BOARDROOM_EVENT_START: return updateBoardroomEventStart(state);
        case actionTypes.UPDATE_BOARDROOM_EVENT_SUCCESS: return updateBoardroomEventSuccess(state, action);
        case actionTypes.UPDATE_BOARDROOM_EVENT_FAIL: return updateBoardroomEventFail(state, action);
        case actionTypes.DELETE_BOARDROOM_EVENT_START: return deleteBoardroomEventStart(state);
        case actionTypes.DELETE_BOARDROOM_EVENT_SUCCESS: return deleteBoardroomEventSuccess(state, action);
        case actionTypes.DELETE_BOARDROOM_EVENT_FAIL: return deleteBoardroomEventFail(state, action);
        case actionTypes.RESPOND_TO_BOARDROOM_EVENT_START: return respondToBoardroomEventStart(state, action);
        case actionTypes.RESPOND_TO_BOARDROOM_EVENT_SUCCESS: return respondToBoardroomEventSuccess(state, action);
        case actionTypes.RESPOND_TO_BOARDROOM_EVENT_FAIL: return respondToBoardroomEventFail(state, action);
        default: return state;
    }
};
