import axiosInstance from '../../assets/helpers/axiosInstance';
import { endLoading, startLoading } from "../loading/actions";
import * as loadingTypes from "../loading/types";
import {setError} from "../error/actions";
import * as errorTypes from "../error/types";

export const SET_CURRENCIES_BY_LEGAL_ENTITY = "currencies/SET_CURRENCIES_BY_LEGAL_ENTITY";
export const SET_ALL_CURRENCIES = "currencies/SET_ALL_CURRENCIES";

const setCurrenciesByLegalEntity = currenciesByLegalEntity => ({
    type: SET_CURRENCIES_BY_LEGAL_ENTITY,
    currenciesByLegalEntity,
});

const setAllCurrencies = allCurrencies => ({
    type: SET_ALL_CURRENCIES,
    allCurrencies,
});

export const getCurrenciesByLegalEntity = (id) => async dispatch => {
    dispatch(startLoading(loadingTypes.currenciesGetCurrenciesByLegalEntity));
    try {
        const res = await axiosInstance.get(`/currencies/getCurrencyByLegalEntity?uniqueId=${id}`);
        dispatch(setCurrenciesByLegalEntity(res.data));
    } catch (err) {
        dispatch(setError(errorTypes.currenciesGetCurrenciesByLegalEntity, `${err.message ? err.message + " at " + errorTypes.currenciesGetCurrenciesByLegalEntity : "Something went wrong at " + errorTypes.currenciesGetCurrenciesByLegalEntity }`));
    } finally {
        dispatch(endLoading());
    }
};

export const getAllCurrencies = () => async dispatch => {
    dispatch(startLoading(loadingTypes.currenciesGetAllCurrencies));
    try {
        const res = await axiosInstance.get(`/currencies/getAll`);
        dispatch(setAllCurrencies(res.data));
        return res.data
    } catch (err) {
        dispatch(setError(errorTypes.currenciesGetAllCurrencies, `${err.message ? err.message + " at " + errorTypes.currenciesGetAllCurrencies : "Something went wrong at " + errorTypes.currenciesGetAllCurrencies }`));
    } finally {
        dispatch(endLoading());
    }
};

// export const SET_CURRENCIES = "currencies/SET_CURRENCIES";
//
// const setCurrencies = currencies => ({
//     type: SET_CURRENCIES,
//     currencies,
// });
//
// export const getCurrencies = (id) => async dispatch => {
//     dispatch(startLoading(loadingTypes.currenciesGetCurrencies));
//     try {
//         const res = await axiosInstance.get(`/currencies/getCurrencies`);
//         dispatch(setCurrencies(res.data));
//     } catch (err) {
//         dispatch(setError(errorTypes.currenciesGetCurrencies, `${err.message ? err.message + " at " + errorTypes.currenciesGetCurrencies : "Something went wrong at " + errorTypes.currenciesGetCurrencies }`));
//     } finally {
//         dispatch(endLoading());
//     }
// };


