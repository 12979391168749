import axiosInstance from '../../assets/helpers/axiosInstance';
import { endLoading, startLoading } from "../loading/actions";
import * as loadingTypes from "../loading/types";
import {setError} from "../error/actions";
import * as errorTypes from "../error/types";
import {setSuccessMessage} from "../success/actions";

export const SET_ALL_TENDERS = "tenders/SET_ALL_TENDERS";
export const SET_ALL_QUOTE_NUMBERS = "tenders/SET_ALL_QUOTE_NUMBERS";
export const SET_ALL_EOI_NUMBERS = "tenders/SET_ALL_EOI_NUMBERS";

const setAllTenders = tenders => ({
    type: SET_ALL_TENDERS,
    tenders
});

const setAllQuoteNumbers = allQuoteNumbers => ({
    type: SET_ALL_QUOTE_NUMBERS,
    allQuoteNumbers,
});

const setAllEOINumbers = allEOINumbers => ({
    type: SET_ALL_EOI_NUMBERS,
    allEOINumbers,
});

// START: Tenders
//region
export const getAllTenders = (type) => async dispatch => {
    dispatch(startLoading(loadingTypes.tendersGetAllTenders));
    try {
        const res = await axiosInstance.get(`/tenders/getAllTenders?type=${type}`)
        dispatch(setAllTenders(res.data));
    } catch (err) {
        dispatch(setError(errorTypes.tendersGetAllTenders, `${err.message ? err.message + " at " + errorTypes.tendersGetAllTenders : "Something went wrong at " + errorTypes.tendersGetAllTenders }`));
    } finally {
        dispatch(endLoading());
    }
}

export const addTender = (technicalUserId, coordinatorUserId, data) => async dispatch => {
    dispatch(startLoading(loadingTypes.tendersAddTender));
    try {
        await axiosInstance.post(`/tenders/addTender?coordinatorId=${coordinatorUserId}&technicalId=${technicalUserId}`, data)
        dispatch(setSuccessMessage("Success! Tender added."));
    } catch (err) {
        dispatch(setError(errorTypes.tendersAddTender, `${err.message ? err.message + " at " + errorTypes.tendersAddTender : "Something went wrong at " + errorTypes.tendersAddTender }`));
    } finally {
        dispatch(endLoading());
    }
};

export const updateTender = (technicalUserId, coordinatorUserId, data) => async dispatch => {
    dispatch(startLoading(loadingTypes.tendersUpdateTender));
    try {
        await axiosInstance.post(`/tenders/updateTender?coordinatorId=${coordinatorUserId}&technicalId=${technicalUserId}`, data);
        dispatch(setSuccessMessage("Success! Tender updated."));
    } catch (err) {
        dispatch(setError(errorTypes.tendersUpdateTender, `${err.message ? err.message + " at " + errorTypes.tendersUpdateTender : "Something went wrong at " + errorTypes.tendersUpdateTender }`));
    } finally {
        dispatch(endLoading());
    }
};

export const deleteTender = (id) => async dispatch => {
    dispatch(startLoading(loadingTypes.tendersDeleteTender));
    try {
        await axiosInstance.delete(`/tenders/deleteTender?uniqueId=${id}`);
        dispatch(setSuccessMessage("Success! Tender removed."));
    } catch (err) {
        dispatch(setError(errorTypes.tendersDeleteTender, `${err.message ? err.message + " at " + errorTypes.tendersDeleteTender : "Something went wrong at " + errorTypes.tendersDeleteTender }`));
    } finally {
        dispatch(endLoading());
    }
}

export const undoDeleteTender = (id, data) => async dispatch => {
    dispatch(startLoading(loadingTypes.tendersUndoDeleteTender));
    try {
        await axiosInstance.post(`/tenders/undoDelete?uniqueId=${id}`, data);
        dispatch(setSuccessMessage("Success! Tender restored."));
    } catch (err) {
        dispatch(setError(errorTypes.tendersUndoDeleteTender, `${err.message ? err.message + " at " + errorTypes.tendersUndoDeleteTender : "Something went wrong at " + errorTypes.tendersUndoDeleteTender }`));
    } finally {
        dispatch(endLoading());
    }
};

export const getNewTenderNumber = () => async dispatch => {
    dispatch(startLoading(loadingTypes.tendersGetNewTenderNumber));
    try {
        return await axiosInstance.get("/tenders/getNewTenderNumber");
    } catch (err) {
        dispatch(setError(errorTypes.tendersGetNewTenderNumber, `${err.message ? err.message + " at " + errorTypes.tendersGetNewTenderNumber : "Something went wrong at " + errorTypes.tendersGetNewTenderNumber }`));
    } finally {
        dispatch(endLoading());
    }
};

//endregion
// END: Tenders

// START: Quotes
//region

export const getAllQuoteNumbers = (type) => async dispatch => {
    dispatch(startLoading(loadingTypes.tendersGetAllQuoteNumbers));
    try {
        const res = await axiosInstance.get(`/quotations/getAll?type=${type}`);
        dispatch(setAllQuoteNumbers(res.data));
    } catch (err) {
        dispatch(setError(errorTypes.tendersGetAllQuoteNumbers, `${err.message ? err.message + " at " + errorTypes.tendersGetAllQuoteNumbers : "Something went wrong at " + errorTypes.tendersGetAllQuoteNumbers }`));
    } finally {
        dispatch(endLoading());
    }
};

export const addQuote = (technicalUserId, coordinatorUserId, data) => async dispatch => {
    dispatch(startLoading(loadingTypes.tendersAddQuote));
    try {
        await axiosInstance.post(`/quotations/addQuotation?coordinatorId=${coordinatorUserId}&technicalId=${technicalUserId}`, data)
        dispatch(setSuccessMessage("Success! Quote added."));
    } catch (err) {
        dispatch(setError(errorTypes.tendersAddQuote, `${err.message ? err.message + " at " + errorTypes.tendersAddQuote : "Something went wrong at " + errorTypes.tendersAddQuote }`));
    } finally {
        dispatch(endLoading());
    }
};

export const updateQuote = (technicalUserId, coordinatorUserId, data) => async dispatch => {
    dispatch(startLoading(loadingTypes.tendersUpdateQuote));
    try {
        await axiosInstance.post(`/quotations/updateQuotation?coordinatorId=${coordinatorUserId}&technicalId=${technicalUserId}`, data);
        dispatch(setSuccessMessage("Success! Quote updated."));
    } catch (err) {
        dispatch(setError(errorTypes.tendersUpdateQuote, `${err.message ? err.message + " at " + errorTypes.tendersUpdateQuote : "Something went wrong at " + errorTypes.tendersUpdateQuote }`));
    } finally {
        dispatch(endLoading());
    }
};

export const deleteQuote = (id) => async dispatch => {
    dispatch(startLoading(loadingTypes.tendersDeleteQuote));
    try {
        await axiosInstance.delete(`/quotations/deleteQuotation?uniqueId=${id}`);
        dispatch(setSuccessMessage("Success! Quote removed."));
    } catch (err) {
        dispatch(setError(errorTypes.tendersDeleteQuote, `${err.message ? err.message + " at " + errorTypes.tendersDeleteQuote : "Something went wrong at " + errorTypes.tendersDeleteQuote }`));
    } finally {
        dispatch(endLoading());
    }
}

export const undoDeleteQuote = (id, data) => async dispatch => {
    dispatch(startLoading(loadingTypes.tendersUndoDeleteQuote));
    try {
        await axiosInstance.post(`/quotations/undoDelete?uniqueId=${id}`, data);
        dispatch(setSuccessMessage("Success! Quote restored."));
    } catch (err) {
        dispatch(setError(errorTypes.tendersUndoDeleteQuote, `${err.message ? err.message + " at " + errorTypes.tendersUndoDeleteQuote : "Something went wrong at " + errorTypes.tendersUndoDeleteQuote }`));
    } finally {
        dispatch(endLoading());
    }
};

export const getNewQuoteNumber = () => async dispatch => {
    dispatch(startLoading(loadingTypes.tendersGetNewQuoteNumber));
    try {
        return await axiosInstance.get("/quotations/getNewQuotationNumber");
    } catch (err) {
        dispatch(setError(errorTypes.tendersGetNewQuoteNumber, `${err.message ? err.message + " at " + errorTypes.tendersGetNewQuoteNumber : "Something went wrong at " + errorTypes.tendersGetNewQuoteNumber }`));
    } finally {
        dispatch(endLoading());
    }
};

//endregion
// END: Quotes

// START: EOI
//region

export const getAllEOINumbers = (type) => async dispatch => {
    dispatch(startLoading(loadingTypes.tendersGetAllEOINumbers));
    try {
        const res = await axiosInstance.get(`/eoi/getAll?type=${type}`);
        dispatch(setAllEOINumbers(res.data));
    } catch (err) {
        dispatch(setError(errorTypes.tendersGetAllEOINumbers, `${err.message ? err.message + " at " + errorTypes.tendersGetAllEOINumbers : "Something went wrong at " + errorTypes.tendersGetAllEOINumbers }`));
    } finally {
        dispatch(endLoading());
    }
};

export const addEOI = (id, data) => async dispatch => {
    dispatch(startLoading(loadingTypes.tendersAddEOI));
    try {
        await axiosInstance.post(`/eoi/addEoi?coordinatorId=${id}`, data)
        dispatch(setSuccessMessage("Success! EOI added."));
    } catch (err) {
        dispatch(setError(errorTypes.tendersAddEOI, `${err.message ? err.message + " at " + errorTypes.tendersAddEOI : "Something went wrong at " + errorTypes.tendersAddEOI }`));
    } finally {
        dispatch(endLoading());
    }
};

export const updateEOI = (id, data) => async dispatch => {
    dispatch(startLoading(loadingTypes.tendersUpdateEOI));
    try {
        await axiosInstance.post(`/eoi/updateEoi?coordinatorId=${id}`, data);
        dispatch(setSuccessMessage("Success! EOI updated."));
    } catch (err) {
        dispatch(setError(errorTypes.tendersUpdateEOI, `${err.message ? err.message + " at " + errorTypes.tendersUpdateEOI : "Something went wrong at " + errorTypes.tendersUpdateEOI }`));
    } finally {
        dispatch(endLoading());
    }
};

export const deleteEOI = (id) => async dispatch => {
    dispatch(startLoading(loadingTypes.tendersDeleteEOI));
    try {
        await axiosInstance.delete(`/eoi/deleteEoi?uniqueId=${id}`);
        dispatch(setSuccessMessage("Success! EOI removed."));
    } catch (err) {
        dispatch(setError(errorTypes.tendersDeleteEOI, `${err.message ? err.message + " at " + errorTypes.tendersDeleteEOI : "Something went wrong at " + errorTypes.tendersDeleteEOI }`));
    } finally {
        dispatch(endLoading());
    }
}

export const undoDeleteEOI = (id, data) => async dispatch => {
    dispatch(startLoading(loadingTypes.tendersUndoDeleteEOI));
    try {
        await axiosInstance.post(`/eoi/undoDelete?uniqueId=${id}`, data);
        dispatch(setSuccessMessage("Success! EOI restored."));
    } catch (err) {
        dispatch(setError(errorTypes.tendersUndoDeleteEOI, `${err.message ? err.message + " at " + errorTypes.tendersUndoDeleteEOI : "Something went wrong at " + errorTypes.tendersUndoDeleteEOI }`));
    } finally {
        dispatch(endLoading());
    }
};

export const getNewEOINumber = () => async dispatch => {
    dispatch(startLoading(loadingTypes.tendersGetNewEOINumber));
    try {
        return await axiosInstance.get("/eoi/getNewEoiNumber");
    } catch (err) {
        dispatch(setError(errorTypes.tendersGetNewEOINumber, `${err.message ? err.message + " at " + errorTypes.tendersGetNewEOINumber : "Something went wrong at " + errorTypes.tendersGetNewEOINumber }`));
    } finally {
        dispatch(endLoading());
    }
};

//endregion
// END: EOI