import React from "react";
import NumberFormat from "react-number-format";
import PropTypes from "prop-types";
import {Field} from "react-final-form";
import {OnChange} from "react-final-form-listeners";
import createDecorator from "final-form-focus";

import { useForm, useFormState } from "react-final-form";
import get from "lodash/get";

export function NumberFormatOnInput(props) {
    const { inputRef, onChange, value, ...other } = props;

    return (
        <NumberFormat
            {...other}
            getInputRef={inputRef}
            onValueChange={(values) => {
                onChange({
                    target: {
                        name: props.name,
                        value: values.value,
                    },
                });
            }}
            value={value}
            allowLeadingZeros={false}
            thousandSeparator
            isNumericString
            maxLength={value >= 9999999999 ? 10 : null}
            decimalScale={value >= 9999999999 ? 0 : 2}
        />
    );
}

NumberFormatOnInput.propTypes = {
    inputRef: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
};

export function PercentageFormatOnInput(props) {
    const { inputRef, onChange, value, ...other } = props;

    return (
        <NumberFormat
            {...other}
            suffix={'%'}
            decimalScale={value >= 100 ? 0 : 2}
            allowNegative={false}
            getInputRef={inputRef}
            allowLeadingZeros={false}
            value={value > 100 ? 100 : value}
            onValueChange={(values) => {
                onChange({
                    target: {
                        name: props.name,
                        value: values.value,
                    },
                });
            }}
            isNumericString
            maxLength={value >= 100 ? 3 : null}
        />
    );
}

PercentageFormatOnInput.propTypes = {
    inputRef: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
};

export const WhenFieldChanges = ({ shouldChangeHandler, field, set, to }) => {
    const { values } = useFormState();
    const form = useForm();

    return (
        <OnChange name={field}>
            {() => {
                if (shouldChangeHandler)
                    shouldChangeHandler(get(values, field)) &&
                    form.change(set, to);
                else form.change(set, to);
            }}
        </OnChange>
    );
};

WhenFieldChanges.propTypes = {
    field: PropTypes.string.isRequired,
    set: PropTypes.string.isRequired,
    shouldChangeHandler: PropTypes.func,
    to: PropTypes.any
};
WhenFieldChanges.defaultProps = {};

export const Condition = ({when, is, children}) => (
    <Field name={when} subscription={{value: true}}>
        {({input: {value}}) => (value === is ? children : null)}
    </Field>
)

export const focusOnError = createDecorator();

export const stringifyKeys = values =>
    Object.keys(values).reduce((result, key) => {
        result[`key${key}`] = values[key]
        return result
    }, {})

export const destringifyKeys = values =>
    Object.keys(values).reduce((result, key) => {
        result[Number(key.substring(3))] = values[key]
        return result
    }, {})