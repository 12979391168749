import * as actionTypes from './actions';

const initialState = {
    allAuditLogs: [],
    auditLog: [],
    transactionTypes: [],
    transactionSubTypes: []
};

const setAllAuditLogs = (state, { allAuditLogs }) => ({ ...state, allAuditLogs });
const setAuditLog = (state, { auditLog }) => ({ ...state, auditLog });
const setTransactionTypes = (state, { transactionTypes }) => ({ ...state, transactionTypes });
const setTransactionSubTypes = (state, { transactionSubTypes }) => ({ ...state, transactionSubTypes });

export default (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_ALL_AUDIT_LOGS:
            return setAllAuditLogs(state, action);
        case actionTypes.SET_AUDIT_LOG:
            return setAuditLog(state, action);
        case actionTypes.SET_TRANSACTION_TYPES:
            return setTransactionTypes(state, action);
        case actionTypes.SET_TRANSACTION_SUBTYPES:
            return setTransactionSubTypes(state, action);
        default:
            return state;
    }
};