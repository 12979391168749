import React from "react";
import { createBrowserHistory } from "history";
import { Router, Route, Switch, Redirect } from "react-router";
import allRoutes from "./assets/helpers/routes";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import axiosInstance from "./assets/helpers/axiosInstance";

const App = () => {
	const hist = createBrowserHistory();

	const getRoutes = routes => {
		return routes.map(route => {
			if (route.external) {
				return null;
			} else if (route.collapse) {
				return route.views.map(item => {
					if (item.collapse) {
						return item.views.map(view => {
							if (view.external) {
								return null;
							} else {
								return (
									<Route
										key={view.id}
										exact
										path={view.path}
										component={view.component}
									/>
								);
							}
						});
					} else {
						if (item.external) {
							return null;
						} else {
							return (
								<Route
									key={item.id}
									exact
									path={item.path}
									component={item.component}
								/>
							);
						}
					}
				});
			} else {
				return (
					<Route
						key={route.id}
						exact
						path={route.path}
						component={route.component}
					/>
				);
			}
		});
	};

	const routerMap = allRoutes.map(allRoute => {
		if (allRoute.layout) {
			return (
				<Route key={allRoute.id} exact path={allRoute.paths}>
					<allRoute.layout>{getRoutes(allRoute.routes)}</allRoute.layout>
				</Route>
			);
		} else {
			return getRoutes(allRoute.routes);
		}
	});
	// For common config
	axiosInstance.defaults.headers.post["Content-Type"] = "application/json";
	axiosInstance.defaults.headers.get["Accept"] = "application/json";

	axiosInstance.interceptors.response.use(
		function (response) {
			return response;
		},
		function (error) {
			if (error.response.status === 401) {
				hist.push("/app/login");
			}
			return Promise.reject(error);
		}
	);

	return (
		<MuiPickersUtilsProvider utils={MomentUtils}>
			<Router history={hist}>
				<Switch>
					<Redirect from="/oauth2/redirect" to="/app" />
					{routerMap}
					<Redirect from="/" to="/app/admin/boardrooms" />
				</Switch>
			</Router>
		</MuiPickersUtilsProvider>
	);
};

export default App;
