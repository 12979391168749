import axiosInstance from "../../assets/helpers/axiosInstance";

export const GET_CURRENT_PERMISSIONS_START =
	"user/GET_CURRENT_PERMISSIONS_START";
export const GET_CURRENT_PERMISSIONS_SUCCESS =
	"user/GET_CURRENT_PERMISSIONS_SUCCESS";
export const GET_CURRENT_PERMISSIONS_FAIL = "user/GET_CURRENT_PERMISSIONS_FAIL";

export const GET_USERS_START = "user/GET_USERS_START";
export const GET_USERS_SUCCESS = "user/GET_USERS_SUCCESS";
export const GET_USERS_FAIL = "user/GET_USERS_FAIL";

export const GET_USER_DETAILS_START = "user/GET_USER_DETAILS_START";
export const GET_USER_DETAILS_SUCCESS = "user/GET_USER_DETAILS_SUCCESS";
export const GET_USER_DETAILS_FAIL = "user/GET_USER_DETAILS_FAIL";

export const GET_CURRENT_USER = "user/GET_CURRENT_USER";

export const getUsers = () => dispatch => {
	dispatch({ type: GET_USERS_START });
	return axiosInstance
		.get("/api/users")
		.then(res =>
			dispatch({ type: GET_USERS_SUCCESS, data: res.data, error: null })
		)
		.catch(error => dispatch({ type: GET_USERS_FAIL, data: null, error }));
};

export const getUserDetails = id => dispatch => {
	dispatch({ type: GET_USER_DETAILS_START });
	return axiosInstance
		.get("/api/user/" + id)
		.then(res =>
			dispatch({ type: GET_USER_DETAILS_SUCCESS, data: res.data, error: null })
		)
		.catch(error =>
			dispatch({ type: GET_USER_DETAILS_FAIL, data: null, error: error })
		);
};

export const getCurrentUser = () => dispatch =>
	axiosInstance
		.get("/api/user/me")
		.then(res => dispatch({ type: GET_CURRENT_USER, payload: res.data }));
