import React, {useEffect, useState} from "react";
import MaterialTable from "material-table";
import DetailsDialog from "./DetailsDialog";
import styled, {css} from "styled-components";
import {Clear, Done, Warning} from "@material-ui/icons";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import {connect} from "react-redux";
import * as tenderActions from "../../../store/tenders/actions";
import {getPermissionsByRole} from "../../../store/permissions/actions";
import FilterAutocomplete from "../../../components/Inputs/Filter/FilterAutocomplete";
import Button from "@material-ui/core/Button";
import {formatLongDate, formatShortDate, formatShortTableDate, getFilteredData} from "../../../assets/helpers/globals";
import debounce from "lodash/debounce";
import isEqual from "lodash/isEqual";
import {useInterval} from "../../../hooks/useInterval";
import * as loadingTypes from "../../../store/loading/types";
import AuditLoggingTableDialog from "../../Audit/TableDialog";
import ConfirmationDialog from "../../../components/Dialogs/ConfirmationDialog/ConfirmationDialog";
import {getAuditLog, getTransactionSubTypes} from "../../../store/audit/actions";
import NewTenderDialog from "./NewTenderDialog";
import {getAllProfitCentres} from "../../../store/profitCentre/actions";
import {getAllLegalEntities} from "../../../store/legalEntities/actions";
import CustomAction from "../../../components/Table/CustomAction";
import {getUsers} from "../../../store/user/actions";
import {CsvBuilder} from 'filefy';
import {getTimeZones} from "../../../store/timeZone/actions";

const FilterPaper = styled(Paper)`
  margin-bottom: 18px;
  margin-top: 14px;
`;

const FilterGrid = styled(Grid)`
  ${props => props.container && css`
    margin-left: 10px; 
    width: 98%;
  `}
  ${props => props.item && css`
    align-self: center
  `}
`;

const P = styled.p`
  color: #388e3c;
  border: 1px solid #4caf50;
  border-radius: 16px;
  height: 24px;
  padding-left: 4px;
  padding-right: 8px;
  white-space: nowrap;
  display: flex;
  max-width: fit-content;
  ${props => props.status === "Deleted" && css`
    color: #d32f2f;
    border: 1px solid #f44336;
  `}
`;

const RegisteredIcon = styled(Done)`
  display: none;
  ${props => props.status === "Registered" && css`
    margin-top: -1px;
    margin-right: 2px;
    display: flex;
    color: #388e3c;
  `}
`;

const DeletedIcon = styled(Warning)`
  display: none;
  ${props => (props.status === "Deleted") && css`
    margin-top: -1px;
    margin-right: 2px;
    display: flex;
    color: #d32f2f;
  `}
`;

const TenderRegister = ({
						// state
							tenders,
							timeZones,
							legalEntities,
						    allProfitCentres,
							users,
							auditLog,
							transactionSubTypes,
							loading,
						    usersLoading,

						// actions
							getAllTenders,
							addTender,
							updateTender,
							deleteTender,
							undoDeleteTender,
							getNewTenderNumber,

							getTimeZones,
							getAllLegalEntities,
							getAllProfitCentres,
							getUsers,

							getUserRoles,
							getAuditLog,
							getTransactionSubTypes,
					   }) => {
	const initialFilterState = {userFullName: null, entityName: null, clientName: null, centres: null, status: null}
	const initialDialogState = {open: false, data: {}}
	const initialAuditState = {open: false, type: ""}

	const [addTenderDialogOpen, setAddTenderDialogOpen] = useState(false);
	const [editDialogOpen, setEditDialogOpen] = useState(false);
	const [deleteTenderConfirmationOpen, setDeleteTenderConfirmationOpen] = useState(false);
	const [undoDeleteTenderConfirmationOpen, setUndoDeleteTenderConfirmationOpen] = useState(false);
	const [tenderDetails, setTenderDetails] = useState(initialDialogState);
	const [selectedTender, setSelectedTender] = useState(undefined);
	const [auditLogDetails, setAuditLogDetails] = useState(undefined);
	const [auditLogDialogOpen, setAuditLogDialogOpen] = useState(initialAuditState);
	const [adding, setAdding] = useState(undefined);
	const [editing, setEditing] = useState(undefined);
	const [filter, setFilter] = useState(initialFilterState);
	const [roles, setRoles] = useState({
		is_tender_register_viewer: false,
		is_tender_register_creator: false,
		is_tender_register_modifier: false,
	})

	const actions = [
		{
			icon: "visibility",
			tooltip: "View Tender",
			onClick: async (_event, tender) => {
				setTenderDetails({open: true, data: tender});
			},
		},
		{
			icon: "edit",
			tooltip: "Edit Tender",
			onClick: async (_event, tender) => {
				if (!timeZones || !timeZones.length)
					getTimeZones();
				if (!legalEntities || !legalEntities.length)
					await getAllLegalEntities();
				if (!allProfitCentres || !allProfitCentres.length)
					await getAllProfitCentres();
				if (!users || !users.length)
					await getUsers();

				setEditing({
					...tender,
					dueDate: new Date(tender.dueDate),
					siteVisit: tender.siteVisit ? new Date(tender.siteVisit) : "",
					bidType: tender.bidType && tender.bidType !== "" ? tender.bidType : "Self bidding",
					legalEntityType: tender.legalEntity ? "existingLegalEntity" :
						tender.otherLegalEntity ? "otherLegalEntity" :
							"existingLegalEntity"
				})
				setEditDialogOpen(true);
			},
			hidden: !(roles.is_tender_register_creator || roles.is_tender_register_modifier)
		},
		rowData =>
			({
			icon: "delete",
			tooltip: "Remove Tender",
			onClick: async (_event, tender) => {
				setSelectedTender(tender);
				setDeleteTenderConfirmationOpen(true)
			},
			hidden: !(roles.is_tender_register_modifier && !rowData.isDeleted)
		}),
		rowData =>
			({
			icon: "replay",
			tooltip: "Restore Tender",
			onClick: async (_event, tender) => {
				setSelectedTender(tender);
				setUndoDeleteTenderConfirmationOpen(true)
			},
			hidden: !(roles.is_tender_register_modifier && rowData.isDeleted)
		}),
		{
			icon: "history",
			tooltip: "View Tender History",
			onClick: async (_event, tender) => {
				if (!isEqual(tender, auditLogDetails)) {
					let typeId

					if (!transactionSubTypes || !transactionSubTypes.length) {
						let res = await getTransactionSubTypes();
						typeId = res.data.find(transactionSubType => transactionSubType.subTypeName === "N/A" && transactionSubType.transactionType?.typeName === "Tender").uniqueId;
					} else
						typeId = transactionSubTypes.find(transactionSubType => transactionSubType.subTypeName === "N/A" && transactionSubType.transactionType?.typeName === "Tender").uniqueId;

					await getAuditLog(tender.uniqueId, typeId);
					setAuditLogDetails(tender)
					setAuditLogDialogOpen({open: true, type: "Tender"})
				}
				else
					setAuditLogDialogOpen({open: true, type: "Tender"})
			}
		},
		{
			icon: 'refresh',
			tooltip: 'Refresh Data',
			isFreeAction: true,
			onClick: debounce(() => getAllTenders(""), 300, {
				'leading': false,
				'trailing': true
			}),
			disabled: !(roles.is_tender_register_viewer || roles.is_tender_register_creator || roles.is_tender_register_modifier)
		},
		{
			icon: 'add',
			isFreeAction: true,
			custom: true,
			component: Button,
			onClick: () => {},
			props: {
				disabled:  !roles.is_tender_register_creator,
				variant: "contained",
				color: "primary",
				children: "Add tender",
				onClick: async () => {
					let tenderNumber = await getNewTenderNumber();

					if (!timeZones || !timeZones.length)
						getTimeZones();
					if (!legalEntities || !legalEntities.length)
						getAllLegalEntities();
					if (!allProfitCentres || !allProfitCentres.length)
						getAllProfitCentres();
					if (!users || !users.length)
						getUsers();

					setAdding({
						tenderNumber: tenderNumber.data,
						legalEntityType: "existingLegalEntity",
						legalEntity: '',
						technicalLead: '',
						tenderCoordinator: '',
						profitCentres: '',
						bidType: 'Self bidding',
					})
					setAddTenderDialogOpen(true)
				},
			},
		}
	];

	const filterData = (data, query) => {
		return data.filter((item) => {
			for (let key in query) {
				switch (key) {
					case "entityName":
						if (item.legalEntity === null || item.legalEntity[key] === undefined || !query[key].includes(item.legalEntity[key])) {
							return false;
						}
						break;
					case "userFullName":
						if (item.tenderCoordinator === null || item.tenderCoordinator[key] === undefined || !query[key].includes(item.tenderCoordinator[key])) {
							return false;
						}
						break;
					case "centres":
						if (item.profitCentres === null || item.profitCentres[key] === undefined || !query[key].includes(item.profitCentres[key])) {
							return false;
						}
						break;
					default:
						if (item[key] === undefined || !query[key].includes(item[key])) {
							return false;
						}
						break;
				}
			}
			return true;
		});
	};

	useInterval(() => {
		if (roles.is_tender_register_viewer || roles.is_tender_register_creator || roles.is_tender_register_modifier)
			getAllTenders("");
	}, 300000);

	useEffect(() => {
		(async function() {
			const res = await getUserRoles("tender","register");
			setRoles({
				is_tender_register_creator: res.some(name => name === "tender_register_creator"),
				is_tender_register_viewer: res.some(name => name === "tender_register_viewer"),
				is_tender_register_modifier: res.some(name => name === "tender_register_modifier"),
			});
		})();

		if (roles.is_tender_register_viewer || roles.is_tender_register_creator || roles.is_tender_register_modifier)
			getAllTenders("");
	}, [getUserRoles, getAllTenders, roles.is_tender_register_viewer, roles.is_tender_register_creator, roles.is_tender_register_modifier])

	return (
		<>
			<FilterPaper>
				<FilterGrid container spacing={2}>
					<FilterGrid item xs={12} sm={6} md={4} lg={3}>
						<FilterAutocomplete
							onChange={(event, newValue) => setFilter({...filter, userFullName: newValue})}
							value={filter.userFullName}
							options={tenders.map(tender => tender.tenderCoordinator?.userFullName)}
							placeholder="Tender Coordinator"
							noOptionsText="No Tender Coordinators"
						/>
					</FilterGrid>
					<FilterGrid item xs={12} sm={6} md={4} lg={3}>
						<FilterAutocomplete
							onChange={(event, newValue) => setFilter({...filter, entityName: newValue})}
							value={filter.entityName}
							options={tenders.map(tender => tender.legalEntity?.entityName)}
							placeholder="Legal Entity"
							noOptionsText="No Legal Entities"
						/>
					</FilterGrid>
					<FilterGrid item xs={12} sm={6} md={4} lg={3}>
						<FilterAutocomplete
							onChange={(event, newValue) => setFilter({...filter, clientName: newValue})}
							value={filter.clientName}
							options={tenders.map(tender => tender.clientName)}
							placeholder="Client Name"
							noOptionsText="No Client Names"
						/>
					</FilterGrid>
					<FilterGrid item xs={12} sm={6} md={4} lg={3}>
						<FilterAutocomplete
							onChange={(event, newValue) => setFilter({...filter, centres: newValue})}
							value={filter.centres}
							options={tenders.map(tender => tender.profitCentres?.centres)}
							placeholder="Profit Centres"
							noOptionsText="No Profit Centres"
						/>
					</FilterGrid>
					<FilterGrid item xs={12} sm={6} md={4} lg={3}>
						<FilterAutocomplete
							onChange={(event, newValue) => setFilter({...filter, status: newValue})}
							value={filter.status}
							options={tenders.map(tender => tender.status)}
							placeholder="Status"
							noOptionsText="No Statuses"
						/>
					</FilterGrid>
					<FilterGrid item xs={12} sm={6} md={4} lg={3}>
						<Button
							color="secondary"
							size="small"
							startIcon={<Clear/>}
							disabled={isEqual(filter, initialFilterState)}
							onClick={() => setFilter(initialFilterState)}>
							Clear All
						</Button>
					</FilterGrid>
				</FilterGrid>
			</FilterPaper>

			<MaterialTable
				columns={[
					{
						title: "Unique ID",
						field: "uniqueId",
						hidden: true
					},
					{
						title: "Created Date",
						field: "createdDate",
						type: "date",
						defaultSort: "desc"
					},
					{
						title: "Tender Number",
						field: "tenderNumber"
					},
					{
						title: "Tender Description",
						field: "tenderDescription"
					},
					{
						title: "Tender Coordinator",
						field: "tenderCoordinator.userFullName"
					},
					{
						title: "Technical Lead",
						field: "technicalLead.userFullName",
						hidden: true
					},
					{
						title: "Legal Entity",
						field: "legalEntity.entityName",
						render: rowData => rowData.legalEntity ? <span>{rowData.legalEntity.entityName}</span> :
							<span>{rowData.otherLegalEntity}</span>,
					},
					{
						title: "Client Name",
						field: "clientName"
					},
					{
						title: "Bid Type",
						field: "bidType",
						hidden: true
					},
					{
						title: "Bidding Comment",
						field: "bidTypeComment",
						hidden: true
					},
					{
						title: "Due Date",
						field: "dueDate",
						type: "date",
						render: rowData => <span>{formatLongDate(rowData.dueDate) + " " + rowData.dueDateTimeZone}</span>
					},
					{
						title: "Due Date Time Zone",
						field: "dueDateTimeZone",
						hidden: true
					},
					{
						title: "Site Visit",
						field: "siteVisit",
						type: "date",
						hidden: true
					},
					{
						title: "Profit Centre",
						field: "profitCentres.centres"
					},
					{
						title: "Modified Date",
						field: "lastModifiedDate",
						type: "date",
						hidden: true
					},
					{
						title: "Comments",
						field: "comments",
						hidden: true
					},
					{
						title: "Deleted Date",
						field: "deletedAt",
						type: "date",
						hidden: true
					},
					{
						title: "Status",
						field: "status",
						hidden: !roles.is_tender_register_modifier,
						render: data => (
							<P status={data.status}>
								<RegisteredIcon status={data.status}/>
								<DeletedIcon status={data.status}/>
								{data.status}</P>
						),
					}
				]}
				components={{ Action: CustomAction }}
				actions={actions}
				data={isEqual(filter, initialFilterState) ? tenders : getFilteredData(filter, tenders, [], filterData)}
				isLoading={addTenderDialogOpen === false &&
					editDialogOpen === false && (
						loading.type === loadingTypes.tendersGetAllTenders ||
						loading.type === loadingTypes.tendersGetNewTenderNumber ||
						loading.type === loadingTypes.timeZonesGetTimeZones ||
						loading.type === loadingTypes.legalEntitiesGetAllLegalEntities ||
						loading.type === loadingTypes.profitCentresGetAllProfitCentres ||
						usersLoading ||
						loading.type === loadingTypes.auditGetTransactionSubTypes ||
						loading.type === loadingTypes.auditGetAuditLog ||
						loading.type === loadingTypes.permissionsGetPermissionsByRole)
				}
				options={{
					actionsColumnIndex: -1,
					pageSize: 10,
					searchFieldAlignment: "left",
					showTitle: false,
					sorting: true,
					isLoading: true,
					search: true,
					filtering: false,
					exportButton: {
						csv: true,
						pdf: false
					},
					exportCsv: (columns, data) => {
						const columnTitles = columns.map(columnDef => columnDef.title);

						const csvData = data.map(rowData =>
							columns.map(columnDef => {
								switch (columnDef.field) {
									case "legalEntity.entityName":
										return rowData.legalEntity ? rowData.legalEntity.entityName : rowData.otherLegalEntity;
									case "tenderCoordinator.userFullName":
										return rowData.tenderCoordinator?.userFullName;
									case "technicalLead.userFullName":
										return rowData.technicalLead?.userFullName;
									case "profitCentres.centres":
										return rowData.profitCentres?.centres;
									default:
										break;
								}
								if (columnDef.type === "date" && rowData[columnDef.field]) {
									return formatShortTableDate(rowData[columnDef.field])
								}
								return rowData[columnDef.field]
							})
						);

						return new CsvBuilder(`Tender Register Export - ${formatShortDate(new Date())}`)
							.setColumns(columnTitles)
							.addRows(csvData)
							.exportFile();
					},
				}}
			/>

			<DetailsDialog
				key={"Tender-Register-Details-" + tenderDetails.data.uniqueId}
				details={tenderDetails.data}
				onClose={() => setTenderDetails(initialDialogState)}
				open={tenderDetails.open}
			/>
			<NewTenderDialog
				open={addTenderDialogOpen}
				onClose={() => setAddTenderDialogOpen(false)}
				onSubmit={async (technicalUserId, coordinatorUserId, newTender) => {
					await addTender(technicalUserId, coordinatorUserId, newTender);
					getAllTenders("");
					setAddTenderDialogOpen(false)
				}}
				legalEntities={legalEntities}
				timeZones={timeZones}
				allProfitCentres={allProfitCentres}
				users={users}
				initialValues={adding}
				loading={loading}
			/>
			<NewTenderDialog
				key={"Tender-Register-Edit-" + editing?.uniqueId}
				open={editDialogOpen}
				onClose={() => setEditDialogOpen(false)}
				onSubmit={async (technicalUserId, coordinatorUserId, updatedTender) => {
					await updateTender(technicalUserId, coordinatorUserId, updatedTender);
					getAllTenders("");
					setEditDialogOpen(false);
				}}
				legalEntities={legalEntities}
				timeZones={timeZones}
				allProfitCentres={allProfitCentres}
				users={users}
				initialValues={editing}
				loading={loading}
			/>
			<AuditLoggingTableDialog
				open={auditLogDialogOpen.open}
				auditPayload={auditLog}
				type={auditLogDialogOpen.type}
				onClose={() => setAuditLogDialogOpen(initialAuditState)}
				loading={loading.type === loadingTypes.auditGetAuditLog}
			/>
			<ConfirmationDialog
				open={deleteTenderConfirmationOpen}
				handleClose={() => setDeleteTenderConfirmationOpen(false)}
				handleConfirmation={async confirm => {
					if (confirm) {
						await deleteTender(selectedTender.uniqueId);
						getAllTenders("");
					}
					setDeleteTenderConfirmationOpen(false);
				}}
				headerText={"Confirm tender removal"}
				promptText={"Are you sure you want to remove the tender ?"}
				loading={loading.type === loadingTypes.tendersDeleteTender}
			/>
			<ConfirmationDialog
				open={undoDeleteTenderConfirmationOpen}
				handleClose={() => setUndoDeleteTenderConfirmationOpen(false)}
				handleConfirmation={async confirm => {
					if (confirm) {
						await undoDeleteTender(selectedTender.uniqueId);
						getAllTenders("");
					}
					setUndoDeleteTenderConfirmationOpen(false);
				}}
				headerText={"Confirm tender restoration"}
				promptText={"Are you sure you want to restore the tender ?"}
				loading={loading.type === loadingTypes.tendersUndoDeleteTender}
			/>
		</>
	);
}

const mapStateToProps = state => ({
	tenders: state.tendersReducer.tenders,
	legalEntities: state.legalEntitiesReducer.legalEntities,
	allProfitCentres: state.profitCentreReducer.allProfitCentres,
	users: state.userReducer.users,
	timeZones: state.timeZoneReducer.timeZones,

	usersLoading: state.userReducer.usersLoading,
	loading: state.loadingReducer.loading,
	auditLog: state.auditReducer.auditLog,
	transactionSubTypes: state.auditReducer.transactionSubTypes,
});

const mapDispatchToProps = dispatch => ({
	getAllTenders: (type) => dispatch(tenderActions.getAllTenders(type)),
	addTender: (technicalUserId, coordinatorUserId, tender) => dispatch(tenderActions.addTender(technicalUserId, coordinatorUserId, tender)),
	updateTender: (technicalUserId, coordinatorUserId, tender) => dispatch(tenderActions.updateTender(technicalUserId, coordinatorUserId, tender)),
	deleteTender: id => dispatch(tenderActions.deleteTender(id)),
	undoDeleteTender: id => dispatch(tenderActions.undoDeleteTender(id)),
	getNewTenderNumber: () => dispatch(tenderActions.getNewTenderNumber()),

	getTimeZones: () => dispatch(getTimeZones()),
	getAllLegalEntities: () => dispatch(getAllLegalEntities()),
	getAllProfitCentres: () => dispatch(getAllProfitCentres()),
	getUsers : () => dispatch(getUsers()),

	getUserRoles: (roleType, roleSubType) => dispatch(getPermissionsByRole(roleType, roleSubType)),
	getAuditLog: (id, type) => dispatch(getAuditLog(id, type)),
	getTransactionSubTypes: () => dispatch(getTransactionSubTypes()),
});

export default connect(mapStateToProps, mapDispatchToProps)(TenderRegister);