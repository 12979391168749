import axiosInstance from '../../assets/helpers/axiosInstance';
import { endLoading, startLoading } from "../loading/actions";
import * as loadingTypes from "../loading/types";
import {setError} from "../error/actions";
import * as errorTypes from "../error/types";

export const SET_ALL_AUDIT_LOGS = "audit/SET_ALL_AUDIT_LOGS";
export const SET_AUDIT_LOG = "audit/SET_AUDIT_LOG";
export const SET_TRANSACTION_TYPES = "audit/SET_TRANSACTION_TYPES";
export const SET_TRANSACTION_SUBTYPES = "audit/SET_TRANSACTION_SUBTYPES";

const setAllAuditLogs = allAuditLogs => ({
    type: SET_ALL_AUDIT_LOGS,
    allAuditLogs,
});

const setAuditLog = auditLog => ({
    type: SET_AUDIT_LOG,
    auditLog,
});

const setTransactionTypes = transactionTypes => ({
    type: SET_TRANSACTION_TYPES,
    transactionTypes
});

const setTransactionSubTypes = transactionSubTypes => ({
    type: SET_TRANSACTION_SUBTYPES,
    transactionSubTypes
});

export const getAllAuditLogs = () => async dispatch => {
    dispatch(startLoading(loadingTypes.auditGetAllAuditLogs));
    try {
        const res = await axiosInstance.get("/auditLogging/getAllLogs");
        dispatch(setAllAuditLogs(res.data));
    } catch (err) {
        dispatch(setError(errorTypes.auditGetAllAuditLogs, `${err.message ? err.message + " at " + errorTypes.auditGetAllAuditLogs : "Something went wrong at " + errorTypes.auditGetAllAuditLogs }`));
    } finally {
        dispatch(endLoading());
    }
};

export const getAuditLog = (id, type) => async dispatch => {
    dispatch(startLoading(loadingTypes.auditGetAuditLog));
    try {
        const res = await axiosInstance.get(`/auditLogging/getOneLog?transactionId=${id}&transactionSubType=${type}`);
        dispatch(setAuditLog(res.data));
    } catch (err) {
        dispatch(setError(errorTypes.auditGetAuditLog, `${err.message ? err.message + " at " + errorTypes.auditGetAuditLog : "Something went wrong at " + errorTypes.auditGetAuditLog }`));
    } finally {
        dispatch(endLoading());
    }
};

export const getTransactionTypes = () => async dispatch => {
    dispatch(startLoading(loadingTypes.auditGetTransactionTypes));
    try {
        const res = await axiosInstance.get("/auditLogging/getTransactionType");
        dispatch(setTransactionTypes(res.data));
        return res;
    } catch (err) {
        dispatch(setError(errorTypes.auditGetTransactionTypes, `${err.message ? err.message + " at " + errorTypes.auditGetTransactionTypes : "Something went wrong at " + errorTypes.auditGetTransactionTypes }`));
    } finally {
        dispatch(endLoading());
    }
};

export const getTransactionSubTypes = () => async dispatch => {
    dispatch(startLoading(loadingTypes.auditGetTransactionSubTypes));
    try {
        const res = await axiosInstance.get("/auditLogging/getTransactionSubType");
        dispatch(setTransactionSubTypes(res.data));
        return res;
    } catch (err) {
        dispatch(setError(errorTypes.auditGetTransactionSubTypes, `${err.message ? err.message + " at " + errorTypes.auditGetTransactionSubTypes : "Something went wrong at " + errorTypes.auditGetTransactionSubTypes }`));
    } finally {
        dispatch(endLoading());
    }
};