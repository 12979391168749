import React, { Component } from "react";
import PropTypes from "prop-types";
import styles from "./CustomToolbar.module.css";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import Avatar from '@material-ui/core/Avatar';
import Grid from '@material-ui/core/Grid';



// import Badge from "@material-ui/core/Badge";
import MenuIcon from "@material-ui/icons/Menu";
// import NotificationsIcon from "@material-ui/icons/Notifications";
import NotificationsDialog from "../../Dialogs/NotificationsDialog/NotificationsDialog";
import { withRouter } from "react-router-dom";

class CustomToolbar extends Component {
	state = {
		notificationsAnchor: null,
		dummyData: [
			// {
			// 	id: 1,
			// 	title: "Test Notification",
			// 	description:
			// 		"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean in ullamcorper nunc, eget mollis urna.",
			// },
		],
	};

	toggle = () => {
		if (this.props.isMobile) {
			return (
				<IconButton
					edge="start"
					color="primary"
					className={styles.ToggleButton}
					onClick={this.props.toggleClicked}
				>
					<MenuIcon />
				</IconButton>
			);
		}
		return null;
	};

	notificationsClickHandler = () => {
		// this.setState({
		//     notificationsAnchor : event.currentTarget
		// });

		this.props.history.push("/app/notifications");
	};

	notificationsOnCloseHandler = () => {
		this.setState({
			notificationsAnchor: null,
		});
	};

	render() {
		const { notificationsAnchor, dummyData } = this.state;
		return (
			<div className={styles.Root}>
				<AppBar position="static" className={styles.AppBar}>
					<Toolbar>
						{this.toggle()}

						<Grid item xs={9} >
						<Typography variant="h6" className={styles.Title} >
							{this.props.title}
						</Typography>
						</Grid>

						<Grid  container direction="row" justify="flex-end" alignItems="center"  item xs={3}>
						<Typography variant="h6" className={styles.AvatarName} >
							{this.props.user?.userFullName}
						</Typography>

						<div className={styles.AvatarContainer}>
							<Avatar className={styles.AvatarIcon} >
								{this.props.user?.userName.charAt(0)}
							</Avatar>
						</div>



						</Grid>


						{/* <IconButton
							edge="start"
							color="inherit"
							onClick={this.notificationsClickHandler}
						>
							<Badge badgeContent={2} className={styles.Badge}>
								<NotificationsIcon color="secondary" />
							</Badge>
						</IconButton> */}
					</Toolbar>
				</AppBar>
				<NotificationsDialog
					open={notificationsAnchor ? true : false}
					anchorEl={notificationsAnchor}
					handleClose={this.notificationsOnCloseHandler}
					data={dummyData}
				/>
			</div>
		);
	}
}


export default withRouter(CustomToolbar);

Toolbar.propTypes = {
	toggleClicked: PropTypes.func,
	isMobile: PropTypes.bool,
	user: PropTypes.object,
};
