import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Typography from "@material-ui/core/Typography";

import styles from "./DetailsDialog.module.css";
import CustomDialogTitle from "../../Dialogs/CustomDialogTitle/CustomDialogTitle";
import DetailsList from "../../Lists/DetailsList/DetailsList";
import Loader from "../../Loader/Loader";
import isEqual from "lodash/isEqual";
import styled, {css} from "styled-components";

export const areEqual = (prevProps, nextProps) => {
	/*
    return true if passing nextProps to render would return
    the same result as passing prevProps to render,
    otherwise return false
    */
	return isEqual(prevProps, nextProps);
}

const ResponseButton = styled(Button)`
	${props => (props.active !== "" && props.status === props.active) && css`
		color: #1a73e8;
		background-color: rgba(35, 52, 72, 0.04);
	`}
`;

const DetailsDialog = ({ bookingDetails, handleClose, open, user }) => {
	const getTitle = id => {
		let index = bookingDetails.listItems.findIndex(item => item.id === id);
		return index !== -1 && bookingDetails.listItems[index].primaryText && bookingDetails.listItems[index].show
			? bookingDetails.listItems[index].primaryText
			: "(No Title)";
	};

	const isOrganizer = (bookingDetails, user) => {
		const organizer = bookingDetails.listItems.find(item => item.itemType === "attendeeList").attendees.find(item => item.organizer === true);
		return organizer !== undefined && organizer.email === user.userEmail;
	}

	const isCreator = (bookingDetails, user) => {
		return bookingDetails.creator && true && bookingDetails.creator.email === user.userEmail;
	}

	const isAttendee = (bookingDetails, user) => {
		const attendee = bookingDetails.listItems.find(item => item.itemType === "attendeeList").attendees.find(item => item.email === user.userEmail);
		return attendee !== undefined;
	}

	const getLoggedInAttendee = (bookingDetails, user) => bookingDetails.listItems.find(item => item.itemType === "attendeeList").attendees.find(item => item.email === user.userEmail);


	return bookingDetails.loaded ? (
		<Dialog
			onClose={handleClose}
			aria-labelledby="details-dialog-title"
			fullWidth
			maxWidth={"sm"}
			className={styles.Dialog}
			open={open}
		>
			<CustomDialogTitle
				id="details-dialog-title"
				showTextContent
				onEdit={
					bookingDetails.onEdit && (isOrganizer(bookingDetails, user) || isCreator(bookingDetails, user))
						? () => bookingDetails.onEdit(bookingDetails.id)
						: null
				}
				onDelete={
					bookingDetails.onDelete && (isOrganizer(bookingDetails, user) || isCreator(bookingDetails, user))
						? () => bookingDetails.onDelete(bookingDetails.id)
						: null
				}
				onClose={bookingDetails.onClose}
			>
				{bookingDetails.titleProperty
					? getTitle(bookingDetails.titleProperty)
					: bookingDetails.title}
			</CustomDialogTitle>
			<DialogContent className={styles.DialogContent} dividers>
				{bookingDetails.topParagraphItems &&
				bookingDetails.topParagraphItems.length > 0
					? bookingDetails.topParagraphItems.map((item, index) =>
						item.show ? (
							<p key={index} className={styles.TopParagraph}>
								{item.text}
							</p>
						) : null
					)
					: null}
				<DetailsList bookingListItems={bookingDetails.listItems} />
			</DialogContent>

			{bookingDetails.bottomBarActions && isAttendee(bookingDetails, user) ? (
				<DialogActions className={styles.ActionContainer}>
					<div className={styles.ActionDescriptionContainer}>
						<Typography className={styles.ActionDescription}>
							{bookingDetails.bottomBarActions.description}
						</Typography>
					</div>
					<div className={styles.ActionButtonContainer}>
						{bookingDetails.bottomBarActions.actions &&
						bookingDetails.bottomBarActions.actions.length > 0
							? bookingDetails.bottomBarActions.actions.map((item, index) => (
								<div className={styles.ActionButtonWrapper} key={index}>
									<ResponseButton
										onClick={item.handler}
										className={styles.ActionButton}
										disabled={item.loading}
										status={item.value}
										active={isAttendee(bookingDetails, user) ? getLoggedInAttendee(bookingDetails, user).responseStatus : ""}
										{...item.config}
									>
										{item.label}
									</ResponseButton>
									<Loader loading={item.loading} center size="small" />
								</div>
							))
							: null}
					</div>
				</DialogActions>
			) : null}
		</Dialog>
	) : null;
};

export default React.memo(DetailsDialog, areEqual);