import React from "react";
import AdminView from "./DetailsViews/AdminView";
import RequesterView from "./DetailsViews/RequesterView";

const ResponseDetailsDialog = ({
                                   details,
                                   onClose,
                                   open,
                                   isProjectManager,
                                   onSubmit,
                                   loading,
                                   downloadFile
}) =>
    isProjectManager ?
        <AdminView details={details} onClose={onClose} open={open} onSubmit={onSubmit} loading={loading} downloadFile={downloadFile} /> :
        <RequesterView details={details} onClose={onClose} open={open} loading={loading} downloadFile={downloadFile} />

export default ResponseDetailsDialog;
