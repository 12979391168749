import React from 'react'
import { MTableAction } from 'material-table'

const CustomAction = (props) => {
  if (props.action.custom) {
    const Comp = props.action.component
    return <Comp {...props.action.props} />
  }

  return <MTableAction {...props} />
}

export default CustomAction
