import React from 'react';
import Grid from '@material-ui/core/Grid';
import DigitalBoardsIFrame from '../../../components/IFrames/DigitalBoardsIFrame';

const CurrentEPCMTenders = () => {
    return (
        <Grid 
            container
            justify="center"
            alignItems="center"
            style={{height : "100%"}}>
                <DigitalBoardsIFrame src="https://boards.epcmdev.com/epcmtenders" title="Current EPCM Tenders" />
           </Grid>
    );
};

export default CurrentEPCMTenders;