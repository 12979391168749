import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import styles from './Loader.module.css';
import clsx from 'clsx';

const Loader = (props) => {
    const loaderColorClass = props.light ? styles.LightLoader : styles.DarkLoader;
    const loaderSizeCenterClass = props.center ? props.size === 'small' ? styles.CenterSmallLoader : styles.CenterBigLoader : props.size === 'small' ? styles.SmallLoader : styles.BigLoader;

    return (
        props.loading ? <CircularProgress className={clsx(loaderSizeCenterClass, loaderColorClass)} /> : null
    );
};

export default Loader;