import React /*, { useEffect } */ from "react";
import { Calendar, momentLocalizer, Views } from "react-big-calendar";
import moment from "moment";
// import Loader from '../../../components/Loader/Loader';
import "./BoardroomCalendar.scss";

const localizer = momentLocalizer(moment);

const BoardroomCalendar = props => {
	// useEffect(() => {
	//     console.log("rendered [BoardroomCalendar]");
	//   });

	const eventColor = event => {
		let resourceTitle = "";

		props.resourceMap.forEach(res => {
			if (res.resourceId === event.resourceId) {
				resourceTitle = res.title;
			}
		});
		switch (resourceTitle.toLowerCase()) {
			case "epcm offshore boardroom":
				return { className: "OffShore" };
			case "epcm onshore boardroom":
				return { className: "OnShore" };
			case "epcm pipelines boardroom":
				return { className: "Pipelines" };
			case "lng - boardroom":
				return { className: "Lng" };
			case "tesla - boardroom":
				return { className: "Tesla" };
			case "hot tap - boardroom":
				return { className: "HotTap" };
			default:
				return "";
		}
	};

	const convertToDate = (event, type) => {
		return type === "start"
			? new Date(event.start.dateTime)
			: new Date(event.end.dateTime);
	};

	const titleAccessor = event => {
		return event.summary ? event.summary : "(No Title)";
	};

	// const allDayAccessor = (event) => {

	//     const startDate = moment(new Date(event.start.dateTime));
	//     const endDate = moment(new Date(event.end.dateTime));

	//     return moment.duration(endDate.diff(startDate)).asHours() > 24;
	// };

	return (
		<div className={"Wrapper"}>
			<Calendar
				popup
				popupOffset={30}
				selectable
				events={props.events}
				localizer={localizer}
				defaultView={Views.DAY}
				views={["day", "work_week", "month"]}
				resources={props.resourceMap}
				titleAccessor={event => titleAccessor(event)}
				// allDayAccessor={allDayAccessor}
				startAccessor={event => convertToDate(event, "start")}
				endAccessor={event => convertToDate(event, "end")}
				resourceIdAccessor="resourceId"
				resourceTitleAccessor="title"
				date={props.date}
				view={props.view}
				onSelectEvent={props.handleSelectEvent}
				onSelectSlot={props.handleSelectOpenSlot}
				onRangeChange={props.handleRangeChange}
				onNavigate={props.handleNavigate}
				onView={props.handleViewChange}
				onDrillDown={props.handleDrillDown}
				eventPropGetter={eventColor}
			/>
			{/* <Loader loading={props.eventsLoading} /> */}
		</div>
	);
};

export default React.memo(BoardroomCalendar);
