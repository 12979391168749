import axiosInstance from '../../assets/helpers/axiosInstance';
import { setSuccessMessage } from "../success/actions";
import { endLoading, startLoading } from "../loading/actions";
import * as loadingTypes from "../loading/types";
import {setError} from "../error/actions";
import * as errorTypes from "../error/types";

export const SET_ALL_PURCHASES = "purchases/SET_ALL_PURCHASES";
export const SET_PURCHASE_PROJECTS = "purchases/SET_PURCHASE_PROJECTS";
export const SET_PURCHASE_TENDERS = "purchases/SET_PURCHASE_TENDERS";
export const SET_PURCHASE_IT_EQUIPMENT = "purchases/SET_PURCHASE_IT_EQUIPMENT";
export const SET_PURCHASE_OFFICE_EQUIPMENT = "purchases/SET_PURCHASE_OFFICE_EQUIPMENT";
export const SET_PURCHASE_OTHERS = "purchases/SET_PURCHASE_OTHERS";

const setAllPurchases = allPurchases => ({ type: SET_ALL_PURCHASES, allPurchases });
const setPurchaseProjects = purchaseProjects => ({ type: SET_PURCHASE_PROJECTS, purchaseProjects });
const setPurchaseTenders = purchaseTenders => ({ type: SET_PURCHASE_TENDERS, purchaseTenders });
const setPurchaseItEquipment = purchaseItEquipment => ({ type: SET_PURCHASE_IT_EQUIPMENT, purchaseItEquipment });
const setPurchaseOfficeEquipment = purchaseOfficeEquipment => ({ type: SET_PURCHASE_OFFICE_EQUIPMENT, purchaseOfficeEquipment });
const setPurchaseOthers = purchaseOthers => ({ type: SET_PURCHASE_OTHERS, purchaseOthers });

export const getAllPurchases = () => async dispatch => {
    dispatch(startLoading(loadingTypes.purchasesGetAllPurchases));
    try {
        const res = await axiosInstance.get("/purchases/getPurchases");
        dispatch(setAllPurchases(res.data));
    } catch (err) {
        dispatch(setError(errorTypes.purchasesGetAllPurchases, `${err.message ? err.message + " at " + errorTypes.purchasesGetAllPurchases : "Something went wrong at " + errorTypes.purchasesGetAllPurchases }`));
    } finally {
        dispatch(endLoading());
    }
};

// START: Project Purchase
//region
export const getPurchaseProjects = () => async dispatch => {
    dispatch(startLoading(loadingTypes.purchasesGetPurchaseProjects));
    try {
        const res = await axiosInstance.get("/purchases/getProjectPurchases");
        dispatch(setPurchaseProjects(res.data));
    } catch (err) {
        dispatch(setError(errorTypes.purchasesGetPurchaseProjects, `${err.message ? err.message + " at " + errorTypes.purchasesGetPurchaseProjects : "Something went wrong at " + errorTypes.purchasesGetPurchaseProjects }`));
    } finally {
        dispatch(endLoading());
    }
};

export const addPurchaseProjectRequest = (body) => async dispatch => {
    dispatch(startLoading(loadingTypes.purchasesAddPurchaseProjectRequest));
    try {
        await axiosInstance.post(`/purchases/addProjectPurchase`, body,{ headers: { "Content-Type": "multipart/form-data" } })
        dispatch(setSuccessMessage("Success! Project purchase request created."));
    } catch (err) {
        dispatch(setError(errorTypes.purchasesAddPurchaseProjectRequest, `${err.message ? err.message + " at " + errorTypes.purchasesAddPurchaseProjectRequest : "Something went wrong at " + errorTypes.purchasesAddPurchaseProjectRequest }`));
    } finally {
        dispatch(endLoading());
    }
};

export const updatePurchaseProjectRequest = (id, body) => async dispatch => {
    dispatch(startLoading(loadingTypes.purchasesUpdatePurchaseProjectRequest));
    try {
        await axiosInstance.post(`/purchases/updateProjectPurchase?requestId=${id}`, body,{ headers: { "Content-Type": "multipart/form-data" } });
        dispatch(setSuccessMessage("Success! Project purchase request updated."));
    } catch (err) {
        dispatch(setError(errorTypes.purchasesUpdatePurchaseProjectRequest, `${err.message ? err.message + " at " + errorTypes.purchasesUpdatePurchaseProjectRequest : "Something went wrong at " + errorTypes.purchasesUpdatePurchaseProjectRequest }`));
    } finally {
        dispatch(endLoading());
    }
};

export const deletePurchaseProjectRequest = (id) => async (dispatch, getState) => {
    const removePurchaseProjectRequest = () => {
        const requests = getState().purchasesReducer.purchaseProjects;
        const newPurchaseProjectRequests = requests.filter(
            request => request.requestid !== id
        );
        dispatch(setPurchaseProjects(newPurchaseProjectRequests));
    };

    dispatch(startLoading(loadingTypes.purchasesDeletePurchaseProjectRequest));
    try {
        await axiosInstance.delete(`purchases/deleteProjectPurchase/${id}`,);
        dispatch(setSuccessMessage("Success! Project purchase request cancelled."));
        removePurchaseProjectRequest();
    } catch (err) {
        dispatch(setError(errorTypes.purchasesDeletePurchaseProjectRequest, `${err.message ? err.message + " at " + errorTypes.purchasesDeletePurchaseProjectRequest : "Something went wrong at " + errorTypes.purchasesDeletePurchaseProjectRequest }`));
    } finally {
        dispatch(endLoading());
    }
}

export const updatePurchaseProjectRequestStatus = (id, data) => async dispatch => {
    dispatch(startLoading(loadingTypes.purchasesUpdatePurchaseProjectRequestStatus));
    try {
        await axiosInstance.post(`/purchases/updatePurchaseProjectStatus?requestId=${id}`, data);
        dispatch(setSuccessMessage("Success! Project purchase request status updated."));
    } catch (err) {
        dispatch(setError(errorTypes.purchasesUpdatePurchaseProjectRequestStatus, `${err.message ? err.message + " at " + errorTypes.purchasesUpdatePurchaseProjectRequestStatus : "Something went wrong at " + errorTypes.purchasesUpdatePurchaseProjectRequestStatus }`));
    } finally {
        dispatch(endLoading());
    }
};
//endregion
// END: Project Purchase

// START: Tender Purchase
//region
export const getPurchaseTenders = () => async dispatch => {
    dispatch(startLoading(loadingTypes.purchasesGetPurchaseTenders));
    try {
        const res = await axiosInstance.get("/purchases/getTenderPurchases");
        dispatch(setPurchaseTenders(res.data));
    } catch (err) {
        dispatch(setError(errorTypes.purchasesGetPurchaseTenders, `${err.message ? err.message + " at " + errorTypes.purchasesGetPurchaseTenders : "Something went wrong at " + errorTypes.purchasesGetPurchaseTenders }`));
    } finally {
        dispatch(endLoading());
    }
};

export const addPurchaseTenderRequest = (body) => async dispatch => {
    dispatch(startLoading(loadingTypes.purchasesAddPurchaseTenderRequest));
    try {
        await axiosInstance.post(`/purchases/addTenderPurchase`, body,{ headers: { "Content-Type": "multipart/form-data" } })
        dispatch(setSuccessMessage("Success! Tender purchase request created."));
    } catch (err) {
        dispatch(setError(errorTypes.purchasesAddPurchaseTenderRequest, `${err.message ? err.message + " at " + errorTypes.purchasesAddPurchaseTenderRequest : "Something went wrong at " + errorTypes.purchasesAddPurchaseTenderRequest }`));
    } finally {
        dispatch(endLoading());
    }
};

export const updatePurchaseTenderRequest = (id, body) => async dispatch => {
    dispatch(startLoading(loadingTypes.purchasesUpdatePurchaseTenderRequest));
    try {
        await axiosInstance.post(`/purchases/updateTenderPurchase?requestId=${id}`, body,{ headers: { "Content-Type": "multipart/form-data" } });
        dispatch(setSuccessMessage("Success! Tender purchase request updated."));
    } catch (err) {
        dispatch(setError(errorTypes.purchasesUpdatePurchaseTenderRequest, `${err.message ? err.message + " at " + errorTypes.purchasesUpdatePurchaseTenderRequest : "Something went wrong at " + errorTypes.purchasesUpdatePurchaseTenderRequest }`));
    } finally {
        dispatch(endLoading());
    }
};

export const deletePurchaseTenderRequest = (id) => async (dispatch, getState) => {
    const removePurchaseTenderRequest = () => {
        const requests = getState().purchasesReducer.purchaseTenders;
        const newPurchaseTenderRequests = requests.filter(
            request => request.requestId !== id
        );
        dispatch(setPurchaseTenders(newPurchaseTenderRequests));
    };

    dispatch(startLoading(loadingTypes.purchasesDeletePurchaseTenderRequest));
    try {
        await axiosInstance.delete(`purchases/deleteTenderPurchase/${id}`,);
        dispatch(setSuccessMessage("Success! Tender purchase request cancelled."));
        removePurchaseTenderRequest();
    } catch (err) {
        dispatch(setError(errorTypes.purchasesDeletePurchaseTenderRequest, `${err.message ? err.message + " at " + errorTypes.purchasesDeletePurchaseTenderRequest : "Something went wrong at " + errorTypes.purchasesDeletePurchaseTenderRequest }`));
    } finally {
        dispatch(endLoading());
    }
}

export const updatePurchaseTenderRequestStatus = (id, data) => async dispatch => {
    dispatch(startLoading(loadingTypes.purchasesUpdatePurchaseTenderRequestStatus));
    try {
        await axiosInstance.post(`/purchases/updateTenderPurchaseStatus?requestId=${id}`, data);
        dispatch(setSuccessMessage("Success! Tender purchase request status updated."));
    } catch (err) {
        dispatch(setError(errorTypes.purchasesUpdatePurchaseTenderRequestStatus, `${err.message ? err.message + " at " + errorTypes.purchasesUpdatePurchaseTenderRequestStatus : "Something went wrong at " + errorTypes.purchasesUpdatePurchaseTenderRequestStatus }`));
    } finally {
        dispatch(endLoading());
    }
};
//endregion
// END: Tender Purchase

// START: IT Equipment Purchase
//region
export const getPurchaseItEquipment = () => async dispatch => {
    dispatch(startLoading(loadingTypes.purchasesGetPurchaseItEquipment));
    try {
        const res = await axiosInstance.get("/purchases/getItPurchases");
        dispatch(setPurchaseItEquipment(res.data));
    } catch (err) {
        dispatch(setError(errorTypes.purchasesGetPurchaseItEquipment, `${err.message ? err.message + " at " + errorTypes.purchasesGetPurchaseItEquipment : "Something went wrong at " + errorTypes.purchasesGetPurchaseItEquipment }`));
    } finally {
        dispatch(endLoading());
    }
};

export const addPurchaseItEquipmentRequest = (body) => async dispatch => {
    dispatch(startLoading(loadingTypes.purchasesAddPurchaseItEquipmentRequest));
    try {
        await axiosInstance.post(`/purchases/addItPurchase`, body,{ headers: { "Content-Type": "multipart/form-data" } })
        dispatch(setSuccessMessage("Success! IT Equipment purchase request created."));
    } catch (err) {
        dispatch(setError(errorTypes.purchasesAddPurchaseItEquipmentRequest, `${err.message ? err.message + " at " + errorTypes.purchasesAddPurchaseItEquipmentRequest : "Something went wrong at " + errorTypes.purchasesAddPurchaseItEquipmentRequest }`));
    } finally {
        dispatch(endLoading());
    }
};

export const updatePurchaseItEquipmentRequest = (id, body) => async dispatch => {
    dispatch(startLoading(loadingTypes.purchasesUpdatePurchaseItEquipmentRequest));
    try {
        await axiosInstance.post(`/purchases/updateItPurchase?requestId=${id}`, body,{ headers: { "Content-Type": "multipart/form-data" } });
        dispatch(setSuccessMessage("Success! IT Equipment purchase request updated."));
    } catch (err) {
        dispatch(setError(errorTypes.purchasesUpdatePurchaseItEquipmentRequest, `${err.message ? err.message + " at " + errorTypes.purchasesUpdatePurchaseItEquipmentRequest : "Something went wrong at " + errorTypes.purchasesUpdatePurchaseItEquipmentRequest }`));
    } finally {
        dispatch(endLoading());
    }
};

export const deletePurchaseItEquipmentRequest = (id) => async (dispatch, getState) => {
    const removePurchaseItEquipmentRequest = () => {
        const requests = getState().purchasesReducer.purchaseItEquipment;
        const newPurchaseItEquipmentRequests = requests.filter(
            request => request.requestId !== id
        );
        dispatch(setPurchaseItEquipment(newPurchaseItEquipmentRequests));
    };

    dispatch(startLoading(loadingTypes.purchasesDeletePurchaseItEquipmentRequest));
    try {
        await axiosInstance.delete(`purchases/deleteItPurchase/${id}`,);
        dispatch(setSuccessMessage("Success! IT Equipment purchase request cancelled."));
        removePurchaseItEquipmentRequest();
    } catch (err) {
        dispatch(setError(errorTypes.purchasesDeletePurchaseItEquipmentRequest, `${err.message ? err.message + " at " + errorTypes.purchasesDeletePurchaseItEquipmentRequest : "Something went wrong at " + errorTypes.purchasesDeletePurchaseItEquipmentRequest }`));
    } finally {
        dispatch(endLoading());
    }
}

export const updatePurchaseItEquipmentRequestStatus = (id, data) => async dispatch => {
    dispatch(startLoading(loadingTypes.purchasesUpdatePurchaseItEquipmentRequestStatus));
    try {
        await axiosInstance.post(`/purchases/updateItPurchaseStatus?requestId=${id}`, data);
        dispatch(setSuccessMessage("Success! IT Equipment purchase request status updated."));
    } catch (err) {
        dispatch(setError(errorTypes.purchasesUpdatePurchaseItEquipmentRequestStatus, `${err.message ? err.message + " at " + errorTypes.purchasesUpdatePurchaseItEquipmentRequestStatus : "Something went wrong at " + errorTypes.purchasesUpdatePurchaseItEquipmentRequestStatus }`));
    } finally {
        dispatch(endLoading());
    }
};
//endregion
// END: IT Equipment Purchase

// START: Office Equipment Purchase
//region
export const getPurchaseOfficeEquipment = () => async dispatch => {
    dispatch(startLoading(loadingTypes.purchasesGetPurchaseOfficeEquipment));
    try {
        const res = await axiosInstance.get("/purchases/getOfficePurchases");
        dispatch(setPurchaseOfficeEquipment(res.data));
    } catch (err) {
        dispatch(setError(errorTypes.purchasesGetPurchaseOfficeEquipment, `${err.message ? err.message + " at " + errorTypes.purchasesGetPurchaseOfficeEquipment : "Something went wrong at " + errorTypes.purchasesGetPurchaseOfficeEquipment }`));
    } finally {
        dispatch(endLoading());
    }
};

export const addPurchaseOfficeEquipmentRequest = (body) => async dispatch => {
    dispatch(startLoading(loadingTypes.purchasesAddPurchaseOfficeEquipmentRequest));
    try {
        await axiosInstance.post(`/purchases/addOfficePurchase`, body,{ headers: { "Content-Type": "multipart/form-data" } })
        dispatch(setSuccessMessage("Success! Office Equipment purchase request created."));
    } catch (err) {
        dispatch(setError(errorTypes.purchasesAddPurchaseOfficeEquipmentRequest, `${err.message ? err.message + " at " + errorTypes.purchasesAddPurchaseOfficeEquipmentRequest : "Something went wrong at " + errorTypes.purchasesAddPurchaseOfficeEquipmentRequest }`));
    } finally {
        dispatch(endLoading());
    }
};

export const updatePurchaseOfficeEquipmentRequest = (id, body) => async dispatch => {
    dispatch(startLoading(loadingTypes.purchasesUpdatePurchaseOfficeEquipmentRequest));
    try {
        await axiosInstance.post(`/purchases/updateOfficePurchase?requestId=${id}`, body,{ headers: { "Content-Type": "multipart/form-data" } });
        dispatch(setSuccessMessage("Success! Office Equipment purchase request updated."));
    } catch (err) {
        dispatch(setError(errorTypes.purchasesUpdatePurchaseOfficeEquipmentRequest, `${err.message ? err.message + " at " + errorTypes.purchasesUpdatePurchaseOfficeEquipmentRequest : "Something went wrong at " + errorTypes.purchasesUpdatePurchaseOfficeEquipmentRequest }`));
    } finally {
        dispatch(endLoading());
    }
};

export const deletePurchaseOfficeEquipmentRequest = (id) => async (dispatch, getState) => {
    const removePurchaseOfficeEquipmentRequest = () => {
        const requests = getState().purchasesReducer.purchaseOfficeEquipment;
        const newPurchaseOfficeEquipmentRequests = requests.filter(
            request => request.requestId !== id
        );
        dispatch(setPurchaseOfficeEquipment(newPurchaseOfficeEquipmentRequests));
    };

    dispatch(startLoading(loadingTypes.purchasesDeletePurchaseOfficeEquipmentRequest));
    try {
        await axiosInstance.delete(`purchases/deleteOfficePurchase/${id}`,);
        dispatch(setSuccessMessage("Success! Office Equipment purchase request cancelled."));
        removePurchaseOfficeEquipmentRequest();
    } catch (err) {
        dispatch(setError(errorTypes.purchasesDeletePurchaseOfficeEquipmentRequest, `${err.message ? err.message + " at " + errorTypes.purchasesDeletePurchaseOfficeEquipmentRequest : "Something went wrong at " + errorTypes.purchasesDeletePurchaseOfficeEquipmentRequest }`));
    } finally {
        dispatch(endLoading());
    }
}

export const updatePurchaseOfficeEquipmentRequestStatus = (id, data) => async dispatch => {
    dispatch(startLoading(loadingTypes.purchasesUpdatePurchaseOfficeEquipmentRequestStatus));
    try {
        await axiosInstance.post(`/purchases/updateOfficePurchaseStatus?requestId=${id}`, data);
        dispatch(setSuccessMessage("Success! Office Equipment purchase request status updated."));
    } catch (err) {
        dispatch(setError(errorTypes.purchasesUpdatePurchaseOfficeEquipmentRequestStatus, `${err.message ? err.message + " at " + errorTypes.purchasesUpdatePurchaseOfficeEquipmentRequestStatus : "Something went wrong at " + errorTypes.purchasesUpdatePurchaseOfficeEquipmentRequestStatus }`));
    } finally {
        dispatch(endLoading());
    }
};
//endregion
// END: Office Equipment Purchase

// START: Other Purchase
//region
export const getPurchaseOthers = () => async dispatch => {
    dispatch(startLoading(loadingTypes.purchasesGetPurchaseOthers));
    try {
        const res = await axiosInstance.get("/purchases/getOtherPurchases");
        dispatch(setPurchaseOthers(res.data));
    } catch (err) {
        dispatch(setError(errorTypes.purchasesGetPurchaseOthers, `${err.message ? err.message + " at " + errorTypes.purchasesGetPurchaseOthers : "Something went wrong at " + errorTypes.purchasesGetPurchaseOthers }`));
    } finally {
        dispatch(endLoading());
    }
};

export const addPurchaseOtherRequest = (body) => async dispatch => {
    dispatch(startLoading(loadingTypes.purchasesAddPurchaseOtherRequest));
    try {
        await axiosInstance.post(`/purchases/addOtherPurchase`, body,{ headers: { "Content-Type": "multipart/form-data" } })
        dispatch(setSuccessMessage("Success! Other purchase request created."));
    } catch (err) {
        dispatch(setError(errorTypes.purchasesAddPurchaseOtherRequest, `${err.message ? err.message + " at " + errorTypes.purchasesAddPurchaseOtherRequest : "Something went wrong at " + errorTypes.purchasesAddPurchaseOtherRequest }`));
    } finally {
        dispatch(endLoading());
    }
};

export const updatePurchaseOtherRequest = (id, body) => async dispatch => {
    dispatch(startLoading(loadingTypes.purchasesUpdatePurchaseOtherRequest));
    try {
        await axiosInstance.post(`/purchases/updateOtherPurchase?requestId=${id}`, body,{ headers: { "Content-Type": "multipart/form-data" } });
        dispatch(setSuccessMessage("Success! Other purchase request updated."));
    } catch (err) {
        dispatch(setError(errorTypes.purchasesUpdatePurchaseOtherRequest, `${err.message ? err.message + " at " + errorTypes.purchasesUpdatePurchaseOtherRequest : "Something went wrong at " + errorTypes.purchasesUpdatePurchaseOtherRequest }`));
    } finally {
        dispatch(endLoading());
    }
};

export const deletePurchaseOtherRequest = (id) => async (dispatch, getState) => {
    const removePurchaseOtherRequest = () => {
        const requests = getState().purchasesReducer.purchaseOthers;
        const newPurchaseOtherRequests = requests.filter(
            request => request.requestId !== id
        );
        dispatch(setPurchaseOthers(newPurchaseOtherRequests));
    };

    dispatch(startLoading(loadingTypes.purchasesDeletePurchaseOtherRequest));
    try {
        await axiosInstance.delete(`purchases/deleteOtherPurchase/${id}`,);
        dispatch(setSuccessMessage("Success! Other purchase request cancelled."));
        removePurchaseOtherRequest();
    } catch (err) {
        dispatch(setError(errorTypes.purchasesDeletePurchaseOtherRequest, `${err.message ? err.message + " at " + errorTypes.purchasesDeletePurchaseOtherRequest : "Something went wrong at " + errorTypes.purchasesDeletePurchaseOtherRequest }`));
    } finally {
        dispatch(endLoading());
    }
}

export const updatePurchaseOtherRequestStatus = (id, data) => async dispatch => {
    dispatch(startLoading(loadingTypes.purchasesUpdatePurchaseOtherRequestStatus));
    try {
        await axiosInstance.post(`/purchases/updateOtherPurchaseStatus?requestId=${id}`, data);
        dispatch(setSuccessMessage("Success! Other purchase request status updated."));
    } catch (err) {
        dispatch(setError(errorTypes.purchasesUpdatePurchaseOtherRequestStatus, `${err.message ? err.message + " at " + errorTypes.purchasesUpdatePurchaseOtherRequestStatus : "Something went wrong at " + errorTypes.purchasesUpdatePurchaseOtherRequestStatus }`));
    } finally {
        dispatch(endLoading());
    }
};
//endregion
// END: Other Purchase