import * as actionTypes from "./actions";

const initialState = { loading: false };

const startLoading = action => ({ loading: { type: action.loading } });
const endLoading = () => initialState;

export default (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.START_LOADING:
			return startLoading(action);
		case actionTypes.END_LOADING:
			return endLoading();
		default:
			return state;
	}
};
