import axiosInstance from '../../assets/helpers/axiosInstance';
import { setSuccessMessage } from "../../store/success/actions";

export const CREATE_ASSET_START = 'assets/CREATE_ASSET_START'
export const CREATE_ASSET_SUCCESS = 'assets/CREATE_ASSET_SUCCESS'
export const CREATE_ASSET_FAIL = 'assets/CREATE_ASSET_FAIL'

export const UPDATE_ASSET_START = 'assets/UPDATE_ASSET_START'
export const UPDATE_ASSET_SUCCESS = 'assets/UPDATE_ASSET_SUCCESS'
export const UPDATE_ASSET_FAIL = 'assets/UPDATE_ASSET_FAIL'

export const GET_ASSETS_START = 'assets/GET_ASSETS_START'
export const GET_ASSETS_SUCCESS = 'assets/GET_ASSETS_SUCCESS'
export const GET_ASSETS_FAIL = 'assets/GET_ASSETS_FAIL'

export const DELETE_ASSETS_START = 'assets/DELETE_ASSETS_START'
export const DELETE_ASSETS_SUCCESS = 'assets/DELETE_ASSETS_SUCCESS'
export const DELETE_ASSETS_FAIL = 'assets/DELETE_ASSETS_FAIL'

export const createAsset = ({ data: asset }) => dispatch => {
	dispatch({ type: CREATE_ASSET_START })
	
	return axiosInstance.post('/asset/addSingleAssets', asset)
		.then(() => {
			dispatch({ type: CREATE_ASSET_SUCCESS })
			dispatch(setSuccessMessage('Success! Asset created.'))
		})
		.catch(error => {
			dispatch({
				type: CREATE_ASSET_FAIL,
				error,
			})
			throw error
		})
}

export const updateAsset = ({ data: updatedAsset }) => dispatch => {
	dispatch({ type: UPDATE_ASSET_START })

	return axiosInstance.post('/asset/updateAssets', updatedAsset)
		.then(res => {
			dispatch({ type: UPDATE_ASSET_SUCCESS, asset: res.data })
		})
		.catch(error => {
			dispatch({ type: UPDATE_ASSET_FAIL, error })
			throw error
		})
}

export const deleteAsset = assetNo => dispatch => {
	dispatch({ type: DELETE_ASSETS_START })

	return axiosInstance.delete(`/asset/deleteAssets/${assetNo}`)
		.then(() => {
			dispatch({ type: DELETE_ASSETS_SUCCESS, assetNo })
		})
		.catch(error => {
			dispatch({ type: DELETE_ASSETS_FAIL, error })
			throw error
		})
}

export const getAssets = () => dispatch => {
	dispatch({ type: GET_ASSETS_START })

	return axiosInstance.get('/asset/getAssets')
		.then(res => {
			if (res.headers['content-type'] !== 'application/json') {
				return dispatch({ type: GET_ASSETS_FAIL, error: new Error('Unknown data format, try logging out and in again.') })
			} 
		
			dispatch({ type: GET_ASSETS_SUCCESS, assets: res.data })
		})
		.catch(error => {
			dispatch({ type: GET_ASSETS_FAIL, error })
		})
}
