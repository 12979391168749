import React from 'react';
import { connect } from 'react-redux';
import Snackbar from '@material-ui/core/Snackbar';
import { Alert } from '@material-ui/lab';

import { hideError } from "../../store/error/actions";

const ErrorAlert = (props) => {
    return (
        props.error && <Snackbar
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            open={props.isOpen}
            autoHideDuration={5000}
            onClose={props.onHideError}
        >
            <Alert variant="filled" onClose={props.onHideError} severity="error">
                {props.error.errorMessage}
            </Alert>
        </Snackbar>
    );
};

const mapStateToProps = (state) => {
    return {
        error: state.errorReducer.error,
        isOpen: state.errorReducer.isOpen,
    }
};

const mapDispatchToProps = (dispatch) => ({ onHideError: () => dispatch(hideError()) });

export default connect(mapStateToProps, mapDispatchToProps)(ErrorAlert);
